const updateConstants = {
	UPDATE_TYPES: {
		PROJECT_CREATED: 'project_created',
		PROJECT_ENDED: 'project_ended',
		CONTEST_STARTED: 'contest_started',
		WINNER_SELECTED: 'winner_selected',
		CUSTOM: 'custom',
	},
	updateTypeToString: function (type) {
		switch (type) {
			case this.UPDATE_TYPES.PROJECT_CREATED:
				return 'Project created';
			case this.UPDATE_TYPES.PROJECT_ENDED:
				return 'Project ended';
			case this.UPDATE_TYPES.CONTEST_STARTED:
				return 'Contest started';
			case this.UPDATE_TYPES.WINNER_SELECTED:
				return 'Winner selected';
			case this.UPDATE_TYPES.CUSTOM:
				return 'Custom';
			default:
				return 'Unknown';
		}
	}
};

export default updateConstants;
