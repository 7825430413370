import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { CloudDownload, HourglassEmpty } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import JSZip from 'jszip';

import { PAYMENT } from '../../../../constants';
import { Auth, StringUtils } from '../../../../utils';

const styles = () => ({
	download: {
		display: 'inline-block',
	}
});

function BulkPayoutDownloadButton({ payouts, classes, enqueueSnackbar, style }) {
	const [downloading, setDownloading] = useState(false);

	const token = Auth.getValidStoredTokens();
	const accessToken = token ? token.accessToken : '';

	async function getPDFContent(url) {
		const downloadUrl = url + `?accessToken=${accessToken}`;

		try {
			const response = await fetch(downloadUrl);
			return await response.blob();
		} catch (e) {
			enqueueSnackbar('Could not get invoice PDF', { variant: 'error' });
			return null;
		}
	}

	async function downloadPayouts() {
		setDownloading(true);

		try {
			const zip = new JSZip();

			await Promise.all(payouts.map(async (payout) => {
				if ([
					PAYMENT.PAYOUT_STATUS.APPROVED,
					PAYMENT.PAYOUT_STATUS.PAID,
					PAYMENT.PAYOUT_STATUS.PAYMENT_RECEIVED
				].indexOf(payout.status) !== -1
				&& payout.invoiceAvailable) {
					const date = StringUtils.simpleDate(payout.createdAt);
					const fullName = StringUtils.capitalizeAll(payout.paymentInfo.firstName + ' ' + payout.paymentInfo.lastName);
					const fileName = `${
						(payout.invoiceCode && payout.invoiceCode !== '-') ? payout.invoiceCode + ' | ' : ''
					}${fullName} | ${date}.pdf`;
					const content = await getPDFContent(payout.invoiceUrl);

					if (content) {
						zip.file(fileName, content, { binary: true });
					}
				}
			}));

			const content = await zip.generateAsync({ type: 'base64' });
			window.location.href = 'data:application/zip;base64,' + content;
		} finally {
			setDownloading(false);
		}
	}

	return (
		<div className={classes.download} style={style}>
			<Tooltip title={(downloading ? 'Downloading...' : 'Download listed payouts\' invoices')}>
				<IconButton onClick={downloadPayouts} disabled={downloading}>
					{downloading ? <HourglassEmpty /> : <CloudDownload />}
				</IconButton>
			</Tooltip>
		</div>
	);
}

export default withSnackbar(withStyles(styles)(BulkPayoutDownloadButton));
