const expertConstants = {
	TYPE: {
		INDEPENDENT: 'independent',
		AGENCY: 'agency',
	},
	typeToString: function (type) {
		switch (type) {
			case this.TYPE.INDEPENDENT:
				return 'Independent';
			case this.TYPE.AGENCY:
				return 'Agency';
			default:
				return 'Unknown';
		}
	},
	AGENCY_TYPE: {
		ENGINEERING_FIRM: 'engineering_firm',
		PRODUCT_DESIGN_FIRM: 'product_design_firm',
		PRODUCT_AND_ENGINEERING_FIRM: 'product_and_engineering_firm',
		ORGANISED_GROUP: 'organised_group',
	},
	agencyTypeToString: function (agencyType) {
		switch (agencyType) {
			case this.AGENCY_TYPE.ENGINEERING_FIRM:
				return 'Engineering firm';
			case this.AGENCY_TYPE.PRODUCT_DESIGN_FIRM:
				return 'Product design agency';
			case this.AGENCY_TYPE.PRODUCT_AND_ENGINEERING_FIRM:
				return 'Product and engineering firm';
			case this.AGENCY_TYPE.ORGANISED_GROUP:
				return 'Organised group';
			default:
				return 'Unknown';
		}
	},
	EXPERTISE: {
		PRODUCT_DESIGN: 'product_design',
		MECHANICAL_ENGINEERING: 'mechanical_engineering',
		HARDWARE_ENGINEERING: 'hardware_engineering',
		FIRMWARE_ENGINEERING: 'firmware_engineering',
		ELECTRICAL_ENGINEERING: 'electrical_engineering',
		PRODUCTION_ENGINEERING: 'production_engineering',
		MAKER: 'maker',
		SUPPLIER: 'supplier',
	},
	expertiseToString: function (type) {
		switch (type) {
			case this.EXPERTISE.PRODUCT_DESIGN:
				return 'Product Design';
			case this.EXPERTISE.MECHANICAL_ENGINEERING:
				return 'Mechanical Engineering';
			case this.EXPERTISE.HARDWARE_ENGINEERING:
				return 'Hardware Engineering';
			case this.EXPERTISE.FIRMWARE_ENGINEERING:
				return 'Firmware Engineering';
			case this.EXPERTISE.ELECTRICAL_ENGINEERING:
				return 'Electrical Engineering';
			case this.EXPERTISE.PRODUCTION_ENGINEERING:
				return 'Production Engineering';
			case this.EXPERTISE.MAKER:
				return 'Maker';
			case this.EXPERTISE.SUPPLIER:
				return 'Supplier';
			default:
				return 'Unknown';
		}
	},
	SCORE: [0, 1, 2, 3, 4, 5, 6, 7, 8],
	FLAGS: {
		SKETCH: 'sketch',
		MODEL: 'model',
		ELECTRICAL: 'electrical',
		MECHANICAL: 'mechanical',
		PROTOTYPE: 'prototype',
		EVENTS: 'events',
	},
	YEARS_EXPERIENCE: {
		Y_0_STUDENT: 'y_0_student',
		Y_0_1: 'Y_0_1',
		Y_1_5: 'y_1_5',
		Y_5_10: 'y_5_10',
		Y_MORE_10: 'y_mode_10',
	},
	yearsToString: function (str) {
		switch (str) {
			case this.YEARS_EXPERIENCE.Y_0_STUDENT:
				return '0, I\'m an student';
			case this.YEARS_EXPERIENCE.Y_0_1:
				return '0-1 years';
			case this.YEARS_EXPERIENCE.Y_1_5:
				return '1-5 years';
			case this.YEARS_EXPERIENCE.Y_5_10:
				return '5-10 years';
			case this.YEARS_EXPERIENCE.Y_MORE_10:
				return 'More than 10 years';
			default:
				return 'Unknown';
		}
	},
	INVOICE_TYPE: {
		INDEPENDENT: 'independent',
		BUSINESS: 'business',
	},
	invoiceTypeToString: function (str) {
		switch (str) {
			case this.INVOICE_TYPE.INDEPENDENT:
				return 'Independent';
			case this.INVOICE_TYPE.BUSINESS:
				return 'Business';
			default:
				return 'Independent';
		}
	},
	FREELANCER_TIME: {
		LESS_THAN_2_YEARS: 'less_than_2_years',
		MORE_THAN_2_YEARS: 'more_than_2_years',
	},
	freelancerTimeToString: function (str) {
		switch (str) {
			case this.FREELANCER_TIME.LESS_THAN_2_YEARS:
				return 'Less than 2 years';
			case this.FREELANCER_TIME.MORE_THAN_2_YEARS:
				return 'More than 2 years';
			default:
				return 'Unknown';
		}
	},
	EUROPE_OPERATOR: {
		YES: 'yes',
		NO: 'no',
		COUNTRY_NOT_IN_EU: 'country_not_in_eu',
	},
	europeOperatorToString: function (str) {
		switch (str) {
			case this.EUROPE_OPERATOR.YES:
				return 'Yes';
			case this.EUROPE_OPERATOR.NO:
				return 'No';
			case this.EUROPE_OPERATOR.COUNTRY_NOT_IN_EU:
				return 'Country not in EU';
			default:
				return 'Unknown';
		}
	},
	USER_STATUS: {
		ANY: 'any',
		NOT_SIGNED_NDA: 'not_signed_nda',
		SIGNED_NDA_NOT_LOGGED_IN: 'signed_nda_not_logged_in',
		SIGNED_NDA_LOGGED_IN: 'signed_nda_logged_in'
	},
	userToUserStatus: function (user) {
		if (user.expert.ndaSigned) {
			if (user.lastLoggedAt) {
				return this.USER_STATUS.SIGNED_NDA_LOGGED_IN;
			} else {
				return this.USER_STATUS.SIGNED_NDA_NOT_LOGGED_IN;
			}
		} else {
			return this.USER_STATUS.NOT_SIGNED_NDA;
		}
	},
	userStatusToString: function (userStatus) {
		switch (userStatus) {
			case this.USER_STATUS.ANY:
				return 'Any';
			case this.USER_STATUS.NOT_SIGNED_NDA:
				return 'Not signed NDA';
			case this.USER_STATUS.SIGNED_NDA_NOT_LOGGED_IN:
				return 'Signed NDA + NOT logged in';
			case this.USER_STATUS.SIGNED_NDA_LOGGED_IN:
				return 'Signed NDA + logged in';
			default:
				return 'Unknown';
		}
	},
	hasSignedNdaToString: function (user) {
		return (user.expert.ndaSignedAt) ? 'Yes' : 'No';
	},
	hasLoggedInToString: function (user) {
		return (user.lastLoggedAt) ? 'Yes' : 'No';
	},
};

export default expertConstants;
