import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Checkbox, Chip, CircularProgress,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	FormControl, Select, MenuItem, TextField, Typography,
} from '@material-ui/core';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add, CalendarToday, Delete, Edit, GetApp, Visibility } from '@material-ui/icons';
import {
	Grid, Table, TableColumnResizing, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import ConfirmationDialog from '../../../../../components/dialog/confirmation-dialog';
import DatePicker from '../../../../../components/inputs/date-picker';
import { PROPOSAL } from '../../../../../constants';
import ServerAPI from '../../../../../services/server-api';

const styles = () => ({
	grid: {
		paddingLeft: "28px",
	},
	calendarIcon: {
		verticalAlign: "bottom",
		marginRight: "5px",
	},
	hiddenInput: {
		display: "none",
	},
	commentInput: {
		width: "100%",
	},
	checkbox: {
		"& div": {
			background: "#fff",
			paddingTop: "10px",
			paddingBottom: "10px",
		},
	},
	selectChip: {
		margin: "0px auto",
	},
	selectChipNo: {
		border: "2px solid #1976d2",
		backgroundColor: "transparent",
	},
	approvedSelect: {
		'&:before': {
			border: 'none !important',
        },
        '&:after': {
			border: 'none !important',
        }
	},
});

class ProposalDetail extends Component {
	state = {
		selectedFileVersion: {},
		uploading: false,
		openAddFileDialog: false,
		selectedUploadFile: null,
		selectedProposalFile: null,
		openDisapproveFileDialog: false,
		openEditFileDialog: false,
		openDeleteFileDialog: false,
	};
	
	onOpenAddFileDialog = (proposalFile) => {
		this.setState({
			openAddFileDialog: true,
			selectedUploadFile: {
				proposalFile,
			},
		});
	};
	
	onCloseAddFileDialog = () => {
		this.setState({
			openAddFileDialog: false,
			selectedUploadFile: null,
		});
	};
	
	onSelectedUploadFileChange = (e) => {
		const { target: { files } } = e;
		const { selectedUploadFile } = this.state
		return this.setState({
			selectedUploadFile: {
				...selectedUploadFile,
				file: (files.length > 0) ? files[0] : null
			},
		});
	};
	
	onConfirmAddFile = async () => {
		const { selectedUploadFile } = this.state;
		const { proposalFile, file } = selectedUploadFile;
		const { enqueueSnackbar, processValueChange, applyChanges, row: { proposal } } = this.props;
		
		this.setState({
			uploading: true,
		}, async () => {
			const result = await ServerAPI.addFileVersionToProposal(proposal.id, proposalFile.id, {
				filename: file.name,
				file: file,
			});
			
			// Close window
			this.onCloseAddFileDialog();
			
			this.setState({
				uploading: false,
			});
			
			if (result.error) return enqueueSnackbar(result.error.message || 'Could not create file version', { variant: 'error' });
			enqueueSnackbar('File version created', { variant: 'success' });
			
			await processValueChange({ file: { proposalFileId: proposalFile.id, } });
			applyChanges();
		});
	};
	
	onOpenDisapproveFileDialog = (proposalFile, fileVersion) => {
		this.setState({
			openDisapproveFileDialog: true,
			selectedProposalFile: {
				proposalFile,
				fileVersion
			},
		});
	};
	
	onCloseDisapproveFileDialog = () => {
		this.setState({
			openDisapproveFileDialog: false,
			selectedProposalFile: null,
		});
	};
	
	onConfirmDisapproveFile = async () => {
		const { enqueueSnackbar } = this.props;
		const { selectedProposalFile } = this.state;
		const { fileVersion } = selectedProposalFile;
		
		if (!fileVersion.comment || fileVersion.comment.length === 0) {
			return enqueueSnackbar('Comment can not be empty', { variant: 'error' });
		}
		
		this.onCloseDisapproveFileDialog();
		
		return this.updateFileVersion(selectedProposalFile, {
			approvedStatus: PROPOSAL.APPROVED_STATUS.NO,
			comment: selectedProposalFile.fileVersion.comment,
		});
	};
	
	updateFileVersion = async (selectedProposalFile, data = {}) => {
		const { proposalFile, fileVersion } = selectedProposalFile;
		const { enqueueSnackbar, processValueChange, applyChanges, row: { proposal } } = this.props;
		
		const result = await ServerAPI.updateFileVersionFromProposal(proposal.id, proposalFile.id, fileVersion.id, data);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update file version', { variant: 'error' });
		enqueueSnackbar('File version updated', { variant: 'success' });
		
		await processValueChange({ file: { proposalFileId: proposalFile.id, fileVersionId: fileVersion.id, ...data } });
		applyChanges();
	};
	
	onOpenEditFileDialog = (proposalFile, fileVersion) => {
		this.setState({
			openEditFileDialog: true,
			selectedProposalFile: {
				proposalFile,
				fileVersion
			},
		});
	};
	
	onCloseEditFileDialog = () => {
		this.setState({
			openEditFileDialog: false,
			selectedProposalFile: null,
		});
	};
	
	onChangeCreatedAt = (date) => {
		const { selectedProposalFile } = this.state;
		selectedProposalFile.fileVersion.createdAt = date;
 		this.setState({
			selectedProposalFile,
		});
	};
	
	onChangeComment = (e) => {
		const { target: { value } } = e;
		const { selectedProposalFile } = this.state;
		selectedProposalFile.fileVersion.comment = value;
		this.setState({
			selectedProposalFile
		});
	};
	
	onConfirmEditFile = async () => {
		const { selectedProposalFile } = this.state;
		
		this.onCloseEditFileDialog();
		
		return this.updateFileVersion(selectedProposalFile, {
			createdAt: selectedProposalFile.fileVersion.createdAt,
			comment: selectedProposalFile.fileVersion.comment
		});
	};
	
	onOpenDeleteFileDialog = (proposalFile, fileVersion) => {
		this.setState({
			openDeleteFileDialog: true,
			selectedProposalFile: {
				proposalFile,
				fileVersion
			},
		});
	};
	
	onCloseDeleteFileDialog = () => {
		this.setState({
			openDeleteFileDialog: false,
			selectedProposalFile: null,
		});
	};
	
	onConfirmDeleteFile = async () => {
		const { enqueueSnackbar, processValueChange, applyChanges, row: { proposal } } = this.props;
		const { selectedProposalFile } = this.state;
		const { proposalFile, fileVersion } = selectedProposalFile;
		
		// Close window
		this.onCloseDeleteFileDialog();
		
		const result = await ServerAPI.removeFileVersionFromProposal(proposal.id, proposalFile.id, fileVersion.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove file version', { variant: 'error' });
		enqueueSnackbar('File version deleted', { variant: 'success' });
		
		await processValueChange({ file: { proposalFileId: proposalFile.id, fileVersionId: fileVersion.id, deleted: true } });
		applyChanges();
	};
	
	onChangeApproved = async (e, proposalFile, fileVersion) => {
		const { target: { value } } = e;

		// Show popup to enter a comment
		if (value === PROPOSAL.APPROVED_STATUS.NO && !fileVersion.comment) {
			return this.onOpenDisapproveFileDialog(proposalFile, fileVersion);
		}
		
		return this.updateFileVersion({ proposalFile, fileVersion }, {
			approvedStatus: value,
		});
	};
	
	onChangePublished = async (e, proposalFile, fileVersion) => {
		const { target: { checked } } = e;
		
		return this.updateFileVersion({ proposalFile, fileVersion }, {
			published: !!checked,
		});
	};
	
	onChangeFileVersion = async (e, proposalFile) => {
		const { target: { value } } = e;
		const { selectedFileVersion } = this.state;
		selectedFileVersion[proposalFile.id] = value;
		this.setState({
			selectedFileVersion
		});
	};

	getSelectedFileVersionOrLatest = (proposalFile) => {
		const { selectedFileVersion } = this.state;
		
		let fileVersion;
		const selectedFileVersionId = selectedFileVersion[proposalFile.id];
		if (selectedFileVersionId) {
			fileVersion = proposalFile.versions.find(v => v.id === selectedFileVersionId);
		}
		if (!fileVersion) fileVersion = proposalFile.versions[proposalFile.versions.length - 1];
		
		return fileVersion;
	};

	render () {
		const { classes, row } = this.props;
		const { proposal, contest } = row;

		const {
			uploading,
			openAddFileDialog,
			selectedUploadFile,
			selectedProposalFile,
			openDisapproveFileDialog,
			openEditFileDialog,
			openDeleteFileDialog,
		} = this.state;
		
		const columns = [
			{ name: 'actions', title: 'Actions' },
			{ name: 'name', title: 'File Name' },
			{ name: 'version', title: 'Version' },
			{ name: 'approved', title: 'Approved' },
			{ name: 'comment', title: 'Comment' },
			{ name: 'published', title: 'Published' },
		];

		const widthsMap = { actions: 270, version: 140, approved: 120 };
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 180 : 100),
		}));

		const isWinningProposal = contest.selectedProposal
			&& contest.selectedProposal.id
			&& (proposal.id === contest.selectedProposal.id);

		const rows = proposal.files.map(proposalFile => {
			// Order by date ASC
			proposalFile.versions = proposalFile.versions
				.map(v => ({ ...v, createdAt: new Date(v.createdAt) }))
				.sort((a, b) => a.createdAt - b.createdAt);

			// Check if we can add a new version
			const lastVersion = PROPOSAL.getLastVersion(proposalFile);
			const isRejected = lastVersion && (lastVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.NO);
			const canAddNewVersion = isRejected || isWinningProposal;
			
			// Get selected version or latest
			const fileVersion = this.getSelectedFileVersionOrLatest(proposalFile);
			
			// Get file URLs
			const { file } = fileVersion;
			const { previsualizeUrl, downloadUrl } = ServerAPI.getFileUrls(file.id);
			
			return {
				fileVersion,
				actions: <div>
					<Tooltip title="Add file version">
						<span>
							<IconButton
								aria-label="Add file version"
								onClick={() => this.onOpenAddFileDialog(proposalFile, fileVersion)}
								disabled={!canAddNewVersion}>
								<Add />
							</IconButton>
						</span>
					</Tooltip>
					<Tooltip title="Preview">
						<a href={previsualizeUrl} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="Preview">
								<Visibility />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Download">
						<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="Download">
								<GetApp />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Edit">
						<IconButton aria-label="Edit" onClick={() => this.onOpenEditFileDialog(proposalFile, fileVersion)}>
							<Edit />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete file">
						<IconButton aria-label="Delete file" onClick={() => this.onOpenDeleteFileDialog(proposalFile, fileVersion)}>
							<Delete />
						</IconButton>
					</Tooltip>
				</div>,
				name: <div>
					<Tooltip title={dayjs(fileVersion.createdAt).format('DD/MM/YYYY')} className={classes.calendarIcon}>
						<CalendarToday />
					</Tooltip>
					{file.name}
				</div>,
				version: <FormControl variant="outlined">
					<Select
						className={classes.checkbox}
						onChange={(e) => this.onChangeFileVersion(e, proposalFile)}
						value={fileVersion.id}>
						{proposalFile.versions.map((v, i) => (
							<MenuItem key={i} value={v.id}>
								{(proposalFile.versions.length === (i + 1) ? 'Latest' : 'Version #' + (i + 1))}
							</MenuItem>
						))}
					</Select>
				</FormControl>,
				approved: <FormControl className={classes.formControl}>
					<Select
						className={classes.approvedSelect}
						labelId="approved-label"
						id="approved"
						name="approved"
						value={fileVersion.approvedStatus}
						onChange={(e) => this.onChangeApproved(e, proposalFile, fileVersion)}
						disabled={fileVersion.approvedStatus !== PROPOSAL.APPROVED_STATUS.PENDING}
					>
						<MenuItem value={PROPOSAL.APPROVED_STATUS.PENDING}>
							<Chip label="Pending" className={classes.selectChip} />
						</MenuItem>
						<MenuItem value={PROPOSAL.APPROVED_STATUS.YES}>
							<Chip label="Yes" color="primary" className={classes.selectChip} />
						</MenuItem>
						<MenuItem value={PROPOSAL.APPROVED_STATUS.NO}>
							<Chip label="No" className={classes.selectChip + ' ' + classes.selectChipNo} />
						</MenuItem>
					</Select>
				</FormControl>,
				comment: fileVersion.comment || '-',
				published: <Checkbox
					checked={fileVersion.published}
					onChange={(e) => this.onChangePublished(e, proposalFile, fileVersion)}
					name="published"
					color="primary"
					disabled={fileVersion.approvedStatus !== PROPOSAL.APPROVED_STATUS.YES}
					/>,
			};
		});
		
		const CustomTableRow = (props) => {
			const { row: { fileVersion } } = props;
			let color;
			switch (fileVersion.approvedStatus) {
				case PROPOSAL.APPROVED_STATUS.PENDING:
					color = '#f7a5b3';
					break;
				case PROPOSAL.APPROVED_STATUS.YES:
					color = '#4ca14863';
					break;
				case PROPOSAL.APPROVED_STATUS.NO:
					color = '#ffdce2';
					break;
				default:
					color = 'transparent';
					break;
			}
			
			return (
				<Table.Row
					{...props}
					style={{ backgroundColor: color }}
					/>
			);
		};
		
		return (
			<div className={classes.grid}>
				<Grid
					columns={columns}
					rows={rows}
					>
					<Table rowComponent={CustomTableRow} />
					<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
					<TableHeaderRow />
				</Grid>
				{selectedUploadFile && (
					<Dialog
						open={openAddFileDialog}
						onClose={this.onCloseAddFileDialog}
						fullWidth={true}
						>
						<DialogTitle>
							Add a new version of the file
						</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Select the file you want to upload
							</DialogContentText>
							<div className={classes.fileInput}>
								<input
									className={classes.hiddenInput}
									id="contained-button-file"
									type="file"
									onChange={this.onSelectedUploadFileChange}
									/>
								<label htmlFor="contained-button-file">
									{uploading ? (
										<CircularProgress />
									) : (
										<Button variant="contained" color="primary" component="span">
											Select file
										</Button>
									)}
									&nbsp;
									{selectedUploadFile.file && (
										<span>File selected: {selectedUploadFile.file.name}</span>
									)}
								</label>
							</div>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onCloseAddFileDialog} color="primary">
								Cancel
							</Button>
							<Button
								disabled={!selectedUploadFile.file || uploading}
								color="primary"
								onClick={this.onConfirmAddFile}>
								{uploading ? 'Uploading...' : 'Upload file version'}
							</Button>
						</DialogActions>
					</Dialog>
				)}
				{selectedProposalFile && (
					<Fragment>
						<Dialog
							open={openDisapproveFileDialog}
							onClose={this.onCloseDisapproveFileDialog}
							fullWidth={true}
							>
							<DialogTitle>
								Disapprove file version
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Add a comment with the reasons why this file version is not valid
								</DialogContentText>
								<FormControl className={classes.commentInput}>
									<Typography variant="subtitle2" gutterBottom>
										Comment
									</Typography>
									<TextField
										label="Comment"
										multiline
										defaultValue={selectedProposalFile.fileVersion.comment}
										variant="outlined"
										name="comment"
										onChange={this.onChangeComment}
										/>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.onCloseDisapproveFileDialog} color="primary">
									Cancel
								</Button>
								<Button
									color="primary"
									onClick={this.onConfirmDisapproveFile}>
									Update file version
								</Button>
							</DialogActions>
						</Dialog>
						<Dialog
							open={openEditFileDialog}
							onClose={this.onCloseEditFileDialog}
							fullWidth={true}
							>
							<DialogTitle>
								Edit file version
							</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Edit the file version of the proposal
								</DialogContentText>
								<Typography variant="subtitle2" gutterBottom>
									Created at
								</Typography>
								<DatePicker
									fixOverflow={true}
									container={false}
									date={new Date(selectedProposalFile.fileVersion.createdAt)}
									onChange={this.onChangeCreatedAt}
									/>
								{selectedProposalFile.fileVersion.approvedStatus === PROPOSAL.APPROVED_STATUS.NO && (
									<Fragment>
										<Typography variant="subtitle2" gutterBottom>
											Comment
										</Typography>
										<TextField
											label="Comment"
											multiline
											defaultValue={selectedProposalFile.fileVersion.comment}
											variant="outlined"
											name="comment"
											onChange={this.onChangeComment}
											/>
									</Fragment>
								)}
							</DialogContent>
							<DialogActions>
								<Button onClick={this.onCloseEditFileDialog} color="primary">
									Cancel
								</Button>
								<Button
									color="primary"
									onClick={this.onConfirmEditFile}>
									Update file version
								</Button>
							</DialogActions>
						</Dialog>
						<ConfirmationDialog
							title={'Are you sure you want to delete the file version?'}
							content={'This will permanently delete the file version.'}
							primaryText={'Delete file version'}
							secondaryText={'Cancel'}
							open={openDeleteFileDialog}
							onClose={this.onCloseDeleteFileDialog}
							onConfirm={this.onConfirmDeleteFile}
							/>
					</Fragment>
				)}
			</div>
		);
	}
};

ProposalDetail.propTypes = {
	row: PropTypes.object.isRequired,
};

ProposalDetail.defaultProps = {
	row: {},
};

export default withSnackbar(withStyles(styles)(ProposalDetail));
