import React, { Component } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';

class LoadingBox extends Component {
	render() {
		return (
			<Grid
				container
				spacing={0}
				direction="column"
				alignItems="center"
				justifyContent="center"
				style={{ height: '100%' }}
			>
				<Grid item xs={3}>
					<CircularProgress />
				</Grid>
			</Grid>
		);
	}
}

export default LoadingBox;
