import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, GetApp, Visibility } from '@material-ui/icons';
import {
	Grid, Table, TableColumnResizing, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import ServerAPI from '../../services/server-api';

const styles = theme => ({
	
});

class FileGrid extends Component {
	onOpenDeleteFileDialog(f) {
		const { onDelete } = this.props;
		onDelete(f);
	}
	
	render() {
		const { files } = this.props;
		
		const columns = [
			{ name: 'actions', title: 'Actions' },
			{ name: 'name', title: 'Name' },
			{ name: 'filename', title: 'File Name' },
			{ name: 'createdAt', title: 'Created at' },
		];

		const widthsMap = { actions: 180 };
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 180 : 100),
		}));

		const rows = files.map(f => {
			const { previsualizeUrl, downloadUrl } = ServerAPI.getFileUrls(f.id);

			return {
				actions: <div>
					<Tooltip title="Preview">
						<a href={previsualizeUrl} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="Preview">
								<Visibility />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Download">
						<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="Download">
								<GetApp />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Delete file">
						<IconButton aria-label="Delete file" onClick={() => this.onOpenDeleteFileDialog(f)}>
							<Delete />
						</IconButton>
					</Tooltip>
				</div>,
				name: f.title,
				filename: f.name,
				createdAt: dayjs(f.createdAt).format('DD MMMM YYYY'),
			};
		});
		
		return (
			<Grid
				columns={columns}
				rows={rows}
				>
				<Table />
				<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
				<TableHeaderRow />
			</Grid>
		);
	}
}

FileGrid.propTypes = {
	classes: PropTypes.object.isRequired,
	files: PropTypes.array.isRequired,
	onDelete: PropTypes.func,
};

FileGrid.defaultProps = {
	classes: {},
	files: [],
	onDelete() {},
};

export default withSnackbar(withStyles(styles)(FileGrid));
