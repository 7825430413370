import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Card, CardContent, CircularProgress, Typography,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Chip, Avatar, Tooltip,
} from '@material-ui/core';

import TransactionIcon from '@material-ui/icons/ArrowForward';

import TablePagination from '@material-ui/core/TablePagination';

import { withSnackbar } from 'notistack';

import { PAYMENT } from '../../../constants';
import PayoutStatus from './components/payout-status';
import PayoutInvoice from './components/payout-invoice';
import PayButton from './components/pay-button';
import BulkPayoutDownloadButton from './components/bulk-payout-download-button';
import Association from './components/association';

import DateRangePicker from '../../../components/inputs/date-range-picker';

import { ADMIN } from '../../../constants';
import UserUtils from '../../../utils/user';
import StringUtils from '../../../utils/string';
import ServerAPI from '../../../services/server-api';

const DEFAULT_CURRENCY = 'EUR';

function showWiseOptionsBasedOnUser() {
	return UserUtils.userHasAdminRole([
		ADMIN.ROLES.SUPERADMIN, ADMIN.ROLES.MANAGER,
	]);
}

const styles = theme => ({
	cardContent: {
		textAlign: 'left',
	},
	topNavigation: {
		marginLeft: '30px',
		fontSize: '16px',
		color: 'rgba(0, 0, 0, 0.87)',
		textDecoration: 'none',
		opacity: '0.5',
		'&:hover': {
			textDecoration: 'underline',
			opacity: '0.8',
		},
	},
	filterButton: {
		display: 'inline-block',
		marginRight: '10px',
		backgroundColor: '#EBEBEB',
		padding: '5px 10px 5px 10px',
		borderRadius: '50px',
		border: '1px solid transparent',
		cursor: 'pointer',
		
		"&:hover": {
			border: '1px solid #969696',
			backgroundColor: '#D6D6D6',
		}
	},
	activeFilterButton: {
		border: '1px solid #969696',
		backgroundColor: '#D6D6D6',
	},
	tableContainer: {
		marginTop: '20px',
	},
	table: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
	},
	netAmount: {
		color: '#ADADAD',
		fontSize: '12px',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '120px',
	},
	dates: {
		marginTop: '-10px',
		marginBottom: '-20px',
		display: 'inline-block',
	},
	datePicker: {
		justifyContent: 'right',
	},
	topButtons: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '2 2',
		alignItems: 'baseline',
	},
});

function PayoutsPage({ classes, enqueueSnackbar }) {
	const [result, setResult] = useState({ data: null, error: null, isLoading: true, totalCount: null });
	const [status, setStatus] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [humanReadableEndDate, setHumanReadableEndDate] = useState(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(50);
	const [balance, setBalance] = useState(null);
	const [payoutsSummary, setPayoutsSummary] = useState(null);
	const [neededToTransfer, setNeededToTransfer] = useState(0);

	const showWiseOptions = showWiseOptionsBasedOnUser();

	const getPayouts = useCallback(async (silent = true) => {
		if (!silent) {
			setResult(r => ({
				...r,
				data: null,
				error: null,
				isLoading: false,
				totalCount: null,
			}));
		}

		const currentResult = await ServerAPI.getPayouts(status, startDate, endDate, currentPage, pageSize);
		if (currentResult.error) {
			setResult(r => ({
				...r,
				data: null,
				error: currentResult.error,
				isLoading: false,
				totalCount: null,
			}));

			return enqueueSnackbar((currentResult.error && currentResult.error.message) || 'Error getting payouts', { variant: 'error' });
		}

		setResult(r => ({
			...r,
			data: currentResult.data.results,
			error: null,
			isLoading: false,
			totalCount: currentResult.data.totalCount,
		}));
	}, [status, startDate, endDate, currentPage, pageSize, enqueueSnackbar]);
	
	useEffect(() => {
		getPayouts();
	}, [getPayouts]);

	useEffect(() => {
		if (!payoutsSummary || !balance) return;

		const difference = (payoutsSummary.soon + payoutsSummary.ready) - balance.amount;
		setNeededToTransfer((difference > 0 ? difference : 0));
	}, [balance, payoutsSummary]);

	const getBalance = useCallback(async () => {
		if (!showWiseOptions) return;

		// We get the balance. We don't mind if there is an error
		const result = await ServerAPI.getWiseBalance();
		if (result.error) return;

		return setBalance({
			amount: result.data.amount.value,
			currency: result.data.amount.currency,
		});
	}, [showWiseOptions]);

	const getPayoutsSummary = useCallback(async () => {
		if (!showWiseOptions) return;

		// We get the summary. We don't mind if there is an error
		const result = await ServerAPI.getPayoutsSummary();
		if (result.error) return;

		return setPayoutsSummary({
			ready: result.data.ready,
			soon: result.data.soon,
		});
	}, [showWiseOptions]);

	// Get balance
	useEffect(() => {
		getBalance();
		getPayoutsSummary();
	}, [getBalance, getPayoutsSummary]);
	
	function handleChangePayoutStatus(silent = true) {
		getPayouts(silent);
		getBalance();
		getPayoutsSummary();
	}
	
	function handleChangePayoutInvoice() {
		getPayouts();
		getBalance();
		getPayoutsSummary();
	}

	function activeStatus(checkStatus) {
		return (checkStatus === status) ? classes.activeFilterButton : '';
	}

	function setHumanEndDate(date) {
		setHumanReadableEndDate(date);
		if (date) {
			date = new Date(date);
			date.setDate(date.getDate() + 1);
		}

		setEndDate(date);
	}

	return (
		<Card className={classes.root} variant="outlined">
			<CardContent className={classes.cardContent}>
				<Typography variant="h5" gutterBottom>
					Payouts

					{ showWiseOptions && balance &&
						<Tooltip title='Money available in the Wise balance'>
							<a href='https://wise.com' target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
								<Chip
									avatar={<Avatar alt='Wise balance' src='/assets/vendors/wise/wise-logo-color.png' />}
									label={`${StringUtils.numberFormat(balance.amount)} ${balance.currency} available`}
									variant='outlined'
									clickable
									style={{ marginLeft: 20, marginTop: -2 }}
								/>
							</a>
						</Tooltip>
					}

					{ showWiseOptions && balance && payoutsSummary &&
						<Tooltip title={`Ready to be paid: ${StringUtils.numberFormat(payoutsSummary.ready)} ${DEFAULT_CURRENCY}; Soon to be paid: ${StringUtils.numberFormat(payoutsSummary.soon)} ${DEFAULT_CURRENCY}`}>
							<a href='https://wise.com' target='_blank' rel='noreferrer' style={{ textDecoration: 'none' }}>
								<Chip
									icon={<TransactionIcon size='small' style={{ color: '#78FFAD'}} />}
									label={`${StringUtils.numberFormat(neededToTransfer)} ${balance.currency} needed to make transfers`}
									variant='outlined'
									disabled={(neededToTransfer <= 0)}
									clickable
									style={{ marginLeft: 10, marginTop: -2 }}
								/>
							</a>
						</Tooltip>
					}

					{ result.data && 
						<BulkPayoutDownloadButton
							payouts={result.data.map(r => r.payouts[0])}
							style={{ marginLeft: 10 }}
						/> 
					}
				</Typography>

				<div className={classes.topButtons}>
					<div className={classes.filterButton + ' ' + activeStatus('')} onClick={() => setStatus('')}>All</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.PENDING)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.PENDING)}>Pending by expert</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.APPROVED)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.APPROVED)}>Approved by expert</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.PAYING_PROGRAMMATIC)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.PAYING_PROGRAMMATIC)}>Paying</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.PAID)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.PAID)}>Paid</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.PAYMENT_RECEIVED)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.PAYMENT_RECEIVED)}>Payment Received</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.NO_PAYMENT_INFO)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.NO_PAYMENT_INFO)}>No payment information</div>
					<div className={classes.filterButton + ' ' + activeStatus(PAYMENT.PAYOUT_STATUS.CANCELED)} onClick={() => setStatus(PAYMENT.PAYOUT_STATUS.CANCELED)}>Canceled</div>

					<div className={classes.dates}>
						<DateRangePicker
							container={false}
							startDate={startDate}
							endDate={humanReadableEndDate}
							onChangeStart={(e) => setStartDate(e)}
							onChangeEnd={(e) => setHumanEndDate(e)}
							isClearable={true}
							placeholderText={'No date'}
							className={classes.datePicker}
						/>
					</div>
				</div>

				<TableContainer className={classes.tableContainer}>
					<Table aria-label="simple table" className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '130px' }}>Date</TableCell>
								<TableCell style={{ width: '130px' }}>User</TableCell>
								<TableCell style={{ width: '130px' }}>Created by</TableCell>
								<TableCell style={{ width: '130px' }}>Updated by</TableCell>
								<TableCell style={{ width: '230px' }}>Status</TableCell>
								<TableCell>Concept</TableCell>
								<TableCell style={{ width: '100px', textAlign: 'right' }}>Amount</TableCell>
								<TableCell style={{ width: '130px', textAlign: 'center' }}>Invoice</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{result.isLoading && (
								<TableRow>
									<TableCell colSpan={8}>
										<Box py={5} style={{ textAlign: "center" }}>
											<CircularProgress />
										</Box>
									</TableCell>
								</TableRow>
							)}
							{result.data && result.data.map(r => (
								<TableRow key={r.payouts[0].id} className={classes.row}>
									<TableCell style={{ width: '130px' }}>
										{dayjs(r.payouts[0].createdAt).format('DD MMM YYYY')}
									</TableCell>
									<TableCell style={{ width: '130px' }}>
										<Link to={`/payments/users/${r.id}/payouts`} style={{ color: 'rgba(0, 0, 0, 0.87)'}} title={`Open ${r.firstName}'s payouts page`}>
											{r.firstName} {r.lastName}
										</Link>
									</TableCell>
									{r.payouts[0].createdBy.firstName ? (<TableCell className={classes.ellipsis} title={`${r.payouts[0].createdBy.firstName} ${r.payouts[0].createdBy.lastName} (${r.payouts[0].createdBy.email})`}>
											{r.payouts[0].createdBy.firstName} {r.payouts[0].createdBy.lastName.charAt(0)}
										</TableCell>
									) : (
										<TableCell>{'-'}</TableCell>
									)}

									{r.payouts[0].updatedBy.firstName ? (
										<TableCell
											className={classes.ellipsis}
											title={`${r.payouts[0].updatedBy.firstName} ${r.payouts[0].updatedBy.lastName} (${r.payouts[0].updatedBy.email})`}
										>
											{r.payouts[0].updatedBy.firstName} {r.payouts[0].updatedBy.lastName.charAt(0)}
										</TableCell>
									) : (
										<TableCell>{'-'}</TableCell>
									)}

									<TableCell style={{ width: '250px' }}>
										<PayoutStatus
											payout={r.payouts[0]}
											onChange={handleChangePayoutStatus}
										/>

										{
											r.payouts[0].status === PAYMENT.PAYOUT_STATUS.APPROVED && showWiseOptions &&
											<PayButton payout={r.payouts[0]} onChangeStatus={handleChangePayoutStatus} />
										}
									</TableCell>
									<TableCell>
										<Association payout={r.payouts[0]} />
										{r.payouts[0].concept}
									</TableCell>
									<TableCell style={{ width: '100px', textAlign: 'right' }}>
										<span style={{ color: (r.payouts[0].amount > 0) ? '#119D1F' : '#B83539' }}>
											{r.payouts[0].amount}€
										</span>
										{r.payouts[0].netAmount && (
											<div className={classes.netAmount}>{r.payouts[0].netAmount}€</div>
										)}
									</TableCell>
									<TableCell>
										<PayoutInvoice
											payout={r.payouts[0]}
											onChange={handleChangePayoutInvoice}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>

			{!result.isLoading &&
				<TablePagination
					component="div"
					count={result.totalCount || 1}
					page={currentPage || 0}
					onPageChange={(e, p) => setCurrentPage(p)}
					rowsPerPage={pageSize}
					onRowsPerPageChange={(e) => setPageSize(e.target.value)}
					style={{ marginBottom: '15px' }}
				/>
			}
		</Card>
	);
}

export default withSnackbar(withStyles(styles)(PayoutsPage));
