import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import {
	Button, CircularProgress,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	TextField,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import ConfirmationDialog from '../../../components/dialog/confirmation-dialog';
import FileGrid from '../../../components/grid/file-grid';

import ServerAPI from '../../../services/server-api';

const styles = () => ({
	tableActions: {
		margin: "20px 0px",
	},
	dialogContent: {
		overflowY: "hidden",
	},
	hiddenInput: {
		display: "none",
	},
	fileInput: {
		marginTop: "20px",
	},
});

class TemplatesTab extends Component {
	state = {
		openAddFileDialog: false,
		uploading: false,
		selectedName: null,
		selectedFile: null,
		openDeleteFileDialog: false,
	};
	
	onOpenAddFileDialog = () => {
		this.setState({
			openAddFileDialog: true,
		});
	};
	
	onCloseAddFileDialog = () => {
		this.setState({
			openAddFileDialog: false,
			uploading: false,
			selectedName: null,
			selectedFile: null,
		});
	};
	
	onSelectedNameChange = (e) => {
		const { value } = e.target;
		return this.setState({
			selectedName: value,
		});
	};
	
	onSelectedFileChange = (e) => {
		const { files } = e.target;
		return this.setState({
			selectedFile: (files.length > 0) ? files[0] : null,
		});
	};
	
	onConfirmAddFileDialog = async () => {
		const { enqueueSnackbar, contestType, updateData } = this.props;
		const { selectedName, selectedFile } = this.state;

		if (!selectedName) return enqueueSnackbar('Name not valid', { variant: 'error' });

		this.setState({
			uploading: true,
		}, async () => {
			const result = await ServerAPI.addTemplate(contestType, {
				name: selectedName,
				filename: selectedFile.name,
				file: selectedFile,
			});
			
			// Close window
			this.onCloseAddFileDialog();
			
			this.setState({
				uploading: false,
			});
			
			if (result.error) return enqueueSnackbar(result.error.message || 'File could not be uploaded', { variant: 'error' });
			enqueueSnackbar('File added', { variant: 'success' });
			
			updateData();
		});
	};
	
	onOpenDeleteFileDialog = (f) => {
		this.setState({
			openDeleteFileDialog: true,
			selectedFile: f,
		});
	};
	
	onCloseDeleteFileDialog = () => {
		this.setState({
			openDeleteFileDialog: false,
			selectedFile: null,
		});
	};
	
	onConfirmDeleteFileDialog = async () => {
		const { enqueueSnackbar, contestType, updateData } = this.props;
		const { selectedFile } = this.state;
		
		// Close dialog
		this.onCloseDeleteFileDialog();
		
		const result = await ServerAPI.deleteTemplate(contestType, selectedFile.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove file', { variant: 'error' });
		enqueueSnackbar('File deleted', { variant: 'success' });
		
		// Update data
		updateData();
	};
	
	render() {
		const { classes, site, contestType } = this.props;
		const {
			openAddFileDialog,
			uploading,
			selectedName,
			selectedFile,
			openDeleteFileDialog,
		} = this.state;
		
		const { templates } = site.contests[contestType];
		
		return (
			<Fragment>
				<div className={classes.tableActions}>
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={this.onOpenAddFileDialog}>
						Add Template
					</Button>
					<FileGrid
						files={templates.map(t => t.file)}
						onDelete={this.onOpenDeleteFileDialog}
						/>
				</div>
				<Dialog
					fullWidth={true}
					open={openAddFileDialog}
					onClose={this.onCloseAddFileDialog}
					>
					<DialogTitle>
						Add a new template
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<DialogContentText>
							Select the file you want to upload
						</DialogContentText>
						<TextField
							label="Name"
							defaultValue={selectedName}
							variant="outlined"
							name="name"
							onChange={this.onSelectedNameChange}
							/>
						<div className={classes.fileInput}>
							<input
								className={classes.hiddenInput}
								id="contained-button-file"
								type="file"
								onChange={this.onSelectedFileChange}
								/>
							<label htmlFor="contained-button-file">
								{uploading ? (
									<CircularProgress />
								) : (
									<Button variant="contained" color="primary" component="span">
										Select file
									</Button>
								)}
								&nbsp;
								{selectedFile && (
									<span>File selected: {selectedFile.name}</span>
								)}
							</label>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseAddFileDialog} color="primary">
							Cancel
						</Button>
						<Button
							disabled={!selectedFile || uploading}
							color="primary"
							onClick={this.onConfirmAddFileDialog}>
							{uploading ? 'Uploading...' : 'Upload file'}
						</Button>
					</DialogActions>
				</Dialog>
				<ConfirmationDialog
					title={'Are you sure you want to delete the file?'}
					content={'This will permanently delete the file.'}
					primaryText={'Delete file'}
					secondaryText={'Cancel'}
					open={openDeleteFileDialog}
					onClose={this.onCloseDeleteFileDialog}
					onConfirm={this.onConfirmDeleteFileDialog}
				/>
			</Fragment>
		);
	}
}

TemplatesTab.propTypes = {
	classes: PropTypes.object.isRequired,
	site: PropTypes.object.isRequired,
	contestType: PropTypes.string.isRequired,
};

TemplatesTab.defaultProps = {
	classes: {},
	site: {},
	contestType: "",
};

export default withSnackbar(withStyles(styles)(TemplatesTab));
