import React from 'react';

const styles = {
	wrapper: {
		textAlign: 'left',
		margin: '40px 40px 20px 40px',
	},
	emptyMessage: {
		display: 'block',
		backgroundColor: '#F5F5F5',
		width: '30%',
		padding: '20px',
		borderRadius: '3px',
	},
	question: {
		marginBottom: '20px',
	},
	title: {
		fontSize: '17px',
		fontWeight: '500',
	},
	answer: {
		fontSize: '15px',
		color: '#A8A8A8',
	}
};

function ClientFeedback({ data }) {
	return (
		<div style={styles.question}>
			<div style={styles.title}>{ data.title }</div>
			<div style={styles.answer}>{ (data.type === 'text' ? data.value : data.value + '/5') }</div>
		</div>
	);
}

export default function ContestReviewTab({ contest }) {
	const clientReview = contest.clientReview;

	if (!clientReview || !clientReview.length) {
		return (
			<div style={styles.wrapper}>
				<div style={styles.emptyMessage}>
					Client has not given feedback to this contest yet
				</div>
			</div>
		);
	}

	return (
		<div style={styles.wrapper}>
			{ 
				clientReview.map(r => {
					return <ClientFeedback data={r} /> 
				})
			}
		</div>
	);
}
