import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { HistoryUtils } from '../utils';

function CustomRouter({
	basename,
	children,
	window
}) {
	const historyRef = useRef(createBrowserHistory({
		window
	}));

	const history = historyRef.current;
	const [state, setState] = useState({
		action: history.action,
		location: history.location
	});

	useEffect(() => {
		HistoryUtils.initHistoryUtils(history);
	}, [history]);

	useLayoutEffect(() => {
		history.listen(setState);
	}, [history]);

	return (
		<Router
			basename={basename}
			location={state.location}
			navigationType={state.action}
			navigator={history}>
			{children}
		</Router>
	);
}

export default CustomRouter;
