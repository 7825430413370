import { useEffect, useState } from 'react';
import Cookie from 'js-cookie';

import Config from '../config';
import { Auth } from '../utils';
import ServerAPI from '../services/server-api';

const SSO_COOKIE_NAME = 'sso_token_admin_' + getEnvironment(Config.environment);

function redirectToSSO(path = false) {
	const { location } = window
	const domain = location.host.replace('.lastbasic.com', '');
	if (!path) path = location.pathname + location.search;

	const encodedPath = encodeURIComponent(path);
	const redirectTo = `https://sso.lastbasic.com?domain=${domain}&path=${encodedPath}`;

	// Redirect
	location.href = redirectTo; 
}

function getEnvironment(environment) {
	switch (environment) {
		case 'development':
			return 'dev';
		case 'preproduction':
			return 'preprod';
		case 'production':
			return 'prod';
		default:
			return environment;
	}
}

function AuthWrapper({ children, active = false }) {
	const [authChecked, setAuthChecked] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!active) return setAuthChecked(true);
		checkToken();
	}, [active]);

	async function checkToken() {
		const token = Cookie.get(SSO_COOKIE_NAME);
		if (!token) return redirectToSSO();

		const result = await ServerAPI.tokenInfo(token);
		if (result.error) {
			if (result.error.message) return setError(result.error.message);
			return redirectToSSO();
		}
		
		// Save user data
		Auth.storeTokens(result.data);

		setAuthChecked(true);
	}

	if (error) {
		return (
			<div style={{ padding: '20px' }}>
				An error ocurred while validating your token: <i>{error}</i>. Try again
			</div>
		);
	}

	if (!authChecked) return null;

	return children;
}

export {
	SSO_COOKIE_NAME,
	redirectToSSO,
	AuthWrapper,
};
