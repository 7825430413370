import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { withSnackbar } from 'notistack';
import { Card, CardContent, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import LoadingBox from '../../../components/loading-box'
import { AntTabs, AntTab, TabPanel } from '../../../components/tabs/white-tabs';
import TemplatesTab from './templates-tab';
import GuidelinesTab from './guidelines-tab';

import { CONTEST } from '../../../constants';
import ServerAPI from '../../../services/server-api';
import { HistoryUtils, StringUtils } from '../../../utils';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	cardContent: {
		padding: "24px 0px",
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		padding: theme.spacing(2),
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	leftButtons: {
		flex: "1",
	},
	tabs: {
		padding: "16px 16px 0px 16px",
	},
	tableActions: {
		margin: "20px 0px",
	},
	hiddenInput: {
		display: "none",
	},
	fileInput: {
		marginTop: "20px",
	},
});

class LBContentPage extends Component {
	state = {
		isLoading: true,
		tabSelected: 'sketch',
		siteData: null,
	};
	
	updateSiteData = async () => {
		const results = await ServerAPI.getSite();
		if (results.error) return {};		
		return results.data;
	};

	updateData = async () => {
		const siteData = await this.updateSiteData();
		
		// Check if data changed
		if (deepEqual(this.state.siteData, siteData)) {
			return this.setState({
				isLoading: false,
			});
		};
		
		this.setState({
			isLoading: false,
			siteData,
		});
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();
	};
	
	goBack = () => {
		HistoryUtils.goBackOrPush({
			pathname: '/projects',
		});
	};
	
	onChangeTab = (e, v) => {
		this.setState({
			tabSelected: v,
		});
	};
	
	render() {
		const { classes } = this.props;
		const {
			isLoading,
			tabSelected,
			siteData,
		} = this.state;
		const contestNames = Object.values(CONTEST.CONTEST_TYPES);
		
		if (isLoading) return <LoadingBox />;
		
		return (
			<Card className={classes.root} variant="outlined">
				<CardContent className={classes.cardContent}>
					<Typography variant="h5" gutterBottom>
						LB Content
					</Typography>
					<AntTabs className={classes.tabs} value={tabSelected} onChange={this.onChangeTab}>
						{contestNames.map(v => (
							<AntTab
								key={v}
								label={<span className={classes.iconTab}>{StringUtils.capitalizeAll(v)}</span>}
								value={v}
							/>
						))}
					</AntTabs>
					{contestNames.map(v => (
						<TabPanel className={classes.tabPanel} value={tabSelected} index={v} key={v}>
							<TemplatesTab
								site={siteData}
								contestType={v}
								updateData={this.updateData}
								/>
							<GuidelinesTab
								site={siteData}
								contestType={v}
								updateData={this.updateData}
								/>
						</TabPanel>
					))}
				</CardContent>
			</Card>
		);
	}
}

LBContentPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

LBContentPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(LBContentPage));
