import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';

import { PROJECT } from '../../../../constants';
import DateRangePicker from '../../../../components/inputs/date-range-picker';
import LoadingBox from '../../../../components/loading-box';
import Stat from '../components/stat';
import { buildFunnelData } from './funnel';

import ServerAPI from '../../../../services/server-api';

HighchartsSankey(Highcharts);

const styles = (theme) => ({
	root: {
		margin: "10px 20px",
		[theme.breakpoints.down('sm')]: {
			margin: "10px",
		},
	},
	datePicker: {
		padding: '0px'
	},
	cardContent: {
		padding: '10px 0px !important',
	},
});

class ProjectsPanel extends Component {
	state = {
		isLoading: true,
		startTime: null,
		endTime: null,
		projectsFunnelData: [],
		projectsCount: 0,
		activeProjectsCount: 0,
		feasibleProjectsCount: 0,
		paidProjectsCount: 0,
		projectFeasibilityRatio: 0,
		projectPaidRatio: 0,
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();
	};
	
	getFunnelData = (projectsList) => {
		// Get typeforms
		const typeforms = {};
		const campaigns = {};
		for (const p of projectsList) {
			const { form } = p;			
			if (!form) continue;
			const { definition, hidden } = form.formResponse;
			
			if (definition) {
				const { id, title } = definition;
				typeforms[id] = title;				
			}
			
			if (hidden) {
				const { utmCampaign } = hidden;
				if (utmCampaign) campaigns[utmCampaign] = utmCampaign;
			}
		}
				
		const steps = [
			{
				...Object.fromEntries(Object.entries(campaigns).map(([id]) => {
					return [
						'Campaign: ' + id,
						{
							filter: (p) => {
								const { form } = p;
								return form && form.formResponse.hidden && form.formResponse.hidden.utmCampaign === id;
							}
						}
					];
				})),
				'Unknown Source': {
					filter: (p) => {
						const { form } = p;
						return !form || !form.formResponse.hidden || !form.formResponse.hidden.utmCampaign;
					}
				}
			},
			{
				...Object.fromEntries(Object.entries(typeforms).map(([id, title]) => {
					return [
						title,
						{
							filter: (p) => {
								const { form } = p;
								return form && form.formResponse.definition.id === id;
							}
						}
					];
				})),
				'Unknown Typeform': {
					filter: (p) => {
						const { form } = p;
						return !form || !form.formResponse.definition || !form.formResponse.definition.id;
					}
				},
			},
			{
				'Feasible': {
					filter: (p) => {
						return p.feasibility === PROJECT.FEASIBILITY.YES;
					},
				},
				'Pending': {
					filter: (p) => {
						return p.feasibility === PROJECT.FEASIBILITY.PENDING;
					},
				},
				'Not Feasible': {
					filter: (p) => {
						return p.feasibility === PROJECT.FEASIBILITY.NO;
					},
				}
			},
			{
				'Paid': {
					filter: (p) => {
						return p.paid;
					},
				},
				'Not paid': {
					filter: (p) => {
						return !p.paid;
					}
				}
			}
		];

		// Build funnel
		return buildFunnelData(steps, projectsList);
	};

	getMetrics = async (newState) => {
		let { startTime, endTime } = { ...this.state, ...newState };
		startTime = startTime || new Date('1970-01-01');
		endTime = endTime || new Date('2099-01-01');

		const results = await ServerAPI.getProjectsMetrics({ startTime, endTime });
		if (results.error) return null;

		const projectsFunnelData = this.getFunnelData(results.data.projectsFunnel.projects);
		const {
			projectsCount,
			activeProjectsCount,
			feasibleProjectsCount,
			paidProjectsCount,
			projectFeasibilityRatio,
			projectPaidRatio,
		} = results.data;
		
		return {
			projectsFunnelData,
			projectsCount,
			activeProjectsCount,
			feasibleProjectsCount,
			paidProjectsCount,
			projectFeasibilityRatio,
			projectPaidRatio,
		};
	};

	updateData = async (newState = {}) => {
		const metricsData = await this.getMetrics(newState);
		if (!metricsData) return;

		const {
			projectsFunnelData,
			projectsCount,
			activeProjectsCount,
			feasibleProjectsCount,
			paidProjectsCount,
			projectFeasibilityRatio,
			projectPaidRatio,
		} = metricsData;

		this.setState({
			...newState,
			isLoading: false,
			projectsFunnelData,
			projectsCount,
			activeProjectsCount,
			feasibleProjectsCount,
			paidProjectsCount,
			projectFeasibilityRatio,
			projectPaidRatio,
		});
	};

	setStartTime = (val) => {
		this.updateData({ startTime: val });
	};

	setEndTime = (val) => {
		this.updateData({ endTime: val });
	};

	render() {
		const { classes } = this.props;
		const {
			isLoading,
			startTime,
			endTime,
			projectsFunnelData,
			projectsCount,
			activeProjectsCount,
			feasibleProjectsCount,
			paidProjectsCount,
			projectFeasibilityRatio,
			projectPaidRatio,
		} = this.state;
		
		if (isLoading) {
			return <LoadingBox />;
		}
		
		return (
			<Box className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Card variant="outlined">
							<CardContent className={classes.cardContent}>
								<DateRangePicker
									className={classes.datePicker}
									container={false}
									isClearable={true}
									startDate={startTime}
									endDate={endTime}
									onChangeStart={(e) => this.setStartTime(e)}
									onChangeEnd={(e) => this.setEndTime(e)}
									placeholderText={'No date'}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card variant="outlined">
							<CardContent className={classes.cardContent}>
								{projectsCount ? (
									<HighchartsReact
										highcharts={Highcharts}
										options={{
											title: {
												text: 'Total Projects Funnel'
											},
											series: [{
												name: "Project flow",
												type: "sankey",
												keys: ["from", "to", "weight"],
												data: projectsFunnelData,
											}]
										}}
									/>
								) : (
									<Typography>No projects to show in funnel</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Total Briefings'}
							data={projectsCount}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Feasible Projects'}
							data={feasibleProjectsCount}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Paid Projects'}
							data={paidProjectsCount}
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Active Projects'}
							data={activeProjectsCount}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Briefing feasibility ratio'}
							data={Math.round(projectFeasibilityRatio * 100) + '%'}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Briefing conversion ratio'}
							data={Math.round(projectPaidRatio * 100) + '%'}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default withStyles(styles)(ProjectsPanel);
