import React, { Component } from 'react';

import AbstractRoute from './abstract-route';

class PrivateRoute extends Component {
	render() {
		const props = this.props;
		
		return (
			<AbstractRoute
				{...props}
				isPrivate={true}
				redirectTo={'/login'}
			/>
		);
	}
}

export default PrivateRoute;
