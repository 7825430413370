import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSankey from 'highcharts/modules/sankey';

import DateRangePicker from '../../../../components/inputs/date-range-picker';
import LoadingBox from '../../../../components/loading-box';
import Stat from '../components/stat';
import { buildFunnelData } from './funnel';

import ServerAPI from '../../../../services/server-api';

HighchartsSankey(Highcharts);

const styles = (theme) => ({
	root: {
		margin: "10px 20px",
		[theme.breakpoints.down('sm')]: {
			margin: "10px",
		},
	},
	datePicker: {
		padding: '0px'
	},
	cardContent: {
		padding: '10px 0px !important'		
	}
});

class ExpertsPanel extends Component {
	state = {
		isLoading: true,
		startTime: null,
		endTime: null,
		expertsFunnelData: [],
		expertsCount: 0,
		expertsWithNdaCount: 0,
		loggedInExpertsCount: 0,
		proposalValidationRatio: 0,
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();
	};
	
	getFunnelData = (expertList) => {
		// Get typeforms
		const typeforms = {};
		const campaigns = new Set();
		for (const u of expertList) {
			const { form } = u.expert;			
			if (!form) continue;
			const { definition, hidden } = form.formResponse;
			
			if (definition) {
				const { id, title } = definition;
				typeforms[id] = title;				
			}
			if (hidden) {
				const { utmCampaign } = hidden;
				if (utmCampaign) {
					campaigns.add(utmCampaign);
				}
			}
		}

		const steps = [
			{
				...Object.fromEntries([...campaigns].map(campaignId => {
					return [
						`Campaign: ${campaignId}`,
						{
							filter: (u) => {
								const { form } = u.expert;
								return form && form.formResponse.hidden && form.formResponse.hidden.utmCampaign === campaignId;
							}
						}
					];
				})),
				'Unknown Source': {
					filter: (u) => {
						const { form } = u.expert;
						return !form || !form.formResponse.hidden || !form.formResponse.hidden.utmCampaign;
					}
				}
			},
			{
				...Object.fromEntries(Object.entries(typeforms).map(([id, title]) => {
					return [
						title,
						{
							filter: (u) => {
								const { form } = u.expert;
								return form && form.formResponse.definition.id === id;
							}
						}
					];
				})),
				'Unknown Typeform': {
					filter: (u) => {
						const { form } = u.expert;
						return !form || !form.formResponse.definition || !form.formResponse.definition.id;
					}
				},
			},
			{
				'Signed NDA': {
					filter: (u) => {
						return u.expert.ndaSignedAt;
					},
				},
				'Not Signed NDA': {
					filter: (u) => {
						return !u.expert.ndaSignedAt;
					},
				}
			},
			{
				'Logged in': {
					filter: (u) => {
						return u.lastLoggedAt;
					},
				},
				'Not logged in': {
					filter: (u) => {
						return !u.lastLoggedAt;
					}
				}
			}
		];

		// Build funnel
		return buildFunnelData(steps, expertList);
	};

	getMetrics = async (newState) => {
		let { startTime, endTime } = { ...this.state, ...newState };
		startTime = startTime || new Date('1970-01-01');
		endTime = endTime || new Date('2099-01-01');

		const results = await ServerAPI.getExpertsMetrics({ startTime, endTime });
		if (results.error) return null;

		const expertsFunnelData = this.getFunnelData(results.data.expertsFunnel.experts);
		const expertsCount = results.data.expertsCount;
		const expertsWithNdaCount = results.data.expertsWithNdaCount;
		const loggedInExpertsCount = results.data.loggedInExpertsCount;
		const proposalValidationRatio = results.data.proposalValidationRatio;
		
		return {
			expertsFunnelData,
			expertsCount,
			expertsWithNdaCount,
			loggedInExpertsCount,
			proposalValidationRatio,
		};
	};

	updateData = async (newState = {}) => {
		const metricsData = await this.getMetrics(newState);
		if (!metricsData) return;

		const {
			expertsFunnelData,
			expertsCount,
			expertsWithNdaCount,
			loggedInExpertsCount,
			proposalValidationRatio,
		} = metricsData;

		this.setState({
			...newState,
			isLoading: false,
			expertsFunnelData,
			expertsCount,
			expertsWithNdaCount,
			loggedInExpertsCount,
			proposalValidationRatio,
		});
	};

	setStartTime = (val) => {
		this.updateData({ startTime: val });
	};

	setEndTime = (val) => {
		this.updateData({ endTime: val });
	};

	render() {
		const { classes } = this.props;
		const {
			isLoading,
			startTime,
			endTime,
			expertsFunnelData,
			expertsCount,
			expertsWithNdaCount,
			loggedInExpertsCount,
			proposalValidationRatio,
		} = this.state;
		
		if (isLoading) {
			return <LoadingBox />;
		}
		
		return (
			<Box className={classes.root}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Card variant="outlined">
							<CardContent className={classes.cardContent}>
								<DateRangePicker
									className={classes.datePicker}
									container={false}
									isClearable={true}
									startDate={startTime}
									endDate={endTime}
									onChangeStart={(e) => this.setStartTime(e)}
									onChangeEnd={(e) => this.setEndTime(e)}
									placeholderText={'No date'}
								/>
							</CardContent>
						</Card>
					</Grid>
					<Grid item xs={12}>
						<Card variant="outlined">
							<CardContent className={classes.cardContent}>
								{expertsCount ? (
									<HighchartsReact
										highcharts={Highcharts}
										options={{
											title: {
												text: 'Total Experts Funnel'
											},
											series: [{
												name: "Expert flow",
												type: "sankey",
												keys: ["from", "to", "weight"],
												data: expertsFunnelData,
											}]
										}}
									/>
								) : (
									<Typography>No experts to show in funnel</Typography>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Total Experts'}
							data={expertsCount}
							/>
					</Grid>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Signed NDA'}
							data={expertsWithNdaCount}
						/>
					</Grid>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Logged in'}
							data={loggedInExpertsCount}
						/>					
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item md={4} xs={12}>
						<Stat
							label={'Approved proposal ratio'}
							data={(proposalValidationRatio * 100) + '%'}
						/>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default withStyles(styles)(ExpertsPanel);
