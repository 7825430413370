import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
	Tooltip, Dialog, DialogTitle, DialogActions, DialogContent, Button,
	CircularProgress, Checkbox, Select, MenuItem
} from '@material-ui/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { withSnackbar } from 'notistack';

import ServerAPI from '../../../../services/server-api';

import { PAYMENT } from '../../../../constants';

const INVOICE_DESCRIPTION = 'LastBasic Payout';

const ACCEPTED_CURRENCIES = [
	'EUR',
	'USD',
	'INR',
	'GBP',
];

const styles = () => ({
	button: {
		marginLeft: 10,
		display: 'inline-block',
		width: 32,
		height: 32,
		borderRadius: 100,
		color: 'white',
		backgroundColor: 'rgb(56, 82, 125)',
		verticalAlign: 'middle',
		paddingTop: 7.5,
		paddingLeft: 7,
		textAlign: 'center',
		fontSize: 12,
		cursor: 'pointer',

		'&:hover': {
			opacity: 0.9,
		}
	},
	wiseLogo: {
		width: 18,
		height: 18,
		backgroundImage: 'url(/assets/vendors/wise/wise-logo.png)',
		backgroundSize: 18,
	},
	loadingMessage: {
		marginTop: 10,
		textAlign: 'center',
	},
	loadingBox: {
		marginBottom: 20,
		textAlign: 'center',
		/* backgroundColor: '#F5F5F5', */
		padding: 30,
		userSelect: 'none',
		color: '#5C5C5C',
	},
	quoteItem: {
		marginBottom: 15,
		padding: 20,
		textAlign: 'center',
		backgroundColor: 'transparent',
		borderRadius: 6,
		display: 'inline-block',
		verticalAlign: 'top',
		color: '#333',
		border: '2px solid #5788C9',
		width: 'calc(48% - 12px)',
	},
	quoteTitle: {
		color: 'inherit',
		opacity: 0.8,
		marginBottom: 4,
		fontSize: 13,
	},
	quoteData: {
		fontSize: 29,
		opacity: 0.9,
		color: 'inherit',
		fontWeight: 500,
	},
	quoteDataDetail: {
		fontSize: 15,
		opacity: 0.5,
		color: '#333',
		display: 'inline-block',
		marginLeft: 10,
	},
	quoteInformation: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		marginBottom: 0,
		alignItems: 'center',
	},
	quoteSource: {
		backgroundColor: '#fff',
		border: '2px dashed #D9D9D9',
		color: '#595959',
	},
	info: {
		fontSize: 12,
		opacity: 0.8,
		marginBottom: 20,
		padding: 10,
		backgroundColor: '#FAEEAD',
		borderRadius: 6,
	},
	fee: {
		marginBottom: 17,
		fontSize: 14,
		opacity: 0.7,
		marginLeft: 5,
	},
	confirmationCheck: {
		marginLeft: -7,
		cursor: 'pointer',
	},
	arrow: {
		display: 'inline-block',
		width: 24,
		height: 24,
		paddingRight: 10,
		marginBottom: 12,
	},
});

function PayButton({ payout, classes, enqueueSnackbar, onChangeStatus }) {
	const [quoteModalOpen, setQuoteModalOpen] = useState(false);
	const [transferDisabled, setTransferDisabled] = useState(true);
	const [invoiceId, setInvoiceId] = useState(payout.invoice);
	const [quoteLoaded, setQuoteLoaded] = useState(false);
	const [quote, setQuote] = useState({});
	const [understood, setUnderstood] = useState(false);
	const [makingTransfer, setMakingTransfer] = useState(false);
	const [currencyModalOpen, setCurrencyModalOpen] = useState(false);
	const [currency, setCurrency] = useState(ACCEPTED_CURRENCIES[0]);

	async function openQuoteModal() {
		// Set states
		setQuoteLoaded(false);
		setQuote(false);
		setTransferDisabled(true);
		setUnderstood(false);
		setMakingTransfer(false);

		// Open modal
		setQuoteModalOpen(true);

		// Create invoice if not created
		let quotableInvoiceId;
		if (!invoiceId) {
			const resultInvoice = await createInvoice();
			if (!resultInvoice || !resultInvoice.data.id) return setQuoteModalOpen(false);

			quotableInvoiceId = resultInvoice.data.id;
			setInvoiceId(quotableInvoiceId)
			onChangeStatus();
		} else {
			quotableInvoiceId = invoiceId;
		}

		// Create quote
		const resultQuote = await createQuote(quotableInvoiceId);
		if (!resultQuote) return setQuoteModalOpen(false);

		// Set quote
		setQuoteLoaded(true);
		setQuote(resultQuote.data);

		return;
	}

	function closeQuoteModal() {
		return setQuoteModalOpen(false);
	}

	function openCurrencyModal() {
		return setCurrencyModalOpen(true);
	}

	function closeCurrencyModal() {
		return setCurrencyModalOpen(false);
	}

	async function createInvoice() {
		const object = {
			payoutId: payout.id,
			type: PAYMENT.INVOICE_TYPES.PAYOUT,
			direction: PAYMENT.INVOICE_DIRECTIONS.OUT,
			amountInCents: (payout.netAmount * 100),
			description: INVOICE_DESCRIPTION,
			destinationCurrency: currency,
		};

		const result = await ServerAPI.createInvoice(object);
		if (result.error) {
			enqueueSnackbar(result.error.message || 'Invoice could not be created for this payout', { variant: 'error' })
			return null;
		}

		return result;
	}

	async function createQuote(invoiceId) {
		const result = await ServerAPI.createQuote(invoiceId);
		if (result.error) {
			enqueueSnackbar(result.error.message || 'Quote could not be created', { variant: 'error' })
			return null;
		}

		return result;
	}

	function updateUnderstood(e) {
		setUnderstood(e.target.checked);
		setTransferDisabled(!e.target.checked);
	}

	async function makeTransfer() {
		setMakingTransfer(true);

		const result = await ServerAPI.createTransfer(invoiceId);
		if (result.error) {
			setMakingTransfer(false);
			enqueueSnackbar(result.error.message || 'Transfer could not be made', { variant: 'error' })
			return null;
		}

		// Since transfer updates payout status, we update the page
		onChangeStatus(false);
		closeQuoteModal();
		setMakingTransfer(false);

		return enqueueSnackbar('Transfer made successfully', { variant: 'success' });
	}

	function selectCurrency() {
		closeCurrencyModal();
		openQuoteModal();
	}

	function handleCurrencyChange(e) {
		const value = e.target.value;
		setCurrency(value);
	}

	return (
		<Fragment>
			<Tooltip title='Pay with Wise'>
				<div className={classes.button} onClick={(invoiceId ? openQuoteModal : openCurrencyModal)}>
					<div className={classes.wiseLogo}></div>
				</div>
			</Tooltip>

			<Dialog
				open={currencyModalOpen}
				onClose={closeCurrencyModal}
				fullWidth
			>
			<DialogTitle>
				Pay with Wise
			</DialogTitle>
			<DialogContent>
				Select currency for {payout.paymentInfo.firstName} {payout.paymentInfo.lastName}, which lives in {payout.paymentInfo.countryResidence}
				<br/><br/>
				<Select value={currency} onChange={handleCurrencyChange}>
					{ ACCEPTED_CURRENCIES.map(currency =>
						<MenuItem value={currency} >
							{currency}
						</MenuItem>
					)}
				</Select>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeCurrencyModal}>
					Close
				</Button>

				<Button color='primary' variant='contained' disableElevation onClick={selectCurrency}>
					Use currency
				</Button>
			</DialogActions>
			</Dialog>

			<Dialog
				open={quoteModalOpen}
				onClose={closeQuoteModal}
				fullWidth
			>
			<DialogTitle>
				Pay with Wise
			</DialogTitle>
			<DialogContent>
				{ !quoteLoaded &&
					<div className={classes.loadingBox}>
						<CircularProgress color='inherit' size={25} />
						<div className={classes.loadingMessage}>Creating a quote for this payout...</div>
					</div>
				}

				{ quoteLoaded && quote.paymentOption &&
					<Fragment>
						<div className={classes.info}>
							This payment will be handled by Wise. If the receiver has not set their account with Wise, Wise will send an email to configure their banking information.&nbsp;
							<b>Receivers need to complete their bank details within 1 week</b> for the transfer to be actually done; if not, the transfer order will be canceled automatically by Wise.&nbsp;
							<b>Transfers cannot be canceled once they are made</b>.
						</div>

						<div className={classes.quoteInformation}>
							<div className={classes.quoteItem + ' ' + classes.quoteSource}>
								<div className={classes.quoteTitle}>Source amount</div>
								<div className={classes.quoteData}>{quote.paymentOption.sourceAmount} {quote.paymentOption.sourceCurrency}</div>
							</div>

							<div className={classes.arrow}>
								<ArrowForwardIosIcon style={{ color: '#828282' }} />
							</div>

							<div className={classes.quoteItem}>
								<div className={classes.quoteTitle}>Target amount {(quote.rate && quote.rate !== 1) ? ` - Rate: ${quote.rate}` : ''}</div>
								<div className={classes.quoteData}>{quote.paymentOption.targetAmount} {quote.paymentOption.targetCurrency}</div>
							</div>
						</div>

						<div className={classes.fee}>
							Wise fee: {(quote.paymentOption.feePercentage * 100).toFixed(2)}% ({quote.paymentOption.fee.total} {quote.paymentOption.sourceCurrency})
						</div>

						<label className={classes.confirmationCheck}>
							<Checkbox
								checked={understood}
								onChange={(e) => updateUnderstood(e)}
								name='understood'
								color='inherit'
							/> <span style={{ userSelect: 'none' }}>I understand the transfer <b>cannot be canceled</b></span>
						</label>
					</Fragment>
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={closeQuoteModal}>
					Close
				</Button>

				<Button color='primary' variant='contained' disableElevation disabled={transferDisabled || makingTransfer} onClick={makeTransfer}>
					{ makingTransfer ? 'Approving quote and making transfer...' : 'Approve quote and make transfer' }
				</Button>
			</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default withSnackbar(withStyles(styles)(PayButton));
