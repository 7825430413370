import React, { Component } from 'react'
import PropTypes from 'prop-types'

import dayjs from 'dayjs';
import deepEqual from 'deep-equal';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Box, Card, CardContent,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	FormControl, InputLabel, MenuItem, Select, Switch,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	TextField, Typography
} from '@material-ui/core'
import {
	ArrowBack,
} from '@material-ui/icons';
import { withSnackbar } from 'notistack';

import { EXPERT } from '../../../constants';
import ExpertFlags from './components/expert-flags';
import LoadingBox from '../../../components/loading-box'
import withRouter from '../../../components/withRouter';
import ServerAPI from '../../../services/server-api'
import { HistoryUtils } from '../../../utils'

const styles = () => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	buttons: {
		textAlign: "left",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		}
	},
	bottomButtons: {
		marginTop: "20px",
	},
	manualScore: {
		minWidth: '100px',
	},
});

class ExpertEditPage extends Component {
	constructor(props) {
		super(props);

		const { location } = props;
		const { data } = location;
		
		this.state = {
			isLoading: !(data && data.user),
			openDeleteDialog: false,
			// Data
			user: (data) ? data.user : null,
		};
	}

	async updateExpertData() {
		const { id } = this.props.match.params;
		if (!id) return null;
		
		const results = await ServerAPI.getExpert(id);
		if (results.error) return null;
		
		return results.data;
	}

	async updateData() {
		const user = await this.updateExpertData();
		
		// Check if data changed
		if (deepEqual(this.state.user, user)) return;
		
		this.setState({
			isLoading: false,
			user,
		});
	}

	componentDidMount = async () => {
		let { user } = this.state;
		if (user) return;
		
		this.updateData();
	};

	goBack() {
		const { user } = this.state;
		
		HistoryUtils.goBackOrPush({
			pathname: '/expert/' + user.id,
		});
	}

	onCommentChange(e) {
		const { value } = e.target;
		const { user } = this.state;
		user.expert.comment = value;
		this.setState({ user });
	}

	onManualScoreChange(e, value) {
		const { key } = value;
		const { user } = this.state;
		user.expert.manualScore = key;
		this.setState({ user });
	}

	onFlagsChange(flags) {
		const { user } = this.state;
		user.expert.flags = flags;
		this.setState({ user });
	}

	onEmailSubscriptionChange(e) {
		const { checked } = e.target;
		const { user } = this.state;
		user.emailNotificationsEnabled = checked;
		this.setState({ user });
	}
	
	async onClickSave() {
		const { enqueueSnackbar } = this.props;
		const { user } = this.state;
		
		const result = await ServerAPI.updateExpert(user.id, {
			comment: user.expert.comment,
			emailNotificationsEnabled: user.emailNotificationsEnabled,
			flags: user.expert.flags,
			manualScore: parseInt(user.expert.manualScore),
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update expert', { variant: 'error' });
		enqueueSnackbar('Expert updated', { variant: 'success' });
		
		HistoryUtils.goBackOrPush({
			pathname: '/expert/' + user.id,
		});
	}
	
	onOpenDeleteDialog() {
		this.setState({
			openDeleteDialog: true,
		});
	}
	
	onCloseDeleteDialog() {
		this.setState({
			openDeleteDialog: false,
		});
	}
	
	async onClickConfirmDelete() {
		const { enqueueSnackbar } = this.props;
		const { user } = this.state;
		
		// Close window
		this.onCloseDeleteDialog();
		
		const result = await ServerAPI.deleteExpert(user.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update expert', { variant: 'error' });
		enqueueSnackbar('Expert deleted', { variant: 'success' });
		
		HistoryUtils.goBackOrPush({
			pathname: '/experts',
		});
	}
	
	render() {
		const { classes } = this.props;

		const {
			isLoading,
			openDeleteDialog,
			// Data
			user,
		} = this.state;
		
		const expert = user && user.expert;
		
		if (isLoading) return <LoadingBox />;
		
		return (
			<Box className={classes.root}>
				<Card className={classes.root} variant="outlined">
					<CardContent>
						<div className={classes.buttons}>
							<Button
								startIcon={<ArrowBack />}
								onClick={(e) => this.goBack()}>
								Go Back
							</Button>
						</div>
						<Typography variant="h5" gutterBottom>
							Edit Expert
						</Typography>
						<TableContainer>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell><b>First Name</b></TableCell>
										<TableCell>{user.firstName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Last Name</b></TableCell>
										<TableCell>{user.lastName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Email</b></TableCell>
										<TableCell>{user.email}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Manual Score</b></TableCell>
										<TableCell>
											<FormControl className={classes.formControl}>
												<InputLabel>Manual Score</InputLabel>
												<Select
													className={classes.manualScore}
													value={expert.manualScore}
													onChange={(e, v) => this.onManualScoreChange(e, v)}
													>
													{Object.values(EXPERT.SCORE).map((v, i) => {
														return (
															<MenuItem value={v} key={i}>
																{i}
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Comment</b></TableCell>
										<TableCell>
											<TextField
												label="Comment"
												multiline
												value={expert.comment}
												onChange={(e, v) => this.onCommentChange(e, v)}
												variant="outlined"
												/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Flags</b></TableCell>
										<TableCell>
											<ExpertFlags
												editable
												flags={expert.flags}
												onChange={(f) => this.onFlagsChange(f)} />
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Email Subscription</b></TableCell>
										<TableCell>
											<Switch
												checked={user.emailNotificationsEnabled}
												onChange={(e) => this.onEmailSubscriptionChange(e)}
												color="primary"
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Created at</b></TableCell>
										<TableCell>{dayjs(user.createdAt).format('D MMMM YYYY')}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
						<div className={classes.buttons + " " + classes.bottomButtons}>
							<Button variant="contained" color="primary" onClick={(e) => this.onClickSave()}>
								Save
							</Button>
							<Button variant="contained" color="secondary" onClick={(e) => this.onOpenDeleteDialog()}>
								Delete
							</Button>
						</div>
					</CardContent>
				</Card>
				<Dialog
					open={openDeleteDialog}
					onClose={() => this.onCloseDeleteDialog()}
					>
					<DialogTitle>
						Do you really want to delete {user.firstName}? :(
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							A deleted expert will not be shown in the expert list. It will be hidden, wandering in the Limbo... forever.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => this.onCloseDeleteDialog()} color="primary">
							Cancel
						</Button>
						<Button onClick={() => this.onClickConfirmDelete()} color="primary" autoFocus>
							Yes, delete
						</Button>
					</DialogActions>
				</Dialog>
			</Box>
		);
	}
}

ExpertEditPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ExpertEditPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(ExpertEditPage)));
