import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import ServerAPI from '../../../../services/server-api';
import ContestCardGrid from '../components/contest-card-grid';

const styles = () => ({
	tableActions: {
		margin: "20px 0px",
	},
	dialogContent: {
		overflowY: "hidden",
	},
	hiddenInput: {
		display: "none",
	},
	fileInput: {
		marginTop: "20px",
	},
});

class ContestCardTab extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			newRoundButtonDisabled: false,
		};
	}

	updateNewRoundButtonDisability = (disabled) => {
		this.setState({
			newRoundButtonDisabled: disabled,
		});
	}

	addRound = async () => {
		const { newRoundButtonDisabled } = this.state;
		const { enqueueSnackbar, project, type, updateData } = this.props;

		if (newRoundButtonDisabled) return;

		this.updateNewRoundButtonDisability(true);

		const result = await ServerAPI.addContestRound(project.id, type, {});
		if (result.error) {
			this.updateNewRoundButtonDisability(false);
			return enqueueSnackbar(result.error.message || 'File could not be uploaded', { variant: 'error' });
		}

		enqueueSnackbar('Round added', { variant: 'success' });
		
		updateData();

		this.updateNewRoundButtonDisability(false);
	};

	render() {
		const { newRoundButtonDisabled } = this.state;
		const { classes, project, type, contest, updateData } = this.props;
		
		return (
			<Fragment>
				<div className={classes.tableActions}>
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={this.addRound}
						style={(newRoundButtonDisabled ? { cursor: 'progress', opacity: 0.7 } : {})}
					>
						Add new round
					</Button>
				</div>
				<ContestCardGrid
					project={project}
					type={type}
					contest={contest}
					updateData={updateData}
				/>
			</Fragment>
		);
	}
}

ContestCardTab.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	contest: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};

ContestCardTab.defaultProps = {
	classes: {},
	project: {},
	type: null,
	contest: {},
	updateData() {},
};

export default withSnackbar(withStyles(styles)(ContestCardTab));
