import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Box, Card, CardContent, CircularProgress,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	TextField, Typography
} from '@material-ui/core';
import { Add, ArrowBack } from '@material-ui/icons';

import PROJECT from '../../../constants/project';
import ConfirmationDialog from '../../../components/dialog/confirmation-dialog';
import DateRangePicker from '../../../components/inputs/date-range-picker';
import FileGrid from '../../../components/grid/file-grid';
import withRouter from '../../../components/withRouter';
import { HistoryUtils } from '../../../utils';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	header: {
		marginBottom: "50px",
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		padding: theme.spacing(2),
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	cardContent: {
		padding: "0px",
	},
	tableActions: {
		margin: "20px 0px",
	},
	hiddenInput: {
		display: "none",
	},
	fileInput: {
		marginTop: "20px",
	},
	dateRange: {
		padding: theme.spacing(0)
	},
});

class RawMaterialsPage extends Component {
	constructor(props) {
		super(props);
		
		const { location } = props;
		const { data } = location;
		
		this.state = {
			isLoading: !(data && data.project),
			// Data
			project: (data) ? data.project : null,
			// Dialog
			openAddFileDialog: false,
			selectedName: null,
			selectedFile: null,
			uploading: false,
			openDeleteFileDialog: false,
		};
	}
	
	updateProjectData = async () => {
		const { id } = this.props.match.params;
		if (!id) return;
		
		const results = await ServerAPI.getProject(id);
		if (results.error) return null;
		
		return results.data;
	};

	updateData = async () => {
		const project = await this.updateProjectData();
		
		// Check if data changed
		if (deepEqual(this.state.project, project)) return;
		
		this.setState({
			isLoading: false,
			project,
		});
	};

	componentDidMount = async () => {
		let { project } = this.state;
		if (project) return;
		
		this.updateData();
	};
	
	goBack = () => {
		HistoryUtils.goBackOrPush({
			pathname: '/projects',
		});
	};
	
	updateState = async (data) => {
		const { enqueueSnackbar } = this.props;
		const { project } = this.state;
		
		const stateObj = PROJECT.getState(project, PROJECT.STATES.CLIENT_ONBOARDING);
		
		const prevState = {}
		for (const k in data) {
			prevState[k] = stateObj[k];
			stateObj[k] = data[k];
		}
		
		this.setState({ project }, async () => {
			const result = await ServerAPI.updateState(project.id, PROJECT.STATES.CLIENT_ONBOARDING, data);
			if (result.error) {
				// Recover state
				for (const k in prevState) {
					stateObj[k] = prevState[k];
				}
				this.setState({ project });
				return enqueueSnackbar(result.error.message || 'Could not update state', { variant: 'error' });
			}
			
			enqueueSnackbar('State updated', { variant: 'success' });
		});
	};
	
	onStartTimeChange = async (newDate) => {
		const { enqueueSnackbar } = this.props;
		const { project } = this.state;
		
		const state = PROJECT.getState(project, PROJECT.STATES.CLIENT_ONBOARDING);
	
		if (!newDate && state.endDate) {
			return enqueueSnackbar('Start date cannot be empty with a selected end date', { variant: 'error' });
		}

		await this.updateState({
			startDate: newDate,
		});
	};
	
	onEndTimeChange = async (newDate) => {
		const { enqueueSnackbar } = this.props;
		const { project } = this.state;
		
		const state = PROJECT.getState(project, PROJECT.STATES.CLIENT_ONBOARDING);
		
		if (!state.startDate && newDate) {
			return enqueueSnackbar('Start date cannot be empty with a selected end date', { variant: 'error' });
		}
		
		await this.updateState({
			endDate: newDate,
		});
	};
	
	onOpenAddFileDialog = () => {
		this.setState({
			openAddFileDialog: true,
		});
	};
	
	onCloseAddFileDialog = () => {
		this.setState({
			openAddFileDialog: false,
			selectedName: null,
			selectedFile: null,
		});
	};
	
	onSelectedNameChange = (e) => {
		const { value } = e.target;
		return this.setState({
			selectedName: value,
		});
	};
	
	onSelectedFileChange = (e) => {
		const { files } = e.target;
		return this.setState({
			selectedFile: (files.length > 0) ? files[0] : null,
		});
	};
	
	onClickConfirmAddFile = async () => {
		const { enqueueSnackbar } = this.props;
		const { project, selectedName, selectedFile } = this.state;

		if (!selectedName) return enqueueSnackbar('Name not valid', { variant: 'error' });

		this.setState({
			uploading: true,
		}, async () => {
			const result = await ServerAPI.addRawMaterial(project.id, {
				name: selectedName || '',
				filename: selectedFile.name,
				file: selectedFile,
			});
			
			// Close window
			this.onCloseAddFileDialog();
			this.setState({
				uploading: false,
			});
			
			if (result.error) return enqueueSnackbar(result.error.message || 'File could not be uploaded', { variant: 'error' });
			else enqueueSnackbar('File added', { variant: 'success' });
			
			this.updateData();
		});
	};
	
	onOpenDeleteFileDialog = (f) => {
		this.setState({
			openDeleteFileDialog: true,
			selectedFile: f,
		});
	};
	
	onCloseDeleteFileDialog = (f) => {
		this.setState({
			openDeleteFileDialog: false,
		});
	};
	
	onConfirmDeleteFileDialog = async () => {
		const { enqueueSnackbar } = this.props;
		const { project, selectedFile } = this.state;
		
		// Close dialog
		this.onCloseDeleteFileDialog();
		
		const result = await ServerAPI.deleteRawMaterial(project.id, selectedFile.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove file', { variant: 'error' });
		enqueueSnackbar('File deleted', { variant: 'success' });
		
		// Update data
		this.updateData();
	};
	
	render() {
		const { classes } = this.props;
		const {
			isLoading,
			project,
			openAddFileDialog,
			selectedName,
			selectedFile,
			uploading,
			openDeleteFileDialog,
		} = this.state;
		
		const state = project && PROJECT.getState(project, PROJECT.STATES.CLIENT_ONBOARDING);
		const startDate = state && state.startDate ? new Date(state.startDate) : null;
		const endDate = state && state.endDate ? new Date(state.endDate) : null;
		
		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<div>
						<Card variant="outlined">
							<CardContent className={classes.cardContent}>
								<div className={classes.buttons}>
									<div className={classes.leftButtons}>
										<Button
											startIcon={<ArrowBack />}
											onClick={(e) => this.goBack()}>
											Go Back
										</Button>
									</div>
								</div>
								<Typography variant="h5" gutterBottom>
									Raw Materials
								</Typography>
								<Typography gutterBottom>
									{project.name}
								</Typography>
								<Box m={3}>
									<Typography variant="subtitle2" gutterBottom>
										Duration of Client Onboarding
									</Typography>
									<DateRangePicker
										className={classes.dateRange}
										container={false}
										isClearable={true}
										placeholderText={'No date'}
										startDate={startDate}
										endDate={endDate}
										onChangeStart={this.onStartTimeChange}
										onChangeEnd={this.onEndTimeChange}
										/>
								</Box>
								<div className={classes.tableActions}>
									<Button
										variant="outlined"
										color="primary"
										startIcon={<Add />}
										onClick={(e) => this.onOpenAddFileDialog()}>
										Add File
									</Button>
								</div>
								<FileGrid
									files={project.rawMaterials}
									onDelete={(f) => this.onOpenDeleteFileDialog(f)}
									/>
							</CardContent>
						</Card>
						<Dialog
							fullWidth={true}
							open={openAddFileDialog}
							onClose={() => this.onCloseAddFileDialog()}
							>
							<DialogTitle>
								Add a new file to raw materials
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
								<DialogContentText>
									Select the file you want to add to the raw materials.
								</DialogContentText>
								<TextField
									label="Name"
									defaultValue={selectedName}
									variant="outlined"
									name="name"
									onChange={(e) => this.onSelectedNameChange(e)}
									/>
								<div className={classes.fileInput}>
									<input
										className={classes.hiddenInput}
										id="contained-button-file"
										type="file"
										onChange={(e) => this.onSelectedFileChange(e)}
										/>
									<label htmlFor="contained-button-file">
										{uploading ? (
											<CircularProgress />
										) : (
											<Button variant="contained" color="primary" component="span">
												Select file
											</Button>
										)}
										&nbsp;
										{selectedFile && (
											<span>File selected: {selectedFile.name}</span>
										)}
									</label>
								</div>
							</DialogContent>
							<DialogActions>
								<Button onClick={() => this.onCloseAddFileDialog()} color="primary">
									Cancel
								</Button>
								<Button
									disabled={!selectedFile || uploading}
									color="primary"
									onClick={() => this.onClickConfirmAddFile()}>
									{uploading ? 'Uploading...' : 'Upload file'}
								</Button>
							</DialogActions>
						</Dialog>
						<ConfirmationDialog
							title={'Are you sure you want to delete the file?'}
							content={'This will permanently delete the file.'}
							primaryText={'Delete file'}
							secondaryText={'Cancel'}
							open={openDeleteFileDialog}
							onClose={this.onCloseDeleteFileDialog}
							onConfirm={this.onConfirmDeleteFileDialog}
							/>
					</div>
				)}
			</Box>
		);
	}
}

RawMaterialsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

RawMaterialsPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(RawMaterialsPage)));
