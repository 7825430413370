import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { Avatar, Badge, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Category } from '@material-ui/icons';

import { PROJECT, PROPOSAL, CONTEST } from '../../../../constants';

const styles = () => ({
	flagList: {
		display: 'inline-flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: "center",
	},
	link: {
		textDecoration: "none",
		padding: "0px",
	},
	flagAndBadge: {	
		display: "flex",
		flexDirection: "column",
	},
	badge: {
		width: "50%",
		marginTop: "5px",
	},
	flagContainer: {
		position: "relative",
		padding: "3px",
		maxHeight: "39.59px",
	},
	flagIcon: {
		width: "1.4em",
		height: "1.4em",
	},
	numProposals: {
		position: "absolute",
		top: "calc(50% - 7px)",
		width: "100%",
		left: "0px",
		fontSize: "14px",
		fontWeight: "600",
	}
});

class ProjectStatus extends Component {
	getDeadlineText(startDate, endDate) {
		let deadlineText = "";
		if (startDate && endDate) {
			const start = dayjs(startDate);
			const end = dayjs(endDate);
			deadlineText = end.diff(start, 'day') + " days";
		} else if (startDate) {
			const start = dayjs(startDate);
			deadlineText = "started on " + start.format('DD/MM/YYYY');
		}
		
		return deadlineText;
	}
	
	renderFlag(project, stateName, active) {
		const { classes } = this.props;
		const { STATES } = PROJECT;
		
		let color;
		let textColor;
		let altText = "";
		let urlName;
		
		switch (stateName) {
			case STATES.SKETCH_CONTEST:
				color = "#02b2da";
				textColor = "white";
				altText = "Sketch";
				urlName = 'sketch';
				break;
			case STATES.MODEL_CONTEST:
				color = "#d9b41e";
				textColor = "white";
				altText = "3D modeling";
				urlName = 'model';
				break;
			case STATES.ELECTRICAL_CONTEST:
				color = "#3aa936";
				textColor = "white";
				altText = "Electrical";
				urlName = 'electrical';
				break;
			case STATES.MECHANICAL_CONTEST:
				color = "#ea4e1d";
				textColor = "white";
				altText = "Mechanical";
				urlName = 'mechanical';
				break;			
			case STATES.PROTOTYPING_CONTEST:
				color = "#682384";
				textColor = "white";
				altText = "Prototyping";
				urlName = 'prototyping';
				break;
			default:
				color = "black";
				textColor = "white";
				altText = "No label";
				urlName = '';
		}
		
		const state = PROJECT.getState(project, stateName);
		const deadlineText = this.getDeadlineText(state.startDate, state.endDate);
		
		// Check for unseen files
		// Check for not approved version files
		const contest = PROJECT.getContestByState(project, stateName);
		const unseenVersionFiles = contest.proposals.reduce((acc, p) => {
			const unseenFiles = PROPOSAL.getUnseenFileVersions(p);
			return acc + unseenFiles.length;
		}, 0);

		// Is an active contest
		const activeContest = contest.active;

		// Check for not approved shared files
		const unseenSharedFiles = CONTEST.getUnseenSharedFiles(contest).length;

		// Sum both unseen file versions and shared files
		const unseenFiles = unseenVersionFiles + unseenSharedFiles;

		return (
			<Tooltip title={altText + (deadlineText ? " (" + deadlineText + ")" : '') + (activeContest ? '' : ' (Disabled)')}>
				<div className={classes.flagAndBadge}>
					<IconButton className={classes.link}>
						<Link to={'/project/' + project.id + '/contest/' + urlName}>
							<div className={classes.flagContainer}>
								<Avatar className={classes.flagIcon} style={{ opacity: active ? 1 : 0.2, backgroundColor: (activeContest ? color : '#DEDEDE' ) }}>
									<span className={classes.numProposals} style={{ color: active ? textColor : 'black' }}>
										{contest.proposals.length}
									</span>
								</Avatar>
							</div>
						</Link>
					</IconButton>
					<Badge
						className={classes.badge}
						badgeContent={unseenFiles}
						color="secondary"
						variant="dot">
					</Badge>
				</div>
			</Tooltip>
		);
	}
	
	render() {
		const { classes, project } = this.props;
		const { STATES } = PROJECT;
		
		const flags = [
			STATES.SKETCH_CONTEST,
			STATES.MODEL_CONTEST,
			STATES.ELECTRICAL_CONTEST,
			STATES.MECHANICAL_CONTEST,
			STATES.PROTOTYPING_CONTEST,
		];
		
		const allStates = [
			STATES.CLIENT_ONBOARDING,
			STATES.PROJECT_SET_UP,
			...flags,
		];
		
		const projectIndex = allStates.indexOf(project.state);
		const projectSetupDone = (allStates.indexOf(STATES.PROJECT_SET_UP) <= projectIndex);
		
		const clientOboardingState = PROJECT.getState(project, PROJECT.STATES.CLIENT_ONBOARDING);
		const deadlineText = this.getDeadlineText(clientOboardingState.startDate, clientOboardingState.endDate);
		
		return (
			<div>
				<div className={classes.flagList}>
					<Tooltip title={'Raw Materials' + (deadlineText ? " (" + deadlineText + ")" : '')}>
						<IconButton className={classes.link}>
							<Link to={'/project/' + project.id + '/rawMaterials'}>
								<div className={classes.flagContainer}>
									<Category
										className={classes.flagIcon}
										style={{ color: 'mediumseagreen',  opacity: projectSetupDone ? 1 : 0.2 }}
									/>
								</div>
							</Link>
						</IconButton>
					</Tooltip>
					{flags.map((state, i) => {
						const flagIndex = allStates.indexOf(state);
						
						return (
							<div key={i}>
								{this.renderFlag(project, state, flagIndex <= projectIndex)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

ProjectStatus.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
};

ProjectStatus.defaultProps = {
	classes: {},
	project: {},
};

export default withStyles(styles)(ProjectStatus);
