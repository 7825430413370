const adminConstants = {
	ROLES: {
		MARKETING: 'marketing',
		OPERATIONS: 'operations',
		DEVELOPMENT: 'development',
		MANAGER: 'manager',
		SUPERADMIN: 'superadmin'
	},
};
	
export default adminConstants;
