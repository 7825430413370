import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
	Tooltip, Dialog, DialogTitle, DialogActions, DialogContent, Button,
	MenuItem, Select, FormControl, InputLabel,
} from '@material-ui/core';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

import ProjectSearch from '../../../../components/project/project-search';

import { PAYMENT } from '../../../../constants';

import { withSnackbar } from 'notistack';

import ServerAPI from '../../../../services/server-api';

const styles = () => ({
	button: {
		display: 'inline-block',
		cursor: 'pointer',
		paddingTop: 2,
		marginRight: 3,
		'&:hover': {
			opacity: 0.9,
		}
	},
	buttonIcon: {
		fontSize: 15,
		paddingTop: 3,
	},
	associatedIcon: {
		opacity: 0.6,
		color: '#69DB6C',
	},
	notAssociatedIcon: {
		color: '#FAC03E',
	},
	associated: {
		marginBottom: 20,
	},
	form: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: 20,
		gap: 20,
	},
	input: {
		display: 'inline-block',
		width: '100%',
	},
	selector: {
		display: 'inline-block',
	},
});

function Association({ payout, classes, enqueueSnackbar }) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [loadingQuery, setLoadingQuery] = useState(false);

	const [projectAssociated, setProjectAssociated] = useState(payout.project);
	const [projectToAssociate, setProjectToAssociate] = useState(payout.project);

	const [costLineAssociated, setCostLineAssociated] = useState(payout.costLine);
	const [costLineToAssociate, setCostLineToAssociate] = useState(payout.costLine);

	function openDialog() {
		setDialogOpen(true);
	}

	function closeDialog({ saved }) {
		if (!saved) {
			setCostLineToAssociate(costLineAssociated);
			setProjectToAssociate(projectAssociated);
		}

		setDialogOpen(false);
	}

	async function associateProject() {
		setLoadingQuery(true);

		const projectId = (projectToAssociate && projectToAssociate.id) || null;
		const costLine = costLineToAssociate;

		const result = await ServerAPI.associatePayoutToProject({
			projectId,
			costLine,
			payoutId: payout.id,
		});

		if (result.error) {
			setLoadingQuery(false);
			return enqueueSnackbar(result.error.message || 'Could not associate payout to project', { variant: 'error' });
		}

		setProjectAssociated(projectToAssociate);

		setCostLineAssociated(costLineToAssociate);

		setLoadingQuery(false);
		closeDialog({ saved: true });

		return enqueueSnackbar('Payout updated', { variant: 'success' });
	}

	function projectSelected(result) {
		const projectResult = (result.length && result[0]) || null;
		let project = null;

		if (projectResult) {
			project = {
				id: projectResult.id,
				name: projectResult.name,
			};
		}

		setProjectToAssociate(project);
	}

	const payoutLines = Object.values(PAYMENT.PAYOUT_LINES).map(line => {
		return {
			name: line,
			title: PAYMENT.getPayoutLinesForHumans(line),
		};
	});

	return (
		<Fragment>
			<Tooltip title={(projectAssociated ? 'Payout associated to project' : 'Associate payout to project')}>
				<div className={classes.button} onClick={openDialog}>
					{
							projectAssociated 
						?
							<RadioButtonCheckedIcon className={classes.buttonIcon + ' ' + classes.associatedIcon} />
						:
							<RadioButtonUncheckedIcon className={classes.buttonIcon + ' ' + classes.notAssociatedIcon} />
					}
				</div>
			</Tooltip>

			<Dialog
				open={dialogOpen}
				onClose={closeDialog}
				fullWidth
			>
			<DialogTitle>
				Associate payout to project
			</DialogTitle>
			<DialogContent>
				<div className={classes.form}>
					<div className={classes.input}>
						<ProjectSearch
							maxSelection={1}
							onChange={projectSelected}
							placeholder={'Search project by name'}
							defaultValue={(projectToAssociate ? [projectToAssociate] : [])}
						/>
					</div>

					<div className={classes.selector}>
						<FormControl variant='outlined'>
							<InputLabel id='cost-line-label'>Cost line</InputLabel>
							<Select
								labelId='cost-line-label'
								label={'Cost line'}
								value={costLineToAssociate || PAYMENT.PAYOUT_LINES.NONE}
								onChange={(e) => setCostLineToAssociate(e.target.value)}
								autoWidth={false}
							>
								{ 
									payoutLines.map(line => {
										return <MenuItem value={line.name}>{line.title}</MenuItem>
									})
								}
							</Select>
						</FormControl>
					</div>
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={closeDialog}>
					Close
				</Button>

				<Button color='primary' variant='contained' disableElevation disabled={loadingQuery} onClick={associateProject}>
					{(loadingQuery ? 'Saving...' : 'Save')}
				</Button>
			</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default withSnackbar(withStyles(styles)(Association));
