import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import {
	Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';

import { EXPERT } from '../../../../constants';
import { StringUtils } from '../../../../utils';

const styles = () => ({

});

function PaymentInfoTable({ data, classes }) {
	return (
		<TableContainer>
			<Table className={classes.table}>
				<TableBody>
					<TableRow>
						<TableCell><b>First Name</b></TableCell>
						<TableCell>{data.firstName}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Last Name</b></TableCell>
						<TableCell>{data.lastName}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Email</b></TableCell>
						<TableCell>{data.email}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Country Residence</b></TableCell>
						<TableCell>
							{StringUtils.capitalizeAll(data.paymentInfo.countryResidence)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Invoice Type</b></TableCell>
						<TableCell>
							{EXPERT.invoiceTypeToString(data.paymentInfo.invoiceType)}
						</TableCell>
					</TableRow>
					{data.paymentInfo.invoiceType === EXPERT.INVOICE_TYPE.INDEPENDENT ? (
						<React.Fragment>
							<TableRow>
								<TableCell><b>Is a registered freelancer?</b></TableCell>
								<TableCell>
									{data.paymentInfo.isRegisteredFreelancer ? 'Yes' : 'No'}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell><b>Freelancer Time</b></TableCell>
								<TableCell>
									{EXPERT.freelancerTimeToString(data.paymentInfo.freelancerTime)}
								</TableCell>
							</TableRow>
						</React.Fragment>
					) : (
						<TableRow>
							<TableCell><b>Business Name</b></TableCell>
							<TableCell>{data.paymentInfo.businessName || '-'}</TableCell>
						</TableRow>
					)}
					<TableRow>
						<TableCell><b>ID Number</b></TableCell>
						<TableCell>{data.paymentInfo.idNumber}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Tax Residence</b></TableCell>
						<TableCell>{data.paymentInfo.taxResidence}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Is a intra-communitary operator?</b></TableCell>
						<TableCell>
							{EXPERT.europeOperatorToString(data.paymentInfo.europeOperator)}
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Tax Document</b></TableCell>
						<TableCell>
							<a
								href={data.paymentInfo.taxDocument}
								target="_blank"
								rel="noopener noreferrer">
								{data.paymentInfo.taxDocument}
							</a>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>VAT Tax</b></TableCell>
						<TableCell>{data.paymentInfo.vatTax}%</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Retention Tax</b></TableCell>
						<TableCell>{data.paymentInfo.retentionTax}%</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><b>Last updated at</b></TableCell>
						<TableCell>{new Date(data.paymentInfo.updatedAt).toDateString()}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default withStyles(styles)(PaymentInfoTable);
