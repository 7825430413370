import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle,
	InputLabel, FormControl, MenuItem, Select, Typography, Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import {
	Add, Edit, Remove,
	SentimentSatisfiedAlt, SentimentVeryDissatisfied, Visibility, 
} from '@material-ui/icons';

import TextFieldMarkdown from '../../../../../components/inputs/text-field-markdown';
import ServerAPI from '../../../../../services/server-api';

const styles = (theme) => ({
	title: {
		display: 'flex',
		
		"& h6": {
			flex: '1',
		},
	},
	content: {
		"& svg": {
			verticalAlign: "middle",
			marginRight: "5px",
		},
	},
	formControl: {
		minWidth: 120,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	commentInput: {
		width: "calc(100% - 16px)",
	},
});

class ProposalFeedbackDialog extends Component {
	constructor(props) {
		super(props);
		
		const { proposal } = this.props;
		if (!proposal.feedback) proposal.feedback = {};
		if (!proposal.feedback.info) proposal.feedback.info = {};
		
		this.state = {
			proposal,
			editMode: false,
		};
	}
	
	onClose = () => {
		const { onClose } = this.props;

		this.setState({ editMode: false });
		
		onClose();
	};
	
	onToggleEditMode = () => {
		this.setState({
			editMode: !this.state.editMode,
		});
	};
	
	onChangeFeedbackRating = (e) => {
		const { value } = e.target;
		const { proposal } = this.state;

		proposal.feedback.rating = value;
		
		this.setState({ proposal });
	};
	
	onChangeFeedbackInfoField = (e) => {
		const { name, value } = e.target;
		const { proposal } = this.state;
		
		proposal.feedback.info[name] = value;
		
		this.setState({ proposal });
	};

	onChangeFeedbackWinner = (e) => {
		const checked = e.target.checked;
		this.setState({ winner: checked });
	};
	
	onSubmitFeedback = async () => {
		const { enqueueSnackbar, onUpdate } = this.props;
		const { proposal, winner } = this.state;

		if (typeof proposal.feedback.rating !== 'number') {
			proposal.feedback.rating = 0;
		}
			
		const result = await ServerAPI.updateProposal(proposal.id, {
			feedback: proposal.feedback,
			winner: winner,
		});
		if (result.error) {
			enqueueSnackbar(result.error.message || 'Could not update feedback', { variant: 'error' });
			return onUpdate();
		}
		enqueueSnackbar('Feedback updated', { variant: 'success' });
		
		this.setState({ editMode: false });
		
		onUpdate();
	};

	componentDidMount() {
		// Check if is winner
		const { contest } = this.props;
		const { proposal } = this.state;
		const isWinner = contest.selectedProposal && (contest.selectedProposal.id.toString() === proposal.id.toString());

		this.setState({ winner: isWinner });
	}
	
	render() {
		const { classes, open } = this.props;
		const { editMode, proposal, winner } = this.state;		
		
		let rating;
		let pros, cons, keepFeatures, removeFeatures;
		if (proposal.feedback) {
			rating = proposal.feedback.rating;
			
			if (proposal.feedback.info) {
				const info = proposal.feedback.info;
				pros = info.pros;
				cons = info.cons;
				keepFeatures = info.keepFeatures;
				removeFeatures = info.removeFeatures;
			}
		}
		
		return (
			<Fragment>
				<Dialog
					open={open}
					onClose={this.onClose}
					fullWidth={true}
					>
					<DialogTitle className={classes.title} disableTypography={true}>
						<Typography variant="h6">
							Feedback details
						</Typography>
						<Button
							variant="contained"
							color="primary"
							startIcon={editMode ? <Visibility /> : <Edit />}
							onClick={this.onToggleEditMode}>
							{editMode ? 'View' : 'Edit'}
						</Button>
					</DialogTitle>
					<DialogContent className={classes.content}>
						<div>
							<Typography variant="subtitle2">
								Rating
							</Typography>
							{editMode ? (
								<FormControl variant="outlined" className={classes.formControl}>
									<InputLabel id="rating-label">Rating</InputLabel>
									<Select
										label="Rating"
										labelId="rating-label"
										value={rating || ''}
										onChange={this.onChangeFeedbackRating}
										>
										{[0, 1, 2, 3, 4, 5].map((v, i) => (
											<MenuItem value={v} key={i}>
												{v || '-'}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							) : (
								<Typography variant="body2" paragraph>
									{rating || '-'}
								</Typography>
							)}
						</div>
						<div>
							<Typography variant="subtitle2">
								Winning proposal
							</Typography>
							<FormControlLabel
								label="Winner"
								control={<Checkbox
									disabled={!editMode}
									size={editMode ? "small" : "medium"}
									color="primary"
									checked={winner}
									onChange={this.onChangeFeedbackWinner}
								/>}
							/>
						</div>
						<div>
							<Typography variant="subtitle2">
								<SentimentSatisfiedAlt />
								Pros
							</Typography>
							{editMode ? (
								<FormControl className={classes.formControl + " " + classes.commentInput}>
									<TextFieldMarkdown
										multiline
										label="Pros"
										variant="outlined"
										name="pros"
										value={pros}
										onChange={this.onChangeFeedbackInfoField}
										inputProps={{ maxLength: 2000 }}
										/>
								</FormControl>
							) : (
								<ReactMarkdown>
									{pros}
								</ReactMarkdown>							
							)}
						</div>
						<div>
							<Typography variant="subtitle2">
								<SentimentVeryDissatisfied />
								Cons
							</Typography>
							{editMode ? (
								<FormControl className={classes.formControl + " " + classes.commentInput}>
									<TextFieldMarkdown
										multiline
										label="Cons"
										variant="outlined"
										name="cons"
										value={cons}
										onChange={this.onChangeFeedbackInfoField}
										inputProps={{ maxLength: 2000 }}
										/>
								</FormControl>
							) : (
								<ReactMarkdown>
									{cons}
								</ReactMarkdown>							
							)}
						</div>
						<div>
							<Typography variant="subtitle2">
								<Add />
								Keep Features
							</Typography>
							{editMode ? (
								<FormControl className={classes.formControl + " " + classes.commentInput}>
									<TextFieldMarkdown
										multiline
										label="Keep features"
										variant="outlined"
										name="keepFeatures"
										value={keepFeatures}
										onChange={this.onChangeFeedbackInfoField}
										inputProps={{ maxLength: 2000 }}
										/>
								</FormControl>
							) : (
								<ReactMarkdown>
									{keepFeatures}
								</ReactMarkdown>							
							)}
						</div>
						<div>
							<Typography variant="subtitle2">
								<Remove />
								Remove Features
							</Typography>
							{editMode ? (
								<FormControl className={classes.formControl + " " + classes.commentInput}>
									<TextFieldMarkdown
										multiline
										label="Remove features"
										variant="outlined"
										name="removeFeatures"
										value={removeFeatures}
										onChange={this.onChangeFeedbackInfoField}
										inputProps={{ maxLength: 2000 }}
										/>
								</FormControl>
							) : (
								<ReactMarkdown>
									{removeFeatures}
								</ReactMarkdown>							
							)}
						</div>
					</DialogContent>
					{editMode && (
						<DialogActions>
							<Button onClick={this.onClose} color="primary">
								Cancel
							</Button>
							<Button
								color="primary"
								onClick={this.onSubmitFeedback}>
								Submit
							</Button>
						</DialogActions>
					)}
				</Dialog>
			</Fragment>
		);
	}
}

ProposalFeedbackDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	proposal: PropTypes.object.isRequired,
	contest: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	onUpdate: PropTypes.func,
	onClose: PropTypes.func,
};

ProposalFeedbackDialog.defaultProps = {
	classes: {},
	proposal: {},
	contest: {},
	open: false,
	onUpdate() {},
	onClose() {},
};

export default withSnackbar(withStyles(styles)(ProposalFeedbackDialog));
