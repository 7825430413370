import { withStyles } from "@material-ui/styles";

const styles = {
	nothing: {
		backgroundColor: 'transparent',
		border: '1px solid #F0F0F0',
		borderRadius: 8,
		width: '100%',
		height: 90,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	nothingText: {
		fontSize: 15,
		opacity: 0.4,
	}
};

function Nothing({ classes }) {
	return (
		<div className={classes.nothing}>
			<div className={classes.nothingText}>No graph to show</div>
		</div>
	);
};

export default withStyles(styles)(Nothing);
