import jwt from '../jwt';

const LocalStore = window.localStorage;

export const TOKENS = 'TOKENS';

const getStoredTokens = () => {
	try {
		const strTokens = LocalStore.getItem(TOKENS);
		if (!strTokens) return null;
		return JSON.parse(strTokens);
	} catch (error) {
		return null;
	}
};

const getValidStoredTokens = (remove = false) => {
	try {
		const strTokens = LocalStore.getItem(TOKENS);
		if (!strTokens) return null;
		const tokens = JSON.parse(strTokens);
		if (!tokens.accessToken) return null;

		if (jwt.isValidToken(tokens.accessToken)) return tokens;

		// Clean storage
		if (remove) LocalStore.removeItem(TOKENS);
		return null;
	} catch (error) {
		return null;
	}
};

const storeTokens = (tokens) => {
	try {
		LocalStore.setItem(TOKENS, JSON.stringify(tokens));
		return true;
	} catch (error) {
		return false;
	}
};

const removeTokens = () => {
	try {
		LocalStore.removeItem(TOKENS);
		return true;
	} catch (error) {
		return false;
	}
};

const authUtils = {
	getStoredTokens,
	getValidStoredTokens,
	storeTokens,
	removeTokens,
};

export default authUtils;
