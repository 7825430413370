const projectConstants = {
	STATES: {
		CLIENT_ONBOARDING: 'client_onboarding',
		PROJECT_SET_UP: 'project_set_up',
		SKETCH_CONTEST: 'sketch_contest',
		MODEL_CONTEST: 'model_contest',
		ELECTRICAL_CONTEST: 'electrical_contest',
		MECHANICAL_CONTEST: 'mechanical_contest',
		PROTOTYPING_CONTEST: 'prototyping_contest',
		CLOSED: 'closed',
	},
	getCurrentState: function (project) {
		return this.getState(project, project.state);
	},
	getState: function (project, stateName) {
		if (!project.states) return null;
		return project.states[stateName];
	},
	stateToString: function (state) {
		switch (state) {
			case this.STATES.CLIENT_ONBOARDING:
				return 'Client Onboarding';
			case this.STATES.PROJECT_SET_UP:
				return 'Project Set Up';
			case this.STATES.SKETCH_CONTEST:
				return 'Sketch Contest';
			case this.STATES.MODEL_CONTEST:
				return '3D Model Contest';
			case this.STATES.ELECTRICAL_CONTEST:
				return 'Electrical Contest';
			case this.STATES.MECHANICAL_CONTEST:
				return 'Mechanical Contest';
			case this.STATES.PROTOTYPING_CONTEST:
				return 'Prototyping Contest';
			case this.STATES.CLOSED:
				return 'Closed';
			default:
				return 'Unknown';
		}
	},
	stateToStringSimplified: function (state) {
		switch (state) {
			case this.STATES.CLIENT_ONBOARDING:
				return 'Onboarding';
			case this.STATES.PROJECT_SET_UP:
				return 'Set Up';
			case this.STATES.SKETCH_CONTEST:
				return 'Sketch';
			case this.STATES.MODEL_CONTEST:
				return '3D Model';
			case this.STATES.ELECTRICAL_CONTEST:
				return 'Electrical';
			case this.STATES.MECHANICAL_CONTEST:
				return 'Mechanical';
			case this.STATES.PROTOTYPING_CONTEST:
				return 'Prototyping';
			case this.STATES.CLOSED:
				return 'Closed';
			default:
				return state;
		}
	},
	isOnContest: function (state) {
		return (this.stateToContestType(state) !== null);
	},
	phaseToString: function (state) {
		let name = this.stateToString(state);
		if (name.indexOf('Contest') >= 0) name = name.replace('Contest', 'Phase');
		else name += 'Phase';
		return name;
	},
	CONTEST_TYPES: {
		SKETCH: 'sketch',
		MODEL: 'model',
		ELECTRICAL: 'electrical',
		MECHANICAL: 'mechanical',
		PROTOTYPING: 'prototyping',
	},
	contestTypeToString: function (type) {
		switch (type) {
			case this.CONTEST_TYPES.SKETCH:
				return 'Sketch';
			case this.CONTEST_TYPES.MODEL:
				return '3D Model';
			case this.CONTEST_TYPES.ELECTRICAL:
				return 'Electrical';
			case this.CONTEST_TYPES.MECHANICAL:
				return 'Mechanical';
			case this.CONTEST_TYPES.PROTOTYPING:
				return 'Prototyping';
			default:
				return null;
		}
	},
	getContest: function (project, contestType) {
		return project.contests.find(c => c.type === contestType);
	},
	getContestByState: function (project, state) {
		const contestType = this.stateToContestType(state);
		if (!contestType) return null;
		return this.getContest(project, contestType);
	},
	getCurrentContest: function (project) {
		return this.getContest(project, project.state);
	},
	contestTypeToState: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return this.STATES.SKETCH_CONTEST;
			case this.CONTEST_TYPES.MODEL:
				return this.STATES.MODEL_CONTEST;
			case this.CONTEST_TYPES.ELECTRICAL:
				return this.STATES.ELECTRICAL_CONTEST;
			case this.CONTEST_TYPES.MECHANICAL:
				return this.STATES.MECHANICAL_CONTEST;
			case this.CONTEST_TYPES.PROTOTYPING:
				return this.STATES.PROTOTYPING_CONTEST;
			default:
				return null;
		}
	},
	stateToContestType: function (state) {
		switch (state) {
			case this.STATES.SKETCH_CONTEST:
				return this.CONTEST_TYPES.SKETCH;
			case this.STATES.MODEL_CONTEST:
				return this.CONTEST_TYPES.MODEL;
			case this.STATES.ELECTRICAL_CONTEST:
				return this.CONTEST_TYPES.ELECTRICAL;
			case this.STATES.MECHANICAL_CONTEST:
				return this.CONTEST_TYPES.MECHANICAL;
			case this.STATES.PROTOTYPING_CONTEST:
				return this.CONTEST_TYPES.PROTOTYPING;
			default:
				return null;
		}
	},
	FEASIBILITY: {
		PENDING: 'pending',
		YES: 'yes',
		NO: 'no',
	},
	feasibilityToString: function (feasibility) {
		switch (feasibility) {
			case this.FEASIBILITY.PENDING:
				return 'Pending';
			case this.FEASIBILITY.YES:
				return 'Yes';
			case this.FEASIBILITY.NO:
				return 'No';
			default:
				return feasibility || 'Unknown';
		}
	},
	FEASIBILITY_REASON: {
		NONE: 'none',
		NOT_FOR_US_NOW: 'not_for_us_now',
		TOO_COMPLEX: 'too_complex',
		NOT_ENOUGH_INFO: 'not_enough_info',
		NOT_FOR_LASTBASIC: 'not_for_lastbasic',
	},
	feasibilityReasonToString: function (reason) {
		switch (reason) {
			case this.FEASIBILITY_REASON.NONE:
				return 'None';
			case this.FEASIBILITY_REASON.NOT_FOR_US_NOW:
				return 'Not for us now';
			case this.FEASIBILITY_REASON.TOO_COMPLEX:
				return 'Too big/complex';
			case this.FEASIBILITY_REASON.NOT_ENOUGH_INFO:
				return 'Not enough info';
			case this.FEASIBILITY_REASON.NOT_FOR_LASTBASIC:
				return 'Not for LastBasic';
			default:
				return reason || 'Unknown';
		}
	},
	READY_TO_PAY: {
		READY: 'ready',
		CHAT_MORE: 'chat_more',
		NOT_READY: 'not_ready',
		NOT_INTERESTED: 'not_interested',
	},
	readyToPayToString: function (readyToPay) {
		switch (readyToPay) {
			case this.READY_TO_PAY.READY:
				return 'I\'m ready to pay';
			case this.READY_TO_PAY.CHAT_MORE:
				return 'Need to chat more';
			case this.READY_TO_PAY.NOT_READY:
				return 'Not ready';
			case this.READY_TO_PAY.NOT_INTERESTED:
				return 'Not interested';
			default:
				return readyToPay || 'Unknown';
		}
	},
	NOT_PAYING_REASON: {
		NOT_ENOUGH_MONEY: 'not_enough_money',
		TESTING_SYSTEM: 'testing_system',
	},
	notPayingReasonToString: function (reason) {
		switch (reason) {
			case this.NOT_PAYING_REASON.NOT_ENOUGH_MONEY:
				return 'Not enough money';
			case this.NOT_PAYING_REASON.TESTING_SYSTEM:
				return 'Just testing the system';
			default:
				return reason || 'Unknown';
		}
	},
	CLIENT_CONFIRMATION: {
		DRAFT: 'draft',
		PENDING: 'pending',
		NO: 'no',
		YES: 'yes',
	},
	SCORING_TYPE: {
		COST: 'cost',
		NUM_PARTS: 'numParts',
		RESOURCE: 'resource',
		COMPLEXITY: 'complexity',
		PRODUCT_REQUIREMENT: 'productRequirements'
	},
	scoringLabelByType: function (scoringType) {
		switch (scoringType) {
			case this.SCORING_TYPE.COST:
				return 'Cost';
			case this.SCORING_TYPE.NUM_PARTS:
				return 'Number of parts';
			case this.SCORING_TYPE.RESOURCE:
				return 'Resource';
			case this.SCORING_TYPE.COMPLEXITY:
				return 'Complexity';
			case this.SCORING_TYPE.PRODUCT_REQUIREMENT:
				return 'Product requirement';
			default:
				return scoringType || 'Unknown'
		}
	},
	scoringToString: function (scoringType, value) {
		if (value < 1 || value > 3) throw new Error('Invalid value for project scoring')
		
		switch (scoringType) {
			case this.SCORING_TYPE.COST:
				return ({
					'1': '>/= 40% Margin Range',
					'2': '0-40% Margin Range',
					'3': 'Project would have 0 or neg margin'
				})[value];
			case this.SCORING_TYPE.NUM_PARTS:
				return ({
					'1': 'Prototype has 0 to 20 parts',
					'2': 'Prototype has 20 to 40 parts',
					'3': 'Prototype has 40+ parts'
				})[value];
			case this.SCORING_TYPE.RESOURCE:
				return ({
					'1': 'Project requires generalists',
					'2': 'Project requires gens/specialists',
					'3': 'Project requires research and specialists'
				})[value];
			case this.SCORING_TYPE.COMPLEXITY:
				return ({
					'1': 'Prototype requires no testing',
					'2': 'Prototype requires minimal debugging',
					'3': 'Prototype requires iterations'
				})[value];
			case this.SCORING_TYPE.PRODUCT_REQUIREMENT:
				return ({
					'1': '>80% of requirements are defined',
					'2': '20-80% of requirements are defined',
					'3': '<20% of requirements are defined'
				})[value];
			default:
				return scoringType || 'Unknown'
		}
	}
};

export default projectConstants;
