import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import {
	Badge, Button, CircularProgress, IconButton,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	TextField, Tooltip, Typography,
} from '@material-ui/core';
import { Add, Delete, Edit, EmojiEventsOutlined, RateReview } from '@material-ui/icons';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';

import { EditingState, RowDetailState } from '@devexpress/dx-react-grid';
import {
	Grid, Table as GridTable, TableHeaderRow, TableColumnResizing, TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';

import { DetailEditCell, DetailCell } from '../../../../../components/grid/detail-cell-utils';
import ConfirmationDialog from '../../../../../components/dialog/confirmation-dialog';
import DatePicker from '../../../../../components/inputs/date-picker';
import ServerAPI from '../../../../../services/server-api';
import { PROJECT, PROPOSAL } from '../../../../../constants';
import ProposalDetails from './proposal-details';
import ProposalFeedbackDialog from './proposal-feedback-dialog';

const customTheme = createTheme({
	overrides: {
		MuiTableCell: {
			root: {
				padding: '4px',
			},
		},
	},
});

const styles = theme => ({
	hiddenInput: {
		display: "none",
	},
	trophyIcon: {
		verticalAlign: "text-bottom",
	},
});

class ProposalGrid extends Component {
	state = {
		// Dialogs
		selectedProposal: null,
		selectedFile: null,
		// Upload file Dialog
		openUploadDialog: false,
		// See feddback
		openFeedbackDialog: false,
		// Edit Dialog
		openEditProposalDialog: false,
		// Delete proposal Dialog
		openDeleteProposalDialog: false,
		// Paging
		expandedRowIds: [],
		currentPage: 0,
		pageSize: 10,
	};
	
	onExpandedRowIdsChange = (expandedRowIds) => {
		this.setState({
			expandedRowIds,
		});
	};
	
	onCurrentPageChange = (v) => {
		this.setState({ expandedRowIds: [], currentPage: v }, () => {
			this.updateData();
		});
	};
	
	onPageSizeChange = (v) => {
		this.setState({ expandedRowIds: [], pageSize: v }, () => {
			this.updateData();
		});
	};
	
	// -----------------
	// Upload dialog
	// -----------------
	
	onOpenUploadDialog = (proposal) => {
		this.setState({
			openUploadDialog: true,
			selectedProposal: proposal,
		});
	};
	
	onCloseUploadDialog = () => {
		this.setState({
			openUploadDialog: false,
			selectedProposal: null,
			selectedFile: null,
		});
	};
	
	onSelectedFileChange = (e) => {
		const { files } = e.target;
		return this.setState({
			selectedFile: (files.length > 0) ? files[0] : null,
		});
	};
	
	onClickConfirmUploadFile = async () => {
		const { enqueueSnackbar, updateData } = this.props;
		const { selectedProposal, selectedFile } = this.state;

		this.setState({
			uploading: true,
		}, async () => {
			const result = await ServerAPI.addFileToProposal(selectedProposal.id, {
				filename: selectedFile.name,
				file: selectedFile,
			});
			
			// Close window
			this.onCloseUploadDialog();
			
			this.setState({
				uploading: false,
			});
			
			if (result.error) return enqueueSnackbar(result.error.message || 'File could not be uploaded', { variant: 'error' });
			enqueueSnackbar('File added', { variant: 'success' });
			
			updateData();
		});
	};
	
	// -----------------
	// Proposal feedback
	// -----------------
	
	onOpenFeedbackDialog = (p) => {
		this.setState({
			openFeedbackDialog: true,
			selectedProposal: p,
		});
	};
	
	onCloseFeedbackDialog = () => {
		this.setState({
			openFeedbackDialog: false,
			selectedProposal: null,
		});
	};

	// --------------------
	// Edit Proposal Dialog
	// --------------------

	onOpenEditProposalDialog = (proposal) => {
		this.setState({
			openEditProposalDialog: true,
			selectedProposal: proposal,
		});
	};
	
	onCloseEditProposalDialog = () => {
		this.setState({
			openEditProposalDialog: false,
			selectedProposal: null,
		});
	};
	
	onChangeCreatedAt = (createdAt) => {
		const { selectedProposal } = this.state;
		selectedProposal.createdAt = createdAt;
		
		this.setState({
			selectedProposal,
		});
	};
	
	onChangeNumber = (e) => {
		const { value } = e.target;
		const { selectedProposal } = this.state;
		selectedProposal.number = value;
		
		this.setState({
			selectedProposal,
		});
	};
		
	onClickConfirmEditProposal = async () => {
		const { enqueueSnackbar, updateData } = this.props;
		const { selectedProposal } = this.state;
		
		// Close window
		this.onCloseEditProposalDialog();
		
		const result = await ServerAPI.updateProposal(selectedProposal.id, {
			number: selectedProposal.number,
			createdAt: selectedProposal.createdAt,
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not updated proposal', { variant: 'error' });
		enqueueSnackbar('Proposal updated', { variant: 'success' });
		
		updateData();
	};
	
	// ---------------
	// Delete proposal
	// ---------------
	
	onOpenDeleteProposalDialog = (proposal) => {
		this.setState({
			openDeleteProposalDialog: true,
			selectedProposal: proposal,
		});
	};
	
	onCloseDeleteProposalDialog = () => {
		this.setState({
			openDeleteProposalDialog: false,
			selectedProposal: null,
		});
	};
	
	onConfirmDeleteProposal = async () => {
		const { enqueueSnackbar, updateData } = this.props;
		const { selectedProposal } = this.state;
		
		// Close window
		this.onCloseDeleteProposalDialog();
		
		const result = await ServerAPI.deleteProposal(selectedProposal.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove proposal', { variant: 'error' });
		enqueueSnackbar('Proposal deleted', { variant: 'success' });
		
		// Update data
		this.setState({ expandedRowIds: [] });
		updateData();
	};
	
	render() {
		const { classes, project, type, updateData } = this.props;
		const {
			// Feedback Dialog
			openFeedbackDialog,
			// Upload Dialog
			openUploadDialog,
			selectedFile,
			uploading,
			// Edit Dialog
			openEditProposalDialog,
			// Delete Dialog
			openDeleteProposalDialog,
			selectedProposal,
			// Paging
			expandedRowIds,
		} = this.state;
		
		const columns = [
			{ name: 'actions', title: 'Actions' },
			{ name: 'proposalNumber', title: 'Proposal Number' },
			{ name: 'expertName', title: 'Expert Name' },
			{ name: 'date', title: 'Date' },
			{ name: 'feedback', title: 'Feedback' },
			{ name: 'rating', title: 'Rating' },
		];
		
		const widthsMap = {actions: 160};
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 150 : 90),
		}));
		
		let contest;
		let rows = [];
		if (project) {
			contest = PROJECT.getContest(project, type);
			if (!contest) return <div>Type not valid</div>;
			
			// Sort by creation ASC
			contest.proposals = contest.proposals.sort((a, b) => a.createdAt - b.createdAt);
			
			rows = contest.proposals.map((p, i) => {
				const u = p.user;
				
				const unseenFileVersionsCount = PROPOSAL.getUnseenFileVersions(p).length;
				const hasApprovedFiles = PROPOSAL.hasApprovedFile(p);
				const isWinner = contest.selectedProposal && (contest.selectedProposal.id.toString() === p.id.toString());
			
				return {
					contest,
					proposal: p,
					actions: <div>
						<Tooltip title="Add file">
							<IconButton aria-label="Add file" onClick={(e) => this.onOpenUploadDialog(p)}>
								<Add />
							</IconButton>
						</Tooltip>
						<Tooltip title="Edit">
							<IconButton aria-label="Add file" onClick={(e) => this.onOpenEditProposalDialog(p)}>
								<Edit />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete">
							<IconButton aria-label="Delete" onClick={(e) => this.onOpenDeleteProposalDialog(p)}>
								<Delete />
							</IconButton>
						</Tooltip>
					</div>,
					proposalNumber: <div className={classes.proposalName}>
						<Badge badgeContent={unseenFileVersionsCount} color="secondary" variant="dot">
							<span>Proposal {p.number ? ('#' + p.number) : 'X'}</span>
						</Badge>
					</div>,
					expertName: <Tooltip title="Go to expert">
						<Link to={'/expert/' + u.id} params={{ user: u }}>
							{u.firstName + " " + u.lastName}
						</Link>
					</Tooltip>,
					date: dayjs(p.createdAt).format('D MMMM YYYY'),
					feedback: <Tooltip title={hasApprovedFiles ? "See Feedback" : "No approved files"}>
						<span>
							<IconButton
								disabled={!hasApprovedFiles}
								aria-label="Feedback"
								onClick={(e) => this.onOpenFeedbackDialog(p)}>
								<RateReview />
							</IconButton>
						</span>
					</Tooltip>,
					rating: <div>
						<span>{(p.feedback && p.feedback.rating) || '-'}</span>
						&nbsp;
						{isWinner && <EmojiEventsOutlined className={classes.trophyIcon} />}
					</div>,
				};
			});
		}
		
		const commitChanges = ({ changed }) => {
			/*let updateGrid = false;
			
			for (const i in changed) {
				const { proposal, update } = changed[i];
				if (!proposal) continue;
				if (proposal.deleted) {
					contest.proposals = contest.proposals.filter(p => p.id !== proposal.id);
				}
				
				if (update) updateGrid = true;
			}
			
			// Update
			this.setState({ project });*/
			
			const { updateData } = this.props;
			updateData();
		};
		
		return (
			<ThemeProvider theme={customTheme}>
				<Grid
					className={classes.table}
					columns={columns}
					rows={rows}
				>
					<RowDetailState
						expandedRowIds={expandedRowIds}
						onExpandedRowIdsChange={this.onExpandedRowIdsChange}
					/>
					<EditingState
						onCommitChanges={commitChanges}
					/>
					<GridTable />
					<TableColumnResizing
						defaultColumnWidths={defaultColumnWidths} />
					<TableHeaderRow />
					<TableRowDetail
						cellComponent={DetailCell}
						contentComponent={ProposalDetails}
					/>
					<DetailEditCell />
				</Grid>
				<Dialog
					fullWidth={true}
					open={openUploadDialog}
					onClose={this.onCloseUploadDialog}
				>
					<DialogTitle>
						Add a new file to the proposal
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Select the file you want to add to this proposal.
						</DialogContentText>
						<input
							className={classes.hiddenInput}
							id="contained-button-file"
							type="file"
							onChange={this.onSelectedFileChange}
						/>
						<label htmlFor="contained-button-file">
							{uploading ? (
								<CircularProgress />
							) : (
								<Button variant="contained" color="primary" component="span">
									Select file
								</Button>
							)}
							&nbsp;
							{selectedFile && (
								<span>File selected: {selectedFile.name}</span>
							)}
						</label>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseUploadDialog} color="primary">
							Cancel
						</Button>
						<Button
							disabled={!selectedFile || uploading}
							color="primary"
							onClick={this.onClickConfirmUploadFile}>
							{uploading ? 'Uploading...' : 'Upload file'}
						</Button>
					</DialogActions>
				</Dialog>
				{selectedProposal && (
					<Fragment>
						<Dialog
							fullWidth={true}
							open={openEditProposalDialog}
							onClose={this.onCloseEditProposalDialog}
							>
							<DialogTitle>
								Edit Proposal
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
								<div style={{ marginBottom: '20px' }}>
									<Typography variant="subtitle2" gutterBottom>
										Number
									</Typography>
									<TextField
										label="Number"
										type="number"
										variant="outlined"
										size="small"
										disabled={selectedProposal.number === null || typeof selectedProposal.number === 'undefined'}
										value={selectedProposal.number}
										onChange={this.onChangeNumber}
										/>
								</div>
								<div>
									<Typography variant="subtitle2" gutterBottom>
										Created at
									</Typography>
									<DatePicker
										fixOverflow={true}
										container={false}
										date={new Date(selectedProposal.createdAt)}
										onChange={this.onChangeCreatedAt}
										/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.onCloseEditProposalDialog} color="primary">
									Cancel
								</Button>
								<Button
									color="primary"
									onClick={this.onClickConfirmEditProposal}>
									Submit
								</Button>
							</DialogActions>
						</Dialog>
						<ProposalFeedbackDialog
							open={openFeedbackDialog}
							contest={contest}
							proposal={selectedProposal}
							onUpdate={updateData}
							onClose={this.onCloseFeedbackDialog}
						/>
					</Fragment>
				)}
				<ConfirmationDialog
					title={'Are you sure you want to delete the proposal?'}
					content={'Deleting the proposal will also delete all the files uploaded.'}
					primaryText={'Delete proposal'}
					secondaryText={'Cancel'}
					open={openDeleteProposalDialog}
					onClose={this.onCloseDeleteProposalDialog}
					onConfirm={this.onConfirmDeleteProposal}
				/>
			</ThemeProvider>
		);
	}
}

ProposalGrid.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
};

ProposalGrid.defaultProps = {
	classes: {},
	project: {},
	type: '',
};

export default withSnackbar(withStyles(styles)(ProposalGrid));
