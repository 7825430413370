import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Box from './components/box';
import ProjectsFinancialsTable from './components/projects-financials-table';

const styles = {
	body: {
		margin: 10,
		color: 'rgb(35, 38, 42)',
	},
	pageTitle: {
		fontSize: 27,
		color: 'rgb(35, 38, 42)',
		fontWeight: 500,
		marginBottom: 15,
		marginLeft: 3,
	},
	boxRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		gap: 20,
	},
};

function FinancialsPage({ classes }) {
	return (
		<div className={classes.body}>
			<div className={classes.pageTitle}>Financials</div>

			<div className={classes.boxRow}>
				<Box title={'Active projects'} color={'#6AE6A5'} width={60}>
					<ProjectsFinancialsTable filter={'active'} color={'#6280BD'} />
				</Box>

				<Box title={'Closed projects'} color={'#7E72ED'} width={40}>
					<ProjectsFinancialsTable filter={'closed'} color={'#6280BD'} />
				</Box>
			</div>
		</div>
	);
}

export default withStyles(styles)(FinancialsPage);
