import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Box, Card, CardContent, Chip, Divider,
	Dialog, DialogTitle, DialogActions, DialogContent,
	FormControl, Typography,
} from '@material-ui/core';

import { ADMIN, USER } from '../../../constants';
import ServerAPI from '../../../services/server-api';
import Auth from '../../../utils/auth';
import UserUtils from '../../../utils/user';
import LoadingBox from '../../../components/loading-box';
import UserSearch from '../../../components/user/user-search';

const styles = theme => ({
	list: {
		listStyle: 'none',
		margin: '20px 0px 0px 0px',
		padding: '0px',
	},
	subscribeButton: {
		padding: '2px 8px',
	},
	formControl: {
		width: '100%',
	},
	dialogContent: {
		overflowY: 'hidden',
	},
	chip: {
		marginRight: '5px',
	},
});

class InternalNotificationPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			internalNotifications: [],
			selectedNotification: null,
			selectedUser: null,
			showAddUserDialog: false,
		};
	}

	componentDidMount = async () => {
		let { internalNotifications } = this.state;
		if (internalNotifications.length > 0) return;

		this.getInternalNotifications();
	};

	getInternalNotifications = async () => {
		const results = await ServerAPI.getInternalNotifications();
		if (results.error) return this.setState({ isLoading: false });

		this.setState({
			isLoading: false,
			internalNotifications: results.data,
		});
	};

	handleClickSubscribe = async (notif, isSubscribed) => {
		const { enqueueSnackbar } = this.props;

		let func;
		if (isSubscribed) {
			func = ServerAPI.unsubscribeToInternalNotification;
		} else {
			func = ServerAPI.subscribeToInternalNotification;
		}

		const result = await func.call(ServerAPI, notif.type);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not change subscription', { variant: 'error' });

		// Update
		this.getInternalNotifications();
	};

	shouldShowEditableUserList () {
		return UserUtils.userHasAdminRole([
			ADMIN.ROLES.OPERATIONS, ADMIN.ROLES.MANAGER, ADMIN.ROLES.SUPERADMIN
		]);
	}

	handleClickAddUser = (n) => {
		this.setState({
			showAddUserDialog: true,
			selectedNotification: n,
		});
	};

	handleCloseAddUser = () => {
		this.setState({
			showAddUserDialog: false,
		});
	};

	handleChangeSelectedUser = (users) => {
		this.setState({
			selectedUser: users.length ? users[0] : null,
		});
	};

	handleAddSelectedUser = async (user) => {
		const { enqueueSnackbar } = this.props;
		const { selectedNotification, selectedUser } = this.state;

		this.setState({
			showAddUserDialog: false,
			selectedNotification: null,
			selectedUser: null,
		});

		const result = await ServerAPI.addUserToInternalNotification(selectedNotification.type, selectedUser.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not add user', { variant: 'error' });

		// Update
		this.getInternalNotifications();
	};

	handleDelete = async (notif, user) => {
		const { enqueueSnackbar } = this.props;

		const result = await ServerAPI.removeUserFromInternalNotification(notif.type, user.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove user', { variant: 'error' });

		// Update
		this.getInternalNotifications();
	};

	render() {
		const { classes } = this.props;

		const {
			isLoading,
			internalNotifications,
			selectedUser,
			showAddUserDialog,
		} = this.state;

		if (isLoading) {
			return <LoadingBox />;
		}

		const token = Auth.getValidStoredTokens();
		const userId = (token && token.user) ? token.user.id : null
		const showEditableUserList = this.shouldShowEditableUserList();

		return (
			<Box className={classes.root}>
				<Card className={classes.root} variant="outlined">
					<CardContent>
						<Typography variant="h5" gutterBottom>
							Internal Notifications
						</Typography>
						<ul className={classes.list}>
							{internalNotifications.map((n, i) => {
								const isSubscribed = n.users.some(u => u.id === userId);

								return (
									<Box py={1} key={n.type}>
										<Typography variant="h6" gutterBottom>
											{n.label || `Unknown (${n.type})`}
										</Typography>
										<Typography variant="subtitle1" gutterBottom>
											{n.description || 'No description'}
										</Typography>
										{showEditableUserList ? (
											<Button
												className={classes.subscribeButton}
												disableElevation
												color="primary"
												variant="contained"
												size="small"
												onClick={() => this.handleClickAddUser(n)}>
												Add user
											</Button>
										) : (
											<Button
												className={classes.subscribeButton}
												disableElevation
												color={isSubscribed ? 'default' : 'primary'}
												variant="contained"
												size="small"
												onClick={() => this.handleClickSubscribe(n, isSubscribed)}>
												{isSubscribed ? 'Unsubscribe' : 'Subscribe'}
											</Button>
										)}
										<Box my={2}>
											{showEditableUserList ? (
												<Fragment>
													{n.users.map(u => (
														<Chip
															key={u.id}
															label={`${u.firstName} ${u.lastName.substr(0, 1)}`}
															onDelete={() => this.handleDelete(n, u)}
															className={classes.chip}
															/>
													))}
												</Fragment>
											) : (
												<Fragment>
													{n.users.map(u => (
														<Chip
															key={u.id}
															label={`${u.firstName} ${u.lastName.substr(0, 1)}`}
															className={classes.chip}
															/>
													))}
												</Fragment>
											)}
											{n.users.length === 0 && (
												<div>No users subscribed</div>
											)}
										</Box>
										{i !== (internalNotifications.length - 1) && (
											<Divider />
										)}
									</Box>
								);
							})}
						</ul>
					</CardContent>
				</Card>
				{showAddUserDialog && (
					<Dialog
						fullWidth={true}
						open={showAddUserDialog}
						onClose={this.handleCloseAddUser}
						>
						<DialogTitle>
							Add user to internal notification
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<FormControl className={classes.formControl}>
								<UserSearch
									type={USER.TYPES.ADMIN}
									maxSelection={1}
									onChange={this.handleChangeSelectedUser}
									/>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleCloseAddUser} color="primary">
								Cancel
							</Button>
							<Button
								disabled={!selectedUser}
								color="primary"
								onClick={this.handleAddSelectedUser}>
								Add selected user
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</Box>
		);
	}
}

InternalNotificationPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

InternalNotificationPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(InternalNotificationPage));
