import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
	level: {
		width: '30px',
		height: '30px',
		borderRadius: '100%',
		border: '2px dashed #E8E8E8',
		textAlign: 'center',

	},
	levelNumber: {
		paddingTop: '3px',
		fontWeight: '600',
		color: 'inherit',
	},
});

class CreditLevel extends React.Component {
	createDynamicStyle = (level) => {
		const style = {};

		const colors = {
			'0': '#D4D4D4',
			'1': '#83B8FF',
			'2': '#FFDB41',
			'3': '#FFB581',
			'4': '#FF75DB',
			'5': '#3FC465',
			'6': '#FFB605',
			'7': '#FF0376',
			'8': '#00D7FF',
			'9': '#70BFD4',
			'10': '#E000FF',
		};

		style.borderColor = ((level in colors) ? colors[level] : '#E000FF');
		style.color = ((level in colors) ? colors[level] : '#E000FF');

		return style;
	}

	render() {
		const {
			classes,
			level,
		} = this.props;

		return (
			<Tooltip title={'Level ' + level}>
				<div className={classes.level} style={{...this.createDynamicStyle(level)}}>
					<div className={classes.levelNumber}>
						{level}
					</div>
				</div>
			</Tooltip>
		);
	}
}

export default withStyles(styles)(CreditLevel);
