import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { withSnackbar } from 'notistack';
import {
	Button, Box, Card, CardContent,
	Typography, Switch, FormControlLabel, Checkbox,
	Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import {
	ArrowBack, Assignment, Description, Feedback, FormatListNumbered, PermMedia, LiveHelp
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { PROJECT, PROPOSAL, CONTEST } from '../../../../constants';
import { AntTabs, AntTab, TabPanel } from '../../../../components/tabs/white-tabs';
import DateRangePicker from '../../../../components/inputs/date-range-picker';
import DropdownMenu from '../../../../components/menu/dropdown-button';
import LoadingBox from '../../../../components/loading-box';
import withRouter from '../../../../components/withRouter';
import ServerAPI from '../../../../services/server-api';
import { HistoryUtils } from '../../../../utils';

import ProposalTab from './proposal-tab';
import ContestCardTab from './contest-card-tab';
import SharedFileTab from './shared-file-tab';
import ContestReviewTab from './contest-review-tab';
import ContestSetupTab from './contest-setup-tab';
import ContestStepsTab from './contest-steps-tab'

const TABS = {
	CONTEST_REVIEW: 'contest-review',
	CONTEST_STEPS: 'cotest-steps',
	CONTEST_SETUP: 'contest-setup',
	PROPOSALS: 'proposals',
	ROUNDS: 'rounds',
	SHARED_FILES: 'shared-files',
};

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	cardContent: {
		padding: "0px",
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		padding: theme.spacing(2),

		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	leftButtons: {
		flex: "1",
	},
	tabs: {
		padding: "16px 16px 0px 16px",
	},
	iconTab: {
		"& svg": {
			verticalAlign: "top",
		},
	},
	dateRange: {
		padding: theme.spacing(0)
	},
	redDot: {
		width: 8,
		height: 8,
		backgroundColor: 'red',
		display: 'inline-block',
		borderRadius: '100%',
	},
	checkbox: {
		padding: "0px 5px 0px 0px",
		margin: 0,
	},
});

class ContestPage extends Component {
	constructor(props) {
		super(props);

		const { params: { type } } = props.match;
		const { query } = props.location;

		this.state = {
			isLoading: !(query && query.project),
			// Data
			type: type || '',
			project: (query) ? query.project : null,
			contestActive: true,
			// Tabs
			tabSelected: TABS.PROPOSALS,
			openCloseContestDialog: false,
			contestEndDate: null,
			sendEmailContestClosed: false,
		};
	}

	setStateSynchronous = (stateUpdate) => {
		return new Promise(resolve => {
			this.setState(stateUpdate, () => resolve());
		});
	};

	updateProjectData = async () => {
		const { id } = this.props.match.params;
		if (!id) return;

		const results = await ServerAPI.getProject(id);
		if (results.error) return;

		return results.data;
	};

	updateData = async (hardRefresh = false) => {
		if (hardRefresh) {
			this.setState({
				isLoading: true,
				project: null,
			});
		}

		const project = await this.updateProjectData();

		// Check if data changed
		if (hardRefresh) {
			if (deepEqual(this.state.project, project)) return;
		}

		this.setState({
			isLoading: false,
			project,
		});
	};

	componentDidMount = async () => {
		const { project } = this.state;
		if (project) return;

		this.updateData();
	};
	
	componentDidUpdate = async () => {
		const { params: { type } } = this.props.match;
		if (type === this.state.type) return;

		this.setState({ type }, () => {
			this.updateData(true);
		});
	};

	goBack = () => {
		HistoryUtils.goBackOrPush({
			pathname: '/projects',
		});
	};

	onChangeTab = (e, v) => {
		this.setState({
			tabSelected: v,
		});
	};

	contestToString = (type) => {
		const state = PROJECT.contestTypeToState(type);
		return PROJECT.stateToString(state);
	};

	updateState = async (data, showSnackbar = true) => {
		const { enqueueSnackbar } = this.props;
		const { type, project } = this.state;
		const stateName = PROJECT.contestTypeToState(type);
		const state = PROJECT.getState(project, stateName);

		const prevState = {};
		for (const k in data) {
			prevState[k] = state[k];
			state[k] = data[k];
		}

		await this.setStateSynchronous({ project });

		const result = await ServerAPI.updateState(project.id, stateName, data);
		if (result.error) {
			// Recover state
			for (const k in prevState) {
				state[k] = prevState[k];
			}
			this.setState({ project });
			return enqueueSnackbar(result.error.message || 'Could not update contest', { variant: 'error' });
		}

		if (showSnackbar) enqueueSnackbar('Contest updated', { variant: 'success' });
	};

	onStartTimeChange = async (startDate) => {
		const { enqueueSnackbar } = this.props;
		const { type, project } = this.state;
		const stateName = PROJECT.contestTypeToState(type);
		const state = PROJECT.getState(project, stateName);

		if (!startDate && state.endDate) {
			return enqueueSnackbar('Start date cannot be empty with a selected end date', { variant: 'error' });
		}

		await this.updateState({
			startDate,
		});
	};

	onEndTimeChange = async (endDate) => {
		const { enqueueSnackbar } = this.props;
		const { type, project } = this.state;
		const stateName = PROJECT.contestTypeToState(type);
		const state = PROJECT.getState(project, stateName);

		if (!state.startDate && endDate) {
			return enqueueSnackbar('Start date cannot be empty with a selected end date', { variant: 'error' });
		}

		this.setState({
			contestEndDate: endDate,
			openCloseContestDialog: true,
		});
	};

	onChangeActivity = async (e) => {
		const state = e.target.checked;
		const { project, type } = this.state;

		const { id } = this.props.match.params;
		const { enqueueSnackbar } = this.props;

		const result = await ServerAPI.updateContest(id, type, {
			active: state,
		});

		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update contest', { variant: 'error' });
		enqueueSnackbar('Contest updated', { variant: 'success' });

		const contests = project.contests.map(c => {
			if (c.type !== type) return c;
			return {
				...c,
				active: state,
			};
		});

		project.contests = contests;

		this.setState({
			project: project,
		});
	};

	onCloseContestDialog = () => {
		this.setState({
			openCloseContestDialog: false,
			sendEmailContestClosed: false,
		});
	};

	onSendEmailContestClosed = (event) => {
		this.setState({ sendEmailContestClosed: event.target.checked });
	};

	onConfirmContestDialog = async () => {
		const { type, contestEndDate, sendEmailContestClosed } = this.state;
		const { id } = this.props.match.params;
		const { enqueueSnackbar } = this.props;

		this.onCloseContestDialog();

		await this.updateState({
			endDate: contestEndDate,
		}, false);

		if (contestEndDate) {
			const result = await ServerAPI.closeContest(id, type, sendEmailContestClosed);
			if (result.error) {
				await this.updateState({
					endDate: null,
				}, false);
				return enqueueSnackbar(result.error.message || 'Could not close contest', { variant: 'error' });
			}
		}

		enqueueSnackbar((contestEndDate ? 'Contest closed' : 'Contest updated') + (sendEmailContestClosed ? ' and emails sent' : ''), { variant: 'success' });
	};

	changeType = (type) => {
		this.setState({
			type,
		});
	};

	handleClickOtherContest = (item) => {
		const { navigate } = this.props;
		const { project } = this.state;
		const { value } = item;
		navigate({
			pathname: '/project/' + project.id + '/contest/' + value
		});
	};

	render() {
		const { classes } = this.props;
		const {
			isLoading,
			// Data
			type,
			project,
			// Tabs
			tabSelected,
			openCloseContestDialog,
			sendEmailContestClosed,
			contestEndDate,
		} = this.state;

		if (isLoading) return <LoadingBox />;
		else if (!project) return <div>Project not found</div>;

		const stateName = PROJECT.contestTypeToState(type);
		if (!stateName) {
			// Check if an ID has been passed
			const contestById = project.contests.find(c => c.id === type);
			if (contestById) {
				this.changeType(contestById.type);
				return null;
			} else {
				return <div>Contest type not valid</div>;
			}
		}

		const contest = PROJECT.getContest(project, type);
		const state = PROJECT.getState(project, stateName);
		const name = PROJECT.phaseToString(stateName);
		const startDate = state.startDate ? new Date(state.startDate) : null;
		const endDate = state.endDate ? new Date(state.endDate) : null;

		const unseenVersionFiles = contest.proposals.reduce((acc, p) => {
			const unseenFiles = PROPOSAL.getUnseenFileVersions(p);
			return acc + unseenFiles.length;
		}, 0);

		// Check for not approved shared files
		const unseenSharedFiles = CONTEST.getUnseenSharedFiles(contest).length;

		const otherContests = Object.values(CONTEST.CONTEST_TYPES)
			.map(c => ({
				label: CONTEST.contestTypeToString(c),
				value: c,
			}));

		return (
			<Fragment>
				<Box className={classes.root}>
					<Card variant="outlined">
						<CardContent className={classes.cardContent}>
							<div className={classes.buttons}>
								<div className={classes.leftButtons}>
									<Button
										startIcon={<ArrowBack />}
										onClick={this.goBack}
									>
										Go Back
									</Button>
								</div>
								<div className={classes.rightButtons}>
									<DropdownMenu
										label="Other contests"
										items={otherContests}
										onClickItem={this.handleClickOtherContest}
									/>
								</div>
							</div>
							<Typography variant="h5" gutterBottom>
								{this.contestToString(type)}
							</Typography>
							<Typography gutterBottom>
								{project.name}
							</Typography>
							<Typography variant="body2" gutterBottom>
								{project.code || '-'}
							</Typography>

							<FormControlLabel
								control={<Switch
									checked={contest.active}
									onChange={this.onChangeActivity}
									color="primary"
									name="contestActive"
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>}
								label="Active"
							/>
							<Box m={3}>
								<Typography variant="subtitle2" gutterBottom>
									Duration of {name}
								</Typography>
								<DateRangePicker
									className={classes.dateRange}
									container={false}
									isClearable={true}
									placeholderText={'No date'}
									startDate={startDate}
									endDate={endDate}
									onChangeStart={this.onStartTimeChange}
									onChangeEnd={this.onEndTimeChange}
								/>
							</Box>
							<AntTabs
								className={classes.tabs}
								onChange={this.onChangeTab}
								scrollButtons="auto"
								value={tabSelected}
								variant="scrollable"
							>
								<AntTab
									label={<span className={classes.iconTab}><Description /> Proposals {unseenVersionFiles > 0 ? <div className={classes.redDot}/> : '' }</span>}
									value={TABS.PROPOSALS}
								/>
								<AntTab
									label={<span className={classes.iconTab}><LiveHelp /> Contest Setup</span>}
									value={TABS.CONTEST_SETUP}
								/>
								<AntTab
									label={<span className={classes.iconTab}><Assignment /> Rounds</span>}
									value={TABS.ROUNDS}
								/>
								<AntTab
									label={<span className={classes.iconTab}><PermMedia /> Shared Files {unseenSharedFiles > 0 ? <div className={classes.redDot}/> : '' }</span>}
									value={TABS.SHARED_FILES}
								/>
								<AntTab
									label={<span className={classes.iconTab}><FormatListNumbered /> Contest steps</span>}
									value={TABS.CONTEST_STEPS}
								/>
								<AntTab
									label={<span className={classes.iconTab}><Feedback /> Contest review</span>}
									value={TABS.CONTEST_REVIEW}
								/>
							</AntTabs>
							<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.PROPOSALS}>
								<ProposalTab
									contest={contest}
									project={project}
									type={type}
									updateData={this.updateData}
								/>
							</TabPanel>
							<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.CONTEST_SETUP}>
								<ContestSetupTab
									contest={contest}
									project={project}
									type={type}
									updateData={this.updateData}
								/>
							</TabPanel>
							<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.ROUNDS}>
								<ContestCardTab
									contest={contest}
									project={project}
									type={type}
									updateData={this.updateData}
								/>
							</TabPanel>
							<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.SHARED_FILES}>
								<SharedFileTab
									contest={contest}
									project={project}
									type={type}
									updateData={this.updateData}
								/>
							</TabPanel>
							<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.CONTEST_STEPS}>
								<ContestStepsTab
									contest={contest}
									contestType={type}
									project={project}
									updateData={this.updateData}
								/>
							</TabPanel>
							<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.CONTEST_REVIEW}>
								<ContestReviewTab
									contest={contest}
								/>
							</TabPanel>
						</CardContent>
					</Card>
				</Box>
				<Dialog
					fullWidth={true}
					open={openCloseContestDialog}
					onClose={this.onCloseContestDialog}
				>
					<DialogTitle>
						{contestEndDate === null ? 'Edit contest end date' : 'Close contest'}
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						{contestEndDate === null ? 'You are about to remove the end date' : 'You are about to close a contest'}
						{contestEndDate !== null &&
							<Fragment>
								<br/><br/>
								<Checkbox
									className={classes.checkbox}
									checked={sendEmailContestClosed}
									onChange={(e) => this.onSendEmailContestClosed(e)}
									name="sendEmail"
									color="primary"
								/>
								Send email to project owner and participants
							</Fragment>
						}
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseContestDialog}>
							Cancel
						</Button>
						<Button onClick={this.onConfirmContestDialog} color="primary">
							{contestEndDate === null ? 'Remove end date' : 'Close contest'}
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

ContestPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ContestPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(ContestPage)));
