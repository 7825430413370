import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import {
	Button,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	FormControl,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { USER } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';
import UserSearch from '../../../../components/user/user-search';
import ProposalGrid from '../components/proposal-grid';

const styles = () => ({
	tableActions: {
		margin: "20px 0px",
	},
	formControl: {
		width: "100%",
	},
	dialogContent: {
		overflowY: "hidden",
	},
});

class ProposalTab extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			openAddExpertDialog: false,
			selectedExperts: [],
		};
	}
	
	onOpenAddExpertDialog = () => {
		this.setState({
			openAddExpertDialog: true,
		});
	};
	
	onCloseAddExpertDialog = () => {
		this.setState({
			openAddExpertDialog: false,
			selectedExperts: [],
		});
	};
	
	onChangeSelectedExperts = (experts) => {
		this.setState({
			selectedExperts: experts,
		});
	};
	
	onClickConfirmAddExpert = async () => {
		const { enqueueSnackbar, project, type, updateData } = this.props;
		const { selectedExperts } = this.state;
		
		// Close dialog
		this.onCloseAddExpertDialog();
		
		// Add expert to contest
		const result = await ServerAPI.createProposal({
			projectId: project.id,
			userId: selectedExperts[0].id,
			type: type,
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update project', { variant: 'error' });
		enqueueSnackbar('Project updated', { variant: 'success' });
		
		// Update list of proposals
		updateData();
	};
	
	render() {
		const { classes, project, type, updateData } = this.props;
		const {
			openAddExpertDialog,
			selectedExperts,
		} = this.state;
		
		return (
			<React.Fragment>
				<div className={classes.tableActions}>
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={this.onOpenAddExpertDialog}>
						Add Expert
					</Button>		
				</div>
				<ProposalGrid
					project={project}
					type={type}
					updateData={updateData}
					/>
				<Dialog
					fullWidth={true}
					open={openAddExpertDialog}
					onClose={this.onCloseAddExpertDialog}
					>
					<DialogTitle>
						Add a new expert to contest
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<DialogContentText>
							Search the expert by first name, last name or email.
						</DialogContentText>
						<FormControl className={classes.formControl}>
							<UserSearch
								type={USER.TYPES.EXPERT}
								maxSelection={1}
								onChange={this.onChangeSelectedExperts}
								/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseAddExpertDialog} color="primary">
							Cancel
						</Button>
						<Button
							disabled={selectedExperts.length === 0}
							color="primary"
							onClick={this.onClickConfirmAddExpert}>
							Add selected expert
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

ProposalTab.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	updateData: PropTypes.func.isRequired,
};

ProposalTab.defaultProps = {
	classes: {},
	project: {},
	type: null,
	updateData() {},
};

export default withSnackbar(withStyles(styles)(ProposalTab));
