class LastWins {
	constructor() {
		this.queue = [];
	}
	
	clear() {
		this.queue = this.queue.map(w => ({...w, active: false}));
	}
	
	enter() {
		this.clear();
		
		const max = this.queue.reduce((acc, w) => Math.max(acc, w.id), 0);
		const myId = max + 1;
		this.queue.push({ id: myId, active: true });
		return myId;
	}
	
	exit(myId) {
		const index = this.queue.findIndex(w => w.id === myId);
		if (index >= 0) {
			const myself = this.queue[index];
			this.queue.splice(index, 1);
			return myself.active;
		}
	}
}

export default LastWins;
