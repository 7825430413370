import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
	Button,Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	nextSteps: {
		width: "100%",
	},
});

function NextStepsDialog(props) {
	const {
		classes,
		open,
		project,
		onSubmit,
		onClose,
	} = props;
		
	const [nextSteps, setNextSteps] = useState(project && project.nextSteps);
	
	useEffect(() => {
		setNextSteps(project && project.nextSteps);
	}, [project]);
	
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
		>
			<DialogTitle>Edit next steps</DialogTitle>
			<DialogContent>
				<TextField
					className={classes.nextSteps}
					label="Next steps"
					multiline
					value={nextSteps || ''}
					variant="outlined"
					name="nextSteps"
					onChange={(e) => setNextSteps(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button onClick={() => onSubmit(nextSteps)} color="primary" autoFocus>
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}

NextStepsDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	project: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

NextStepsDialog.defaultProps = {
	classes: {},
	open: false,
	project: {},
	onSubmit() {},
	onClose() {},
};

export default withStyles(styles)(NextStepsDialog);
