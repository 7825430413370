import { Button, IconButton, Paper, Popover } from '@material-ui/core';
import { Launch, MoreVert, Notifications, Visibility, VisibilityOff } from '@material-ui/icons';
import PopupState, { bindToggle, bindPopover } from 'material-ui-popup-state';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';

import Config from '../../../../config';
import ServerAPI from '../../../../services/server-api';
import withRouter from '../../../../components/withRouter';

const styles = () => ({
	iconButton: {
		padding: "6px",
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		padding: "10px",
	},
});

function MoreActionPopup({ classes, enqueueSnackbar, navigate, project, onUpdate = () => {} }) {
	const onClickUpdates = () => {
		navigate({
			pathname: '/project/' + project.id + '/updates'
		});
	};

	const onClickHideAction = async (hide = true) => {
		const result = await ServerAPI.updateProject(project.id, {
			hidden: hide,
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update project', { variant: 'error' });
		enqueueSnackbar('Project has been updated', { variant: 'success' });
		
		onUpdate();
	};
	
	return (
		<PopupState variant="popover">
			{(popupState) => (
				<div className={classes.popupState}>
					<IconButton
						className={classes.iconButton}
						aria-label="open"
						{...bindToggle(popupState)}
					>
						<MoreVert />
					</IconButton>
					<Popover {...bindPopover(popupState)}>
						<Paper className={classes.paper}>
							<Button
								startIcon={<Notifications />}
								onClick={() => onClickUpdates()}
							>
								Updates
							</Button>
							{project.hidden ? (
								<Button
									startIcon={<Visibility />}
									onClick={(e) => onClickHideAction(false)}
								>
									Unhide Project
								</Button>
							) : (
								<Button
									startIcon={<VisibilityOff />}
									onClick={(e) => onClickHideAction(true)}
								>
									Hide Project
								</Button>
							)}
							<Button
								startIcon={<Launch />}
								href={ServerAPI.getPublicBriefingUrl(project)}
								target="_blank"
							>
								Link to briefing
							</Button>
							<Button
								startIcon={<Launch />}
								href={Config.lastbasic.platform.host + '/project/' + project.id}
								target="_blank"
							>
								Link to platform
							</Button>
							<Button
								startIcon={<Launch />}
								href={Config.lastbasic.platform.host + '/project/' + project.id + '/unlock'}
								target="_blank"
							>
								Link to Unlock Project
							</Button>
						</Paper>
					</Popover>
				</div>
			)}
		</PopupState>
	);
}

export default withSnackbar(withStyles(styles)(withRouter(MoreActionPopup)));
