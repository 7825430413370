import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import {
	Card, CardContent, Typography
} from '@material-ui/core';

const styles = (theme) => ({
	statContent: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	data: {
		fontWeight: "500",
	},
});

class Stat extends Component {
	render() {
		const { classes, data, label } = this.props;
		
		return (
			<Card className={classes.stat} variant="outlined">
				<CardContent className={classes.statContent}>
					<Typography className={classes.label} variant="subtitle1">
						{label}
					</Typography>
					<Typography className={classes.data} variant="h2">
						{data}
					</Typography>
				</CardContent>
			</Card>
		);
	}
}

export default withStyles(styles)(Stat);
