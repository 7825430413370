import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
	TextField,
} from '@material-ui/core'

const styles = theme => ({
	
});

class DelayedTextField extends React.Component {
	timeout = 200; // 200 ms
	timeoutHandle = null;
	
	constructor(props) {
		super(props);
		
		const { value } = props;
		
		this.state = {
			value,
			localChange: false,
		};
	}
	
	static getDerivedStateFromProps(props, state) {
		if (props.value !== state.value) {
			if (state.localChange) {
				return {
					...state,
					localChange: false,
				};
			} else {
				return {
					...state,
					value: props.value,
				};	
			}
		}
		
		return state;
	}
	
	onChange(e) {
		const { onStartChange, onChange } = this.props;
		const { value } = e.target;
		
		// Simulate change
		onStartChange({
			target: {
				value,
			}
		});
		
		this.setState({
			value,
			localChange: true,
		}, () => {
			if (this.timeoutHandler) {
				clearTimeout(this.timeoutHandler);
			}

			this.timeoutHandler = setTimeout(() => {
				this.timeoutHandler = null;
				
				// FIXME: check if we can do this cleaner
				// HACK: Simulate event
				onChange({
					target: {
						value,
					}
				});
			}, this.timeout);
		});
	}
	
	render() {
		const { onChange, onStartChange, ...props } = this.props;
		const { value } = this.state;
		
		return (
			<TextField
				{...props}
				value={value || ''}
				onChange={(e) => this.onChange(e)}
				/>
		);
	}
}

DelayedTextField.propTypes = {
	value: PropTypes.string,
	onStartChange: PropTypes.func,
	onChange: PropTypes.func,
};

DelayedTextField.defaultProps = {
	value: null,
	onStartChange() {},
	onChange() {},
};

export default withStyles(styles)(DelayedTextField);
