import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
	Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,	
} from '@material-ui/core';

const styles = () => ({
	
});

class ConfirmationDialog extends Component {
	render() {
		const {
			className,
			color,
			content,
			onClose,
			onConfirm,
			open,
			primaryText,
			secondaryText,
			title,
			variant,
		} = this.props;
		
		return (
			<Dialog
				className={className}
				open={open}
				onClose={onClose}
				fullWidth={true}
				>
				<DialogTitle>
					{title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose}>
						{secondaryText}
					</Button>
					<Button
						color={(color ? color : 'primary')}
						onClick={onConfirm}
						variant={variant}
					>
						{primaryText}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

ConfirmationDialog.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string,
	primaryText: PropTypes.string,
	secondaryText: PropTypes.string,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
};

ConfirmationDialog.defaultProps = {
	className: '',
	title: '',
	content: '',
	primaryText: '',
	secondaryText: '',
	onClose() {},
	onConfirm() {},
};

export default withStyles(styles)(ConfirmationDialog);
