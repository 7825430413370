import styles from './graph.module.css';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function Graph({ data }) {
	const result = data;

	//if (!results) return <div className={styles.graph}></div>;

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		backgroundColor: "#F5DEB3",
		plugins: {
			title: {
				display: true,
				text: 'Year to date',
			},
		},
	};

	const labels = result.labels;

	const barData = {
		labels,
		datasets: result.datasets,
	};

	// "labels": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

	return (
		<div className={styles.graph}>
			<div className={styles.graphWrap}>

				<div className={styles.desktop}>
					<Bar options={options} data={barData} height={'300px'} plugins={[ChartDataLabels]} />
				</div>
			</div>
		</div>
	);
}
