import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = theme => ({
	bar: {
		width: '150px',
		height: '28px',
		backgroundColor: '#F5F5F5',
		borderRadius: '50px',
		border: '0px solid #E3E3E3',
		cursor: 'pointer',
		opacity: '0.3',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	number: {
		textAlign: 'center',
		position: 'absolute',
		width: '150px',
		height: '28px',
		paddingTop: '4px',
	},
	some: {
		backgroundColor: '#F2CE72',
		opacity: '1',
	}
});

class WalletBar extends React.Component {
	render() {
		const {
			classes,
			walletBalance,
			userId,
		} = this.props;

		return (
			<div style={{ width: '150px' }}>
				<Link to={'/payments/users/' + userId + '/wallet'}>
					<Tooltip title={'Open wallet page'}>
						<div className={classes.bar + ' ' + (walletBalance > 0 ? classes.some : '')}>
							<div className={classes.number}>{walletBalance}€</div>
						</div>
					</Tooltip>
				</Link>
			</div>
		);
	}
}

export default withStyles(styles)(WalletBar);
