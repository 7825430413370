import React, { Component, Fragment } from 'react';
import deepEqual from 'deep-equal';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Box, Card, CardContent,
	Typography, Select, MenuItem, Input,
	Dialog, DialogActions, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import { ArrowBack, Add, Delete } from '@material-ui/icons';
import {
	Grid, Table, TableColumnResizing, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import UPDATE from '../../../constants/update';
import PROJECT from '../../../constants/project';
import DatePicker from '../../../components/inputs/date-picker';
import LoadingBox from '../../../components/loading-box';
import withRouter from '../../../components/withRouter';
import ServerAPI from '../../../services/server-api';
import { HistoryUtils } from '../../../utils';

const styles = () => ({
	root: {
		textAlign: "center"
	},
	header: {
		marginBottom: "50px",
	},
	table: {
		"& tbody tr td:first-child": {
			whiteSpace: "nowrap",
		},
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	leftButtons: {
		flex: "1",
	},
});

class UpdatesPage extends Component {
	constructor(props) {
		super(props);

		const { params: { type } } = props.match;
		const { query } = props.location;
		
		this.state = {
			isLoading: !(query && query.project),
			type: type || '',
			project: (query) ? query.project : null,
			openAddNotificationDialog: false,
			openDeleteNotificationDialog: false,
			notificationType: UPDATE.UPDATE_TYPES.PROJECT_CREATED, 
			notificationId: null,
			notificationContest: null,
			notificationCustom: null,
			notificationDate: new Date(),
		};
	}

	componentDidMount = async () => {
		let { project } = this.state;
		if (project) return;
		
		this.updateData();
	};

	upperFirst = (text) => {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	updateData = async () => {
		const project = await this.updateProjectData();
		
		// Check if data changed
		if (deepEqual(this.state.project, project)) return;

		const rows = project.updates.map(update => {
			const date = new Date(update.createdAt);
			const readableDate = date.toISOString().substr(0,10);

			const type = update.type.replace('_', ' ');
			const readableType = type.charAt(0).toUpperCase() + type.slice(1);

			let readableDetails = [];
			if (update.details) {
				readableDetails = Object.entries(update.details);
			}

			return {
				date: readableDate,
				type: readableType,
				details: readableDetails.map(([k, v]) => {
					return <Fragment>
						<b>{this.upperFirst(k) + ': '}</b>{v}<br />
					</Fragment>;
				}),
				action: <IconButton aria-label="Delete update" onClick={() => this.onOpenDeleteNotificationDialog(update.id)}>
					<Delete />
				</IconButton>,
			};
		});

		this.setState({
			isLoading: false,
			project,
			rows,
		});
	};

	updateProjectData = async () => {
		const { id } = this.props.match.params;
		if (!id) return;
		
		const results = await ServerAPI.getProject(id);
		if (results.error) return null
		
		return results.data;
	};

	goBack = () => {
		const { project } = this.state;

		HistoryUtils.goBackOrPush({
			pathname: '/project/' + project.id,
		});
	};

	onOpenAddNotificationDialog = () => {
		this.setState({
			openAddNotificationDialog: true,
		});
	}

	onCloseAddNotificationDialog = () => {
		this.setState({
			openAddNotificationDialog: false,
			notificationType: UPDATE.UPDATE_TYPES.PROJECT_CREATED, 
			showContest: false,
			notificationContest: null,
			notificationDate: new Date(),
			showCustom: false,
			notificationCustom: null,
			notificationCustomTitle: '',
			notificationCustomDescription: '',
		});
	}

	onNotificationTypeChange = (e) => {
		const { value } = e.target;
		this.setState({
			notificationType: value,
		});

		const needsContestType = [UPDATE.UPDATE_TYPES.WINNER_SELECTED, UPDATE.UPDATE_TYPES.CONTEST_STARTED];
		const needsCustom = [UPDATE.UPDATE_TYPES.CUSTOM];

		if (needsContestType.indexOf(value) > -1) {
			this.setState({
				notificationContest: PROJECT.CONTEST_TYPES.SKETCH,
				notificationCustom: false,
				showContest: true,
				showCustom: false,
			});
		}
		else if (needsCustom.indexOf(value) > -1) {
			this.setState({
				notificationContest: null,
				notificationCustom: true,
				showCustom: true,
				showContest: false,
			});
		}
		else {
			this.setState({
				notificationContest: null,
				notificationCustom: false,
				showCustom: false,
				showContest: false,
			});
		}
	}

	onNotificationContestChange = (e) => {
		const { value } = e.target;
		this.setState({
			notificationContest: value,
		});
	}

	onNotificationCustomTitleChange = (e) => {
		const { value } = e.target;
		this.setState({
			notificationCustomTitle: value,
		});
	}

	onNotificationCustomDescriptionChange = (e) => {
		const { value } = e.target;
		this.setState({
			notificationCustomDescription: value,
		});
	}

	onNotificationDateChange = (date) => {
		this.setState({
			notificationDate: date,
		});
	}

	onOpenDeleteNotificationDialog = (notificationId) => {
		this.setState({
			openDeleteNotificationDialog: true,
			notificationId: notificationId,
		});
	}

	onCloseDeleteNotificationDialog = () => {
		this.setState({
			openDeleteNotificationDialog: false,
		});
	}

	onConfirmDeleteNotification = async () => {
		const { enqueueSnackbar } = this.props;
		const { project, notificationId } = this.state;

		const result = await ServerAPI.deleteUpdate(project.id, notificationId);
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Could not delete update', { variant: 'error' });
		}
		
		enqueueSnackbar('Update deleted', { variant: 'success' });

		// Update data
		this.updateData();
		
		// Close dialog
		this.onCloseDeleteNotificationDialog();
	}

	onConfirmAddNotification = async () => {
		const { enqueueSnackbar } = this.props;
		const { 
			project, 
			notificationType, 
			notificationContest, 
			notificationDate,
			notificationCustom,
			notificationCustomTitle,
			notificationCustomDescription,
		} = this.state;

		const data = {
			type: notificationType,
			date: notificationDate,
		};

		if (notificationContest) {
			data.details = {
				contest: notificationContest,
			};
		}

		if (notificationCustom) {
			data.details = {
				title: notificationCustomTitle,
				description: notificationCustomDescription,
			};
		}

		const result = await ServerAPI.addUpdate(project.id, data);
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Could not create update', { variant: 'error' });
		}
		
		enqueueSnackbar('Update created', { variant: 'success' });

		// Update data
		this.updateData();
		
		// Close dialog
		this.onCloseAddNotificationDialog();
	}

	render() {
		const { classes } = this.props;
		const {
			isLoading,
			// Data
			project,
			openAddNotificationDialog,
			showContest,
			showCustom,
			rows,
			openDeleteNotificationDialog,
			notificationType,
			notificationDate,
		} = this.state;

		const columns = [
			{ name: 'date', title: 'Date' },
			{ name: 'type', title: 'Update type' },
			{ name: 'details', title: 'Details' },
			{ name: 'action', title: 'Action' },
		];

		const widthsMap = { actions: 180, duration: 250 };
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 180 : 120),
		}));

		if (isLoading) return <LoadingBox />;

		return (
			<Box className={classes.root}>
				<Card variant="outlined">
					<CardContent className={classes.cardContent}>
						<div className={classes.buttons}>
							<div className={classes.leftButtons}>
								<Button
									startIcon={<ArrowBack />}
									onClick={this.goBack}>
									Go Back
								</Button>
							</div>
						</div>
						<Typography variant="h5" gutterBottom>
							Project Updates
						</Typography>
						<Typography gutterBottom>
							{project.name}
						</Typography>
						<Typography variant="body2" gutterBottom>
							{project.code || '-'}
						</Typography>
					</CardContent>
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={this.onOpenAddNotificationDialog}>
						Add Notification
					</Button>
					<Grid
						className={classes.table}
						columns={columns}
						rows={rows}
						>
						<Table />
						<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
						<TableHeaderRow />
					</Grid>
					<Dialog
						open={openAddNotificationDialog}
						fullWidth={true}
						onClose={this.onCloseAddNotificationDialog}
						style={{ minWidth: '500px'}}
						>
						<DialogTitle>
							Add a new notification
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<DatePicker
								fixOverflow={true}
								container={false}
								date={notificationDate}
								onChange={this.onNotificationDateChange}
								inline={true}
							/>
							<Select
								labelId="notificationType-label"
								id="notificationType"
								name="notificationType"
								onChange={this.onNotificationTypeChange}
								defaultValue={notificationType}
								style={{ marginLeft: 20 }}
								>
								{Object.values(UPDATE.UPDATE_TYPES).map((type, i) => (
									<MenuItem value={type} key={i}>
										{UPDATE.updateTypeToString(type)}
									</MenuItem>
								))}
							</Select>
							<Select
								labelId="notificationContest-label"
								id="notificationContest"
								name="notificationContest"
								onChange={this.onNotificationContestChange}
								defaultValue={PROJECT.CONTEST_TYPES.SKETCH}
								style={{ marginLeft: 20, display: (showContest ? 'inline-flex' : 'none') }}
								>
								{Object.values(PROJECT.CONTEST_TYPES).map((type, i) => (
									<MenuItem value={type} key={i}>
										{PROJECT.contestTypeToString(type)}
									</MenuItem>
								))}
							</Select>
							<Input
								placeholder={'Title'}
								onChange={this.onNotificationCustomTitleChange}
								style={{ marginLeft: 20, display: (showCustom ? 'inline-flex' : 'none') }}
							></Input>
							<Input
								fullWidth={true}
								placeholder={'Description'}
								onChange={this.onNotificationCustomDescriptionChange}
								style={{ marginTop: 20, marginBottom: 10, display: (showCustom ? 'inline-flex' : 'none') }}
							></Input>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onCloseAddNotificationDialog} color="primary">
								Cancel
							</Button>
							<Button
								color="primary"
								onClick={this.onConfirmAddNotification}>
								Create notification
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						open={openDeleteNotificationDialog}
						onClose={this.onCloseDeleteNotificationDialog}
						fullWidth={true}
						>
						<DialogTitle>
							Delete notification
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							Are you sure you want to delete this notification? You can add it later again
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onCloseDeleteNotificationDialog} color="primary">
								Cancel
							</Button>
							<Button
								color="primary"
								onClick={this.onConfirmDeleteNotification}>
								Delete notification
							</Button>
						</DialogActions>
					</Dialog>
				</Card>
			</Box>
		);
	};
}

export default withSnackbar(withStyles(styles)(withRouter(UpdatesPage)));
