import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Button, Card, CardContent, Checkbox,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	IconButton, Paper, Popover, Tooltip, Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import {
	AttachMoney, CalendarToday, Chat, KeyboardArrowDown, Launch, ThumbUp,
	Star,
} from '@material-ui/icons';

import PopupState, { bindToggle, bindPopover } from 'material-ui-popup-state';
import {
	CustomPaging, IntegratedSorting,
	PagingState, SortingState,
} from '@devexpress/dx-react-grid';
import {
	Grid, PagingPanel, Table, TableHeaderRow, TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';

import DynamicStatus from '../components/dynamic-status';
import FeasibilityDropdown from '../components/feasibility-dropdown';
import NextStepsDialog from './next-steps-dialog';
import ProjectFilter from '../components/project-filter';
import ProjectMiscPopup from '../components/project-misc-popup';
import ProjectStatus from '../components/project-status';

import { PROJECT } from '../../../../constants';
import { TABS } from '../components/project-filter';
import withRouter from '../../../../components/withRouter';
import { Sync } from '../../../../utils';
import ServerAPI from '../../../../services/server-api';

const FILTER_TYPES = {
	PENDING_FEASIBILITY: 'pending_feasibility',
	PENDING_PAYMENT: 'pending_payment',
	ACTIVE: 'active',
	CLOSED: 'closed',
	HIDDEN: 'hidden',
	ALL: 'all'
};

const IGNORE_COLUMNS_BY_TYPE = {
	[FILTER_TYPES.PENDING_FEASIBILITY]: ['dynamicStatus'],
	[FILTER_TYPES.PENDING_PAYMENT]: ['dynamicStatus'],
	[FILTER_TYPES.ACTIVE]: ['actions', 'paid', 'feasibility'],
	[FILTER_TYPES.CLOSED]: ['dynamicStatus', 'paid', 'feasibility'],
	[FILTER_TYPES.HIDDEN]: ['dynamicStatus', 'paid', 'feasibility'],
	[FILTER_TYPES.ALL]: ['dynamicStatus'],
};

const SORTING_BY_TYPE = {
	[FILTER_TYPES.PENDING_FEASIBILITY]:	null,
	[FILTER_TYPES.PENDING_PAYMENT]: null,
	[FILTER_TYPES.ACTIVE]: null,
	[FILTER_TYPES.CLOSED]: null,
	[FILTER_TYPES.HIDDEN]: null,
	[FILTER_TYPES.ALL]: [
		{ columnName: "createdAt", direction: "desc" },
	],
};

const DEFAULT_SORTING = [
	{ columnName: "code", direction: "desc" },
	{ columnName: "createdAt", direction: "desc" },
];

const DEFAULT_FILTER = {
	startTime: null,
	endTime: null,
	name: null,
	type: TABS.ACTIVE,
};

const styles = () => ({
	root: {
		textAlign: "center"
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	leftButtons: {
		flex: "1",
	},
	cardContent: {
		padding: "24px 0px",
	},
	divider: {
		margin: "24px 0px",
	},
	calendarIcon: {
		verticalAlign: "bottom",
		marginRight: "5px",
	},
	feasibilitySelect: {
		'&:before': {
			border: 'none !important',
		},
		'&:after': {
			border: 'none !important',
		}
	},
	selectChip: {
		margin: "0px auto",
	},
	feasibilitySelectChipNo: {
		border: "2px solid #1976d2",
		backgroundColor: "transparent",
	},
	miscCell: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	starredProject: {
		color: '#FFD700',
		marginRight: '5px',
		marginLeft: '0px',
		fontSize: '15px',
	},
	projectName: {
		display: 'flex',
		alignItems: 'center'
	}
});

class ProjectsPage extends Component {
	lastWins = new Sync();

	constructor(props) {
		super(props);

		// Get filter
		const currentFilter = this.getInitialFilter();

		this.state = {
			gridLoading: true,
			// Filter
			...currentFilter,
			// Data
			projectsData: {},
			// Grid
			sorting: SORTING_BY_TYPE[currentFilter.type] || DEFAULT_SORTING,
			currentPage: 0,
			pageSize: 20,
			// Dialog
			openPaidDialog: false,
			openFeasibilityDialog: false,
			selectedProject: null,
			selectedFeasibility: null,
			openNextStepsDialog: false,
		};
	}

	getSearchFilter = () => {
		const { location } = this.props;
		const searchQuery = new URLSearchParams(location.search);

		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			if (!searchQuery.get(p)) continue;
			savedFilter[p] = searchQuery.get(p);
		}

		// Validate & transform
		if (savedFilter.startTime) {
			const d = new Date(savedFilter.startTime);
			if (isNaN(d.getTime())) delete savedFilter.startTime;
			else savedFilter.startTime = d;
		}
		if (savedFilter.endTime) {
			const d = new Date(savedFilter.endTime);
			if (isNaN(d.getTime())) delete savedFilter.endTime;
			else savedFilter.endTime = d;
		}
		if (savedFilter.name && typeof savedFilter.name !== 'string') {
			delete savedFilter.name;
		}
		const types = new Set(Object.values(TABS));
		if (savedFilter.type && !types.has(savedFilter.type)) {
			delete savedFilter.type;
		}

		return savedFilter;
	};

	getInitialFilter = () => {
		const searchFilter = this.getSearchFilter();

		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			savedFilter[p] = searchFilter[p] || DEFAULT_FILTER[p];
		}

		return savedFilter;
	};

	saveCurrentFilter = (newParams) => {
		const { navigate } = this.props;
		const searchFilter = this.getSearchFilter();
		
		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			const value = (typeof newParams[p] !== 'undefined') ? newParams[p] : searchFilter[p];
			if (!value) continue;
			savedFilter[p] = (value instanceof Date)
				? value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
				: value;
		}
		
		navigate({
			search: '?' + new URLSearchParams(savedFilter).toString()
		});
	};

	setStateSynchronous = (stateUpdate) => {
		return new Promise(resolve => {
			this.setState(stateUpdate, () => resolve());
		});
	};

	updateProjectsData = async (newState = {}) => {
		const {
			startTime, endTime, name, type, feasibility,
			sorting, currentPage, pageSize
		} = { ...this.state, ...newState };

		const results = await ServerAPI.getProjects({
			startTime, endTime, name, type, feasibility,
			sorting, currentPage, pageSize
		});
		if (results.error) return {};

		return results.data;
	};

	updateData = async (newState = {}) => {
		const key = this.lastWins.enter();

		await this.setStateSynchronous({
			gridLoading: true,
			...newState,
		});
		this.saveCurrentFilter(newState);

		const projectsData = await this.updateProjectsData(newState);

		// Get out of here
		const isLast = this.lastWins.exit(key);
		if (!isLast) return;

		this.setState({
			gridLoading: false,
			projectsData,
		});
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();
	};

	onStartTimeChange = (date) => {
		this.updateData({
			startTime: date,
			currentPage: 0,
		});
	};

	onEndTimeChange = (date) => {
		this.updateData({
			endTime: date,
			currentPage: 0,
		});
	};

	onNameStartChange = () => {
		// Deactivate other workers
		this.lastWins.clear();
	};

	onNameChange = (text) => {
		this.updateData({
			name: text,
			currentPage: 0,
		});
	};

	onTypeChange = (type = '') => {
		this.updateData({
			type,
			sorting: SORTING_BY_TYPE[type] || DEFAULT_SORTING,
			currentPage: 0,
		});
	};

	onFeasibilityChange = (feasibility = '') => {
		this.updateData({
			feasibility,
			currentPage: 0,
		});
	};

	onClearFilter = () => {
		this.updateData({
			...DEFAULT_FILTER,
			expandedRowIds: [],
			currentPage: 0,
		});
	};

	onCurrentPageChange = (v) => {
		this.updateData({
			currentPage: v,
		});
	};

	onPageSizeChange = (v) => {
		this.updateData({
			pageSize: v,
		});
	};

	onSortingChange = (v) => {
		const found = v.find(obj => obj.columnName === 'createdAt');
		if (!found) v.push({ columnName: "createdAt", direction: "desc" });
		
		this.updateData({
			sorting: v,
			currentPage: 0
		});
	};

	onChangeProjectField = async (project, field, newValue, doUpdate = true) => {
		const { projectsData } = this.state;
		
		// Update
		project[field] = newValue;
		
		// Update projetc in list
		projectsData.projects = projectsData.projects.map(p => {
			if (p.id === project.id) return project;
			else return p;
		});
		
		// Update state
		await this.setStateSynchronous({ projectsData });
		
		if (doUpdate) {
			const { enqueueSnackbar } = this.props;
			
			// Update data
			const result = await ServerAPI.updateProject(project.id, {
				[field]: project[field]
			});
			if (result.error) return enqueueSnackbar(result.error.message || 'Could not update project', { variant: 'error' });
			enqueueSnackbar('Project updated', { variant: 'success' });
		}
	}

	handleClickConfirmFeasibility = async () => {
		// Reload list
		await this.updateData();
	};

	onChangePaid = (event, project) => {
		const { target: { checked } } = event;
		const { enqueueSnackbar } = this.props;
		
		if (!checked) return enqueueSnackbar('Can not mark a project as unpaid', { variant: 'error' });
		if (project.feasibility !== PROJECT.FEASIBILITY.YES) {
			return enqueueSnackbar('Project must be feasible', { variant: 'error' });
		}
		
		this.setState({
			openPaidDialog: true,
			selectedProject: project
		});
	};

	onClosePaidDialog = () => {
		this.setState({
			openPaidDialog: false,
			selectedProject: null
		});
	};

	onClickConfirmPaid = async () => {
		const { selectedProject } = this.state;
		this.onClosePaidDialog();
		// Fake the event click
		const newValue = true;
		await this.onChangeProjectField(selectedProject, 'paid', newValue);
		// Reload list
		this.updateData();
	};

	onOpenNextStepsDialog = (e, p) => {
		this.setState({
			openNextStepsDialog: true,
			selectedProject: p,
		});
	};

	onCloseNextStepsDialog = () => {
		this.setState({
			openNextStepsDialog: false,
			selectedProject: null,
		});
	};

	onConfirmNextStepsDialog = async (newNextSteps) => {
		const { selectedProject } = this.state;
		this.onCloseNextStepsDialog();
		// Fake the event click
		await this.onChangeProjectField(selectedProject, 'nextSteps', newNextSteps);
		// Reload list
		this.updateData();
	};

	render() {
		const { classes } = this.props;
		const {
			gridLoading,
			// Filter
			startTime, endTime,
			name,
			type,
			feasibility,
			// Data
			projectsData,
			// Grid
			sorting,
			currentPage,
			pageSize,
			// Dialog
			openPaidDialog,
			openFeasibilityDialog,
			openNextStepsDialog,
			selectedProject,
		} = this.state;

		const sortingStateColumnExtensions = [
			{ columnName: 'actions', sortingEnabled: false },
			{ columnName: 'feasibility', sortingEnabled: false },
			{ columnName: 'paid', sortingEnabled: false },
			{ columnName: 'misc', sortingEnabled: false },
			{ columnName: 'contests', sortingEnabled: false },
			{ columnName: 'dynamicStatus', sortingEnabled: false },
		];

		let columns = [
			{ name: 'actions', title: 'Actions' },
			{ name: 'clientName', title: 'Client Name' },
			{ name: 'projectName', title: 'Project Name' },
			{ name: 'misc', title: <span></span> },
			{ name: 'feasibility', title: <Tooltip title="Feasibility">
				<ThumbUp style={{ marginLeft: "26px" }} />
			</Tooltip> },
			{ name: 'paid', title: <Tooltip title="Paid">
				<AttachMoney style={{ marginLeft: "26px" }} />
			</Tooltip> },
			{ name: 'code', title: <span style={{ marginLeft: "26px" }}>Code</span> },
			{ name: 'contests', title: 'Contests' },
			{ name: 'dynamicStatus', title: 'Status' },
		];

		// Bug in devextreme: https://github.com/DevExpress/devextreme-reactive/issues/1411
		// Always define the COMPLETE default column widths
		const widthsMap = {
			actions: 90,
			clientName: (type === 'active') ? 130 : null,
			projectName: (type === 'active') ? 130 : null,
			feasibility: 90,
			paid: 90,
			misc: 100,
			code: 90,
			contests: 260,
			dynamicStatus: 350,
		};
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || 170,
		}));

		// Ignore columns by type
		if (IGNORE_COLUMNS_BY_TYPE[type]) {
			columns = columns.filter(c => IGNORE_COLUMNS_BY_TYPE[type].indexOf(c.name) === -1);
		}

		const columnExtensions = [
			{ columnName: 'feasibility', align: 'center' },
			{ columnName: 'paid', align: 'center' },
			{ columnName: 'misc', align: 'center' },
			{ columnName: 'code', align: 'center' },
		];
		
		let rows = [];
		if (gridLoading) {
			// Create a skeleton for each cell
			for (let i = 0; i < pageSize; i++) {
				rows.push(Object.fromEntries(columns.map(c => {
					return [c.name, (<Skeleton width="100%"><Typography>&nbsp;</Typography></Skeleton>)];
				})));
			}
		} else if (projectsData.projects && projectsData.projects.length > 0) {
			rows = projectsData.projects.map(p => {
				let isStarredProject = false;
				let totalScore = 0;

				if (p.scoring) {
					for (const [key, value] of Object.entries(p.scoring)) {
						totalScore += value;
						console.log(key, totalScore);
					}
				}

				if (p.feasibility === 'yes' && totalScore <= 8) {
					isStarredProject = true;
				}

				return {
					actions: <Tooltip title="Open">
						<Link to={'/project/' + p.id} params={{ project: p }}>
							<IconButton aria-label="open">
								<Launch />
							</IconButton>
						</Link>
					</Tooltip>,
					clientName: p.owner.firstName + " " + p.owner.lastName,
					projectName: <div className={classes.projectName}>
						<Link to={'/project/' + p.id} params={{ project: p }} style={{ cursor: 'pointer', color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}>
							<Tooltip title={dayjs(p.createdAt).format('DD/MM/YYYY')} className={classes.calendarIcon}>
								<CalendarToday />
							</Tooltip>
							{isStarredProject &&
								<Tooltip title={'This project has a score of ' + totalScore}>
									<span className={classes.starredProject}>
										<div style={{ display: 'inline-block', paddingTop: '5px' }}>
											<Star fontSize='inherit' color='inherit' />
										</div>
									</span>
								</Tooltip>
							}
							{(p.name === 'No name') ? p.productSolution : p.name}
						</Link>
					</div>,
					feasibility: <FeasibilityDropdown
						project={p}
						onConfirm={this.handleClickConfirmFeasibility}
					/>,
					paid: <Checkbox
						name="paid"
						color="primary"
						checked={p.paid}
						onChange={(e) => this.onChangePaid(e, p)}
					/>,
					code: p.code || '-',
					contests: <ProjectStatus
						project={p}
					/>,
					misc: <div className={classes.miscCell}>
						<PopupState variant="popover" >
							{(popupState) => (
								<div className={classes.popupState}>
									<IconButton aria-label="open" {...bindToggle(popupState)}>
										<KeyboardArrowDown />
									</IconButton>
									<Popover {...bindPopover(popupState)}>
										<Paper style={{ padding: '10px', background: "#fafafa" }}>
											<ProjectMiscPopup project={p} onClick={(e) => popupState.close()}/>
										</Paper>
									</Popover>
								</div>
							)}
						</PopupState>
						<Tooltip title={p.nextSteps || '--'}>
							<IconButton aria-label="open" onClick={(e) => this.onOpenNextStepsDialog(e, p)}>
								<Chat />
							</IconButton>
						</Tooltip>
					</div>,
					dynamicStatus: <DynamicStatus project={p} />,
				};
			});
		}

		return (
			<Box className={classes.root}>
				<Card variant="outlined">
					<CardContent className={classes.cardContent}>
						<Typography variant="h5" gutterBottom>
							Projects
						</Typography>
						<ProjectFilter
							startTime={startTime}
							endTime={endTime}
							name={name}
							type={type}
							feasibility={feasibility}
							onStartTimeChange={this.onStartTimeChange}
							onEndTimeChange={this.onEndTimeChange}
							onNameStartChange={this.onNameStartChange}
							onNameChange={this.onNameChange}
							onTypeChange={this.onTypeChange}
							onFeasibilityChange={this.onFeasibilityChange}
							onClearFilter={this.onClearFilter}
						/>
						<Grid
							className={classes.table}
							columns={columns}
							rows={rows}
						>
							<PagingState
								currentPage={currentPage}
								onCurrentPageChange={this.onCurrentPageChange}
								pageSize={pageSize}
								onPageSizeChange={this.onPageSizeChange}
							/>
							<CustomPaging
								totalCount={projectsData.totalCount}
							/>
							<SortingState
								sorting={sorting}
								columnExtensions={sortingStateColumnExtensions}
								onSortingChange={this.onSortingChange}
							/>
							<IntegratedSorting />
							<Table columnExtensions={columnExtensions} />
							<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
							<TableHeaderRow showSortingControls />
							<PagingPanel
								pageSizes={[20, 30, 40, 50]}
							/>
						</Grid>
					</CardContent>
				</Card>
				<Dialog
					open={openFeasibilityDialog}
					onClose={this.onCloseFeasibilityDialog}
				>
					<DialogTitle>Are you sure you want to update the feasibility of the project?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Project selected: <b>{selectedProject && selectedProject.name}</b>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseFeasibilityDialog} color="primary">
							Cancel
						</Button>
						<Button onClick={this.onClickConfirmFeasibility} color="primary" autoFocus>
							Ok, update feasibility
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={openPaidDialog}
					onClose={this.onClosePaidDialog}
				>
					<DialogTitle>Are you sure you want to publish the project?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Project selected: <b>{selectedProject && selectedProject.name}</b>
							<br /><br />
							Marking the project as paid will generate a <b>project code</b> and <b>publish</b> the project in the platform.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onClosePaidDialog} color="primary">
							Cancel
						</Button>
						<Button onClick={this.onClickConfirmPaid} color="primary" autoFocus>
							Ok, create code and publish
						</Button>
					</DialogActions>
				</Dialog>
				<NextStepsDialog
					project={selectedProject}
					open={openNextStepsDialog}
					onClose={this.onCloseNextStepsDialog}
					onSubmit={this.onConfirmNextStepsDialog}
				/>
			</Box>
		);
	}
}

ProjectsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ProjectsPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(ProjectsPage)));
