import React from 'react';
import { withStyles } from '@material-ui/styles';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

import Nothing from './nothing';

HighchartsMore(Highcharts);

const styles = {};

function WaterfallGraph({ before = [], after = [], currencyName, currencySymbol }) {
	const totalBefore = calculateTotals(before);
	const totalAfter = calculateTotals(after);

	if ((!before.length && !after.length )|| (totalBefore + totalAfter) === 0) {
		return <Nothing />;
	}

	function calculateTotals(data) {
		return data.reduce((acc, line) => acc += line.value, 0);
	}

	function toGraphData(data) {
		return data.map(line => {
			return {
				name: line.title,
				y: line.value,
			};
		});
	}

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={{
				chart: {
					type: 'waterfall',
					height: 250,
				},
				title: {
					text: null,
				},
				xAxis: {
					type: 'category'
				},
				yAxis: {
					title: {
						text: currencyName,
					}
				},
				legend: {
					enabled: false
				},
				tooltip: {
					// eslint-disable-next-line no-template-curly-in-string
					pointFormat: '<b>${point.y:,.2f}</b> ' + currencyName, 
				},
				series: [{
					upColor: '#91F0A1',
					color: '#FFD97D',
					data: [
					...toGraphData(before),
					{
						name: 'Positive balance',
						isIntermediateSum: true,
						color: '#858585',
					},
					...toGraphData(after),
					{
						name: 'Margin',
						isSum: true,
						color: '#858585',
					}],
					dataLabels: {
						enabled: true,
						formatter: function () {
							return this.y + currencySymbol;
						},
						style: {
							fontWeight: 'bold',
						},
					},
					pointPadding: 0
				}]
			}}
		/>
	);
}

export default withStyles(styles)(WaterfallGraph);
