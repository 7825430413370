import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Avatar, IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { EXPERT } from '../../../../constants';

const styles = () => ({
	flagsList: {
		display: 'inline-flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: "center",
	},
	item: {
		margin: "0px 2px",
		
		"&:first-child": {
			marginLeft: "0px",
		},
		"&:last-child": {
			marginRight: "0px",
		},
	},
	iconButton: {
		padding: "2px",
		border: "2px solid transparent",

		'&.active': {
			background: "#eee",
			border: "2px solid #989898",
		}
	},
	iconAvatar: {
		width: "32px",
		height: "32px",
	},
	tagName: {
		fontSize: "12px",
		fontWeight: "600",
	},
});

class ExpertFlags extends Component {
	constructor(props) {
		super(props);
		
		const initialState = ExpertFlags.getDerivedStateFromProps(props, {});
		
		this.state = {
			...initialState,
		};
	}
	
	static getDerivedStateFromProps(props, state) {
		if (state.localChange) {
			return {
				...state,
				localChange: false,
			};
		} else {
			const { FLAGS } = EXPERT;
			const { flags } = props;

			const selectedFlags = Object.fromEntries(Object.values(FLAGS).map(f => {
				return [f, flags.includes(f)];
			}));

			return {
				...state,
				selectedFlags,
			};	
		}
	}
	
	onClick(f) {
		const { onChange } = this.props;
		const { selectedFlags } = this.state;
		
		// Toggle selected flag
		selectedFlags[f] = !selectedFlags[f];
		
		this.setState({ selectedFlags, localChange: true }, () => {
			const selectedValues = Object.entries(selectedFlags).filter(([k, v]) => {
				return v;
			}).map(([k, v]) => {
				return k;
			});
			onChange(selectedValues);
		});
	}
	
	renderFlag(flag, editable = false) {
		const { classes } = this.props;
		const { selectedFlags } = this.state;
		
		let color;
		let altText = "";
		let tagText = "";
		
		const { FLAGS } = EXPERT;
		
		switch (flag) {
			case FLAGS.SKETCH:
				color = "#02b2da";
				altText = "Sketch contest";
				tagText = "SK";
				break;
			case FLAGS.MODEL:
				color = "#d9b41e";
				altText = "3D modeling";
				tagText = "3D";
				break;
			case FLAGS.ELECTRICAL:
				color = "#3aa936";
				altText = "Electrical";
				tagText = "EL";
				break;
			case FLAGS.MECHANICAL:
				color = "#ea4e1d";
				altText = "Mechanical";
				tagText = "ME";
				break;
			case FLAGS.PROTOTYPE:
				color = "#682384";
				altText = "Prototyping";
				tagText = "PR";
				break;
			case FLAGS.EVENTS:
				color = "orange";
				altText = "Events (afterworks, meetings..)";
				tagText = "EV";
				break;
			default:
				color = "black";
				altText = "No label";
				tagText = "-";
		}

		const selected = selectedFlags[flag];

		return (
			<Tooltip title={altText}>
				{editable ? (
					<IconButton className={classes.iconButton + (selected ? ' active' : '')} onClick={() => this.onClick(flag)}>
						<Avatar className={classes.iconAvatar} style={{ backgroundColor: color }}>
							<span className={classes.tagName}>{tagText}</span>
						</Avatar>
					</IconButton>
				) : (
					<Avatar className={classes.iconAvatar} style={{ backgroundColor: color }}>
						<span className={classes.tagName}>{tagText}</span>
					</Avatar>
				)}
			</Tooltip>
		);
	}
	
	render() {
		const { classes, flags, editable } = this.props;
		const { FLAGS } = EXPERT;
		
		const flagList = editable ? Object.values(FLAGS) : flags;
		
		return (
			<div className={classes.flagsList}>
				{flagList.length === 0 ? (
					<div>
						--
					</div>
				) : flagList.map((f, i) => (
					<div key={i} className={classes.item}>
						{this.renderFlag(f, editable)}
					</div>
				))}
			</div>
		);
	}
}

ExpertFlags.propTypes = {
	classes: PropTypes.object.isRequired,
	editable: PropTypes.bool,
	flags: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func,
};

ExpertFlags.defaultProps = {
	classes: {},
	editable: false,
	flags: [],
	onChange() {},
};

export default withStyles(styles)(ExpertFlags);
