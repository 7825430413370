import React from 'react';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import DoubleArrowRoundedIcon from '@material-ui/icons/DoubleArrowRounded';

import ServerAPI from '../../../../services/server-api';

const styles = theme => ({
	cover: {
		width: '28px',
		height: '28px',
		backgroundColor: '#F5F5F5',
		borderRadius: '100%',
		border: '0px solid #E3E3E3',
		textAlign: 'center',
		paddingTop: '4px',
		paddingLeft: '0px',
		opacity: '0.3',
		cursor: 'no-drop',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	coverAccept: {
		cursor: 'pointer',
		opacity: '1',
		backgroundColor: '#EBCE20',
	}
});

class ConvertCredit extends React.Component {
	convert = async (e) => {
		const { changeResults, data, minCreditUntilPayment, creditBalance, userId, enqueueSnackbar } = this.props;
		data.creditBalance = creditBalance - minCreditUntilPayment;
		data.walletBalance = data.walletBalance + minCreditUntilPayment;
		changeResults({ ...data, time: new Date() });

		// Actually make change
		const result = await ServerAPI.transferCredits({ userId, amount: minCreditUntilPayment });

		if (result.error) {
			// Revert UI
			data.creditBalance = data.creditBalance + minCreditUntilPayment;
			data.walletBalance = data.walletBalance - minCreditUntilPayment;
			changeResults({ ...data, time: new Date() });

			return enqueueSnackbar(result.error.message || 'Credit could not be converted', { variant: 'error' });
		}

		return enqueueSnackbar('Credit converted successfully', { variant: 'success' });
	}

	render() {
		const {
			classes,
			minCreditUntilPayment,
			creditBalance,
		} = this.props;

		const allowConversion = (creditBalance >= minCreditUntilPayment);
		return (
			<Tooltip title={(allowConversion ? 'Convert 400€ in credits into wallet' : `Needs to reach ${minCreditUntilPayment}€ in credit to convert into wallet`)}>
				<div onClick={this.convert} className={classes.cover + ' ' + (allowConversion ? classes.coverAccept : '')}>
					<DoubleArrowRoundedIcon
						fontSize='small'
						style={{ color: '#333' }}
					/>
				</div>
			</Tooltip>
		);
	}
}

export default withSnackbar(withStyles(styles)(ConvertCredit));
