import {
	LOGIN_AUTH_ERROR, LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT,
} from './types';

const initialState = {
	authError: null,
	data: null,
	error: null,
	loading: false,
};

function userReducers(state = initialState, action) {
	switch (action.type) {
		case LOGIN_AUTH_ERROR:
			return {
				...state,
				authError: action.error,
				loading  : false,
			};
		case LOGIN_ERROR:
			return {
				...state,
				error  : action.error,
				loading: false,
			};
		case LOGIN_REQUEST:
			return {
				...state,
				error  : null,
				loading: true,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				data: action.data,
			};
		case LOGOUT:
			return initialState;
		default:
			return state;
	}
}

export default userReducers;
