import React, { useCallback, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link, Navigate } from 'react-router-dom';

import {
	Box, Card, CardContent, CircularProgress, Typography,
	IconButton, Tooltip,
} from '@material-ui/core';
import { Launch } from '@material-ui/icons';

import CreditPage from './tabs/credit';
import WalletPage from './tabs/wallet';
import PayoutsPage from './tabs/payouts';

import { ADMIN } from '../../../constants';
import ServerAPI from '../../../services/server-api';
import UserUtils from '../../../utils/user';

import withRouter from '../../../components/withRouter';

function showWalletBasedOnUser() {
	return UserUtils.userHasAdminRole([
		ADMIN.ROLES.DEVELOPMENT, ADMIN.ROLES.SUPERADMIN
	]);
}

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
	cardContent: {
		textAlign: 'left',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '120px',
	},
	name: {
		opacity: '0.5',
	},
	topLink: { 
		color: 'rgba(0, 0, 0, 0.87)', 
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
	},
	header: {
		display: 'inline-block',
		position: 'relative',
		width: '100%',
	},
	sections: {
		display: 'block',
		position: 'relative',
		float: 'right',
		marginTop: '6px',
	},
	title: {
		display: 'block',
		position: 'relative',
		float: 'left',
	},
	topNavigation: {
		marginLeft: '30px',
		fontSize: '16px',
		color: 'rgba(0, 0, 0, 0.87)',
		textDecoration: 'none',
		opacity: '0.5',
		'&:hover': {
			textDecoration: 'underline',
			opacity: '0.8',
		},
	},
	content: {
		display: 'block',
	},
	topNavigationInfo: {
		opacity: '0.6',
	},
});

function PaymentsUserPage({ match, classes }) {
	const { page, userId } = match.params;
	const [result, setResult] = useState({ data: null, error: null, isLoading: true });

	const { data, error, isLoading } = result;
	const showWallet = showWalletBasedOnUser();

	const getPayments = useCallback(async () => {
		setResult(r => ({
			...r,
			isLoading: true,
		}));
		const currentResult = await ServerAPI.getUserPayments({ userId });
		if (currentResult.error) {
			return setResult(r => ({
				error: currentResult.error,
				data: null,
				isLoading: false,
			}));
		}

		setResult(r => ({
			error: null,
			data: currentResult.data,
			isLoading: false,
		}));
	}, [userId]);

	useEffect(() => {
		getPayments();
	}, [getPayments]);

	const setForceUpdate = () => {
		getPayments();
	};
	
	if (!page || ['credit', 'wallet', 'payouts'].indexOf(page) === -1) {
		return <Navigate to={`/payments/users/${userId}/credit`} />;
	} else if (error) {
		return <Navigate to={'/payments'} />;
	}

	if (isLoading) {
		return (
			<Box className={classes.root}>
				<CircularProgress />
			</Box>
		);
	}
	
	return (
		<Box className={classes.root}>
			<Card variant="outlined">
				<CardContent className={classes.cardContent}>
					<div className={classes.header}>
						<div className={classes.title}>
							<Typography variant="h5" gutterBottom>
								<Link to={'/payments'} className={classes.topLink}>
									Payments
								</Link> <span className={classes.name}>
								/ <Link to={'/payments/users/' + data.id + '/credit'} className={classes.topLink}>
									{data.firstName} {data.lastName}
								</Link>

								<Link to={'/expert/' + data.id}>
									<IconButton aria-label="open" size="small" style={{ marginLeft: '10px', marginRight: '5px', marginTop: '-3px' }}>
										<Tooltip title={'Open expert profile'}>
											<Launch />
										</Tooltip>
									</IconButton>
								</Link>
								&nbsp;/ <span style={{ textTransform: 'capitalize' }}>{page}</span></span>
							</Typography>
						</div>
						<div className={classes.sections}>
							<Link to={`/payments/users/${userId}/credit`} className={classes.topNavigation}>
								Credit <span className={classes.topNavigationInfo}>({data.creditBalance}€)</span>
							</Link>

							<Link to={`/payments/users/${userId}/wallet`} className={classes.topNavigation} style={(showWallet ? { display: 'inline-block' } : { display: 'none' })}>
								Wallet <span className={classes.topNavigationInfo}>({data.walletBalance}€)</span>
							</Link>

							<Link to={`/payments/users/${userId}/payouts`} className={classes.topNavigation}>
								Payouts <span className={classes.topNavigationInfo}>({data.openPayouts} open)</span>
							</Link>
						</div>
					</div>

					<div className={classes.content}>
						{page === 'credit' && <CreditPage data={data} setForceUpdate={setForceUpdate} />}

						{page === 'wallet' && <WalletPage data={data} setForceUpdate={setForceUpdate} />}

						{page === 'payouts' && <PayoutsPage data={data} setForceUpdate={setForceUpdate} />}
					</div>
				</CardContent>
			</Card>
		</Box>
	);
}

export default withStyles(styles)(withRouter(PaymentsUserPage));
