import React, { Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Config from '../../../../config';
import { StringUtils } from '../../../../utils';

const styles = {
	item: {
		marginTop: 3,
	},
	linedItem: {
		borderBottom: '1px solid #EDEDED',
		padding: 10,
	},
	key: {
		display: 'inline',
		marginRight: 5,
		fontWeight: 500,
	},
	value: {
		display: 'inline',
	},

	header: {
		marginBottom: 12,
	},
	projectImage: {
		backgroundColor: '#F0F0F0',
		border: '1px solid #F0F0F0',
		borderRadius: 8,
		width: '100%',
		height: 90,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
	},
	ownerAvatar: {
		width: 70,
		height: 70,
		borderRadius: 80,
		backgroundColor: '#F0F0F0',
		position: 'relative',
		marginTop: '-50px',
		marginLeft: 10,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		border: '1px solid #E0E0E0',
	},
	progressWrapper: {
		display: 'inline-block',
		width: '30%',
		height: 15,
		verticalAlign: 'middle',
		borderRadius: 10,
		backgroundColor: '#F0F0F0',
		marginTop: '-2px',
		marginLeft: 4,
	},
	progressValue: {
		width: '90%',
		height: 15,
		borderRadius: 10,
		backgroundColor: '#6D89C1',
	},
	progressValueText: {
		display: 'inline-block',
		marginLeft: 7,
		marginRight: 7,
		opacity: 0.6,
	},
	progressValueAge: {
		display: 'inline-block',
		opacity: 0.6,
	},
	marginAbsolute: {
		display: 'inline-block',
		opacity: 0.6,
		marginLeft: 7,
	},
};

function getAvatarUrl(project) {
	const userId = project.owner.id;

	return `${Config.lastbasic.api.host}/public/user/${userId}/avatar`;
}

function getProjectThumbnailUrl(project) {
	const fileId = (project.image && project.image.id) || null;

	if (!fileId) return null;
	return `${Config.lastbasic.api.host}/public/file/${fileId}/thumbnail`;
}

function calculateProjectAge(project) {
	const projectSetupInvoice = project.financials.revenue && project.financials.revenue.lines && project.financials.revenue.lines.find(l => l.type === 'project_setup');

	let createdAt;
	if (projectSetupInvoice) {
		createdAt = projectSetupInvoice.createdAt;
	} else {
		createdAt = project.createdAt;
	}

	createdAt = new Date(createdAt);

	return StringUtils.timeSince(createdAt);
}

function ProjectInformation({ classes, project }) {
	let currencySymbol = '';
	if (project && project.financials && project.financials.currency) {
		currencySymbol = StringUtils.currencyToSymbol(project.financials.currency);
	}

	const hasProjectImage = (project.image && !!project.image.id) || false;

	return (
		<Fragment>
			<div className={classes.header}>
				<div className={classes.projectImage} style={(hasProjectImage ? { backgroundImage: `url(${getProjectThumbnailUrl(project)})` } : {})}></div>
				<div className={classes.ownerAvatar} style={{ backgroundImage: `url(${getAvatarUrl(project)})`}}></div>
			</div>

			<div className={classes.item}>
				<div className={classes.key}>Project name:</div>
				<div className={classes.value}>{project.name}</div>
			</div>

			<div className={classes.item}>
				<div className={classes.key}>Owner:</div>
				<div className={classes.value}>{project.owner.firstName} {project.owner.lastName}</div>
			</div>

			<div className={classes.item}>
				<div className={classes.key}>Progress:</div>
				<div className={classes.value}>
					<div className={classes.progressWrapper}>
						{
							project.progress > 0 && <div className={classes.progressValue} style={{ width: project.progress }}></div>
						}
					</div>
					<div className={classes.progressValueText}>{project.progress}%</div>
					<div className={classes.progressValueAge}>{calculateProjectAge(project)} old</div>
				</div>
			</div>

			<div className={classes.item}>
				<div className={classes.key}>Margin:</div>
				<div className={classes.value}>
					{StringUtils.numberFormat(project.financials.margin.ratio * 100)}% 
					<div className={classes.marginAbsolute}>{project.financials.margin.absolute}{currencySymbol}</div>
				</div>
			</div>
		</Fragment>
	);
}

export default withStyles(styles)(ProjectInformation);
