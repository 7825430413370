import React, { useRef, useState } from 'react';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import { AddCircleOutline, OpenInNew } from '@material-ui/icons';

import ServerAPI from '../../services/server-api';
import { FileUtils } from '../../utils';

const DEFAULT_MAX_UPLOAD_SIZE = 2097152; // 2 MB

const styles = () => ({
	imageBox: {
		display: 'inline-block',
		marginRight: '15px',
		marginBottom: '15px',
		position: 'relative',
		width: '40%',
		height: '130px',
		backgroundColor: '#F7F7F7',
		borderRadius: '3px',
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: '#CCCCCC',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
	},
	smallBox: {
		width: '24%',
		marginRight: '1%',
		height: '100px',
	},
	imageButton: {
		width: '100%',
		textAlign: 'center',
		marginTop: '38px',
	},
	smallImageButton: {
		marginTop: '24px',
	},
	removeText: {
		fontSize: '13px',
		right: '10px',
		position: 'absolute',
		borderRadius: '3px',
		backgroundColor: '#E8E8E8',
		opacity: 0.4,
		marginTop: '4px',
		cursor: 'pointer',
		'&&:hover': {
			opacity: 0.7,
		},
		padding: '4px',
	},
	imageButtonOpacity: {
		opacity: 0.6,
		'&&:hover': {
			opacity: 1,
		},
	},
});

function FileGridSelector({
	classes,
	enqueueSnackbar,
	defaultFileIds = [],
	onFileIdsChange = () => {},
	minFileCount = null,
	maxFileCount = null,
	maxFileSize = DEFAULT_MAX_UPLOAD_SIZE,
	size = 'small',
}) {
	if (minFileCount > maxFileCount) throw new Error('minFileCount must be less or equal to maxFileCount');

	const [fileIds, setFileIds] = useState(defaultFileIds);

	function onFileUpload(fileId) {
		const newFileIds = [
			...fileIds,
			fileId
		];
		setFileIds(newFileIds);

		// TODO: use useEffect for this
		onFileIdsChange(newFileIds);
	}

	function onFileRemove(fileId) {
		const newFileIds = fileIds.filter(f => f !== fileId);
		setFileIds(newFileIds);

		// TODO: use useEffect for this
		onFileIdsChange(newFileIds);
	}

	const inputCount = Math.max(
		(minFileCount !== null) ? Math.max(minFileCount - fileIds.length, 0) : 0,
		(maxFileCount !== null && fileIds.length < maxFileCount) ? 1 : 0,
	);
	const inputIds = [];
	for (let i = 0; i < inputCount; i++) {
		inputIds.push(i);
	}

	return (
		<div>
			{fileIds.map(fileId => (
				<FileGridItem
					classes={classes}
					key={fileId}
					fileId={fileId}
					onFileRemove={onFileRemove}
					size={size}
				/>
			))}
			{inputIds.map(inputId => (
				<FileGridInput
					classes={classes}
					enqueueSnackbar={enqueueSnackbar}
					key={inputId + generateRandomNumber()}
					maxFileSize={maxFileSize}
					onFileUpload={onFileUpload}
					size={size}
				/>
			))}
		</div>
	);
}

export default withSnackbar(withStyles(styles)(FileGridSelector));

function generateRandomNumber() {
	return Math.floor(Math.random() * 1000);
}

async function uploadFile(file, url) {
	try {
		const response = await fetch(url, {
			method: 'PUT',
			body: file,
		});

		return (response.status === 200);
	} catch (err) {
		return false;
	}
}

export function FileGridInput({
	classes,
	enqueueSnackbar,
	maxFileSize,
	onFileUpload = () => {},
	size,
}) {
	const [uploading, setUploading] = useState(false);
	const randomNumber = useRef(generateRandomNumber());

	async function onFileChange(e) {
		const { target } = e;
		const file = target.files[0];

		try {
			if (file.size > maxFileSize) {
				throw new Error(`File can only be ${Math.round(maxFileSize / 1048576)}MB max. in size`);
			}

			setUploading(true);

			// Get URL
			const signedUrlRequest = await ServerAPI.getFileUploadUrl({
				name: file.name,
				size: file.size,
				extension: file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length) || 'pdf',
				mimeType: file.type || 'application/pdf',
			});
			if (signedUrlRequest.error) throw new Error(signedUrlRequest.error.message || 'Could not get upload URL');

			// Upload file
			const fileUploaded = await uploadFile(file, signedUrlRequest.data.presignedUrl);
			if (!fileUploaded) throw new Error('Could not upload file to AWS');

			// Mark as uploaded
			const uploaded = await ServerAPI.markFileAsUploaded(signedUrlRequest.data.id);
			if (uploaded.error) throw new Error(uploaded.error.message || 'Could not mark file as uploaded');

			onFileUpload(signedUrlRequest.data.id);
		} catch (err) {
			return enqueueSnackbar(err.message || 'Could not upload file', { variant: 'error' });
		} finally {
			setUploading(false);
		}
	}

	return (
		<div className={classes.imageBox + ' ' + (size === 'small' ? classes.smallBox : '')}>
			<div className={classes.imageButton + ' ' + (size === 'small' ? classes.smallImageButton : '')}>
				<input
					disabled={uploading}
					id={'custom-upload-button-file_' + randomNumber.current}
					onChange={onFileChange}
					type='file'
					style={{ display: 'none' }}
				/>
				<label htmlFor={'custom-upload-button-file_' + randomNumber.current}>
					<Tooltip title={'Add image'}>
						<IconButton component="span">
							{uploading ? (
								<CircularProgress size={20} />
							) : (
								<AddCircleOutline />
							)}
						</IconButton>
					</Tooltip>
				</label>
			</div>
		</div>
	);
}

function getImageUrl(id) {
	return FileUtils.getImageDisplayUrl(id);
}

export function FileGridItem({
	classes,
	fileId,
	onFileRemove = () => {},
	size
}) {
	function onClickRemove() {
		onFileRemove(fileId);
	}
	
	const fileImageUrl = getImageUrl(fileId)
	
	return (
		<div
			className={classes.imageBox + ' ' + (size === 'small' ? classes.smallBox : '')}
			style={{ backgroundImage: `url(${fileImageUrl})` }}
		>
			<div className={classes.removeText} onClick={onClickRemove}>Remove</div>

			<div
				className={
					classes.imageButton + ' ' + classes.imageButtonOpacity + ' '
					+ (size === 'small' ? classes.smallImageButton : '')
				}
			>
				<a href={fileImageUrl} target="_blank" rel="noopener noreferrer">
					<IconButton aria-label="Open image">
						<OpenInNew />
					</IconButton>
				</a>
			</div>
		</div>
	);
}
