import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactMarkdown from 'react-markdown';
import { withStyles } from '@material-ui/core/styles';
import {
	Button,
	Dialog, DialogActions, DialogContent, DialogTitle,
	FormControl, TextField, Typography,
} from '@material-ui/core';

const styles = (theme) => ({
	container: {
		position: "relative",
	},
	formControl: {
		minWidth: 120,
		width: "calc(50%)",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	textField: {
		width: "100%",
	},
	markdownButton: {
		zIndex: "9999",
		position: "absolute",
		fontSize: "13px",
		background: "#969696",
		color: "#fff",
		padding: "0px 5px",
		borderRadius: "4px",
		top: "5px",
		right: "5px",
		cursor: "pointer",
		
		"&:hover": {
			background: "#525252",
		}
	},
	markdownEditor: {
		display: "flex",
		flexDirection: "row",
	},
	markdownView: {
		width: "50%",
		padding: "0px 10px",
		wordBreak: "break-word",
	},
});

class TextFieldMarkdown extends Component {
	constructor(props) {
		super(props);
		
		const { value } = props;
		
		this.state = {
			openModal: false,
			textValue: value,
		};
	}

	onToggleModal = (value) => {
		this.setState({
			openModal: !!value,
			textValue: this.props.value,
		});
	};

	onChangeTextField = (e) => {
		const { value } = e.target;

		this.setState({
			textValue: value,
		});
	};

	onSubmitModal = async () => {
		const { name, onChange } = this.props;
		const { textValue } = this.state;
		
		const res = onChange({
			target: {
				name,
				value: textValue
			}
		});

		await Promise.resolve(res);
		
		this.onToggleModal(false);
	};
	
	render() {
		const { classes, ...props } = this.props;
		const { openModal, textValue } = this.state;
		
		const currentValue = textValue || props.value || props.defaultValue;
		
		return (
			<React.Fragment>
				<div className={classes.container}>
					<TextField
						{...props}
						className={props.className + " " + classes.textField}
						/>
					<div className={classes.markdownButton} onClick={() => this.onToggleModal(true)}>
						Edit with markdown
					</div>
				</div>
				<Dialog
					open={openModal}
					onClose={this.onClose}
					fullWidth={true}
					>
					<DialogTitle disableTypography={true}>
						<Typography variant="h6">
							Edit {props.label ? `"${props.label}"` : 'field'} with markdown
						</Typography>
					</DialogTitle>
					<DialogContent>
						<div className={classes.markdownEditor}>
							<FormControl variant="outlined" className={classes.formControl}>
								<TextField
									multiline
									variant="outlined"
									label={props.label || 'Text'}
									name="{props.name || 'text'}"
									defaultValue={currentValue}
									onChange={this.onChangeTextField}
									inputProps={{ maxLength: 2000 }}
									/>
							</FormControl>
							<ReactMarkdown className={classes.markdownView}>
								{currentValue}
							</ReactMarkdown>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							disableElevation
							onClick={() => this.onToggleModal(false)}>
							Cancel
						</Button>
						<Button
							disableElevation
							variant='contained'
							color="primary"
							onClick={this.onSubmitModal}>
							Update field
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

TextFieldMarkdown.propTypes = {
	classes: PropTypes.object.isRequired,
};

TextFieldMarkdown.defaultProps = {
	classes: {},
};

export default withStyles(styles)(TextFieldMarkdown);
