import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Config from '../../../../config';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ServerAPI from '../../../../services/server-api';

const styles = () => ({
	root: {
		"& button" : {
			marginBottom: "10px",
		},
		"& button:last-child" : {
			marginBottom: "10px",
		},
	},
	link: {
		display: "block",
	},
});

class ProjectMiscPopup extends Component {
	render() {
		const { classes, project, onClick } = this.props;

		return (
			<div className={classes.root}>
				{project.competitionExample && (
					<Button
						className={classes.link}
						href={project.competitionExample}
						color="primary"
						target="_blank"
						onClick={(e) => onClick(e)}>
						Link to Competitor
					</Button>
				)}
				{project.productSketch && (
					<Button
						className={classes.link}
						href={ServerAPI.getPublicSketchUrl(project)}
						color="primary"
						target="_blank"
						onClick={(e) => onClick(e)}>
						Link to Sketch
					</Button>
				)}
				<Button
					className={classes.link}
					href={ServerAPI.getPublicBriefingUrl(project)}
					color="primary"
					target="_blank"
					onClick={(e) => onClick(e)}>
					Link to Briefing
				</Button>
				<Button
					className={classes.link}
					color="primary"
					href={Config.lastbasic.platform.host + '/project/' + project.id}
					target="_blank"
				>
					Link to platform
				</Button>
				<Button
					className={classes.link}
					color="primary"
					href={Config.lastbasic.platform.host + '/project/' + project.id + '/unlock'}
					target="_blank"
				>
					Link to Unlock Project
				</Button>
			</div>
		);
	}
}

ProjectMiscPopup.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	onClick: PropTypes.func,
};

ProjectMiscPopup.defaultProps = {
	classes: {},
	project: {},
	onClick() {},
};

export default withStyles(styles)(ProjectMiscPopup);
