import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const styles = () => ({
	root: {
		textAlign: "center"
	},
});

class NotFoundPage extends Component {
	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<Typography variant="h5" gutterBottom>
					Page not found
				</Typography>
			</div>
		);
	}
}

NotFoundPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

NotFoundPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(NotFoundPage);
