import React from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Box, Card, CardContent, CircularProgress, Typography,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	FormControl, Select, InputLabel, MenuItem, TextField, Chip,
} from '@material-ui/core'
import { ArrowBack, DragIndicator } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { PROJECT } from '../../../constants';
import EditedByText from '../../../components/edited-by-text';
import withRouter from '../../../components/withRouter';
import ServerAPI from '../../../services/server-api';
import { HistoryUtils } from '../../../utils';

const styles = () => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	formControl: {
		width: "100%",
	},
	editedBy: {
		marginTop: "10px",
	},
	buttons: {
		textAlign: "left",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		}
	},
	bottomButtons: {
		marginTop: "20px",
	},
	imageThumbnail: {
		maxWidth: "100px",
		background: "#eee",
	},
	dialogContent: {
		overflowY: "hidden",
	},
	hiddenInput: {
		display: "none",
	},
	fileInput: {
		marginTop: "20px",
	},
});

class ProjectEditPage extends React.Component {
	constructor(props) {
		super(props);

		const { location } = props;
		const { data } = location;
		
		this.state = {
			isLoading: !(data && data.project),
			openDeleteDialog: false,
			openUploadImageDialog: false,
			selectedFile: null,
			uploading: false,
			// Data
			project: (data) ? data.project : null,
		};
	}

	async updateProjectData() {
		const { id } = this.props.match.params;
		if (!id) return;
		
		const results = await ServerAPI.getProject(id);
		if (results.error) return;
		
		return results.data;
	}

	async updateData() {
		const project = await this.updateProjectData();
		
		// Check if data changed
		if (deepEqual(this.state.project, project)) return;
		
		this.setState({
			isLoading: false,
			project,
		});
	}

	componentDidMount = async () => {
		let { project } = this.state;
		if (project) return;
		
		this.updateData();
	};

	goBack = () => {
		const { project } = this.state;
		
		HistoryUtils.goBackOrPush({
			pathname: '/project/' + project.id,
		});
	};
	
	onStateChange = (e) => {
		const { project } = this.state;
		project.state = e.target.value;
		this.setState({ project });
	};
	
	onChangeTextField = (e) => {
		const { target: { name, value } } = e;
		const { project } = this.state;
		project[name] = value;
		this.setState({ project });
	};
	
	onSave = async () => {
		const { enqueueSnackbar } = this.props;
		const { project } = this.state;
		
		const result = await ServerAPI.updateProject(project.id, {
			name: project.name,
			number: project.number,
			state: project.state,
			productNeed: project.productNeed,
			productSolution: project.productSolution,
			importantAttributes: project.importantAttributes,
			competitionExample: project.competitionExample,
			nextSteps: project.nextSteps,
			adminComments: project.adminComments,
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update project', { variant: 'error' });
		enqueueSnackbar('Project updated', { variant: 'success' });

		// Save contest order
		const contestOrderResult = await ServerAPI.updateContestOrder(project.id, {
			order: project.contestOrder,
		});
		if (contestOrderResult.error) return enqueueSnackbar(contestOrderResult.error.message || 'Could not update contest order', { variant: 'error' });
		
		HistoryUtils.goBackOrPush({
			pathname: '/project/' + project.id,
		});
	};
	
	onClickDelete = () => {
		this.setState({
			openDeleteDialog: true,
		});
	};
	
	onCloseDeleteDialog = () => {
		this.setState({
			openDeleteDialog: false,
		});
	};
	
	onClickConfirmDelete = async () => {
		const { enqueueSnackbar } = this.props;
		const { project } = this.state;
		
		// Close window
		this.onCloseDeleteDialog();
		
		const result = await ServerAPI.deleteProject(project.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update project', { variant: 'error' });
		enqueueSnackbar('Project deleted', { variant: 'success' });
		
		HistoryUtils.goBackOrPush({
			pathname: '/projects',
		});
	}
	
	onOpenUploadImageDialog = () => {
		this.setState({
			openUploadImageDialog: true,
			selectedFile: null,
			uploading: false,
		});
	};
	
	onCloseUploadImageDialog = () => {
		this.setState({
			openUploadImageDialog: false,
			selectedFile: null,
			uploading: false,
		});
	};
	
	onSelectedFileChange = (e) => {
		const { files } = e.target;
		return this.setState({
			selectedFile: (files.length > 0) ? files[0] : null,
		});
	};
	
	onConfirmUploadImage = () => {
		const { enqueueSnackbar } = this.props;
		const { project, selectedFile } = this.state;

		this.setState({
			uploading: true,
		}, async () => {
			const result = await ServerAPI.updateProjectImage(project.id, {
				filename: selectedFile.name,
				file: selectedFile,
			});
			
			// Close window
			this.onCloseUploadImageDialog();
			
			this.setState({
				uploading: false,
			});
			
			if (result.error) return enqueueSnackbar(result.error.message || 'File could not be uploaded', { variant: 'error' });
			enqueueSnackbar('Image updated', { variant: 'success' });
			
			// TODO: update only the image ?
			this.updateData();
		});
	};
	
	handleOnDragEndContestOrder = (result) => {
		if (!result.destination) return;
		const { project } = this.state;
		const items = Array.from(project.contestOrder);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		project.contestOrder = items;

		this.setState({
			project: project,
		});
	};
	
	render() {
		const { classes } = this.props;

		const {
			isLoading,
			openDeleteDialog,
			openUploadImageDialog,
			selectedFile,
			uploading,
			// Data
			project,
		} = this.state;
		
		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<div>
						<Card className={classes.root} variant="outlined">
							<CardContent>
								<div className={classes.buttons}>
									<Button
										startIcon={<ArrowBack />}
										onClick={this.goBack}>
										Go Back
									</Button>
								</div>
								<Typography variant="h5" gutterBottom>
									Edit Project
								</Typography>
								<TableContainer>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell><b>Name</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Project Name"
															multiline
															defaultValue={project.name}
															variant="outlined"
															name="name"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'name'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Project Number</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Product Number"
															type="number"
															variant="outlined"
															name="number"
															defaultValue={project.number}
															onChange={this.onChangeTextField}
															/>
													<EditedByText
														className={classes.editedBy}
														object={project}
														fieldName={'number'}
														/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Image</b></TableCell>
												<TableCell>
													{project.image ? (
														<img
															className={classes.imageThumbnail}
															src={ServerAPI.getFileUrls(project.image.id).displayUrl}
															alt="Project"
															/>
													) : (
														<span>No image</span>
													)}
													<Button
														variant="contained"
														color="primary"
														onClick={this.onOpenUploadImageDialog}>
														Update image
													</Button>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Client name</b></TableCell>
												<TableCell>{project.owner.firstName + " " + project.owner.lastName}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>State</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<InputLabel>State</InputLabel>
														<Select
															value={project.state}
															onChange={this.onStateChange}
															>
															{Object.values(PROJECT.STATES).map((k, i) => {
																return (
																	<MenuItem value={k} key={k}>
																		{(i + 1) + ") " + PROJECT.stateToString(k)}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Product Need</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Product Need"
															multiline
															defaultValue={project.productNeed}
															variant="outlined"
															name="productNeed"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'productNeed'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Product Solution</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Product Solution"
															multiline
															defaultValue={project.productSolution}
															variant="outlined"
															name="productSolution"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'productSolution'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Important attributes</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Important attributes"
															multiline
															defaultValue={project.importantAttributes}
															variant="outlined"
															name="importantAttributes"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'importantAttributes'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Competitor link</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Competitor link"
															multiline
															defaultValue={project.competitionExample}
															variant="outlined"
															name="competitionExample"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'competitionExample'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Next Steps</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Next Steps"
															multiline
															defaultValue={project.nextSteps}
															variant="outlined"
															name="nextSteps"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'nextSteps'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Admin Comments</b></TableCell>
												<TableCell>
													<FormControl className={classes.formControl}>
														<TextField
															label="Admin Comments"
															multiline
															defaultValue={project.adminComments}
															variant="outlined"
															name="adminComments"
															onChange={this.onChangeTextField}
															/>
														<EditedByText
															className={classes.editedBy}
															object={project}
															fieldName={'adminComments'}
															/>
													</FormControl>
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Contest Order</b></TableCell>
												<TableCell>
													<DragDropContext onDragEnd={this.handleOnDragEndContestOrder}>
														<Droppable droppableId="contestOrder" direction="horizontal">
															{(provided) => (
																<ul
																	ref={provided.innerRef}
																	{...provided.droppableProps}
																	style={{
																		display: 'flex',
																		listStyle: "none",
																		margin: 0,
																		padding: 0
																	}}
																>
																	{project.contestOrder.map((value, index) => {
																		const labelValue = value.charAt(0).toUpperCase() + value.slice(1);
																		return (
																			<Draggable
																				key={value}
																				draggableId={value}
																				index={index}
																			>
																				{(provided) => (
																					<li
																						ref={provided.innerRef}
																						{...provided.draggableProps}
																						{...provided.dragHandleProps}
																						style={provided.draggableProps.style}
																					>
																						<Chip
																							icon={<DragIndicator />}
																							size="small"
																							variant="outlined"
																							color="primary"
																							label={labelValue}
																							style={{
																								cursor: 'pointer',
																								marginRight: 5,
																								backgroundColor: "#fff"
																							}}
																						/>
																					</li>
																				)}
																			</Draggable>
																		);
																	})}
																	{provided.placeholder}
																</ul>
															)}
														</Droppable>
													</DragDropContext>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
								<div className={classes.buttons + " " + classes.bottomButtons}>
									<Button
										variant="contained"
										color="primary"
										onClick={this.onSave}>
										Save
									</Button>
									<Button
										variant="contained"
										color="secondary"
										onClick={this.onClickDelete}>
										Delete
									</Button>
								</div>
							</CardContent>
						</Card>
						<Dialog
							open={openDeleteDialog}
							onClose={this.onCloseDeleteDialog}
							>
							<DialogTitle>
								Do you really want to delete <i>"{project.name}"</i>? :(
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									A deleted project will not be shown in the project list nor in the platform.
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.onCloseDeleteDialog} color="primary">
									Cancel
								</Button>
								<Button onClick={this.onClickConfirmDelete} color="primary" autoFocus>
									Yes, delete
								</Button>
							</DialogActions>
						</Dialog>
						<Dialog
							open={openUploadImageDialog}
							onClose={this.onCloseUploadImageDialog}
							fullWidth={true}
							>
							<DialogTitle>
								Update the project image
							</DialogTitle>
							<DialogContent className={classes.dialogContent}>
								<DialogContentText>
									Select the file you want to upload
								</DialogContentText>
								<div className={classes.fileInput}>
									<input
										className={classes.hiddenInput}
										id="contained-button-file"
										type="file"
										onChange={this.onSelectedFileChange}
										/>
									<label htmlFor="contained-button-file">
										{uploading ? (
											<CircularProgress />
										) : (
											<Button variant="contained" color="primary" component="span">
												Select file
											</Button>
										)}
										&nbsp;
										{selectedFile && (
											<span>File selected: {selectedFile.name}</span>
										)}
									</label>
								</div>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.onCloseUploadImageDialog} color="primary">
									Cancel
								</Button>
								<Button
									disabled={!selectedFile || uploading}
									color="primary"
									onClick={this.onConfirmUploadImage}>
									{uploading ? 'Uploading...' : 'Upload file'}
								</Button>
							</DialogActions>
						</Dialog>
					</div>
				)}
			</Box>
		);
	}
};

ProjectEditPage.propTypes = {
	classes: PropTypes.object.isRequired,
}

ProjectEditPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(ProjectEditPage)));
