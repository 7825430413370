import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Typography,
	Dialog, DialogActions, DialogContent, DialogTitle,
	FormControl, TextField,
	Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';
import { Edit, Visibility } from '@material-ui/icons';

import { EXPERT } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';
import { StringUtils } from '../../../../utils';

const styles = () => ({
	title: {
		display: "flex",
		
		"& h6": {
			flex: "1",
		},
	},
	formControl: {
		minWidth: "50%",
	},
});

class ExpertPaymentInfoDialog extends Component {
	constructor(props) {
		super(props);

		const { user } = props;
		
		this.state = {
			user,
			editMode: false,
		};
	}
	
	onClose = () => {
		const { onClose } = this.props;
		
		this.setState({
			editMode: false,
		});
		onClose();
	};
	
	onToggleEditMode = () => {
		this.setState({
			editMode: !this.state.editMode,
		});
	};
	
	onChangePaymentInfo = (e) => {
		const { target: { name, value } } = e;
		const { user } = this.state;
		
		const { paymentInfo } = user.expert;
		paymentInfo[name] = value;
		
		this.setState({
			user,
		});
	};
	
	onConfirmPaymentInfo = async () => {
		const { enqueueSnackbar, onUpdate } = this.props;
		const { user } = this.state;
		
		const result = await ServerAPI.updateExpert(user.id, {
			paymentInfo: user.expert.paymentInfo,
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update expert', { variant: 'error' });
		enqueueSnackbar('Expert updated', { variant: 'success' });
		
		this.setState({
			editMode: false,		
		});
		
		onUpdate();
	};
	
	render () {
		const { classes, open, onClose } = this.props;
		const { editMode, user } = this.state;
		
		return (
			<Dialog
				open={open}
				onClose={this.onClose}
				fullWidth={true}
				>
				<DialogTitle className={classes.title} disableTypography={true}>
					<Typography variant="h6">
						{editMode ? 'Edit ' : ''} Payment Information
					</Typography>
					<Button
						variant="contained"
						color="primary"
						startIcon={editMode ? <Visibility /> : <Edit />}
						onClick={this.onToggleEditMode}>
						{editMode ? 'View' : 'Edit'}
					</Button>
				</DialogTitle>
				<DialogContent>
					{editMode ? (
						<React.Fragment>
							<div style={{ marginBottom: '20px' }}>
								<FormControl className={classes.formControl}>
									<TextField
										label="VAT Tax"
										type="number"
										inputProps={{ min: "0", max: "100" }}
										variant="outlined"
										size="small"
										name="vatTax"
										defaultValue={user.expert.paymentInfo.vatTax}
										onChange={this.onChangePaymentInfo}
										/>
								</FormControl>
							</div>
							<div>
								<FormControl className={classes.formControl}>
									<TextField
										label="Retention Tax"
										type="number"
										InputProps={{
											inputProps: { 
												min: 0, max: 100
											}
										}}
										fullWidth={true}
										variant="outlined"
										size="small"
										name="retentionTax"
										defaultValue={user.expert.paymentInfo.retentionTax}
										onChange={this.onChangePaymentInfo}
										/>
								</FormControl>
							</div>
							<DialogActions>
								<Button
									color="primary"
									onClick={onClose}>
									Cancel
								</Button>
								<Button
									color="primary"
									onClick={this.onConfirmPaymentInfo}>
									Submit
								</Button>
							</DialogActions>
						</React.Fragment>
					) : (
						<TableContainer>
							<Table className={classes.table}>
								<TableBody>
									<TableRow>
										<TableCell><b>First Name</b></TableCell>
										<TableCell>{user.expert.paymentInfo.firstName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Last Name</b></TableCell>
										<TableCell>{user.expert.paymentInfo.lastName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Email</b></TableCell>
										<TableCell>{user.expert.paymentInfo.email}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Country Residence</b></TableCell>
										<TableCell>
											{StringUtils.capitalizeAll(user.expert.paymentInfo.countryResidence)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Invoice Type</b></TableCell>
										<TableCell>
											{EXPERT.invoiceTypeToString(user.expert.paymentInfo.invoiceType)}
										</TableCell>
									</TableRow>
									{user.expert.paymentInfo.invoiceType === EXPERT.INVOICE_TYPE.INDEPENDENT ? (
										<React.Fragment>
											<TableRow>
												<TableCell><b>Is a registered freelancer?</b></TableCell>
												<TableCell>
													{user.expert.paymentInfo.isRegisteredFreelancer ? 'Yes' : 'No'}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell><b>Freelancer Time</b></TableCell>
												<TableCell>
													{EXPERT.freelancerTimeToString(user.expert.paymentInfo.freelancerTime)}
												</TableCell>
											</TableRow>
										</React.Fragment>
									) : (
										<TableRow>
											<TableCell><b>Business Name</b></TableCell>
											<TableCell>{user.expert.paymentInfo.businessName || '-'}</TableCell>
										</TableRow>
									)}
									<TableRow>
										<TableCell><b>ID Number</b></TableCell>
										<TableCell>{user.expert.paymentInfo.idNumber}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Tax Residence</b></TableCell>
										<TableCell>{user.expert.paymentInfo.taxResidence}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Is a intra-communitary operator?</b></TableCell>
										<TableCell>
											{EXPERT.europeOperatorToString(user.expert.paymentInfo.europeOperator)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Tax Document</b></TableCell>
										<TableCell>
											<a
												href={user.expert.paymentInfo.taxDocument}
												target="_blank"
												rel="noopener noreferrer">
												{user.expert.paymentInfo.taxDocument}
											</a>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>VAT Tax</b></TableCell>
										<TableCell>{user.expert.paymentInfo.vatTax}%</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Retention Tax</b></TableCell>
										<TableCell>{user.expert.paymentInfo.retentionTax}%</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</DialogContent>
			</Dialog>
		);
	}
};

ExpertPaymentInfoDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	user: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
};

ExpertPaymentInfoDialog.defaultProps = {
	open: false,
	user: null,
	onClose() {},
	onUpdate() {},
};

export default withSnackbar(withStyles(styles)(ExpertPaymentInfoDialog));
