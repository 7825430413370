import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import deepEqual from 'deep-equal';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Box, Card, CardContent,
	Dialog, DialogContent, DialogTitle, IconButton, Chip,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Typography
} from '@material-ui/core';
import { AccountBalance, ArrowBack, Edit, Launch } from '@material-ui/icons';

import { CONTEST, EXPERT } from '../../../constants';
import ExpertFlags from './components/expert-flags';
import LoadingBox from '../../../components/loading-box'
import withRouter from '../../../components/withRouter';
import { HistoryUtils, StringUtils } from '../../../utils';
import ExpertPaymentInfoDialog from './components/expert-payment-info-dialog';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	leftButtons: {
		flex: "1",
	},
});

class ExpertPage extends Component {
	constructor(props) {
		super(props);

		const { location } = props;
		const { data } = location;
		
		this.state = {
			isLoading: !(data && data.user),
			// Data
			user: (data) ? data.user : null,
			// Dialog
			openProposalDialog: false,
			openPaymentInfoDialog: false,
			proposalListSelected: [],
		};
	}

	updateExpertData = async() => {
		const { id } = this.props.match.params;
		if (!id) return;
		
		const results = await ServerAPI.getExpert(id);
		if (results.error) return;

		return results.data;
	};

	updateData = async () => {
		const user = await this.updateExpertData();
		
		// Check if data changed
		if (deepEqual(this.state.user, user)) return;
		
		this.setState({
			isLoading: false,
			user,
		});
	};

	componentDidMount = async () => {
		let { user } = this.state;
		if (user) return;
		
		this.updateData();
	};

	goBack = () => {
		HistoryUtils.goBackOrPush({
			pathname: '/experts',
		});
	};
	
	onClickEdit = () => {
		const { navigate } = this.props;
		const { user } = this.state;
		
		navigate(
			(user) ? '/expert/' + user.id + '/edit' : '/experts',
			{ state: { user } }
		);
	};
	
	onOpenPaymentInfoDialog = () => {
		this.setState({
			openPaymentInfoDialog: true,
		});
	};
	
	onClosePaymentInfoDialog = () => {
		this.setState({
			openPaymentInfoDialog: false,
		});
	};
	
	onOpenProposalDialog = (proposalList) => {
		this.setState({
			openProposalDialog: true,
			proposalListSelected: proposalList,
		});
	};

	onCloseProposalDialog = () => {
		this.setState({
			openProposalDialog: false,
			proposalListSelected: [],
		});
	};

	render() {
		const { classes } = this.props;

		const {
			isLoading,
			// Data
			user,
			// Dialog
			openProposalDialog,
			openPaymentInfoDialog,
			proposalListSelected,
		} = this.state;
		
		if (user && !user.expert) return <div>Not an expert</div>;

		const expert = user && user.expert;
		
		if (isLoading) return <LoadingBox />; 
		
		return (
			<Box className={classes.root}>
				<Card className={classes.root} variant="outlined">
					<CardContent>
						<div className={classes.buttons}>
							<div className={classes.leftButtons}>
								<Button
									startIcon={<ArrowBack />}
									onClick={this.goBack}>
									Go Back
								</Button>
							</div>
							<div className={classes.rightButtons}>
								<Button
									variant="contained"
									color="primary"
									startIcon={<Edit />}
									onClick={this.onClickEdit}>
									Edit
								</Button>
								{user.expert.paymentInfo && (
									<Button
										variant="contained"
										color="primary"
										startIcon={<AccountBalance />}
										onClick={this.onOpenPaymentInfoDialog}>
										Payment Information
									</Button>
								)}
							</div>
						</div>
						<Typography variant="h5" gutterBottom>
							Expert
						</Typography>
						<TableContainer>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell><b>First Name</b></TableCell>
										<TableCell>{user.firstName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Last Name</b></TableCell>
										<TableCell>{user.lastName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Email</b></TableCell>
										<TableCell>{user.email}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Country Residence</b></TableCell>
										<TableCell>
											{StringUtils.capitalizeAll(user.expert.countryResidence)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Type of user</b></TableCell>
										<TableCell>
											{
												EXPERT.typeToString(user.expert.expertType)
												+ " ("
												+ (EXPERT.TYPE.INDEPENDENT === user.expert.expertType
													? EXPERT.expertiseToString(user.expert.expertExpertise)
													: EXPERT.agencyTypeToString(user.expert.agencyType)
												)
												+ ")"
											}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Linkedin link</b></TableCell>
										<TableCell>
											{user.expert.lknInput ? (
												<Button
													className={classes.link}
													href={user.expert.lknInput}
													color="primary"
													target="_blank">
													Link to LinkedIn
												</Button>
											) : (
												<Typography>-</Typography>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Portfolio link</b></TableCell>
										<TableCell>
											{user.expert.portfolioInput ? (
												<Button
													className={classes.link}
													href={user.expert.portfolioInput}
													color="primary"
													target="_blank">
													Link to portfolio
												</Button>
											) : (
												<Typography>-</Typography>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Years of experience</b></TableCell>
										<TableCell>{EXPERT.yearsToString(expert.yearsExperience)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Ambassador level</b></TableCell>
										<TableCell>{expert.ambassadorExplanation || '-'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Active level</b></TableCell>
										<TableCell>
											{expert.isEarlyAdopter
												? 'Yes, let\'s start working '
												: 'No, I prefer to join the expert waitlist'
											}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Telegram</b></TableCell>
										<TableCell>
											{expert.isMessaging
												? 'Yes'
												: 'No, maybe in teh future'
											}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Manual Score</b></TableCell>
										<TableCell>{expert.manualScore}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Email Subscription</b></TableCell>
										<TableCell>{user.emailNotificationsEnabled ? 'Yes' : 'No'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Created at</b></TableCell>
										<TableCell>{dayjs(user.createdAt).format('D MMMM YYYY')}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Flags</b></TableCell>
										<TableCell>
											<ExpertFlags flags={expert.flags} />
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Comment</b></TableCell>
										<TableCell>{expert.comment}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Proposals sent</b></TableCell>
										<TableCell>
											<Button
												onClick={() => this.onOpenProposalDialog(expert.sentProposals)}>
												{expert.sentProposals.length} proposals
											</Button>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Proposals accepted</b></TableCell>
										<TableCell>
											<Button
												onClick={() => this.onOpenProposalDialog(expert.acceptedProposals)}>
												{expert.acceptedProposals.length} proposals
											</Button>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Proposals won</b></TableCell>
										<TableCell>
											<Button
												onClick={() => this.onOpenProposalDialog(expert.wonProposals)}>
												{expert.wonProposals.length} proposals
											</Button>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Ratio of performance</b></TableCell>
										<TableCell>
											{(expert.performanceRatio * 100) + '%'}
										</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>
				{user.expert.paymentInfo && (
					<ExpertPaymentInfoDialog
						open={openPaymentInfoDialog}
						onClose={this.onClosePaymentInfoDialog}
						user={user}
						onUpdate={this.updateData}
						/>
				)}
				<Dialog
					open={openProposalDialog}
					onClose={this.onCloseProposalDialog}
					fullWidth={true}
				>
					<DialogTitle>
						Proposals
					</DialogTitle>
					<DialogContent>
						{proposalListSelected.length === 0 && (
							<p>There are no proposals.</p>
						)}
						{proposalListSelected.map((proposal, i) => (
							<div
								key={i}
								style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<p>
									{proposal.contest.project.name} (#{proposal.number || 'X'})
								</p>
								<Chip
									label={CONTEST.contestTypeToString(proposal.contest.type)}
									color="primary"
									variant="outlined"
								/>
								<Link to={'/project/' + proposal.contest.project.id + '/contest/' +  proposal.contest.type}>
									<IconButton aria-label="open">
										<Launch />
									</IconButton>
								</Link>
							</div>
						))}
					</DialogContent>
				</Dialog>
			</Box>
		);
	}
}

ExpertPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ExpertPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(withRouter(ExpertPage));
