import Auth from '../auth';

const userUtils = {
	userHasAdminRole: function (availableRoles = []) {
		if (!Array.isArray(availableRoles)) availableRoles = [availableRoles];

		const token = Auth.getValidStoredTokens();

		if (token && token.user && token.user.admin && token.user.admin.role) {
			const role = token.user.admin.role;
			return (availableRoles.indexOf(role) !== -1);
		}

		return false;
	},
};

export default userUtils;
