import jwtDecode from 'jwt-decode';

const decode = (token) => {
	const decoded = jwtDecode(token);
	return decoded;
};

const isValidToken = (token) => {
	const decoded = jwtDecode(token);
	const time = decoded.exp;
	if (!time)
		return false;
	return (time - (Date.now() / 1000)) > 0;
};

const jwtUtils = {
	decode,
	isValidToken,
};

export default jwtUtils;
