import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import {
	Button, CircularProgress,
	Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
	TextField,
} from '@material-ui/core';
import { Add, Check } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { CONTEST } from '../../../../constants';
import SharedFileGrid from '../components/shared-file-grid';
import ServerAPI from '../../../../services/server-api';

const styles = () => ({
	tableActions: {
		margin: "20px 0px",
	},
	dialogContent: {
		overflowY: "hidden",
	},
	hiddenInput: {
		display: "none",
	},
	fileInput: {
		marginTop: "20px",
	},
});

class SharedFileTab extends Component {
	state = {
		openAddFileDialog: false,
		selectedName: null,
		selectedFile: null,
		uploading: false,
		openMarkAsAllUploadedDialog: false,
	};

	onOpenAddSharedFileDialog = () => {
		this.setState({
			openAddFileDialog: true,
		});
	};

	onCloseAddFileDialog = () => {
		this.setState({
			openAddFileDialog: false,
			selectedName: null,
			selectedFile: null,
		});
	};

	onSelectedNameChange = (e) => {
		const { value } = e.target;
		return this.setState({
			selectedName: value,
		});
	};

	onSelectedFileChange = (e) => {
		const { files } = e.target;
		return this.setState({
			selectedFile: (files.length > 0) ? files[0] : null,
		});
	};

	onConfirmAddSharedFile = async () => {
		const { enqueueSnackbar, project, type, updateData } = this.props;
		const { selectedName, selectedFile } = this.state;

		if (!selectedName) return enqueueSnackbar('Name not valid', { variant: 'error' });

		this.setState({
			uploading: true,
		}, async () => {
			const result = await ServerAPI.addSharedFile(project.id, type, {
				name: selectedName,
				filename: selectedFile.name,
				file: selectedFile,
			});

			// Close window
			this.onCloseAddFileDialog();

			this.setState({
				uploading: false,
			});

			if (result.error) return enqueueSnackbar(result.error.message || 'File could not be uploaded', { variant: 'error' });
			enqueueSnackbar('File added', { variant: 'success' });

			updateData();
		});
	};

	onOpenMarkAsAllUploadedDialog = () => {
		this.setState({
			openMarkAsAllUploadedDialog: true,
		});
	};

	onCloseMarkAsAllUploadedDialog = () => {
		this.setState({
			openMarkAsAllUploadedDialog: false,
		});
	};

	onConfirmMarkAsAllUploaded = async () => {
		const { enqueueSnackbar, project, type, updateData } = this.props;

		const result = await ServerAPI.winnerDone(project.id, type);

		// Close window
		this.onCloseMarkAsAllUploadedDialog();

		if (result.error) return enqueueSnackbar(result.error.message || 'Winner could not be marked as done', { variant: 'error' });
		enqueueSnackbar('Winner marked as done', { variant: 'success' });

		updateData();
	};

	render() {
		const { classes, project, type, contest, updateData } = this.props;
		const {
			openAddFileDialog,
			selectedName,
			selectedFile,
			uploading,
			openMarkAsAllUploadedDialog,
		} = this.state;

		const winner = contest.selectedProposal && contest.selectedProposal.user;
		const winnerName = winner && `${winner.firstName} ${winner.lastName} (${winner.email})`;
		const hasApprovedSharedFiles = contest.sharedFiles.some(s => s.approvalStatus === CONTEST.SHARED_FILE_APPROVAL_STATUSES.APPROVED);
		const hasPendingSharedFiles = contest.sharedFiles.some(s => s.approvalStatus === CONTEST.SHARED_FILE_APPROVAL_STATUSES.PENDING);
		const showWinnerDoneButton = contest.selectedProposal && hasApprovedSharedFiles && !hasPendingSharedFiles;

		return (
			<React.Fragment>
				<div className={classes.tableActions}>
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={this.onOpenAddSharedFileDialog}>
						Add Shared File
					</Button>

					{showWinnerDoneButton &&
						<Button
							disabled={(!!contest.winnerDone)}
							style={{ marginLeft: '20px' }}
							variant="outlined"
							color="primary"
							startIcon={<Check />}
							onClick={this.onOpenMarkAsAllUploadedDialog}>
							Winner done
						</Button>
					}
				</div>
				<SharedFileGrid
					project={project}
					type={type}
					contest={contest}
					updateData={updateData}
				/>
				<Dialog
					fullWidth={true}
					open={openAddFileDialog}
					onClose={this.onCloseAddFileDialog}
				>
					<DialogTitle>
						Add a new shared file
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<DialogContentText>
							Select the file you want to upload
						</DialogContentText>
						<TextField
							label="Name"
							defaultValue={selectedName}
							variant="outlined"
							name="name"
							onChange={this.onSelectedNameChange}
							/>
						<div className={classes.fileInput}>
							<input
								className={classes.hiddenInput}
								id="contained-button-file"
								type="file"
								onChange={this.onSelectedFileChange}
								/>
							<label htmlFor="contained-button-file">
								{uploading ? (
									<CircularProgress />
								) : (
									<Button variant="contained" color="primary" component="span">
										Select file
									</Button>
								)}
								&nbsp;
								{selectedFile && (
									<span>File selected: {selectedFile.name}</span>
								)}
							</label>
						</div>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseAddFileDialog} color="primary">
							Cancel
						</Button>
						<Button
							disabled={!selectedFile || uploading}
							color="primary"
							onClick={this.onConfirmAddSharedFile}>
							{uploading ? 'Uploading...' : 'Upload file'}
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={openMarkAsAllUploadedDialog}
					onClose={this.onCloseMarkAsAllUploadedDialog}
					fullWidth={true}
				>
					<DialogTitle>
						Winner done
					</DialogTitle>
					<DialogContent className={classes.dialogContent}>
						<DialogContentText>
							This action will trigger a payment to <b>{winnerName}</b> for winning this contest, and will send two emails:
							a payout email, and an email stating the shared files have been approved.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.onCloseMarkAsAllUploadedDialog}>
							Cancel
						</Button>
						<Button
							color="primary"
							onClick={this.onConfirmMarkAsAllUploaded}>
							Winner done
						</Button>
					</DialogActions>
				</Dialog>
			</React.Fragment>
		);
	}
}

SharedFileTab.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	contest: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(SharedFileTab));
