import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	root: {
		"& button" : {
			marginBottom: "10px",
		},
		"& button:last-child" : {
			marginBottom: "10px",
		},
	},
	link: {
		display: "block",
	},
});

class ExpertLinksPopup extends Component {
	render () {
		const { classes, user: u } = this.props;
		
		return (
			<div className={classes.root}>
				{u.expert.lknInput ? (
					<Button
						className={classes.link}
						href={u.expert.lknInput}
						color="primary"
						target="_blank">
						Link to Linkedin
					</Button>
				) : (
					<Button
						className={classes.link}
						href={'#'}
						disabled={true}
						color="primary">
						No link to LinkedIn
					</Button>
				)}
				{u.expert.portfolioInput ? (
					<Button
						className={classes.link}
						href={u.expert.portfolioInput}
						color="primary"
						target="_blank">
						Link to portfolio
					</Button>
				) : (
					<Button
						className={classes.link}
						href={'#'}
						disabled={true}
						color="primary">
						No link to portfolio
					</Button>
				)}
			</div>
		);
	}
};

ExpertLinksPopup.propTypes = {
	row: PropTypes.object.isRequired,
};

ExpertLinksPopup.defaultProps = {
	row: {},
};

export default withStyles(styles)(ExpertLinksPopup);
