import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Delete, CloudUpload, Edit } from '@material-ui/icons';
import {
	Box, Button, Dialog, DialogTitle, DialogActions, DialogContent,
	IconButton, Input, Tooltip, Checkbox,
} from '@material-ui/core';

import { PAYMENT } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';
import { Auth } from '../../../../utils';

const styles = () => ({
	iframe: {
		width: '500px',
		height: '500px',
		borderStyle: 'none',
		backgroundColor: '#D1D1D1',
	},
	invoiceDialogIcons: {
		display: 'inline',
		float: 'right',
		marginTop: '-2px',
	},
	invoiceDialogIconsInfo: {
		fontSize: '13px',
	},
	invoiceCode: {
		fontSize: '12px',
		backgroundColor: '#F0F0F0',
		color: '#969696',
		padding: '5px 0px 5px 0px',
		borderRadius: '3px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#E3E3E3'
		},
		textAlign: 'center',
	},
	editInvoiceNumberIcon: {
		marginRight: '10px',
	},
});

function PayoutInvoice({ classes, enqueueSnackbar, payout, onChange = () => {} }) {
	const [openPayoutPdfDialog, setOpenPayoutPdfDialog] = useState(false);
	const [uploadButtonDisplay, setUploadButtonDisplay] = useState(true);
	const [invoiceDialogIconInfo, setInvoiceDialogIconInfo] = useState();
	const [editCustomInvoiceNumber, setEditCustomInvoiceNumber] = useState(false);
	const [newInvoiceNumber, setNewInvoiceNumber] = useState(0);
	const [isReceipt, setIsReceipt] = useState(payout.receipt);

	useEffect(() => {
		setNewInvoiceNumber(payout.invoiceNumber || 0);
	}, [payout]);

	function InvoiceIframe() {
		const token = Auth.getValidStoredTokens();
		const accessToken = token ? token.accessToken : '';

		return (
			<iframe title={'Payout - ' + payout.id} className={classes.iframe} src={payout.invoiceUrl + `?accessToken=${accessToken}`}></iframe>
		);
	}

	async function uploadFile(file, url) {
		const response = await fetch(url, {
			method: 'PUT',
			body: file,
		});

		return (response.status === 200);
	}

	function uploadInvoiceInitialState() {
		setUploadButtonDisplay(true);
		setInvoiceDialogIconInfo('');

		return;
	}

	async function uploadCustomInvoice(e, payout) {
		const { target } = e;
		setUploadButtonDisplay(false);
		setInvoiceDialogIconInfo('Uploading...');

		const file = target.files[0];
		
		if (file.size > 2097152) {
			uploadInvoiceInitialState();
			return enqueueSnackbar('File can only be 2MB max. in size', { variant: 'error' });
		}

		const signedUrlRequest = await ServerAPI.getFileUploadUrl({
			name: file.name,
			size: file.size,
			extension: 'pdf',
			mimeType: 'application/pdf',
		});

		if (signedUrlRequest.error) {
			uploadInvoiceInitialState();
			return enqueueSnackbar(signedUrlRequest.error.message || 'Could not get upload URL', { variant: 'error' });
		}

		let fileUploaded;
		try {
			fileUploaded = await uploadFile(file, signedUrlRequest.data.presignedUrl);
			if (!fileUploaded) {
				uploadInvoiceInitialState();
				return enqueueSnackbar('Could not upload file to AWS', { variant: 'error' });
			}

			// Mark as uploaded
			const uploaded = await ServerAPI.markFileAsUploaded(signedUrlRequest.data.id);
			if (uploaded.error) {
				uploadInvoiceInitialState();
				return enqueueSnackbar(uploaded.error.message || 'Could mark file as uploaded', { variant: 'error' });
			}

			// Update payout
			const result = await ServerAPI.addCustomInvoice(payout.id || payout.id, signedUrlRequest.data.id);
			if (result.error) {
				uploadInvoiceInitialState();
				return enqueueSnackbar(result.error.message || 'Could not upload custom invoice', { variant: 'error' });
			}
		} catch (e) {
			uploadInvoiceInitialState();
			return enqueueSnackbar('Could not upload file', { variant: 'error' });
		}

		enqueueSnackbar('Custom invoice uploaded', { variant: 'success' });
		
		uploadInvoiceInitialState();
		onChange();
	}

	async function removeCustomInvoice(payout) {
		const result = await ServerAPI.removeCustomInvoice(payout.id);
		if (result.error) {
			uploadInvoiceInitialState();
			return enqueueSnackbar(result.error.message || 'Could not remove custom invoice', { variant: 'error' });
		}

		enqueueSnackbar('Custom invoice removed', { variant: 'success' });

		onChange();
	}

	function customInvoiceUploadable(payout) {
		const canUpload = [PAYMENT.PAYOUT_STATUS.PENDING, PAYMENT.PAYOUT_STATUS.APPROVED];
		return canUpload.indexOf(payout.status) !== -1;
	}

	async function updateInvoiceNumber() {
		const result = await ServerAPI.updateCustomInvoiceNumber(payout.id, newInvoiceNumber);
		if (result.error) {
			uploadInvoiceInitialState();
			return enqueueSnackbar(result.error.message || 'Could not update the invoice number', { variant: 'error' });
		}

		enqueueSnackbar('Invoice number updated', { variant: 'success' });
		
		onChange();
	}

	return (
		<Box>
			{payout.invoiceAvailable ? (
				<div className={classes.invoiceCode} onClick={() => setOpenPayoutPdfDialog(true)}>
					{payout.invoiceCode === '-' ? 'Preview' : payout.invoiceCode}
				</div>
			) : (
				<div className={classes.invoiceCode} style={{ cursor: 'no-drop'}}>
					Not available
				</div>
			)}
			<Dialog
				open={openPayoutPdfDialog}
				onClose={() => setOpenPayoutPdfDialog(false)}
			>
				<DialogTitle>
					{ payout.customInvoice ? <span>Custom payout invoice</span> : <span>Payout invoice</span>}
					{ payout.invoiceCode && <span style={{ opacity: '0.5' }}> - {
						payout.invoiceCode === '-' ? 'Preview' : payout.invoiceCode
					}</span> }

					<div className={classes.invoiceDialogIcons}>
						<span className={classes.editInvoiceNumberIcon}>
							<Tooltip title={'Update invoice number'}>
								<IconButton size={'small'} component="span" onClick={() => {
									setEditCustomInvoiceNumber(true)
									setOpenPayoutPdfDialog(false)
								}}>
									<Edit/>
								</IconButton>
							</Tooltip>

							{!payout.customInvoice &&
								<Tooltip title={'Is it a receipt?'}>
									<Checkbox
										checked={isReceipt}
										onChange={async (e) => {
											const checked = e.target.checked;
											setIsReceipt(checked);
											await ServerAPI.markInvoiceAsReceipt(payout.id, checked);
											return enqueueSnackbar('Document updated to ' + (checked ? 'receipt' : 'invoice') + '. Reload to see changes ', { variant: 'success' });
										}}
										color="primary"
									/>
								</Tooltip>
							}
						</span>

						<span style={{ display: (customInvoiceUploadable(payout) ? 'inline' : 'none' )}}>
							<span className={classes.invoiceDialogIconsInfo}>{invoiceDialogIconInfo}</span>
							{payout.customInvoice ? (
								<Fragment>
									<Tooltip title={'Delete custom invoice and generate one automatically'}>
										<IconButton size={'small'} component="span" onClick={() => removeCustomInvoice(payout)}>
											<Delete/>
										</IconButton>
									</Tooltip>
								</Fragment>
							) : (
								<Fragment>
									<input
										id='custom-upload-button-file'
										type='file'
										accept='application/pdf'
										onChange={(e) => uploadCustomInvoice(e, payout)}
										style={{ display: 'none' }}
									/>
									<label htmlFor='custom-upload-button-file' style={{ display: (uploadButtonDisplay ? 'inline' : 'none' ) }}>
										<Tooltip title={'Upload custom invoice'}>
											<IconButton size={'small'} component="span">
												<CloudUpload/>
											</IconButton>
										</Tooltip>
									</label>
								</Fragment>
							)}
						</span>
					</div>
				</DialogTitle>
				<DialogContent>
					{payout &&
						<InvoiceIframe payoutId={payout.id} />
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenPayoutPdfDialog(false)}>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={editCustomInvoiceNumber}
				onClose={() => setEditCustomInvoiceNumber(false)}
			>
				<DialogTitle>
					Update invoice number for {payout.invoiceCode}
				</DialogTitle>
				<DialogContent>
					<Input
						type='number'
						placeholder='New invoice number'
						value={newInvoiceNumber}
						onChange={(e) => setNewInvoiceNumber(e.target.value)}
						autoFocus={true}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setEditCustomInvoiceNumber(false)}>
						Close
					</Button>

					<Button onClick={() => updateInvoiceNumber()} color="primary" variant='contained' disableElevation>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
}

export default withSnackbar(withStyles(styles)(PayoutInvoice));
