import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = theme => ({
	bar: {
		width: '150px',
		height: '28px',
		backgroundColor: '#F5F5F5',
		borderRadius: '50px',
		border: '0px solid #E3E3E3',
		cursor: 'pointer',
		opacity: '0.3',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	number: {
		textAlign: 'center',
		position: 'absolute',
		width: '150px',
		height: '28px',
		paddingTop: '4px',
	},
	some: {
		opacity: '1',
		backgroundColor: '#F5B887',
	}
});

class PayoutsBar extends React.Component {
	render() {
		const {
			classes,
			openPayouts,
			userId,
		} = this.props;

		const number = 'open payout' + (openPayouts === 1 ? '' : 's');
		return (
			<Link to={'/payments/users/' + userId + '/payouts'}>
				<Tooltip title={'Open payouts page'}>
					<div className={classes.bar + ' ' + (openPayouts > 0 ? classes.some : '')}>
						<div className={classes.number}>{openPayouts} {number}</div>
					</div>
				</Tooltip>
			</Link>
		);
	}
}

export default withStyles(styles)(PayoutsBar);
