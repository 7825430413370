import { Fragment } from 'react';
import { Tooltip } from '@material-ui/core';

import styles from './funnel.module.css';

export default function Funnel({ data: results }) {
	return (
		<div className={styles.funnel}>
			{results.map((result, i) => {
				const comparisonPercentaje = result.comparison
					? ((result.current - result.comparison) / result.comparison) * 100
					: 0;
				const isNegative = comparisonPercentaje <= 0;

				let stepChange;
				if (i !== (results.length - 1)) {
					const next = results[i + 1];
					stepChange = result.current && (next.current / result.current) * 100;
					//stepChangeComparison = result.comparison && (next.comparison / result.comparison) * 100;
				}

				return (
					<Fragment>
						<div className={styles.card}>
							<div className={styles.cardWrapper}>
								<div className={styles.cardHead}>
									<div className={styles.cardTitle}>
										{result.color && (
											<div className={styles.colorBubble} style={{ backgroundColor: result.color }}></div>
										)}
										{result.name}
									</div>
									{!!comparisonPercentaje && (
										<Tooltip title={`Previous period: ${result.comparison} ${result.name.toLowerCase()}`}>
											<div className={styles.cardChange + (isNegative ? ' ' + styles.cardChangeNegative : '')}>
												{!isNegative && '+'}{parseInt(comparisonPercentaje)}%
											</div>
										</Tooltip>
									)}
								</div>

								<div className={styles.cardNumbersWrapper}>
									<div className={styles.cardNumber}>
										{result.current}{result.symbol}

										{result.value && (
											<div className={styles.value}>
												{result.potentialValue ? 'Potential value' : 'Value'}: {result.value.toLocaleString('en-US')}€
											</div>
										)}
									</div>

									<div className={styles.cardNumberComparison}>
										{result.comparison}
									</div>
								</div>
							</div>
						</div>

						{!!stepChange && result.showStep && (
							<Tooltip title={result.stepName || ''}>
								<div className={styles.steps} style={(result.stepName ? { cursor: 'pointer' } : {})}>
									{parseInt(stepChange)}%
								</div>
							</Tooltip>
						)}

						{!result.showStep && result.end && (
							<div className={styles.separator}></div>
						)}
					</Fragment>
				);
			})}
		</div>
	);
}
