import Config from '../../config';
import Auth from '../auth';

async function toBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
		reader.readAsDataURL(file);
	});
}

function accessTokenQueryParameter() {
	const token = Auth.getValidStoredTokens();
	const accessToken = token ? token.accessToken : '';
	return `accessToken=${accessToken}`;
};

function getImageDisplayUrl(id) {
	const url = `${Config.lastbasic.api.host}/public/file/${id}/display?${accessTokenQueryParameter()}`;
	return url;
};

function getContestCardPdfUrl(contestId, roundId) {
	const url = `${Config.lastbasic.api.host}/public/contest/${contestId}/round/${roundId}/contest-card?${accessTokenQueryParameter()}`;
	return url;
};

const fileUtils = {
	toBase64,
	getImageDisplayUrl,
	getContestCardPdfUrl,
};

export default fileUtils;
