import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import {
	Box, CircularProgress, TableRow,
	Table, TableBody, TableCell, TableHead,
	TablePagination, Typography, Avatar,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';

import { withStyles } from '@material-ui/core/styles';

import Config from '../../../../config';
import ServerAPI from '../../../../services/server-api';
import { StringUtils } from '../../../../utils';

const styles = {
	projectName: {
		fontWeight: 500,
		color: '#363636',
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			opacity: 0.9,
		},
	},
	projectNameAvatar: {
		width: 21,
		height: 21,
		minWidth: 21,
		minHeight: 21,
		borderRadius: 50,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundColor: '#C7C7C7',
		marginRight: 10,
		border: '0px solid #fafafa',
	},
	projectNameText: {
		marginTop: 2.5,
	},
	tableRow: {
		'&:hover': {
			backgroundColor: '#fafafa',
		},
	},
	margin: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
	},
	marginBig: {
		fontSize: 'inherit',
		opacity: 'inherit',
	},
	marginSmall: {
		fontSize: 10,
		opacity: 0.7,
	},
	avatarWrapper: {
		marginRight: '10px',
		marginBottom: '-8px',
		marginTop: '-2px',
	},
	avatarImage: {
		height: 22,
		width: 22,
	},
	skeletonWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: 10,
	},
	skeleton: {
		display: 'inline-block',
	},
};


function ProjectsFinancialsTable({ classes, filter = null, color }) {
	const [isLoading, setIsLoading] = useState(true);
	const [projects, setProjects] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const loadProjects = useCallback(async () => {
		const getProjectsRequest = await ServerAPI.getProjects({
			type: filter,
			currentPage,
			pageSize,
		});
		if (getProjectsRequest.error) return;

		setIsLoading(false);
		setProjects(getProjectsRequest.data.projects);
		setTotalCount(getProjectsRequest.data.totalCount);
	}, [filter, currentPage, pageSize]);

	useEffect(() => {
		loadProjects();
	}, [loadProjects]);

	function getAvatarUrl(userId) {
		return `${Config.lastbasic.api.host}/public/user/${userId}/avatar`;
	}

	function getRatioColor(ratio, absolute) {
		ratio = parseFloat(ratio);

		if (absolute < 0) return '#B83539';
		if (ratio <= 10 && ratio !== 0) return '#F7AE59';
		if (ratio > 10) return '#119D1F';
		
		return 'inherit';
	}

	function CircularProgressWithContent(props) {
		return (
			<Box position="relative" display="inline-flex">
			<CircularProgress {...props} />
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography variant="caption" component="div" color="textSecondary">
					{props.content}
				</Typography>
			</Box>
			</Box>
		);
	}

	return (
		<Fragment>
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Revenue</TableCell>
						<TableCell>Costs</TableCell>
						<TableCell>Margin</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{isLoading && [...Array(5).keys()].map((n, key) => (
						<TableRow key={key}>
							<TableCell className={classes.skeletonWrapper}>
								<Skeleton className={classes.skeleton} variant="circle" width={30} height={30} />
								<Skeleton className={classes.skeleton} variant="text" width={120} height={30} />
							</TableCell>
							<TableCell>
								<Skeleton className={classes.skeleton} variant="text" width={40} height={30} />
							</TableCell>
							<TableCell>
								<Skeleton className={classes.skeleton} variant="text" width={40} height={30} />
							</TableCell>
							<TableCell>
								<Skeleton className={classes.skeleton} variant="text" width={40} height={30} />
							</TableCell>
						</TableRow>
					))}
					{!isLoading && projects && projects.map(e => {
						const fullName = e.owner.firstName + ' ' + e.owner.lastName;
						const marginRatio = StringUtils.numberFormat(e.financials.margin.ratio * 100);
						const marginAbsolute = e.financials.margin.absolute;

						return (
							<TableRow className={classes.tableRow} key={e.id}>
								<TableCell className={classes.ellipsis}>
									<Link to={`/financials/projects/${e.id}`} className={classes.projectName} title={'Open project page'}>
										<div className={classes.avatarWrapper}>
											<CircularProgressWithContent
												className={classes.projectNameAvatarProgress}
												variant='determinate'
												size={'30px'}
												value={e.progress}
												thickness={4}
												style={{ color }}
												content={ <Avatar alt={fullName} src={getAvatarUrl(e.owner.id)} className={classes.avatarImage} /> }
											/>
										</div>
										<div className={classes.projectNameText}>{e.name}</div>
									</Link>
								</TableCell>
								<TableCell className={classes.ellipsis}>
									{e.financials.revenue.total}€
								</TableCell>
								<TableCell>
									{e.financials.costs.total}€
								</TableCell>
								<TableCell>
									<div className={classes.margin}>
										<div>
											<div className={classes.marginBig} style={{ color: getRatioColor(marginRatio, marginAbsolute) }}>
												{marginRatio}%
											</div>
											<div className={classes.marginSmall}>
												{marginAbsolute}€
											</div>
										</div>
									</div>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>

			{!isLoading && (totalCount > 5) &&
				<TablePagination
					component="div"
					count={totalCount || 0}
					page={currentPage || 0}
					onPageChange={(e, p) => setCurrentPage(p)}
					rowsPerPage={pageSize}
					onRowsPerPageChange={(e) => setPageSize(e.target.value)}
				/>
			}
		</Fragment>
	);
}

export default withStyles(styles)(ProjectsFinancialsTable);
