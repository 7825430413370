import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';

const styles = theme => ({
	bar: {
		width: '150px',
		height: '28px',
		backgroundColor: '#F5F5F5',
		borderRadius: '50px',
		border: '0px solid #E3E3E3',
		cursor: 'pointer',
		opacity: '0.3',
		color: 'rgba(0, 0, 0, 0.87)',
	},
	some: {
		opacity: '1',
	},
	progress: {
		height: '100%',
		width: '0',
		backgroundColor: 'gray',
		borderRadius: '50px 0 0 50px',
	},
	number: {
		textAlign: 'center',
		position: 'absolute',
		width: '150px',
		height: '28px',
		paddingTop: '4px',
	}
});

class CreditBar extends React.Component {
	createDynamicStyle = (creditBalance, minCreditUntilPayment) => {
		const style = {};
		
		// Progress width
		const percentaje = (creditBalance * 100)/minCreditUntilPayment;
		const width = (creditBalance > 0 ? Math.min(Math.max(parseInt(percentaje), 10), 100) : 0);
		style.width = width + '%';

		if (width > 90) {
			style.borderRadius = '50px';
		}

		style.backgroundColor = (width === 100 ? '#A2F0C9' : '#8AC5F5');

		return style;
	}

	render() {
		const {
			classes,
			minCreditUntilPayment,
			creditBalance,
			userId,
		} = this.props;

		return (
			<div style={{ width: '150px' }}>
				<Link to={'/payments/users/' + userId + '/credit'}>
					<Tooltip title={'Open credit page'}>
						<div className={classes.bar + ' ' + (creditBalance > 0 ? classes.some : '')}>
							<div className={classes.number}>{creditBalance}€</div>
							<div className={classes.progress} style={{...this.createDynamicStyle(creditBalance, minCreditUntilPayment)}}/>
						</div>
					</Tooltip>
				</Link>
			</div>
		);
	}
}

export default withStyles(styles)(CreditBar);
