import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	IconButton, Button, TextField,
	Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Input,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { PAYMENT } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
	cardContent: {
		textAlign: 'left',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '120px',
	},
	topButtons: {
		marginBottom: '20px',
		textAlign: 'right',
	},
	note: {
		fontSize: '12px',
		padding: '10px',
		backgroundColor: '#FAEEAD',
		borderRadius: '6px',
		marginBottom: '10px',
	},
	proposalLink: {
		color: 'rgba(0, 0, 0, 0.87)',
	},
});

function WalletPage({ data, classes, enqueueSnackbar, setForceUpdate }) {
	const [dialogCreateShown, setDialogCreateShown] = useState(false);
	const [transactionConcept, setTransactionConcept] = useState('');
	const [transactionAmount, setTransactionAmount] = useState('');
	const [deleteDialogShown, setDeleteDialogShown] = useState(false);
	const [selectedTransaction, setSelectedTransaction] = useState(null);

	async function deleteTransaction(transactionId) {
		const result = await ServerAPI.deleteWalletTransaction({ transactionId });
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Transaction could not be deleted', { variant: 'error' });
		}

		setForceUpdate(new Date());
		setDeleteDialogShown(false);
		return enqueueSnackbar('Transaction deleted successfully', { variant: 'success' });
	}

	function onDeleteTransaction(transactionId) {
		setSelectedTransaction(transactionId);
		setDeleteDialogShown(true);

		return true;
	}

	async function createTransaction() {
		const result = await ServerAPI.createWalletTransaction({
			userId: data.id,
			amount: transactionAmount,
			concept: transactionConcept,
		});
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Wallet transaction could not be created', { variant: 'error' });
		}

		setForceUpdate(new Date());
		emptyCreateFieldsAndClose();
		return enqueueSnackbar('Wallet transaction created successfully', { variant: 'success' });
	}

	function emptyCreateFieldsAndClose() {
		setDialogCreateShown(false);
		setTransactionConcept('');
		setTransactionAmount('');
	}

	return (
		<Fragment>
			<div className={classes.topButtons}>
				<Button color='primary' variant='outlined' onClick={() => setDialogCreateShown(true)}>
					Create wallet transaction
				</Button>
			</div>
			<TableContainer>
				<Table aria-label="simple table" style={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '150px' }}>Date</TableCell>
							<TableCell>Concept</TableCell>
							<TableCell style={{ textAlign: 'right' }}>Amount</TableCell>
							<TableCell style={{ textAlign: 'right' }}>Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.walletTransactions.map(r => (
							<TableRow key={r.id}>
								<TableCell>{dayjs(r.createdAt).format('D MMM YYYY')}</TableCell>
								<TableCell className={classes.ellipsis}>
									{r.type === PAYMENT.WALLET_TRANSACTION_TYPES.CONTEST_WON &&
										<Fragment>
											<Link className={classes.proposalLink} target='_blank' to={`/project/${r.metadata.projectId}/contest/${r.metadata.contest.type}`}>
												Winning proposal - {r.metadata.project.name} ({r.metadata.contest.type})
											</Link>
										</Fragment>
									} 
									{r.type !== PAYMENT.WALLET_TRANSACTION_TYPES.CONTEST_WON &&
										r.concept
									} 
								</TableCell>
								<TableCell style={{ width: '100px', textAlign: 'right' }}><span style={{ color: (r.amount > 0 ? '#119D1F' : '#B83539')}}>{r.amount}€</span></TableCell>
								<TableCell style={{ width: '100px', textAlign: 'right' }}>
									<IconButton onClick={() => onDeleteTransaction(r.id)}>
										<Delete/>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog
				open={dialogCreateShown}
				onClose={() => setDialogCreateShown(false)}
			>
				<DialogTitle>Create wallet transaction</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You are about to create a wallet transaction for {data.firstName} {data.lastName}:
						<div className={classes.note}>Note that <b>this is real money, not credits</b></div>

						<div style={{ marginTop: '15px', marginBottom: '5px'}}>
							<Input
								type='number'
								placeholder='Amount (€)'
								value={transactionAmount}
								onChange={(e) => setTransactionAmount(e.target.value)}
								autoFocus={true}
							/>

							<TextField
								placeholder='Transaction concept'
								value={transactionConcept}
								onChange={(e) => setTransactionConcept(e.target.value)}
								style={{ marginLeft: '20px', width: '300px' }}
							/>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={emptyCreateFieldsAndClose}>
						Close
					</Button>
					<Button onClick={createTransaction} color="primary" variant='contained' disableElevation>
						Create transaction
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={deleteDialogShown}
				onClose={() => setDeleteDialogShown(false)}
			>
				<DialogTitle>Delete wallet transaction</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you really want to delete this transaction?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteDialogShown(false)}>
						Close
					</Button>
					<Button onClick={() => deleteTransaction(selectedTransaction)} color="secondary" variant='contained' disableElevation>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default withSnackbar(withStyles(styles)(WalletPage));
