import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import { Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ServerAPI from '../../../../services/server-api';

const styles = () => ({
	checkbox: {
		padding: "0px 0px 0px 5px",
	}
});

class ExpertDetail extends Component {
	constructor(props) {
		super(props);
		
		const { row } = props;
		
		this.state = {
			ndaSigned: !!row.ndaSigned,
		};
	}
	
	onNDASignedChange(e) {
		const {
			row: { user: { id } },
			enqueueSnackbar,
			processValueChange,
			applyChanges,
		} = this.props;
		
		this.setState({ ndaSigned: !!e.target.checked }, async () => {
			// Update data
			const result = await ServerAPI.updateExpert(id, {
				ndaSigned: this.state.ndaSigned,
			});
			if (result.error) return enqueueSnackbar(result.error.message || 'Could not update expert', { variant: 'error' });
			enqueueSnackbar('Expert updated', { variant: 'success' });

			// Update in parent cell
			await processValueChange({ expert: { ndaSigned: this.state.ndaSigned } });
			applyChanges();
		});
	}

	render () {
		const { classes, row } = this.props;
		const { ndaSigned } = this.state;
		const { typeformName, lastLoggedAt } = row;

		return (
			<Box>
				<Box mb={2} display="flex">
					<Box ml={2} mr={1} fontWeight="fontWeightBold" fontSize="fontSize">Typeform:</Box>
					<Box>{typeformName || 'Unknown'}</Box>
				</Box>
				<Box mb={2} display="flex">
					<Box ml={2} mr={1} fontWeight="fontWeightBold" fontSize="fontSize">Last logged in:</Box>
					<Box>{lastLoggedAt || 'None'}</Box>
				</Box>
				<Box>
					<FormControlLabel
						control={<Checkbox
							className={classes.checkbox}
							checked={ndaSigned}
							onChange={(e) => this.onNDASignedChange(e)}
							name="ndaSigned"
							color="primary"
						/>}
						label={<Box fontWeight="fontWeightBold" fontSize="fontSize">NDA Signed:</Box>}
						labelPlacement="start"
					/>
				</Box>
			</Box>
		);
	}
};

ExpertDetail.propTypes = {
	row: PropTypes.object.isRequired,
};

ExpertDetail.defaultProps = {
	row: {},
};

export default withSnackbar(withStyles(styles)(ExpertDetail));
