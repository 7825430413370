import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
	Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
	comment: {
		width: "100%",
	},
});

function CommentDialog(props) {
	const {
		classes,
		open,
		user,
		onSubmit,
		onClose,
	} = props;
		
	const [comment, setComment] = useState(user && user.expert && user.expert.comment);
	
	useEffect(() => {
		setComment(user && user.expert && user.expert.comment);
	}, [user]);
	
	const handleChangeComment = (e) => {
		setComment(e.target.value);
	};
	
	const handleSubmit = () => {
		onSubmit(comment)
	};
	
	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth={true}
		>
			<DialogTitle>Edit comment</DialogTitle>
			<DialogContent>
				<TextField
					className={classes.comment}
					label="Comment"
					multiline
					value={comment || ''}
					variant="outlined"
					name="comment"
					onChange={handleChangeComment}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleSubmit} color="primary">
					Update
				</Button>
			</DialogActions>
		</Dialog>
	);
}

CommentDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	user: PropTypes.object,
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

CommentDialog.defaultProps = {
	classes: {},
	open: false,
	user: {},
	onSubmit() {},
	onClose() {},
};

export default withStyles(styles)(CommentDialog);
