import { useLocation, useNavigate, useParams } from 'react-router-dom';

function withRouter (Component) {
	const Wrapper = (props) => {
		const navigate = useNavigate();
		const location = useLocation();
		const params = useParams();

		return (
			<Component
				navigate={navigate}
				location={location}
				match={{
					params
				}}
				{...props}
			/>
		);
	};
  
  return Wrapper;
}

export default withRouter;
