import React, { useState, useEffect, } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Card, CardContent, CircularProgress, Typography,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	IconButton, Tooltip,
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import { Launch } from '@material-ui/icons';

import { ADMIN } from '../../../constants';
import ServerAPI from '../../../services/server-api';
import UserUtils from '../../../utils/user';

import DelayedTextField from '../../../components/inputs/delayed-text-field';

import CreditBar from './components/credit-bar';
import ConvertCredit from './components/convert-credit';
import CreditLevel from './components/credit-level';
import PayoutsBar from './components/payouts-bar';
import WalletBar from './components/wallet-bar';

function showWalletBasedOnUser() {
	return UserUtils.userHasAdminRole([
		ADMIN.ROLES.DEVELOPMENT,
		ADMIN.ROLES.SUPERADMIN
	]);
}

const styles = () => ({
	cardContent: {
		textAlign: 'left',
	},
	tableContainer: {
		marginTop: '20px',
	},
	table: {
		borderTop: '1px solid rgba(224, 224, 224, 1)',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '150px',
	},
});

function PaymentsPage({ classes }) {
	const [result, setResult] = useState({ error: null, isLoading: true, payments: null, totalCount: null });
	const [filter, setFilter] = useState({ searchText: '', currentPage: 0, pageSize: 50 });

	const { isLoading, payments, totalCount } = result;
	const { searchText, currentPage, pageSize } = filter;
	const showWallet = showWalletBasedOnUser();

	useEffect(() => {
		async function fetchData() {
			setResult(r => ({
				...r,
				isLoading: true,
			}));
			const { searchText, currentPage, pageSize } = filter;
			const currentResult = await ServerAPI.getPayments({ searchText, currentPage, pageSize });
			if (currentResult.error) {
				return setResult(r => ({
					error: currentResult.error,
					isLoading: false,
					payments: null,
					totalCount: null
				}));
			}
			
			setResult(r => ({
				error: null,
				isLoading: false,
				payments: currentResult.data.results,
				totalCount: currentResult.data.totalCount
			}));
		}
		fetchData();
	}, [filter]);

	function onSearchChange(value) {
		setFilter(f => ({
			...f,
			searchText: value,
			currentPage: 0,
		}));
	};

	function updateResult(id, paymentUpdated) {
		const newPayments = payments.map(p => {
			return (p.id === id ? paymentUpdated : p);
		});
		setResult(r => ({
			...r,
			payments: newPayments
		}));
	}
	
	function handlePageChange(page) {
		setFilter(f => ({
			...f,
			currentPage: page
		}));
	}

	function handlePageSizeChange(size) {
		setFilter(f => ({
			...f,
			currentPage: 0,
			pageSize: size
		}));
	}
	
	// Sort payments by balance DESC
	if (payments) {
		payments.sort((a, b) => (a.creditBalance < b.creditBalance) ? 1 : -1);
	}

	return (
		<Card className={classes.root} variant="outlined">
			<CardContent className={classes.cardContent}>
				<Typography variant="h5" gutterBottom>
					Payments
				</Typography>
				<DelayedTextField
					label='Search by name, surname, or email'
					value={searchText}
					style={{ width: '300px', marginBottom: '40px' }}
					onChange={(e) => onSearchChange(e.target.value)}
					inputProps={{ spellCheck: 'false' }}
				/>
				<TableContainer className={classes.tableContainer}>
					<Table aria-label="simple table" className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Email</TableCell>
								<TableCell>Credit</TableCell>
								<TableCell style={{ display: 'none' }}></TableCell>
								<TableCell style={{ display: showWallet ? 'table-cell' : 'none' }}>Wallet</TableCell>
								<TableCell style={{ textAlign: 'right', width: '120px' }}>Total income</TableCell>
								<TableCell>Open payouts</TableCell>
								<TableCell>Credit Level</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={9}>
										<Box py={5} style={{ textAlign: "center" }}>
											<CircularProgress />
										</Box>
									</TableCell>
								</TableRow>
							)}
							{!isLoading && payments && payments.map(e => (
								<TableRow key={e.id}>
									<TableCell className={classes.ellipsis} title={'Open expert profile'}>
										<Link to={'/expert/' + e.id}>
											<IconButton aria-label="open">
												<Tooltip title={'Open expert profile'}>
													<Launch />
												</Tooltip>
											</IconButton>
										</Link>
									</TableCell>
									<TableCell className={classes.ellipsis} title={e.firstName + ' ' + e.lastName}>
										<Link to={`/payments/users/${e.id}/payouts`} style={{ color: 'rgba(0, 0, 0, 0.87)'}}>
											{e.firstName + ' ' + e.lastName}
										</Link>
									</TableCell>
									<TableCell className={classes.ellipsis} title={e.email}>
										{e.email}
									</TableCell>
									<TableCell>
										<CreditBar
											userId={e.id}
											creditBalance={e.creditBalance}
											minCreditUntilPayment={e.minCreditUntilPayment}
										/>
									</TableCell>
									<TableCell style={{ display: 'none' }}>
										<ConvertCredit
											userId={e.id}
											creditBalance={e.creditBalance}
											minCreditUntilPayment={e.minCreditUntilPayment}
											changeResults={updateResult}
											data={e}
										/>
									</TableCell>
									<TableCell style={{ display: showWallet ? 'table-cell' : 'none' }}>
										<WalletBar
											userId={e.id}
											walletBalance={e.walletBalance}
										/>
									</TableCell>
									<TableCell style={{ textAlign: 'right', opacity: '0.8' }}>
										{e.payoutBalance}€
									</TableCell>
									<TableCell>
										<PayoutsBar
											userId={e.id}
											openPayouts={e.openPayouts}
										/>
									</TableCell>
									<TableCell>
										<CreditLevel 
											level={e.creditLevel}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>

			{!isLoading &&
				<TablePagination
					component="div"
					count={totalCount}
					page={currentPage}
					onPageChange={(e, p) => handlePageChange(p)}
					rowsPerPage={pageSize}
					onRowsPerPageChange={(e) => handlePageSizeChange(e.target.value)}
					style={{ marginBottom: '15px' }}
				/>
			}
		</Card>
	);
}

export default withStyles(styles)(PaymentsPage);
