import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import {
	Card, CardContent, Divider, Typography
} from '@material-ui/core';
import { Chart } from "react-google-charts";

const styles = (theme) => ({
	graph: {
		//boxShadow: "0 2px 4px 0 rgb(35 46 60 / 4%)",
		textAlign: "left",
		
		"& > div": {
			padding: "0px",
		}
	},
	graphTitle: {
		padding: "5px 15px"
	},
	graphChart: {
		padding: "0px",
	},
});

class Graph extends Component {
	render() {
		const { classes, chartData, title, labels } = this.props;
		
		return (
			<Card className={classes.graph} variant="outlined">
				<CardContent className={classes.cardContent}>
					<Typography className={classes.graphTitle} variant="subtitle1">
						{title}
					</Typography>
					<Divider />
					<Chart
						className={classes.graphChart}
						width={'100%'}
						height={'100%'}
						chartType="ComboChart"
						loader={<div>Loading Chart</div>}
						data={[
							labels,
							...chartData,
						]}
						options={{
							legend: {
								position: 'none',
							},
							vAxes: [
								{ title: 'Count (monthly)' },
								{ title: 'Count (cumulative)' },
							],
							hAxis: { title: 'Months' },
							series: {
								0: {
									targetAxisIndex: 0,
									type: 'bars',
								},
								1: {
									color: '#1b76d2',
									targetAxisIndex: 1,
									type: 'area',
								},
							},
						}}
					/>
				</CardContent>
			</Card>
		);
	}
}

export default withStyles(styles)(Graph);
