import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent } from '@material-ui/core';
import dayjs from 'dayjs';
import { Portal } from 'react-overlays';
import 'react-datepicker/dist/react-datepicker.css';

import { default as UTCDatePicker } from './utc-date-picker';

const styles = theme => ({
	root: {
		textAlign: "center",
	},
	cardContent: {
		display: "flex",
		justifyContent: "center",
		padding: theme.spacing(0),
	},
	clearableInput: {
		paddingRight: "27px",
	},
	date: {
		borderRadius: "6px",
	},
	popperComponent: {
		zIndex: '99999',
	},
	inline: {
		display: 'inline',
	},
});

class DatePicker extends Component {
	constructor (props) {
		super(props);
		
		const { defaultDate } = this.props;

		this.state = {
			selectedDate: defaultDate
		};
	}
	
	shouldComponentUpdate(nextProps, nextState) {
		const prevDate = (typeof this.props.date !== 'undefined') ? this.props.date : this.state.selectedDate;
		const nextDate = (typeof nextProps.date !== 'undefined') ? nextProps.date : nextState.selectedDate;

		return (prevDate !== nextDate);
	}
	
	isControlled = () => {
		const { date } = this.props;
		return (typeof date !== 'undefined');
	};
	
	onChange = (newDate, lala) => {
		const { onChange } = this.props;

		if (!this.isControlled()) {
			this.setState({
				selectedDate: newDate,
			});
		}
		
		onChange(newDate);
	};
	
	render() {
		const {
			classes,
			className,
			container,
			date,
			defaultDate,
			fixOverflow,
			inline,
			isClearable,
			name,
			placeholderText,
		} = this.props;
		const { selectedDate } = this.state;
		
		if (date && defaultDate) throw new Error('Use only date or defaultDate');
		
		const CustomInput = ({ name, onClick, value }) => {
			const selectedDate = value ? dayjs(value) : null
			const formattedValue = selectedDate ? selectedDate.format('DD/MM/YYYY') : null
			const unixTimestamp = selectedDate ? selectedDate.unix() : null
			return (
				<Fragment>
					<Button
						className={
							((isClearable && value) ? classes.clearableInput : '')
							+ " "
							+ classes.date
						}
						variant="outlined"
						onClick={onClick}>
						{formattedValue || placeholderText}
					</Button>
					{name && (
						<input type="hidden" name={name} value={unixTimestamp || ''} />
					)}
				</Fragment>
			);
		};

		const MyCustomInput = React.forwardRef((props, ref) => (
			<CustomInput innerRef={ref} {...props} />
		));

		const CalendarContainer = ({ children }) => {
			return fixOverflow ? (
				<Portal>
					{children}
				</Portal>
			) : (
				<React.Fragment>
					{children}
				</React.Fragment>
			);
		};
		
		const component = (
			<div
				className={
					className
					+ " "
					+ (inline ? classes.inline : '')
				}
			>
				<UTCDatePicker
					customInput={<MyCustomInput />}
					dateFormat="yyyy-MM-dd"
					dropdownMode="select"
					isClearable={isClearable}
					name={name}
					onChange={this.onChange}
					popperPlacement="bottom-start"
					popperClassName={classes.popperComponent}
					popperContainer={CalendarContainer}
					popperModifiers={{
						flip: {
							behavior: ['bottom-end', 'bottom-start']
						}
					}}
					selected={this.isControlled() ? date : selectedDate}
					showMonthDropdown
					showYearDropdown
				/>
			</div>
		);
		
		return (container) ? (
			<Card className={classes.root} variant="outlined">
				<CardContent className={classes.cardContent}>
					{component}
				</CardContent>
			</Card>
		) : component;
	}
}

DatePicker.propTypes = {
	className: PropTypes.string,
	container: PropTypes.bool,
	isClearable: PropTypes.bool,
	placeholderText: PropTypes.string,
	date: PropTypes.instanceOf(Date),
	defaultDate: PropTypes.instanceOf(Date),
	onChange: PropTypes.func,
	fixOverflow: PropTypes.bool,
	inline: PropTypes.bool,
	name: PropTypes.string
};

DatePicker.defaultProps = {
	className: '',
	container: true,
	isClearable: false,
	placeholderText: '',
	onChange() {},
	fixOverflow: false,
	inline: false,
	name: 'dateInput'
};

export default withStyles(styles)(DatePicker);
