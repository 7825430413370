import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import ContestCardRow from './contest-card-row';

const styles = () => ({

});

class ContestCardGrid extends Component {
	render() {
		const { contest, project, type, updateData } = this.props;

		return (
			<Fragment>
				{contest.rounds.map((round, index) => {
					return (
						<ContestCardRow
							key={index}
							project={project}
							type={type}
							contest={contest}
							round={round}
							updateData={updateData}
						/>
					);
				})}
			</Fragment>
		);
	}
}

ContestCardGrid.propTypes = {
	classes: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	contest: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(ContestCardGrid));
