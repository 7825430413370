import React, { Fragment, useState } from 'react';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import { 
	Button, IconButton, Tooltip, Dialog, DialogTitle, DialogActions, 
	DialogContent, TextField, Select, MenuItem
} from '@material-ui/core';
import { Add, Delete, Email, FiberManualRecord } from '@material-ui/icons';

import ConfirmationDialog from '../../../../components/dialog/confirmation-dialog';
import ServerAPI from '../../../../services/server-api';
import { PROJECT } from '../../../../constants';

const styles = () => ({
	tableActions: {
		margin: "20px 0px",
	},
	wrapper: {
		textAlign: 'left',
		margin: '40px 40px 20px 40px',
	},
	questionObject: {
		padding: '20px',
		borderRadius: '3px',
		border: '1px solid #EBEBEB',
		width: '30%',
		marginRight: '3%',
		marginBottom: '3%',
		display: 'inline-block',
		verticalAlign: 'top',
		position: 'relative',
	},
	question: {
		fontSize: '15px',
		fontWeight: '500',
		width: '90%',
	},
	option: {
		display: 'inline-block',
		marginRight: '10px',
		fontSize: '12px',
		color: '#B0B0B0',
	},
	deleteButton: {
		position: 'absolute',
		marginTop: '-12px',
		right: '3px',
	},
	answer: {
		display: 'block',
		backgroundColor: '#F5F5F5',
		padding: '4px',
		paddingLeft: '7px',
		paddingRight: '7px',
		borderRadius: '3px',
		marginTop: '10px',
	},
	inputTitle: {
		marginTop: '20px',
		fontSize: '13px',
		opacity: 0.7,
	},
	inputOptions: {
		marginTop: '10px',
	},
	inputOption: {
		display: 'inline-block',
		fontSize: '15px',
		width: '74%',
	},
	inputOptionDelete: {
		display: 'inline-block',
		fontSize: '15px',
		width: '15%',
		marginLeft: '5%',
	},
	ranges: {
		marginTop: '50px',
		marginBottom: '20px',
	},
});

function Question({ question, classes, deleteQuestion, onboardingQuestion }) {
	return (
		<div className={classes.questionObject}>
			<IconButton
				className={classes.deleteButton}
				style={((question.answer && question.answer.length) || !deleteQuestion) ? { display: 'none' } : {}}
				onClick={deleteQuestion}
			>
				<Delete fontSize={'small'} />
			</IconButton>
			<div className={classes.question}>
				{onboardingQuestion && (
					<Tooltip title='Onboarding question'>
						<FiberManualRecord color='primary' fontSize='inherit' style={{ cursor: 'pointer', paddingTop: 5, marginRight: 3 }} />
					</Tooltip>
				)}
				{question.question}
			</div>
			<div className={classes.options}>
				{question.options.map(o => (
					<div className={classes.option} key={o}>{o}</div>
				))}
			</div>

			<div className={classes.answerBlock}>
				{question.answer && question.answer.map(a => (
					<Tooltip key={a} title={'Client\'s answer'}>
						<div className={classes.answer}>{a}</div>
					</Tooltip>
				))}
			</div>
		</div>
	);
}

function ContestSetupTab({ project, type, contest, classes, enqueueSnackbar, updateData }) {
	const [newQuestionDialogDisplayed, setNewQuestionDialogDisplayed] = useState(false);
	const [questionType, setQuestionType] = useState('text');
	const [question, setQuestion] = useState('');
	const [options, setOptions] = useState([]);
	const [option, setOption] = useState('');
	const [minSelectable, setMinSelectable] = useState(1);
	const [maxSelectable, setMaxSelectable] = useState(1);
	const [deleteQuestionDialogDisplayed, setDeleteQuestionDialogDisplayed] = useState(false);
	const [deleteQuestionId, setDeleteQuestionId] = useState(null);
	const [emailDialogDisplay, setEmailDialogDisplay] = useState(false);

	function showQuestionDialog() {
		setNewQuestionDialogDisplayed(true);
	}

	function closeQuestionDialog() {
		setQuestionType('text');
		setOptions([]);
		setOption('');
		setMinSelectable(1);
		setMaxSelectable(1);
		setNewQuestionDialogDisplayed(false);
	}

	function addOption() {
		if (!option) return;
		setOptions([...options, option]);
		setOption('');
	}

	function removeOption(removeOption) {
		const filtered = options.filter(o => o !== removeOption);
		setOptions(filtered);
	}

	async function createQuestion() {
		if (!question) return enqueueSnackbar('Question must be provided', { variant: 'error' });

		const data = {
			question,
			type: questionType,
		};

		if (data.type === 'options') {
			if (options.length === 0) {
				return enqueueSnackbar('Must have at least 1 option', { variant: 'error' });
			}

			if (minSelectable > maxSelectable || maxSelectable > options.length) {
				return enqueueSnackbar('Configuration for options is not correct', { variant: 'error' });
			}

			data.options = options;
			data.maxSelectable = maxSelectable;
			data.minSelectable = minSelectable;
		}

		const result = await ServerAPI.addContestSetupQuestion(project.id, type, data);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not add question', { variant: 'error' });
		enqueueSnackbar('Question added successfully', { variant: 'success' });

		closeQuestionDialog();
		updateData();
	}

	async function deleteQuestion() {
		if (!deleteQuestionId) return;

		const result = await ServerAPI.deleteContestSetupQuestion(project.id, type, deleteQuestionId);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not delete question', { variant: 'error' });
		enqueueSnackbar('Question deleted successfully', { variant: 'success' });

		updateData();
		closeDeleteQuestionDialog();
	}

	function openDeleteQuestionDialog(questionId) {
		setDeleteQuestionId(questionId);
		setDeleteQuestionDialogDisplayed(true);
	}

	function closeDeleteQuestionDialog() {
		setDeleteQuestionId(null);
		setDeleteQuestionDialogDisplayed(false);
	}

	async function sendEmail() {
		const result = await ServerAPI.sendContestSetupQuestionsEmail(project.id, type);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not send email', { variant: 'error' });
		enqueueSnackbar('Email sent successfully', { variant: 'success' });

		setEmailDialogDisplay(false);
	}

	const clientOnboardingPhase = (project && project.states && project.states[PROJECT.STATES.CLIENT_ONBOARDING]);
	const phaseSetupQuestions = (clientOnboardingPhase && clientOnboardingPhase.setupQuestions) || []; 

	return (
		<Fragment>
			<div className={classes.tableActions}>
				<Button
					variant="outlined"
					color="primary"
					startIcon={<Add />}
					onClick={showQuestionDialog}
				>
					Add new question
				</Button>

				<Button
					variant="outlined"
					color="primary"
					startIcon={<Email />}
					onClick={() => setEmailDialogDisplay(true)}
					style={{ marginLeft: '30px' }}
				>
					Send email
				</Button>
			</div>

			<div className={classes.wrapper}>
				{phaseSetupQuestions.map(q => (
					<Question
						classes={classes}
						key={q.id}
						question={q}
						onboardingQuestion={true}
					/>
				))}

				{contest.setupQuestions.map(q => (
					<Question
						classes={classes}
						key={q.id}
						question={q}
						deleteQuestion={() => openDeleteQuestionDialog(q.id)}
					/>
				))}
			</div>

			<Dialog
				fullWidth={true}
				open={newQuestionDialogDisplayed}
				onClose={closeQuestionDialog}
			>
				<DialogTitle>New setup question</DialogTitle>
				<DialogContent>
					<TextField
						label={'Question'}
						variant={'outlined'}
						onChange={(e) => setQuestion(e.target.value)}
						style={{ width: '100%'}}
					/>
					<div className={classes.inputTitle}>Answer type</div>
					<Select
						label={'Question'}
						variant={'outlined'}
						value={questionType}
						onChange={(e) => setQuestionType(e.target.value)}
						style={{ width: '100%'}}
					>
						<MenuItem value={'text'}>Text</MenuItem>
						<MenuItem value={'options'}>Options</MenuItem>
					</Select>
					{questionType === 'options' && (
						<Fragment>
							<div className={classes.inputTitle}>Options</div>
							{options.map(o => (
								<div className={classes.inputOptions} key={o}>
									<div className={classes.inputOption}>{o}</div>
									<div className={classes.inputOptionDelete}>
										<Button
											startIcon={<Delete />}
											variant='outlined'
											onClick={() => removeOption(o)}
										>
											Remove
										</Button>
									</div>
								</div>
							))}

							<TextField
								label={'Option'}
								value={option}
								onChange={(e) => setOption(e.target.value)}
								style={{ width: '80%', marginTop: '10px' }}
							/>

							<Button
								startIcon={<Add />}
								variant='outlined'
								onClick={addOption}
								style={{ width: '15%', marginTop: '20px', marginLeft: '5%' }}
							>
								Add
							</Button>
							
							<div className={classes.ranges}>
								<TextField
									type={'number'}
									label={'Max selectable'}
									variant={'outlined'}
									value={maxSelectable}
									onChange={(e) => setMaxSelectable(e.target.value)}
									style={{ width: '160px'}}
								/>

								<TextField
									type={'number'}
									label={'Min selectable'}
									variant={'outlined'}
									value={minSelectable}
									onChange={(e) => setMinSelectable(e.target.value)}
									style={{ width: '160px', marginLeft: '20px'}}
								/>
							</div>
						</Fragment>
					)}
				</DialogContent>

				<DialogActions>
					<Button 
						onClick={closeQuestionDialog}
						disableElevation
					>
						Close
					</Button>
					<Button
						onClick={createQuestion}
						color='primary'
						variant='contained'
						disableElevation
					>
						Create question
					</Button>
				</DialogActions>
			</Dialog>

			<ConfirmationDialog
				title={'Are you sure you want to delete this quesion?'}
				content={'This will permanently delete the setup question'}
				primaryText={'Delete setup question'}
				secondaryText={'Cancel'}
				open={deleteQuestionDialogDisplayed}
				onClose={closeDeleteQuestionDialog}
				onConfirm={deleteQuestion}
				color={'secondary'}
			/>

			<ConfirmationDialog
				title={'Send notification to project owner'}
				content={'You will now send an email to the project owner. The email will say that there are questions to be answered in this phase'}
				primaryText={'Send email'}
				secondaryText={'Cancel'}
				open={emailDialogDisplay}
				onClose={() => setEmailDialogDisplay(false)}
				onConfirm={sendEmail}
			/>
		</Fragment>
	);
}

export default withSnackbar(withStyles(styles)(ContestSetupTab));
