import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
	Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle,
	FormControl, IconButton, Select, MenuItem, TextField, Tooltip, Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add, CalendarToday, Delete, DragIndicator, Edit, FiberManualRecord, Info, Warning } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import CONTEST from '../../../../constants/contest';
import ConfirmationDialog from '../../../../components/dialog/confirmation-dialog';
import DatePicker from '../../../../components/inputs/date-picker';
import FileGridSelector from '../../../../components/inputs/file-grid-selector';
import withRouter from '../../../../components/withRouter';
import ServerAPI from '../../../../services/server-api';
import FileUtils from '../../../../utils/file';

const styles = theme => ({
	contestStepItem: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: '16px',
		border: '1px solid #EBEBEB',
		borderRadius: '3px',
		gap: '10px',
		background: '#fff',
	},
	contestStepItemBody: {
		flex: '1',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		padding: '20px',
	},
	contestStepItemHandle:  {
		padding: '20px 5px',
		display: 'flex',
		alignItems: 'center',
		background: '#f8f8f8',
		cursor: 'pointer',
		'&:hover': {
			background: '#efefef',
		}
	},
	contestStepItemStatus: {
		border: 'none'
	},
	contestStepList: {
	    margin: '40px 40px 20px 40px',
	},
	contestStepUpdateItem: {
		position: 'relative',
	    display: 'flex',
		marginLeft: '16px',
		padding: '10px 0',
		textAlign: 'left',

		'&::before': {
			position: 'absolute',
			top: '0px',
			bottom: '0px',
			left: '0px',
			display: 'block',
			width: '2px',
			content: '""',
			backgroundColor: '#d9dde5'
		}
	},
	contestStepUpdateItemBody: {
		flex: '1',
	},
	contestStepUpdateItemIcon: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		zIndex: '1',
		width: '32px',
		height: '26px',
		margin: '0px 8px 8px -15px',
		color: '#57606a',
		borderRadius: '50%',
		justifyContent: 'center',
		flexShrink: '0',
		background: '#fff',
		border: '2px solid #fff',
		
		'& svg': {
			height: '26px'
		}
	},
	contestStepUpdateList: {
		width: '100%',
	},
	formControl: {
		width: '100%',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	wrapper: {
		margin: '20px 0px',
	}
});

class ContestSteps extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			openAddContestSteps: false,
			addingContestStep: false,
		};
	}
	
	onClickAddContestStep = () => {
		this.setState({
			openAddContestSteps: true,
			addingContestStep: false
		});
	};

	onCloseAddContestStepModal = () => {
		this.setState({
			openAddContestSteps: false,
			addingContestStep: false
		});
	};
	
	onSubmitAddContestStep = async (e) => {
		e.preventDefault();
		
		const { elements } = e.target;
		const { contestType, enqueueSnackbar, project, updateData } = this.props;
		
		this.setState({ addingContestStep: true });
		
		const result = await ServerAPI.createContestStep(project.id, contestType, {
			title: elements.title.value,
			description: elements.description.value,
		});
		if (result.error) {
			this.setState({ addingContestStep: false });
			return enqueueSnackbar(result.error.message || 'Could not create contest step', { variant: 'error' });
		}
		enqueueSnackbar('Contest step created successfully', { variant: 'success' });

		this.setState({ addingContestStep: false });
		
		this.onCloseAddContestStepModal();
		updateData();
	};

	handleOnDragEndContestSteps = async (draggingResult) => {
		if (!draggingResult.destination) return;
		const { contest, contestType, enqueueSnackbar, project, updateData } = this.props;
		const items = Array.from(contest.steps);
		const [reorderedItem] = items.splice(draggingResult.source.index, 1);
		items.splice(draggingResult.destination.index, 0, reorderedItem);

		const result = await ServerAPI.updateContestStepsOrder(project.id, contestType, {
			stepsOrder: items.map((item, index) => ({
				id: item.id,
				order: index + 1
			}))
		});
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Could not update contest step order', { variant: 'error' });
		}
		enqueueSnackbar('Contest step order updated successfully', { variant: 'success' });

		updateData();
	};

	render() {
		const { classes, contest, contestType, enqueueSnackbar, project, updateData } = this.props;
		const { addingContestStep, openAddContestSteps } = this.state;
		
		const orderedContestSteps = contest.steps.sort((a, b) => {
			return a.order - b.order;
		});
				
		return (
			<div className={classes.wrapper}>
				<div className={classes.tableActions}>
					<Button
						variant="outlined"
						color="primary"
						startIcon={<Add />}
						onClick={this.onClickAddContestStep}
					>
						Add contest step
					</Button>
				</div>
				<div className={classes.contestStepList}>
					{orderedContestSteps.length === 0 && (
						<Alert severity="info">
							No contests steps
						</Alert>
					)}
					<DragDropContext onDragEnd={this.handleOnDragEndContestSteps}>
						<Droppable droppableId="contestSteps" direction="vertical">
							{(provided) => (
								<div
									ref={provided.innerRef}
									{...provided.droppableProps}
								>
									{orderedContestSteps.map((step, index) => (
										<Draggable
											key={step.id}
											draggableId={step.id}
											index={index}
										>
											{(provided) => (
												<ContestStepItem
													classes={classes}
													contest={contest}
													contestType={contestType}
													provided={provided}
													enqueueSnackbar={enqueueSnackbar}
													key={step.id}
													project={project}
													position={index + 1}
													step={step}
													updateData={updateData}
												/>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
				<Dialog
					open={openAddContestSteps}
					onClose={this.onCloseAddContestStepModal}
					fullWidth={true}
				>
					<form className={classes.form} onSubmit={this.onSubmitAddContestStep}>
						<DialogTitle>
							Add Contest Step
						</DialogTitle>
						<DialogContent>
							<FormControl className={classes.formControl}>
								<TextField
									fullWidth
									label="Title"
									name="title"
									variant="outlined"
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									fullWidth
									label="Description"
									multiline
									name="description"
									variant="outlined"
								/>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.onCloseAddContestStepModal}
								type="button"
							>
								Cancel
							</Button>
							<Button
								color="primary"
								disabled={addingContestStep}
								disableElevation={true}
								type="submit"
								variant="contained"
							>
								Add
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</div>
		);
	}
}

ContestSteps.propTypes = {
	classes: PropTypes.object.isRequired,
	contest: PropTypes.object.isRequired,
	contestType: PropTypes.string.isRequired,
	project: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};

export default withSnackbar(withStyles(styles)(withRouter(ContestSteps)));

class ContestStepItem extends Component {
	constructor (props) {
		super(props);

		this.state = {
			editingContestStep: false,
			openEditContestStep: false,
			addingContestStepUpdate: false,
			openAddContestStepUpdate: false,
			openDeleteContestStep: false,
			stepUpdateFileId: null,
		};
	}
	
	onClickEditContestStep = () => {
		this.setState({
			editingContestStep: false,
			openEditContestStep: true,
		});
	};
	
	onCloseEditContestStepModal = () => {
		this.setState({
			editingContestStep: false,
			openEditContestStep: false,
		});
	};
			
	onSubmitEditContestStep = async (e) => {
		e.preventDefault();
		
		const { elements } = e.target;
		const { contestType, enqueueSnackbar, project, step, updateData } = this.props;
		
		this.setState({ editingContestStep: true });
		
		const result = await ServerAPI.updateContestStep(project.id, contestType, step.id, {
			title: elements.title.value,
			description: elements.description.value,
			status: elements.status.value,
			order: step.order,
			startDate: elements.startDate.value ? new Date(Number(elements.startDate.value) * 1000) : null,
			endDate: elements.endDate.value ? new Date(Number(elements.endDate.value) * 1000) : null,
		});
		if (result.error) {
			this.setState({ editingContestStep: false });
			return enqueueSnackbar(result.error.message || 'Could not update contest step', { variant: 'error' });
		}
		enqueueSnackbar('Contest step update successfully', { variant: 'success' });

		this.setState({ editingContestStep: false });
		
		this.onCloseEditContestStepModal();
		updateData();
	};
	
	onClickAddContestStepUpdate = () => {
		this.setState({
			addingContestStepUpdate: false,
			openAddContestStepUpdate: true,
			stepUpdateFileId: null,
		});
	};

	onCloseAddContestStepUpdateModal = () => {
		this.setState({
			addingContestStepUpdate: false,
			openAddContestStepUpdate: false,
		});
	};
	
	onSubmitAddContestStepUpdate = async (e) => {
		e.preventDefault();
		
		const { elements } = e.target;
		const { contestType, enqueueSnackbar, project, step, updateData } = this.props;
		const { stepUpdateFileId } = this.state;
		
		this.setState({ addingContestStepUpdate: true });
		
		const result = await ServerAPI.createContestStepUpdate(project.id, contestType, step.id, {
			title: elements.title.value,
			content: elements.content.value,
			type: elements.type.value,
			files: stepUpdateFileId ? [stepUpdateFileId] : [],
			createdAt: new Date(Number(elements.createdAt.value) * 1000),
		});
		if (result.error) {
			this.setState({ addingContestStepUpdate: false });
			return enqueueSnackbar(result.error.message || 'Could not add contest step update', { variant: 'error' });
		}
		enqueueSnackbar('Contest step update added successfully', { variant: 'success' });

		this.setState({ addingContestStepUpdate: false });
		
		this.onCloseAddContestStepUpdateModal();
		updateData();
	};
	
	onClickDeleteContestStep = async () => {
		this.setState({
			openDeleteContestStep: true
		});
	};

	onCloseDeleteContestStepModal = async () => {
		this.setState({
			openDeleteContestStep: false
		});
	};
	
	onConfirmDeleteContestStep = async () => {
		const { contestType, enqueueSnackbar, project, step, updateData } = this.props;
		
		const result = await ServerAPI.deleteContestStep(project.id, contestType, step.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not delete contest step', { variant: 'error' });
		enqueueSnackbar('Contest step deleted successfully', { variant: 'success' });
		
		this.onCloseDeleteContestStepModal();
		updateData();
	};
	
	onFileIdsChange = (fileIds) => {
		this.setState({
			stepUpdateFileId: fileIds.length > 0 ? fileIds[0] : null
		});
	};
		
	render() {
		const { classes, contest, contestType, enqueueSnackbar, project, provided, position, step, updateData } = this.props;
		const {
			editingContestStep,
			openEditContestStep,
			openAddContestStepUpdate,
			addingContestStepUpdate,
			openDeleteContestStep,
		} = this.state;
		
		const orderedContestStepUpdates = step.updates.sort((a, b) => {
			return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
		});
		
		return (
			<Fragment>
				<div
					className={classes.contestStepItem}
					ref={provided.innerRef}
					{...provided.draggableProps}
					style={provided.draggableProps.style}
				>
					<div
						className={classes.contestStepItemHandle}
						{...provided.dragHandleProps}
					>
						<DragIndicator style={{ height: '32px' }} />
					</div>
					<div className={classes.contestStepItemBody} style={{ gap: '10px' }}>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
							<Tooltip title={CONTEST.contestStepStatusToString(step.status)}>
								<FiberManualRecord
									style={{
										width: '16px',
										height: '16px',
										marginRight: '10px',
										color: CONTEST.contestStepStatusToColor(step.status),
									}}
								/>
							</Tooltip>
							<Typography variant="h6" style={{ flex: '1', textAlign: 'left' }}>
								{position}. {step.title}
							</Typography>
							<Button
								color="primary"
								onClick={this.onClickEditContestStep}
								size="small"
								startIcon={<Edit />}
								variant="outlined"
							>
								Edit step
							</Button>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', gap: '5px' }}>
							<CalendarToday />
							<div style={{ flex: '1', textAlign: 'left' }}>
								<Typography variant="subtitle2">
									Started on: {dayjs(step.startDate).format('DD MMM YYYY') || '--'}
								</Typography>
								{step.startDate && step.endDate && (
									<Typography variant="subtitle2">
										Duration: {dayjs(step.endDate).diff(dayjs(step.startDate), 'day')} days
									</Typography>
								)}
							</div>
						</div>
						<Typography variant="body1">
							{step.description}
						</Typography>
						<Box my={2}>
							<Button
								color="primary"
								onClick={this.onClickAddContestStepUpdate}
								size="small"
								startIcon={<Add />}
								variant="outlined"
							>
								Add update
							</Button>
						</Box>
						<div className={classes.contestStepUpdateList}>
							{orderedContestStepUpdates.map(u => (
								<ContestStepUpdateItem
									classes={classes}
									project={project}
									contest={contest}
									contestType={contestType}
									enqueueSnackbar={enqueueSnackbar}
									key={u.id}
									step={step}
									stepUpdate={u}
									updateData={updateData}
								/>
							))}
						</div>
					</div>
				</div>
				<Dialog
					open={openEditContestStep}
					onClose={this.onCloseEditContestStepModal}
					fullWidth={true}
				>
					<form className={classes.form} onSubmit={this.onSubmitEditContestStep}>
						<DialogTitle>
							Edit Contest Step
						</DialogTitle>
						<DialogContent>
							<FormControl className={classes.formControl}>
								<Select
									className={classes.contestStepStatus}
									defaultValue={step.status}
									name="status"
								>
									<MenuItem value={CONTEST.CONTEST_STEP_STATUSES.PENDING}>
										<Chip
											className={classes.selectChip}
											label="Pending"
											style={{
												backgroundColor: CONTEST.contestStepStatusToColor(
													CONTEST.CONTEST_STEP_STATUSES.PENDING
												)
											}}
										/>
									</MenuItem>
									<MenuItem value={CONTEST.CONTEST_STEP_STATUSES.IN_PROGRESS}>
										<Chip
											className={classes.selectChip}
											label="In progress"
											style={{
												backgroundColor: CONTEST.contestStepStatusToColor(
													CONTEST.CONTEST_STEP_STATUSES.IN_PROGRESS
												)
											}}
										/>
									</MenuItem>
									<MenuItem value={CONTEST.CONTEST_STEP_STATUSES.DONE}>
										<Chip
											className={classes.selectChip}
											label="Done"
											style={{
												backgroundColor: CONTEST.contestStepStatusToColor(
													CONTEST.CONTEST_STEP_STATUSES.DONE
												)
											}}
										/>
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									fullWidth
									label="Title"
									name="title"
									defaultValue={step.title}
									variant="outlined"
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									fullWidth
									label="Description"
									multiline
									name="description"
									defaultValue={step.description}
									variant="outlined"
								/>
							</FormControl>
							<FormControl className={classes.formControl} style={{ width: 'auto' }}>
								<label>Start Date</label >
								<DatePicker
									container={false}
									fixOverflow={true}
									isClearable={true}
									defaultDate={step.startDate || undefined}
									name="startDate"
									placeholderText="No date"
								/>
							</FormControl>
							<FormControl className={classes.formControl} style={{ width: 'auto' }}>
								<label>End Date</label >
								<DatePicker
									container={false}
									fixOverflow={true}
									isClearable={true}
									defaultDate={step.endDate || undefined}
									name="endDate"
									placeholderText="No date"
								/>
							</FormControl>
						</DialogContent>
						<DialogActions style={{ justifyContent: 'space-between' }}>
							<Button
								color="secondary"
								onClick={this.onClickDeleteContestStep}
								type="button"
								variant="contained"
							>
								Delete
							</Button>
							<div>
								<Button
									onClick={this.onCloseEditContestStepModal}
									type="button"
								>
									Cancel
								</Button>
								<Button
									color="primary"
									disabled={editingContestStep}
									disableElevation={true}
									type="submit"
									variant="contained"
								>
									Save
								</Button>
							</div>
						</DialogActions>
					</form>
				</Dialog>
				<ConfirmationDialog
					title={'Are you sure you want to delete this contest step?'}
					content={'This will permanently delete the contest step'}
					primaryText={'Delete contest step'}
					secondaryText={'Cancel'}
					open={openDeleteContestStep}
					onClose={this.onCloseDeleteContestStepModal}
					onConfirm={this.onConfirmDeleteContestStep}
					color={'secondary'}
				/>
				<Dialog
					open={openAddContestStepUpdate}
					onClose={this.onCloseAddContestStepUpdateModal}
					fullWidth={true}
				>
					<form className={classes.form} onSubmit={this.onSubmitAddContestStepUpdate}>
						<DialogTitle>
							Add Contest Step Update
						</DialogTitle>
						<DialogContent>
							<FormControl className={classes.formControl}>
								<Select
									className={classes.contestStepUpdateType}
									defaultValue={CONTEST.CONTEST_STEP_UPDATE_TYPES.INFORMATION}
									name="type"
								>
									<MenuItem value={CONTEST.CONTEST_STEP_UPDATE_TYPES.INFORMATION}>
										<Chip
											className={classes.selectChip}
											label="Information"
											style={{
												backgroundColor: CONTEST.contestStepUpdateTypeToColor(
													CONTEST.CONTEST_STEP_UPDATE_TYPES.INFORMATION
												)
											}}
										/>
									</MenuItem>
									<MenuItem value={CONTEST.CONTEST_STEP_UPDATE_TYPES.ALERT}>
										<Chip
											className={classes.selectChip}
											label="Alert"
											style={{
												backgroundColor: CONTEST.contestStepUpdateTypeToColor(
													CONTEST.CONTEST_STEP_UPDATE_TYPES.ALERT
												)
											}}
										/>
									</MenuItem>
								</Select>
							</FormControl>
							<FormControl className={classes.formControl}>
								<DatePicker
									fixOverflow={true}
									container={false}
									defaultDate={new Date()}
									name="createdAt"
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									fullWidth
									label="Title"
									name="title"
									variant="outlined"
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<TextField
									fullWidth
									label="Content"
									multiline
									name="content"
									variant="outlined"
								/>
							</FormControl>
							<FormControl className={classes.formControl}>
								<FileGridSelector
									maxFileCount={1}
									maxFileSize={200 * 1024 * 1024}
									onFileIdsChange={(fileIds) => this.onFileIdsChange(fileIds)}
								/>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.onCloseAddContestStepUpdateModal}
								type="button"
							>
								Cancel
							</Button>
							<Button
								color="primary"
								disabled={addingContestStepUpdate}
								disableElevation={true}
								type="submit"
								variant="contained"
							>
								Add
							</Button>
						</DialogActions>
					</form>
				</Dialog>
			</Fragment>
		);
	}
}

ContestStepItem.propTypes = {
	classes: PropTypes.object.isRequired,
	contest: PropTypes.object.isRequired,
	contestType: PropTypes.string.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	provided: PropTypes.object.isRequired,
	position: PropTypes.number.isRequired,
	step: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};

class ContestStepUpdateItem extends Component {
	constructor (props) {
		super(props);
		
		this.state = {
			openConfirmDeleteModal: false
		};
	}
	
	onClickDeleteButton = () => {
		this.setState({
			openConfirmDeleteModal: true
		});
	};
	
	onCloseDeleteContestStepUpdateModal = () => {
		this.setState({
			openConfirmDeleteModal: false
		});
	};

	onConfirmDeleteContestStepUpdate = async () => {
		const { contestType, enqueueSnackbar, project, step, stepUpdate, updateData } = this.props;
		
		const result = await ServerAPI.deleteContestStepUpdate(project.id, contestType, step.id, stepUpdate.id);
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Could not delete contest step update', { variant: 'error' });
		}
		enqueueSnackbar('Contest step update deleted successfully', { variant: 'success' });
		
		this.onCloseDeleteContestStepUpdateModal();
		updateData();
	};
	
	render() {
		const { classes, stepUpdate } = this.props;
		const { openConfirmDeleteModal } = this.state;
		return (
			<div className={classes.contestStepUpdateItem}>
				<div
					className={classes.contestStepUpdateItemIcon}
					style={{ color: CONTEST.contestStepUpdateTypeToColor(stepUpdate.type)}}
				>
					<Tooltip title={CONTEST.contestStepUpdateTypeToString(stepUpdate.type)}>
						{stepUpdate.type === CONTEST.CONTEST_STEP_UPDATE_TYPES.INFORMATION ? (
							<Info />
						) : (
							<Warning />
						)}
					</Tooltip>
				</div>
				<div className={classes.contestStepUpdateItemBody}>
					<div style={{ fontSize: '12px' }}>
						{dayjs(stepUpdate.createdAt).format('DD MMM YYYY')}
					</div>
					<Typography variant="subtitle2">
						{stepUpdate.title}
					</Typography>
					<div>
						{stepUpdate.content}
					</div>
					<div>
						{stepUpdate.files && stepUpdate.files.map(file => {
							const imageLink = FileUtils.getImageDisplayUrl(file.id);
							return (
								<a
									key={file.id}
									href={imageLink}
									rel="noreferrer"
									target="_blank"
									style={{
										display: 'inline-block',
										margin: '5px',
										border: '2px solid #ccc',
										borderRadius: '3px',
										background: '#eee',
									}}
								>
									<div
										style={{
											position: 'relative',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											width: '120px',
											height: '120px',
										}}
									>
										<img
											alt=""
											src={imageLink}
											onError={({ currentTarget }) => {
												currentTarget.style.display = 'none';
											}}
											style={{
												display: 'block',
												width: '100%',
												height: '100%',
												zIndex: '1',
											}}
										/>
										<div
											style={{
												position: 'absolute',
												textTransform: 'uppercase',
												fontSize: '16px',
												fontWeight: 'bold',
												color: '#575757'
											}}
										>
											{file.extension}
										</div>
									</div>
								</a>
							);
						})}
					</div>
				</div>
				<div>
					<IconButton onClick={this.onClickDeleteButton}>
						<Delete />
					</IconButton>
				</div>
				<ConfirmationDialog
					title={'Are you sure you want to delete this contest step update?'}
					content={'This will permanently delete the contest step update'}
					primaryText={'Delete contest step update'}
					secondaryText={'Cancel'}
					open={openConfirmDeleteModal}
					onClose={this.onCloseDeleteContestStepUpdateModal}
					onConfirm={this.onConfirmDeleteContestStepUpdate}
					color={'secondary'}
				/>
			</div>
		);
	}
}

ContestStepUpdateItem.propTypes = {
	classes: PropTypes.object.isRequired,
	contest: PropTypes.object.isRequired,
	contestType: PropTypes.string.isRequired,
	enqueueSnackbar: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
	step: PropTypes.object.isRequired,
	stepUpdate: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};
