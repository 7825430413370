import React, { Component } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

class ExpertCell extends Component {
	render() {
		const { value, ...restProps } = this.props;
		return (
			<Table.Cell {...restProps} style={{ padding: "8px" }}>
				{value}
			</Table.Cell>
		);
	}
}

export default ExpertCell;
