const proposalConstants = {
	APPROVED_STATUS: {
		PENDING: 'pending',
		YES: 'yes',
		NO: 'no',
	},
	getLastVersion: function (proposalFile) {
		if (proposalFile.versions.length === 0) return false;
		return proposalFile.versions[proposalFile.versions.length - 1];
	},
	getUnseenFileVersions: function (proposal) {
		const res = [];
		for (const file of proposal.files) {
			for (const version of file.versions) {
				if (version.approvedStatus === this.APPROVED_STATUS.PENDING) {
					res.push(version);
				}
			}
		}
		return res;
	},
	hasApprovedFile: function (proposal) {
		for (const file of proposal.files) {
			for (const version of file.versions) {
				if (version.approvedStatus === this.APPROVED_STATUS.YES) {
					return true;
				}
			}
		}
		return false;
	}
};

export default proposalConstants;
