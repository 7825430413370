import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';

import Config from './config';

import { AuthWrapper } from './components/auth-wrapper';
import PublicRoute from './components/routes/public-route';
import PrivateRoute from './components/routes/private-route';

import LoginPage from './pages/login';
import DashboardPage from './pages/dashboard';
import ForgotPasswordPage from './pages/forgot-password';
import CreatePasswordPage from './pages/create-password';

class AppRoutes extends Component {
	render() {
		return (
			<AuthWrapper active={Config.sso.enabled}>
				<Routes>
					<Route element={<PublicRoute />}>
						<Route exact path="/login" element={<LoginPage />} />
						<Route exact path="/forgot-password" element={<ForgotPasswordPage />} />
						<Route exact path="/create-password" element={<CreatePasswordPage />} />
					</Route>
					<Route element={<PrivateRoute />}>
						<Route path="*" element={<DashboardPage />} />
					</Route>
				</Routes>
			</AuthWrapper>
		);
	}
}

export default AppRoutes;
