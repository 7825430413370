import React from 'react';
import { withStyles } from '@material-ui/styles';

import {
	TableRow, Table, TableBody, TableCell, TableHead,
} from '@material-ui/core';

import Nothing from './nothing';

const styles = {
	bold: {
		fontWeight: 500,
	},
	number: {
		textAlign: 'right',
	},
};

function ProjectDetailTable({ classes, headers = [], values = [] }) {
	if (!values || !values.length) {
		return <Nothing />;
	}

	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					{
						headers.map((header, i) => (
							<TableCell key={i} className={(header.indexOf('Amount') !== -1 ? classes.number : '')}>
								{header}
							</TableCell>
						))
					}
				</TableRow>
			</TableHead>
			<TableBody>
				{
					values.map((column, i) => (
						<TableRow key={i}>
							{column.map((value, i) => 
								<TableCell key={i} className={(i === 0 ? classes.bold : '') + (value.indexOf('€') !== -1 ? classes.number : '')}>
									{value}
								</TableCell>
							)}
						</TableRow>
					))
				}
			</TableBody>
		</Table>
	);
}

export default withStyles(styles)(ProjectDetailTable);
