import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';

import {
	Button, Box, Card, CardContent, CircularProgress,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	Dialog, DialogTitle, DialogActions, DialogContent, TextField,
	Select, MenuItem,
} from '@material-ui/core';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';

import Config from '../../../config';
import ServerAPI from '../../../services/server-api';
import UserSearch from '../../../components/user/user-search';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	topHeader: {
		textAlign: 'left',
	},
	title: {
		fontSize: '25px',
		marginBottom: '10px',
	},
	bottomHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '10px',
		textAlign: 'left',

		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column',
		},
	},
	information: {
		flex: '1',
		maxWidth: '60%',
		padding: '10px',
		marginBottom: '10px',
		borderRadius: '6px',
		backgroundColor: '#FAEEAD',
		fontSize: '12px',

		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
		},
	},
	informationDialog: {
		maxWidth: '100%',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '200px',
	},
});

class ImpersonatorPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			impersonations: null,
			dialogShown: false,
			selectedTarget: null,
			selectedReason: '',
			selectedExpiration: 10,
			tokenShown: false,
			token: null,
		};
	}

	componentDidMount = async () => {
		let { impersonations } = this.state;
		if (impersonations) return;

		this.getImpersonations();
	};

	getImpersonations = async () => {
		const results = await ServerAPI.getImpersonations();

		this.setState({
			isLoading: false,
			impersonations: results.data,
		});
	};

	toHuman = (date) => {
		date = new Date(date);
		const options = { weekday: 'long', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
		return date.toLocaleDateString('en-EN', options);
	};

	onShowDialog = () => {
		this.setState({
			dialogShown: true,
			selectedTarget: false,
		});
	};

	onCloseDialog = () => {
		this.setState({
			dialogShown: false,
		});
	};

	onCreateImpersonation = async () => {
		const { enqueueSnackbar } = this.props;

		const {
			impersonations,
			selectedTarget,
			selectedReason,
			selectedExpiration
		} = this.state;

		const result = await ServerAPI.createImpersonation({
			target: selectedTarget.id,
			reason: selectedReason,
			expiration: selectedExpiration,
		});

		if (result.error) return enqueueSnackbar(result.error.message || 'Could not create the impersonation', { variant: 'error' });

		this.setState({
			impersonations: [result.data, ...impersonations]
		});

		this.onCloseDialog();
		this.showToken(result.data);
	};

	showToken = (result) => {
		this.setState({
			token: result.token,
			tokenShown: true,
		});
	}

	onCloseToken = () => {
		this.setState({
			tokenShown: false,
		});
	}

	informationText = () => {
		return (
			<div>
				Only use this feature for a <b>really good reason</b>. You will request a token to impersonate a user on the platform. 
				Every impersonation request will be logged and public for all the team. <b>Do not share the generated tokens</b>
			</div>
		);
	}

	onChangeTarget = (users) => {
		this.setState({
			selectedTarget: users.length ? users[0] : null,
		});
	}

	onChangeReason = (e) => {
		const { target: { value } } = e;
		this.setState({
			selectedReason: value,
		});
	}

	onChangeExpiration = (e) => {
		const { target: { value } } = e;
		this.setState({
			selectedExpiration: value,
		});
	}

	getAvailableUsers = async (str) => {
		const results = await ServerAPI.getImpersonationAvailableUsers(str);
		if (results.error) return [];
		return results.data;
	}

	render() {
		const { classes } = this.props;

		const {
			isLoading,
			impersonations,
			dialogShown,
			selectedExpiration,
			selectedTarget,
			tokenShown,
			token,
		} = this.state;

		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Fragment>
						<Card className={classes.root} variant="outlined">
							<CardContent>
								<div className={classes.header}>
									<div className={classes.topHeader}>
										<div className={classes.title}>Impersonator</div>
									</div>
									<div className={classes.bottomHeader}>
										<div className={classes.information}>
											{this.informationText()}
										</div>
										<div className={classes.buttons}>
											<Button
												disableElevation
												color="primary"
												variant="contained"
												startIcon={<AccessibilityNewIcon />}
												onClick={() => this.onShowDialog()}>
												New impersonation
											</Button>
										</div>
									</div>
								</div>

								<TableContainer>
									<Table aria-label="simple table" style={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
									<TableHead>
										<TableRow>
											<TableCell>Requester</TableCell>
											<TableCell>Target</TableCell>
											<TableCell>Reason</TableCell>
											<TableCell>Expiration</TableCell>
											<TableCell>Date</TableCell>
										</TableRow>
									</TableHead>
										<TableBody>
											{impersonations.map((elem, i) => (
												<TableRow key={elem.id}>
													<TableCell className={classes.ellipsis} title={elem.requester}>{elem.requester}</TableCell>
													<TableCell className={classes.ellipsis} title={elem.target}>{elem.target}</TableCell>
													<TableCell className={classes.ellipsis} title={elem.reason}>{elem.reason}</TableCell>
													<TableCell className={classes.ellipsis} title={elem.expiration}>{elem.expiration}</TableCell>
													<TableCell className={classes.ellipsis} title={this.toHuman(elem.createdAt)}>{this.toHuman(elem.createdAt)}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>

						<Dialog
							open={dialogShown}
							onClose={this.onCloseDialog}
						>
							<DialogTitle>Impersonate user</DialogTitle>
							<DialogContent>
								<div className={classes.information + ' ' + classes.informationDialog}>
									{this.informationText()}
								</div>
								<UserSearch
									maxSelection={1}
									onSearch={(str) => this.getAvailableUsers(str)}
									onChange={(users) => this.onChangeTarget(users)}
								/>
								<TextField
									label="Reason"
									variant="outlined"
									onChange={this.onChangeReason}
									style={{ width: '100%', marginTop: '15px' }}
								/>
								<Select
									label="Expiration"
									variant="outlined"
									value={selectedExpiration}
									onChange={this.onChangeExpiration}
									style={{ width: '100%', marginTop: '15px' }}
									>
									<MenuItem value={10}>10 minutes</MenuItem>
									<MenuItem value={20}>20 minutes</MenuItem>
									<MenuItem value={30}>30 minutes</MenuItem>
								</Select>
							</DialogContent>
							<DialogActions>
								<Button onClick={this.onCloseDialog}>
									Cancel
								</Button>
								<Button onClick={this.onCreateImpersonation} color="primary" variant='contained' disableElevation>
									Impersonate
								</Button>
							</DialogActions>
						</Dialog>

						{selectedTarget && (
							<Dialog
								open={tokenShown}
								onClose={this.onCloseToken}
							>
								<DialogTitle>Impersonate user</DialogTitle>
								<DialogContent>
									<div className={classes.information + ' ' + classes.informationDialog}>
										{this.informationText()}
									</div>
									We have generated a token to impersonate {selectedTarget.firstName} {selectedTarget.lastName}. 
									This token will expire in {selectedExpiration} minutes. <b>Use with caution</b>
									<br/><br/>
									<a href={Config.lastbasic.platform.host + '/impersonator?token=' + token}>
										Open platform as {selectedTarget.firstName} {selectedTarget.lastName}
									</a>
								</DialogContent>
								<DialogActions>
									<Button onClick={this.onCloseToken} color="primary" autoFocus>
										Close
									</Button>
								</DialogActions>
							</Dialog>
						)}
					</Fragment>
				)}
			</Box>
		);
	}
}

ImpersonatorPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withSnackbar(withStyles(styles)(ImpersonatorPage));
