import React, { Component }from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import {
	AttachMoney, CheckCircle, Delete,
	FiberManualRecord, Reorder,
	ThumbsUpDown, VisibilityOff
} from '@material-ui/icons';

import DateRangePicker from '../../../../components/inputs/date-range-picker';
import DelayedTextField from '../../../../components/inputs/delayed-text-field';
import { AntTabs, AntTab } from '../../../../components/tabs/white-tabs';

export const TABS = {
	PENDING_FEASIBILITY: 'pending_feasibility',
	PENDING_PAYMENT: 'pending_payment',
	ACTIVE: 'active',
	CLOSED: 'closed',
	HIDDEN: 'hidden',
	ALL: 'all',
};

const styles = theme => ({
	filterContainer: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		justifyContent: "center",
		
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	filterColumn: {
		width: "50%",
		
		[theme.breakpoints.down('sm')]: {
			flex: "none",
			width: "100%",
			marginBottom: "20px",
		},
	},
	tabs: {
		marginTop: '30px',
		padding: "16px 16px 0px 16px",
	},
	iconTab: {
		"& svg": {
			maxWidth: '20px',
			verticalAlign: "top",
		},
	},
});

class ProjectFilter extends Component {
	shouldComponentUpdate(nextProps) {
		const prev = this.props;
		const { startTime, endTime, name, type, feasibility } = nextProps;
		
		return (startTime !== prev.startTime) || (endTime !== prev.endTime)
			|| (name !== prev.name) || (type !== prev.type)
			|| (feasibility !== prev.feasibility);
	}
	
	onStartTimeChange = (date) => {
		const { onStartTimeChange } = this.props;
		onStartTimeChange(date);
	};
	
	onEndTimeChange = (date) => {
		const { onEndTimeChange } = this.props;
		onEndTimeChange(date);
	};
	
	onNameStartChange = (e) => {
		const { value } = e.target;
		const { onNameStartChange } = this.props;
		onNameStartChange(value);
	};
	
	onNameChange = (e) => {
		const { value } = e.target;
		const { onNameChange } = this.props;
		onNameChange(value);
	};
	
	onTypeChange = (selectedType) => {
		const { onTypeChange } = this.props;
		onTypeChange(selectedType);
	};
	
	onFeasibilityChange = (selectedFeasibility) => {
		const { onFeasibilityChange } = this.props;
		onFeasibilityChange(selectedFeasibility);
	};
	
	onClearFilter = () => {
		const { onClearFilter } = this.props;
		onClearFilter();
	};
	
	render() {
		const {
			classes,
			startTime, endTime,
			name,
			type,
		} = this.props;
		
		return (
			<div>
				<DateRangePicker
					container={false}
					startDate={startTime}
					endDate={endTime}
					onChangeStart={this.onStartTimeChange}
					onChangeEnd={this.onEndTimeChange}
					isClearable={true}
					placeholderText={'No date'}
					/>
				<div className={classes.filterContainer}>
					<div className={classes.filterColumn}>
						<Typography variant="subtitle2" gutterBottom>
							Search
						</Typography>
						<DelayedTextField
							type="search"
							label="Name or client details"
							value={name}
							onStartChange={this.onNameStartChange}
							onChange={this.onNameChange}
							/>
					</div>
					<div className={classes.filterColumn}>
						<Typography variant="subtitle2" gutterBottom>
							Actions
						</Typography>
						<Button
							variant="outlined"
							startIcon={<Delete />}
							onClick={this.onClearFilter}>
							Clear filters
						</Button>
					</div>
				</div>
				<AntTabs
					className={classes.tabs}
					value={type}
					onChange={(e, v) => this.onTypeChange(v)}
					variant="scrollable"
					scrollButtons="auto"
					>
					<AntTab
						label={<span className={classes.iconTab}><FiberManualRecord /> Active</span>}
						value={TABS.ACTIVE}/>
					<AntTab
						label={<span className={classes.iconTab}><ThumbsUpDown /> Pending feasibility</span>}
						value={TABS.PENDING_FEASIBILITY}/>
					<AntTab
						label={<span className={classes.iconTab}><AttachMoney /> Pending payment</span>}
						value={TABS.PENDING_PAYMENT} />
					<AntTab
						label={<span className={classes.iconTab}><CheckCircle /> Closed</span>}
						value={TABS.CLOSED} />
					<AntTab
						label={<span className={classes.iconTab}><VisibilityOff /> Hidden</span>}
						value={TABS.HIDDEN} />
					<AntTab
						label={<span className={classes.iconTab}><Reorder /> All</span>}
						value={TABS.ALL} />
				</AntTabs>
			</div>
		);
	}
}

ProjectFilter.propTypes = {
	classes: PropTypes.object.isRequired,
	startTime: PropTypes.instanceOf(Date),
	endTime: PropTypes.instanceOf(Date),
	projectName:  PropTypes.string,
	onStartTimeChange: PropTypes.func,
	onEndTimeChange: PropTypes.func,
	onNameStartChange: PropTypes.func,
	onNameChange: PropTypes.func,
	onTypeChange: PropTypes.func,
	onFeasibilityChange: PropTypes.func,
	onClearFilter: PropTypes.func,
};

ProjectFilter.defaultProps = {
	classes: {},
	startTime: new Date((new Date()).setYear((new Date()).getFullYear() - 4)),
	endTime: new Date(),
	projectName: null,
	onStartTimeChange() {},
	onEndTimeChange() {},
	onNameStartChange() {},
	onNameChange() {},
	onTypeChange() {},
	onFeasibilityChange() {},
	onClearFilter() {},
};

export default withStyles(styles)(ProjectFilter);
