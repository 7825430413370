import Config from '../config';
import { Auth, ObjectUtils } from '../utils';

class ServerAPI {
	static forceLogout() {
		Auth.removeTokens();
		window.location.reload();
	}

	static _filterRequest(obj) {
		return Object.fromEntries(Object.entries(obj).filter(e => {
			const value = e[1];
			return (typeof value !== 'undefined');
		}).map(e => {
			let [name, value] = e;
			if (value instanceof Date) value = value.getTime();
			if (ObjectUtils.isPlainObject(value)) value = this._filterRequest(value);
			return [name, value];
		}));
	}

	static async _request(method, path, params, body, opts = {}) {
		opts.repeat = opts.repeat !== false;
		opts.public = !!opts.public;

		if (!path) path = '/';
		if (path.substring(0, 1) !== '/') path = '/' + path;
		if (typeof params !== 'object') params = {};
		if (typeof body !== 'object') body = {};

		const queryParams = Object.entries(params).filter(e => {
			const value = e[1];
			return (typeof value !== 'undefined' && value !== null);
		}).map(e => {
			let [name, value] = e;

			if (value instanceof Date) {
				value = value.getTime();
			} else if (Array.isArray(value)) {
				value = JSON.stringify(value);
			}

			return name + '=' + value;
		}).join('&');

		let response;
		try {
			let token;
			if (!opts.public) {
				token = Auth.getValidStoredTokens();
				if (!token) throw new Error('Expired access token');
			}

			let bodyParams;
			const isFormData = (body instanceof FormData);
			if (isFormData) {
				bodyParams = body;
			} else {
				const filteredBodyParams = this._filterRequest(body);
				bodyParams = (Object.keys(filteredBodyParams).length > 0) ? JSON.stringify(filteredBodyParams) : null;
			}

			let headerParams = {
				'x-api-key': Config.lastbasic.api.key,
				'Authorization': token && 'Bearer ' + token.accessToken,
			};
			if (!isFormData) {
				headerParams['Content-Type'] = 'application/json';
			}

			response = await fetch(Config.lastbasic.api.host + path + '?' + queryParams, {
				method: method || 'GET',
				headers: headerParams,
				body: bodyParams,
			});
			if (response.status !== 200) throw new Error("Got HTTP code " + response.status);

			const result = await response.json();
			if (!result.data) throw new Error('No data');

			return result;
		} catch (err) {
			let responseBody;
			try {
				responseBody = await response.json();
			} catch (err) {
				responseBody = null;
			}

			if (!opts.public && (
				err.message.includes('token') || (response && response.status === 401)
			)) {
				if (!opts.repeat) {
					// Remove token & go home, you're drunk
					this.forceLogout();
					return {
						error: {
							message: 'Invalid token in second try',
						},
					};
				}

				// Refresh the current token
				const newTokens = await this.refreshToken();
				if (newTokens.error) {
					this.forceLogout();
					return {
						error: {
							message: 'Could not refresh token',
						},
					};
				}

				// Store new tokens
				Auth.storeTokens(newTokens.data);

				// Repeat this function call
				return this._request(method, path, params, body, { ...opts, repeat: false });
			} else if (!response) {
				// Network error
				return {
					error: {
						message: 'Network error',
					}
				};
			} else if (response.status === 403) {
				// Forbidden action
				return {
					error: {
						message: 'Forbidden action',
					}
				};
			} else if (responseBody && responseBody.error) {
				return {
					error: responseBody.error,
				};
			}

			return {
				error: {
					message: 'Unknown error',
				},
			};
		}
	}

	static _getFormData(data) {
		const formData = new FormData();
		for (const [k, v] of Object.entries(data)) {
			formData.append(k, v);
		}
		return formData;
	}

	static async _getData(path, params) {
		return this._request('GET', path, params);
	}

	static async _patchData(path, body) {
		return this._request('PATCH', path, {}, body);
	}

	static async _putData(path, body) {
		return this._request('PUT', path, {}, body);
	}

	static async _putMultiPartData(path, data) {
		const formData = this._getFormData(data);
		return this._putData(path, formData);
	}

	static async _postData(path, body) {
		return this._request('POST', path, {}, body);
	}

	static async _postMultiPartData(path, data) {
		const formData = this._getFormData(data);
		return this._postData(path, formData);
	}

	static async _deleteData(path, body) {
		return this._request('DELETE', path, {}, body);
	}

	static async getAllExperts({
		startTime, endTime,
		expertExpertise, name = "", searchText = "",
		flags = [], typeform = null, userStatus = null
	}) {
		return this._getData('/admin/metrics/getAllExperts', {
			startTime, endTime, expertExpertise, name, searchText, flags, typeform, userStatus
		});
	}

	static async getSummaryExperts({
		startTime, endTime,
		expertExpertise, name = "", searchText = "",
		flags = [], typeform = null, userStatus = null
	}) {
		return this._getData('/admin/metrics/getSummaryExperts', {
			startTime, endTime, expertExpertise, name, searchText, flags, typeform, userStatus
		});
	}

	// KPIs

	static async getGlobalMetrics({ startTime, endTime }) {
		return this._getData('/admin/metrics/global', { startTime, endTime });
	}

	static async getExpertsMetrics({ startTime, endTime }) {
		return this._getData('/admin/metrics/experts', { startTime, endTime });
	}

	static async getProjectsMetrics({ startTime, endTime }) {
		return this._getData('/admin/metrics/projects', { startTime, endTime });
	}

	static async getActiveExperts({ startTime, endTime }) {
		return this._getData('/admin/metrics/getActiveExperts', { startTime, endTime });
	}

	static async getCreatedProjects({ startTime, endTime }) {
		return this._getData('/admin/metrics/getCreatedProjects', { startTime, endTime });
	}

	static async getActiveProjects({ startTime, endTime }) {
		return this._getData('/admin/metrics/getActiveProjects', { startTime, endTime });
	}

	static async getProjectFeasabilityRatio({ startTime, endTime }) {
		return this._getData('/admin/metrics/getProjectFeasibilityRatio', { startTime, endTime });
	}

	static async getExperts({
		startTime, endTime,
		expertExpertise, searchText, name = '',
		flags = [], typeform = null, userStatus = null,
		sorting = [], currentPage = 0, pageSize = 10
	}) {

		return this._getData('/admin/expert', {
			startTime, endTime,
			expertExpertise, name, searchText,
			flags, typeform, userStatus,
			sorting, currentPage, pageSize
		});
	}

	static async getExpert(id) {
		return this._getData(`/admin/expert/${id}`);
	}

	static async updateExpert(id, data) {
		return this._putData(`/admin/expert/${id}`, data);
	}

	static async deleteExpert(id) {
		return this._deleteData(`/admin/expert/${id}`);
	}

	static async getProjects({
		startTime, endTime,
		name = '', type = null, feasibility = null,
		sorting = [], currentPage = 0, pageSize = 10
	}) {
		return this._getData('/admin/project', {
			startTime, endTime,
			name, type, feasibility,
			sorting, currentPage, pageSize
		});
	}

	static async getSite() {
		return this._getData('/admin/site');
	}

	static async addTemplate(type, data) {
		return this._postMultiPartData(`/admin/site/contest/${type}/template`, data);
	}

	static async deleteTemplate(type, fileId) {
		return this._deleteData(`/admin/site/contest/${type}/template/${fileId}`);
	}

	static async addGuideline(type, data) {
		return this._postMultiPartData(`/admin/site/contest/${type}/guideline`, data);
	}

	static async deleteGuideline(type, fileId) {
		return this._deleteData(`/admin/site/contest/${type}/guideline/${fileId}`);
	}

	static async getProject(id) {
		return this._getData(`/admin/project/${id}`);
	}

	static async getProjectFinancials(id) {
		return this._getData(`/admin/project/${id}/financials`);
	}

	static async updateProject(id, data) {
		return this._putData(`/admin/project/${id}`, data);
	}

	static async updateProjectFeasibility(id, feasibility, reason = null, comment = null, scoring = null) {
		return this._putData(`/admin/project/${id}/feasibility`, {
			comment, feasibility, reason, scoring
		});
	}

	static async updateProjectImage(id, data) {
		return this._putMultiPartData(`/admin/project/${id}/image`, data);
	}

	static async updateState(id, state, data) {
		return this._putData(`/admin/project/${id}/state/${state}`, data);
	}

	static async deleteProject(id) {
		return this._deleteData(`/admin/project/${id}`);
	}

	static async addRawMaterial(id, data) {
		return this._postMultiPartData(`/admin/project/${id}/rawMaterial`, data);
	}

	static async deleteRawMaterial(id, fileId) {
		return this._deleteData(`/admin/project/${id}/rawMaterial/${fileId}`);
	}

	static async updateContest(id, contestType, data) {
		return this._putData(`/admin/project/${id}/contest/${contestType}`, data);
	}

	static async addContestRound(id, contestType, data) {
		return this._postData(`/admin/project/${id}/contest/${contestType}/round`, data);
	}

	static async updateContestRound(id, contestType, roundId, data) {
		return this._putData(`/admin/project/${id}/contest/${contestType}/round/${roundId}`, data);
	}

	static async deleteContestRound(id, contestType, roundId) {
		return this._deleteData(`/admin/project/${id}/contest/${contestType}/round/${roundId}`);
	}

	static async callExperts(id, contestType, roundId) {
		return this._putData(`/admin/project/${id}/contest/${contestType}/round/${roundId}/call`);
	}

	static async addSharedFile(id, contestType, data) {
		return this._postMultiPartData(`/admin/project/${id}/contest/${contestType}/sharedFile`, data);
	}

	static async updateSharedFile(id, contestType, fileId, data) {
		return this._putData(`/admin/project/${id}/contest/${contestType}/sharedFile/${fileId}`, data);
	}

	static async deleteSharedFile(id, contestType, fileId) {
		return this._deleteData(`/admin/project/${id}/contest/${contestType}/sharedFile/${fileId}`);
	}

	static async createProposal(data) {
		return this._postData('/admin/proposal', data);
	}

	static async updateProposal(id, data) {
		return this._putData(`/admin/proposal/${id}`, data);
	}

	static async deleteProposal(id) {
		return this._deleteData(`/admin/proposal/${id}`);
	}

	static async addFileToProposal(id, data) {
		return this._postMultiPartData(`/admin/proposal/${id}/file`, data);
	}

	static async updateFileFromProposal(proposalId, fileId, data) {
		return this._putData(`/admin/proposal/${proposalId}/file/${fileId}`, data);
	}

	static async removeFileFromProposal(proposalId, fileId) {
		return this._deleteData(`/admin/proposal/${proposalId}/file/${fileId}`);
	}

	static async addFileVersionToProposal(proposalId, fileId, data) {
		return this._postMultiPartData(`/admin/proposal/${proposalId}/file/${fileId}/version`, data);
	}

	static async updateFileVersionFromProposal(proposalId, fileId, versionId, data) {
		return this._putData(`/admin/proposal/${proposalId}/file/${fileId}/version/${versionId}`, data);
	}

	static async removeFileVersionFromProposal(proposalId, fileId, versionId) {
		return this._deleteData(`/admin/proposal/${proposalId}/file/${fileId}/version/${versionId}`);
	}

	static async refreshToken() {
		const token = Auth.getStoredTokens();
		if (!token) {
			return {
				error: {
					message: 'No tokens to refresh',
				},
			};
		}

		return this._request('POST', '/admin/user/login', {}, {
			grantType: 'refresh_token',
			refreshToken: token.refreshToken,
		}, {
			public: true
		});
	}

	static async login(data) {
		return this._request('POST', '/admin/user/login', {}, {
			...data,
			grantType: 'access_token',
		}, {
			public: true
		});
	}

	static async tokenInfo(token) {
		return this._request('GET', '/admin/user/tokenInfo', {
			accessToken: token,
		}, {}, { public: true });
	}

	static async forgotPassword(data) {
		return this._request('POST', '/admin/user/forgotPassword', {}, data, {
			public: true
		});
	}

	static async createPassword(data) {
		return this._request('POST', '/admin/user/forgotPassword/createPassword', {}, data, {
			public: true
		});
	}

	static getPublicSketchUrl(project) {
		const token = Auth.getValidStoredTokens();
		const accessToken = (token) ? token.accessToken : '';

		return `${Config.lastbasic.api.host}/public/project/${project.id}/getPublicSketch?accessToken=${accessToken}`;
	}

	static getPublicBriefingUrl(project) {
		const token = Auth.getValidStoredTokens();
		const accessToken = (token) ? token.accessToken : '';

		return `${Config.lastbasic.api.host}/public/project/${project.id}/getPublicBriefing?accessToken=${accessToken}`;
	}

	static getInvoiceUrl(proposal) {
		const token = Auth.getValidStoredTokens();
		const accessToken = (token) ? token.accessToken : '';

		return `${Config.lastbasic.api.host}/admin/proposal/${proposal.id}/getInvoice?accessToken=${accessToken}`;
	}

	static getFileUrls(fileId) {
		const token = Auth.getValidStoredTokens();
		const accessToken = (token) ? token.accessToken : '';

		const API_FILE_ENDPOINT = `${Config.lastbasic.api.host}/public/file`;

		const displayUrl = `${API_FILE_ENDPOINT}/${fileId}/display?accessToken=${accessToken}`;
		const downloadUrl = `${API_FILE_ENDPOINT}/${fileId}?accessToken=${accessToken}`;
		const previsualizeUrl = `${API_FILE_ENDPOINT}/${fileId}/preview?accessToken=${accessToken}`;

		return {
			displayUrl,
			downloadUrl,
			previsualizeUrl,
		};
	}

	static async deleteUpdate(projectId, updateId) {
		return this._deleteData(`/admin/project/${projectId}/update/${updateId}`);
	}

	static async addUpdate(projectId, data) {
		return this._postData(`/admin/project/${projectId}/update`, data);
	}

	static async updateContestOrder(projectId, data) {
		return this._putData(`/admin/project/${projectId}/contestOrder`, data);
	}

	static async closeContest(projectId, contestType, sendEmail) {
		return this._postData(`/admin/project/${projectId}/contest/${contestType}/closeContest`, {
			sendEmail,
		});
	}

	static async updateUserProfile(userId, data) {
		return this._putData(`/admin/user/profile/${userId}`, data);
	}

	static async getImpersonations() {
		return this._getData('/admin/impersonation');
	}

	static async getImpersonationAvailableUsers(string) {
		return this._getData('/admin/impersonation/searchUsers', {
			string
		});
	}

	static async createImpersonation(data) {
		return this._postData('/admin/impersonation', data);
	}

	static async getPayments({ searchText, currentPage = 0, pageSize = 10 }) {
		return this._getData('/admin/payments/users', {
			searchText, currentPage, pageSize
		});
	}

	static async getUserPayments({ userId }) {
		return this._getData(`/admin/payments/users/${userId}`);
	}

	static async transferCredits({ userId, amount = 0 }) {
		return this._postData(`/admin/payments/users/${userId}/transferCredits`, {
			amount
		});
	}

	static async deleteCredit({ creditId }) {
		return this._deleteData(`/admin/payments/credits/${creditId}`);
	}

	static async createCredit({ userId, amount, concept = 'Credit added'}) {
		return this._postData('/admin/payments/credits', {
			amount, userId, concept,
		});
	}

	static async deleteWalletTransaction({ transactionId }) {
		return this._deleteData(`/admin/payments/wallet/${transactionId}`);
	}

	static async createWalletTransaction({ userId, amount, concept = 'Money added to wallet' }) {
		return this._postData('/admin/payments/wallet', {
			amount, userId, concept,
		});
	}

	static async convertWallet({ userId, amount, createWalletTransaction = false, concept = 'Payout' }) {
		return this._postData(`/admin/payments/users/${userId}/transferWallet`, {
			amount, concept, createWalletTransaction,
		});
	}

	static async deletePayout({ payoutId }) {
		return this._deleteData(`/admin/payments/payouts/${payoutId}`);
	}

	static async changePayoutStatus({ payoutId, status }) {
		return this._putData(`/admin/payments/payouts/${payoutId}`, {
			status,
		});
	}

	static async associatePayoutToProject({ payoutId, projectId, costLine }) {
		return this._putData(`/admin/payments/payouts/${payoutId}/associateProject`, {
			projectId,
			costLine,
		});
	}

	static async getFileUploadUrl(options) {
		return this._postData('/admin/file/getSignedUrl', options);
	}

	static async markFileAsUploaded(fileId) {
		return this._postData(`/admin/file/${fileId}/markAsUploaded`);
	}

	static async addCustomInvoice(payoutId, fileId) {
		return this._postData(`/admin/payments/payouts/${payoutId}/addCustomInvoice`, {
			fileId,
		});
	}

	static async removeCustomInvoice(payoutId) {
		return this._postData(`/admin/payments/payouts/${payoutId}/removeCustomInvoice`);
	}

	static async getPayouts(status, startDate = null, endDate = null, currentPage = 0, pageSize = 500) {
		return this._getData('/admin/payments/payouts', {
			status, currentPage, pageSize, startDate, endDate,
		});
	}

	static async winnerDone(projectId, contestType) {
		return this._postData(`/admin/project/${projectId}/contest/${contestType}/winnerDone`);
	}

	static async confirmContestCard(projectId, contestType, roundId, confirmation, sendEmail = false) {
		return this._postData(`/admin/project/${projectId}/contest/${contestType}/round/${roundId}/confirmContestCard`, {
			confirmation, sendEmail,
		});
	}

	static async updateCustomInvoiceNumber(payoutId, number) {
		return this._putData(`/admin/payments/payouts/${payoutId}/updateCustomInvoiceNumber`, { number });
	}

	static async markInvoiceAsReceipt(payoutId, receipt) {
		return this._putData(`/admin/payments/payouts/${payoutId}/receipt`, { receipt });
	}

	static async searchUser(opts) {
		return this._getData(`/admin/user/search`, opts);
	}

	static async getInternalNotifications() {
		return this._getData(`/admin/notifications/internal`);
	}

	static async subscribeToInternalNotification(type) {
		return this._postData(`/admin/notifications/internal/${type}/subscribe`);
	}

	static async unsubscribeToInternalNotification(type) {
		return this._postData(`/admin/notifications/internal/${type}/unsubscribe`);
	}

	static async addUserToInternalNotification(type, userId) {
		return this._postData(`/admin/notifications/internal/${type}/user`, {
			userId,
		});
	}

	static async removeUserFromInternalNotification(type, userId) {
		return this._deleteData(`/admin/notifications/internal/${type}/user/${userId}`);
	}

	static async createProjectInvoice({ userId, projectId, description, amountInCents, sendEmail }) {
		return this._postData(`/admin/invoice`, {
			userId,
			sendEmail,
			projectId,
			description,
			amountInCents,
			type: 'project_setup',
			direction: 'in',
		});
	}

	static async deleteProjectInvoice(invoiceId) {
		return this._deleteData(`/admin/invoice/${invoiceId}`);
	}

	static async addContestSetupQuestion(id, contestType, data) {
		return this._postData(`/admin/project/${id}/contest/${contestType}/setup-question`, data);
	}

	static async deleteContestSetupQuestion(id, contestType, questionId) {
		return this._deleteData(`/admin/project/${id}/contest/${contestType}/setup-question/${questionId}`);
	}

	static async sendContestSetupQuestionsEmail(id, contestType) {
		return this._postData(`/admin/project/${id}/contest/${contestType}/setup-question/sendEmail`);
	}

	static async createInvoice(data) {
		return this._postData(`/admin/invoice`, data);
	}

	static async createQuote(invoiceId) {
		return this._postData(`/admin/invoice/${invoiceId}/quote`);
	}

	static async createTransfer(invoiceId) {
		return this._postData(`/admin/invoice/${invoiceId}/transfer`);
	}

	static async getWiseBalance() {
		return this._getData(`/admin/wise/balance`);
	}

	static async getPayoutsSummary() {
		return this._getData(`/admin/payments/summary`);
	}

	static async getRadarMetrics(startDate = null, endDate = null, comparisonStartDate = null, comparisonEndDate = null) {
		return this._getData('/admin/metrics/radar', {
			startTime: startDate,
			endTime: endDate,
			comparisonStartTime: comparisonStartDate,
			comparisonEndTime: comparisonEndDate,
		});
	}

	static async getContestSteps(projectId, contestType) {
		return this._getData(`/admin/project/${projectId}/contest/${contestType}/step`);
	}

	static async updateContestStepsOrder(projectId, contestType, stepsOrder) {
		return this._patchData(`/admin/project/${projectId}/contest/${contestType}/stepsOrder`, stepsOrder);
	}

	static async createContestStep(projectId, contestType, newStep) {
		return this._postData(`/admin/project/${projectId}/contest/${contestType}/step`, newStep);
	}

	static async updateContestStep(projectId, contestType, contestStepId, updatedStep) {
		return this._putData(`/admin/project/${projectId}/contest/${contestType}/step/${contestStepId}`, updatedStep);
	}

	static async deleteContestStep(projectId, contestType, contestStepId) {
		return this._deleteData(`/admin/project/${projectId}/contest/${contestType}/step/${contestStepId}`);
	}

	static async createContestStepUpdate(projectId, contestType, contestStepId, newContestStepUpdate) {
		return this._postData(
			`/admin/project/${projectId}/contest/${contestType}/step/${contestStepId}/update`,
			newContestStepUpdate
		);
	}

	static async getContestStepUpdates(projectId, contestType, contestStepId) {
		return this._getData(`/admin/project/${projectId}/contest/${contestType}/step/${contestStepId}/update`);
	}

	static async deleteContestStepUpdate(projectId, contestType, contestStepId, contestStepUpdateId) {
		return this._deleteData(
			`/admin/project/${projectId}/contest/${contestType}/step/${contestStepId}/update/${contestStepUpdateId}`
		);
	}
}

export default ServerAPI;
