import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import PaymentConstants from '../../../constants/payment';

import Box from './components/box';
import ProjectInformation from './components/project-information';
import WaterfallGraph from './components/waterfall-graph';
import ProjectDetailTable from './components/project-detail-table';

import ServerAPI from '../../../services/server-api';
import withRouter from '../../../components/withRouter';
import { StringUtils } from '../../../utils';

const styles = {
	body: {
		margin: 10,
		color: 'rgb(35, 38, 42)',
	},
	pageTitle: {
		fontSize: 27,
		color: 'rgb(35, 38, 42)',
		fontWeight: 500,
		marginBottom: 15,
		marginLeft: 3,
	},
	pageTitleLink: {
		color: 'inherit',
		textDecoration: 'none',
		'&:hover': {
			opacity: 0.9,
			borderBottom: '2px solid rgb(35, 38, 42)',
		},
	},
	projectName: {
		display: 'inline',
		opacity: 0.7,
	},
	boxRow: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'stretch',
		gap: 20,
	},
	skeletonWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		gap: 20,
		marginBottom: 20,
	},
};

function ProjectFinancialsPage({ match, classes }) {
	const projectId = match.params.projectId;
	const [project, setProject] = useState(null);

	const getProject = useCallback(async () => {
		const projectRequest = await ServerAPI.getProject(projectId);
		if (projectRequest.error) return;

		setProject(projectRequest.data);
	}, [projectId]);

	useEffect(() => {
		getProject();
	}, [getProject]);

	// Calculations on costs and revenue
	const costHeaders = ['Concept', 'Amount'];
	const costDetailsValues = [];

	const revenueHeaders = ['Concept', 'Type', 'Date', 'Amount'];
	const revenueDetailsValues = [];

	let projectSetupTotal = 0;
	let totalCosts = 0;

	let currencyName = '';
	let currencySymbol = '';

	if (project) {
		// Get currency
		currencyName = project.financials.currency;
		currencySymbol = StringUtils.currencyToSymbol(currencyName);

		// Costs calculations
		totalCosts = project.financials.costs.total;
		const costLines = project.financials.costs.lines;

		Object.entries(costLines).forEach(([k, v]) => {
			const lineName = PaymentConstants.getPayoutLinesForHumans(k);
			let total = 0;

			v.forEach(line => total += line.amount);

			costDetailsValues.push([
				lineName,
				total + currencySymbol,
			]);
		});

		// Revenue calculations
		const revenueLines = project.financials.revenue.lines;

		const projectSetupLines = revenueLines.filter(l => 
			l.type === PaymentConstants.INVOICE_TYPES.PROJECT_SETUP
		);

		projectSetupLines.forEach(line => projectSetupTotal += line.amount);

		revenueLines.forEach(revenue => {
			revenueDetailsValues.push([
				revenue.name,
				PaymentConstants.getInvoiceTypesForHumans(revenue.type),
				StringUtils.simpleDate(revenue.createdAt),
				revenue.amount + currencySymbol,
			]);
		});
	}

	return (
		<div className={classes.body}>
			<div className={classes.pageTitle}>
				<Link to={'/financials/projects'} className={classes.pageTitleLink}>
					Financials
				</Link>
				
				<div className={classes.projectName}>
					&nbsp;/ { (project ? project.name : <Skeleton variant="text" width={200} style={{ display: 'inline-block' }} />) }
				</div>
			</div>

			<div className={classes.boxRow}>
				<Box
					title={'Project information'}
					color={'#FA8075'}
					width={'30'}
				>
					{ !project && <Skeleton variant="rect" height={200} /> }
					{ project && <ProjectInformation project={project} /> }
				</Box>
				<Box
					title={'Margin overview'}
					color={'#43C8E6'}
					width={'70'}
				>
					{ !project && <Skeleton variant="rect" height={250} /> }
					{ project &&
						<WaterfallGraph
							before={[{ title: 'Project setup', value: projectSetupTotal }]}
							after={[{ title: 'Payments to experts', value: (0 - totalCosts) }]}
							currencyName={currencyName}
							currencySymbol={currencySymbol}
						/>
					}
				</Box>
			</div>

			<div className={classes.boxRow}>
				<Box
					title={'Revenue details'}
					color={'#85DF85'}
					width={50}
				>
					{ !project && <Skeleton variant="rect" height={500} /> }
					{ project && <ProjectDetailTable headers={revenueHeaders} values={revenueDetailsValues} /> }
				</Box>

				<Box
					title={'Costs details'}
					color={'#858585'}
					width={50}
				>
					{ !project && <Skeleton variant="rect" height={500} /> }
					{ project && <ProjectDetailTable headers={costHeaders} values={costDetailsValues} /> }
				</Box>
			</div>
		</div>
	);
}

export default withStyles(styles)(withRouter(ProjectFinancialsPage));
