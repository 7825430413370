const stringUtils = {
	capitalizeAll: function (str) {
		return str.toLowerCase().split(' ').map(w => {
			if (!w.length) return '';
			return w[0].toUpperCase() + w.slice(1);
		}).join(' ');
	},
	numberFormat: function (number) {
		const style = new Intl.NumberFormat('en-EN', {
			maximumFractionDigits: 2,
		});

		return style.format(number);
	},
	simpleDate: function (inputDate) {
		const date = new Date(inputDate);

		const day = date.getDate();
		const month = date.getMonth() + 1;
		const year = date.getFullYear();

		return `${day}/${(month < 10 ? '0' : '')}${month}/${year}`;
	},
	timeSince: function(date) {
		const seconds = Math.floor((new Date() - date) / 1000);
		let interval = seconds / 31536000;
	
		if (interval > 1) {
			const value = Math.floor(interval);
			return value + ' year' + (value !== 1 ? 's' : '');
		}

		interval = seconds / 2592000;
		if (interval > 1) {
			const value = Math.floor(interval);
			return value + ' month' + (value !== 1 ? 's' : '');
		}

		interval = seconds / 86400;
		if (interval > 1) {
			const value = Math.floor(interval);
			return value + ' day' + (value !== 1 ? 's' : '');
		}

		interval = seconds / 3600;
		if (interval > 1) {
			const value = Math.floor(interval);
			return value + ' hour' + (value !== 1 ? 's' : '');
		}

		interval = seconds / 60;
		if (interval > 1) {
			const value = Math.floor(interval);
			return value + ' minute' + (value !== 1 ? 's' : '');
		}

		const value = Math.floor(interval);
		return value + ' second' + (value !== 1 ? 's' : '');
	},
	currencyToSymbol(currency) {
		if (!currency) return null;

		const lowercaseCurrency = currency.toLowerCase();

		switch (lowercaseCurrency) {
			case 'eur':
				return '€';
			case 'usd':
				// Should be US$ but to ease things we will use $
				return '$';
			default:
				return currency.toUpperCase();
		}
	},
	truncate(text = '', maxCharacters = 40) {
		if (text.length <= maxCharacters) return text;

		return text.substring(0, (maxCharacters - 3)).trim() + '...';
	}
};

export default stringUtils;
