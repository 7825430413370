import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
	box: {
		display: 'inline',
		marginBottom: 20,
		minWidth: 290,
	},
	shadow: {
		boxShadow: 'rgba(0, 0, 0, 0.01) 0px 4px 12px',
	},
	fullBox: {
		backgroundColor: '#fff',
		padding: 20,
		borderRadius: 6,
		width: '50%',
	},
	boxTitle: {

	},
	boxTitleTile: {
		display: 'inline-block',
		backgroundColor: '#fafafa',
		height: 23,
		width: 10,
		borderRadius: 3,
		marginRight: 10,
		verticalAlign: 'middle',
	},
	boxTitleText: {
		display: 'inline-block',
		fontSize: 17,
		fontWeight: 500,
		verticalAlign: 'middle',
	},
	children: {
		marginTop: 20,
	},
};

function Box({ classes, color, title, children, width = 50 }) {
	return (
		<div className={classes.box + ' ' + classes.shadow + ' ' + classes.fullBox} style={{ width: width + '%'}}>
			<div className={classes.boxTitle}>
				<div className={classes.boxTitleTile} style={{ backgroundColor: (color || 'gray') }}></div>
				<div className={classes.boxTitleText}>{title}</div>

				{
					children &&
					<div className={classes.children}>
						{children}
					</div>
				}
			</div>
		</div>
	);
}

export default withStyles(styles)(Box);
