import { Fragment, useState } from 'react';
import { Button, MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import StyledMenu from './styled-menu';

function DropdownMenu ({ label, items = [], onClickItem = () => {} }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickItem = (o) => {
		handleClose();
		onClickItem(o);
	};
	
	return (
		<Fragment>
			<Button
				id="demo-customized-button"
				aria-controls="demo-customized-menu"
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				variant="contained"
				disableElevation
				onClick={handleClick}
				endIcon={<KeyboardArrowDown />}
			>
				{label}
			</Button>
			<StyledMenu
				id="demo-customized-menu"
				MenuListProps={{
				  'aria-labelledby': 'demo-customized-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{items.map(o => (
					<MenuItem key={o.label} onClick={() => handleClickItem(o)} disableRipple>
						{o.label}
					</MenuItem>						
				))}
			</StyledMenu>
		</Fragment>
	);
}

export default DropdownMenu;
