import React, { Component } from 'react';

import AbstractRoute from './abstract-route';

class PublicRoute extends Component {
	render() {
		const props = this.props;
		
		return (
			<AbstractRoute
				{...props}
				isPrivate={false}
				redirectTo={'/'}
			/>
		);
	}
}

export default PublicRoute;
