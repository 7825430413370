import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box, Button, Chip, Dialog, DialogActions, DialogContent,
	DialogContentText, DialogTitle, FormControl, Grid, InputLabel,
	MenuItem, Select, TextField, Tooltip
} from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { PROJECT } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';

const useStyles = makeStyles(() => ({
	additionalInput: {
		"& > div": {
			marginBottom: "20px",
		}
	},
	wideInput: {
		width: "100%",
	},
	feasibilitySelect: {
		'&:before': {
			border: 'none !important',
        },
        '&:after': {
			border: 'none !important',
        }
	},
	feasibilitySelectChip: {
		margin: "0px auto",
		'&:hover': {
			cursor: 'pointer',
			opacity: "0.8"
		},
	},
	feasibilitySelectChipNo: {
		border: "2px solid #1976d2",
		backgroundColor: "transparent",
	},
	feasibilityScoringMissing: {
		border: '4px solid #FAA100',
	},
}));

function FeasibilityChip({ feasibility, hasScoring }) {
	const classes = useStyles();

	switch (feasibility) {
		case PROJECT.FEASIBILITY.PENDING:
			return <Chip label="Pending" className={classes.feasibilitySelectChip + ' ' + (!hasScoring && classes.feasibilityScoringMissing)} />;
		case PROJECT.FEASIBILITY.YES:
			return <Chip label="Yes" color="primary" className={classes.feasibilitySelectChip + ' ' + (!hasScoring && classes.feasibilityScoringMissing)} />;
		case PROJECT.FEASIBILITY.NO:
			return <Chip label="No" className={classes.feasibilitySelectChip + ' ' + classes.feasibilitySelectChipNo + ' ' + (!hasScoring && classes.feasibilityScoringMissing)} />;
		default:
			return <Chip label="Pending" className={classes.feasibilitySelectChip} />;
	}
}

function FeasibilityDropdown({ project, onConfirm = () => {} }) {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [openDialog, setOpenDialog] = useState(false);
	const [feasibility, setFeasibility] = useState(project.feasibility || PROJECT.FEASIBILITY.PENDING);
	const [reason, setReason] = useState(project.feasibilityReason || PROJECT.FEASIBILITY_REASON.NONE);
	const [comment, setComment] = useState(project.feasibilityComment);
	const [scoring, setScoring] = useState(project.scoring);

	const isValidInput = (feasibility !== PROJECT.FEASIBILITY.PENDING)

	const showReasonInput = (feasibility === PROJECT.FEASIBILITY.NO);
	const showScoringInputs = [PROJECT.FEASIBILITY.YES, PROJECT.FEASIBILITY.NO].includes(feasibility)

	useEffect(() => {
		if (!showReasonInput) {
			// Reset reason
			setReason(project.feasibilityReason || PROJECT.FEASIBILITY_REASON.NONE);
		}

		if (!showScoringInputs) {
			// Reset scoring
			setScoring(project.scoring);
		}
	}, [feasibility, project, showReasonInput, showScoringInputs])

	const handleClick = () => {
		setOpenDialog(true);
	};

	const handleChangeFeasibility = (event) => {
		const { target: { value } } = event;
		if (project.paid) return enqueueSnackbar('Project is already paid', { variant: 'error' });

		setFeasibility(value);
	};

	const handleChangeReason = (event) => {
		const { target: { value } } = event;
		setReason(value);
	};

	const handleChangeComment = (event) => {
		const { target: { value } } = event;
		setComment(value);
	};

	const handleChangeScoringType = (scoringType, event) => {
		const { target: { value } } = event;
		setScoring(old => ({
			...old,
			[scoringType]: value
		}));
	}

	const handleCloseDialog = () => {
		// Reset all fields
		setFeasibility(project.feasibility || PROJECT.FEASIBILITY.PENDING);
		setReason(project.feasibilityReason || PROJECT.FEASIBILITY_REASON.NONE);
		setComment(project.feasibilityComment);
		setScoring(project.scoring);
		setOpenDialog(false);
	};

	const handleConfirm = async () => {
		setOpenDialog(false);

		// Update data
		const result = await ServerAPI.updateProjectFeasibility(project.id, feasibility, reason, comment, scoring);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update feasibility', { variant: 'error' });
		enqueueSnackbar('Feasibility updated', { variant: 'success' });

		// Update
		project.feasibility = feasibility;
		project.feasibilityReason = reason;
		project.feasibilityComment = comment;
		project.scoring = scoring;

		onConfirm(project);
	};

	const projectScoring = project.scoring;
	const hasScoring = (projectScoring.cost || projectScoring.numParts || projectScoring.resource || projectScoring.productRequirements || projectScoring.complexity); 

	return (
		<Fragment>
			<Box onClick={handleClick}>
				<FeasibilityChip feasibility={feasibility} hasScoring={hasScoring} />
			</Box>
			<Dialog
				open={openDialog}
				onClose={handleCloseDialog}
			>
				<DialogTitle>Are you sure you want to update the feasibility of the project?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Project selected: <b>{project.name}</b>
					</DialogContentText>
					<Box className={classes.additionalInput}>
						<FormControl>
							<InputLabel htmlFor="reason-label">Feasibility</InputLabel>
							<Select
								className={classes.feasibilitySelect}
								labelId="feasibility-label"
								id="feasibility"
								name="feasibility"
								value={feasibility}
								onChange={handleChangeFeasibility}
							>
								{Object.values(PROJECT.FEASIBILITY).map(f => (
									<MenuItem key={f} value={f}>
										<FeasibilityChip feasibility={f} hasScoring={hasScoring} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
						{showReasonInput && (
							<FormControl className={classes.wideInput}>
								<InputLabel htmlFor="reason-label">Reason</InputLabel>
								<Select
									labelId="reason-label"
									id="reason"
									name="reason"
									value={reason}
									onChange={handleChangeReason}
								>
									{Object.values(PROJECT.FEASIBILITY_REASON).map(r => (
										<MenuItem key={r} value={r}>
											{PROJECT.feasibilityReasonToString(r)}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
						{showScoringInputs && (
							<Grid container spacing={1} direction="row" alignItems="center" justify="center">
								{Object.values(PROJECT.SCORING_TYPE).map(scoringType => (
									<Grid item xs={2}>
										<FormControl className={classes.wideInput} style={{ paddingTop: '50px' }}>
											<InputLabel htmlFor={`scoring-${scoringType}`}>
												<Tooltip
													title={
														<div>
															{[1, 2, 3].map(v => (
																<Fragment key={v}>
																	<span>{`${v}: ${PROJECT.scoringToString(scoringType, v)}`}</span>
																	<br />
																</Fragment>
															))}
														</div>
													}
												>
													<Info style={{ marginRight: '5px', verticalAlign: 'sub' }} />
												</Tooltip>
												{PROJECT.scoringLabelByType(scoringType)}
											</InputLabel>
											<Select
												labelId={`scoring-${scoringType}`}
												variant="outlined"
												name={scoringType}
												value={scoring[scoringType]}
												onChange={(e) => handleChangeScoringType(scoringType, e)}
											>
												{[0, 1, 2, 3].map(v => (
													<MenuItem key={v} value={v}>
														{v}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
								))}
							</Grid>
						)}
						<FormControl className={classes.wideInput}>
							<TextField
								label="Comment"
								multiline
								rows={4}
								variant="outlined"
								placeholder="Leave here any additional information"
								defaultValue={comment}
								onChange={handleChangeComment}
							/>
						</FormControl>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} color="primary">
						Cancel
					</Button>
					<Button
						onClick={handleConfirm}
						color="primary"
						autoFocus
						disabled={!isValidInput}>
						Ok, update feasibility
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

FeasibilityDropdown.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	project: PropTypes.object.isRequired,
};

FeasibilityDropdown.defaultProps = {
	onConfirm() {},
};

export default FeasibilityDropdown;
