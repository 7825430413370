import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { PersonRounded, InsertDriveFileRounded, WarningRounded } from '@material-ui/icons';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Tooltip } from '@material-ui/core';

import PROJECT  from '../../../../constants/project';
import CONTEST  from '../../../../constants/contest';

const DEFAULT_CONTEST_DURATION = 10;

const styles = () => ({
	wrapper: {
		paddingTop: '1px',
	},
	dataBlock: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	stateName: {
		color: '#5F5F5F',
		display: 'inline-block',
		minWidth: '70px',
	},
	topData: {
		width: '100%',
	},
	iconData: {
		borderRadius: '3px',
		backgroundColor: '#EDEDED',
		display: 'inline-block',
		marginLeft: '10px',
		color: '#5E5E5E',
		padding: '1px 6px 1px 6px',
		cursor: 'default',
	},
	iconText: {
		display: 'inline-block',
		fontSize: '13px',
		color: '#5F5F5F',
	},
	iconImage: {
		display: 'inline-block',
		position: 'relative',
		opacity: 0.7,
		top: '2px',
		marginLeft: '-1px',
	},
	textTopData: {
		verticalAlign: 'middle',
		display: 'inline-block',
	},
	durationWrapper: {
		verticalAlign: 'middle',
		display: 'inline-block',
		marginLeft: '15px',
		marginBottom: '-15px',
		width: '90px',
	},
	bar: {
		marginTop: '-2px',
		backgroundColor: '#D5D5D5',
		width: '90%',
		height: '6px',
		borderRadius: '50px',
		position: 'relative',
		left: 0,
		right: 0,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	barProgress: {
		borderRadius: '50px',
		width: '40%',
		backgroundColor: '#2DC644',
		height: '100%',
	},
	barDetails: {
		color: '#C9C9C9',
		fontSize: '12px',
		marginTop: '5px',
		width: '100%',
		textAlign: 'center',
	},
	alerts: {
		verticalAlign: 'middle',
		display: 'inline-block',
		fontSize: '30px',
		color: '#F8BB00',
		marginLeft: '15px',
	},
	alert: {
		cursor: 'pointer',
		marginBottom: 0,
		marginTop: '5px',
	},
	textAlert: {
		display: 'block',
		padding: '10px',
		backgroundColor: '#FAEEAD',
		borderRadius: '6px',
		marginBottom: '10px',
		paddingBottom: '5px',
	},
	textAlertIcon: {
		fontSize: '20px',
		color: '#F8BB00',
		verticalAlign: 'middle',
		display: 'inline-block',
		position: 'relative',
	},
	textAlertDescription: {
		marginLeft: '10px',
		verticalAlign: 'top',
		marginTop: '2px',
		position: 'relative',
		display: 'inline-block',
		maxWidth: '90%',
		paddingBottom: '5px',
	},
	detailText: {
		fontSize: '12px',
		marginLeft: '15px',
		display: 'inline-block',
		backgroundColor: '#EDEDED',
		color: '#5E5E5E',
		borderRadius: '3px',
		padding: '2.5px 6px 2.5px 6px',
	},
});

function DynamicStatus({ classes, project }) {
	const [showAlerts, setShowAlerts] = useState(false);
	let showBar = true;
	let detailText = false;
	const alerts = [];

	function unitNumbers(number, singular, plural) {
		return number.toFixed(0) + ' ' + (parseInt(number.toFixed(0)) === 1 ? singular : plural);
	}

	// On contest
	if (PROJECT.isOnContest(project.state)) {
		const contestType = PROJECT.stateToContestType(project.state);

		// Get current contest
		const contest = project.contests.filter(c => c.type === contestType)[0];

		// Calculate unique experts in current contest
		const uniqueExperts = [];
		for (let proposal of contest.proposals) {
			if (uniqueExperts.indexOf(proposal.user) === -1) uniqueExperts.push(proposal.user);
		}

		const uniqueExpertsNumber = uniqueExperts.length;
		const maxExpertsNumber = CONTEST.contestMaxExperts(contestType);

		// Calculate approved proposals
		const approvedProposals = [];
		const pendingProposals = [];
		for (let proposal of contest.proposals) {
			const version = (proposal.files && proposal.files.length && proposal.files[0].versions && proposal.files[0].versions.length && proposal.files[0].versions[0]) || false;
			if (version && version.approvedStatus === 'yes' && version.published) approvedProposals.push(proposal.id);
			if (version && version.approvedStatus === 'pending') pendingProposals.push(proposal.id);
		}

		const approvedProposalsNumber = approvedProposals.length;
		const pendingProposalsNumber = pendingProposals.length;

		// Get contest round
		let round = (contest.rounds && contest.rounds.length && contest.rounds[contest.rounds.length - 1]) || false;
		if (!round) alerts.push('There is no round for this contest');

		const roundConfirmed = (round && round.card && round.card.clientConfirmed === 'yes');
		if (round && !roundConfirmed) {
			alerts.push('There is no approved round for this contest');
			showBar = false;
		}

		// Calculate shared files
		const sharedFiles = contest.sharedFiles || [];
		const approvedSharedFiles = sharedFiles.filter(s => s.approvalStatus === CONTEST.SHARED_FILE_APPROVAL_STATUSES.APPROVED);
		const pendingSharedFilesApproval = sharedFiles.length - approvedSharedFiles.length;

		// Contest ended
		const contestEnded = (pendingSharedFilesApproval === 0 && approvedSharedFiles.length > 0);

		// Calculate dates
		let contestDurationDays;
		let currentStatusDays;
		let currentTodaySinceEndDays = 0;
		let delayed = false;
		let barWidth = 0;

		if (round.startDate) {
			const today = new Date();
			const startDate = new Date(round.startDate);

			let endDate = new Date(round.endDate) || false;
			if (!endDate) {
				endDate = new Date();
				endDate.setDate(endDate.getDate() + DEFAULT_CONTEST_DURATION);
			}

			// Calculate contest duration
			const contestDurationTime = endDate.getTime() - startDate.getTime();
			contestDurationDays = contestDurationTime / (1000 * 3600 * 24);

			// Current date status
			const currentStatusTime = today.getTime() - startDate.getTime();
			currentStatusDays = currentStatusTime / (1000 * 3600 * 24);

			// Current today since endDate
			const currentTodaySinceEndTime = today.getTime() - endDate.getTime();
			currentTodaySinceEndDays = currentTodaySinceEndTime / (1000 * 3600 * 24);

			// Hide bar if round has not started
			let contestStarted = false;
			if (roundConfirmed) {
				if (currentStatusDays < 0) {
					showBar = false;
					detailText = 'Round has not started yet';
				} else {
					contestStarted = true;
				}
			}

			if (roundConfirmed && contestStarted) {
				if (currentStatusDays > contestDurationDays && !contestEnded && pendingProposalsNumber > 0 && approvedProposalsNumber > 0) {
					delayed = true;
					alerts.push('Contest is out of the defined duration');
				}

				let percentaje = (currentStatusDays / contestDurationDays) * 100;
				barWidth = Math.min(Math.max(parseInt(percentaje), 10), 100);

				// Proposals need to be validated
				if (pendingProposalsNumber > 0) {
					alerts.push(`${unitNumbers(pendingProposalsNumber, 'proposal has', 'proposals have')} to be validated`);
				}

				// Experts alerts
				if (currentStatusDays >= 3 && (CONTEST.contestDesiredExperts(contestType) > uniqueExpertsNumber)) {
					alerts.push(`${unitNumbers(uniqueExpertsNumber, 'expert has', 'experts have')} joined this contest. Optimal participants for the ${CONTEST.contestTypeToString(contestType)} is ${CONTEST.contestDesiredExperts(contestType)}`);
				}

				// Proposals alerts
				if ((contestDurationDays - currentStatusDays) <= 3 && CONTEST.contestDesiredProposals(contestType) > approvedProposalsNumber) {
					alerts.push(`${unitNumbers(approvedProposalsNumber, 'proposal has', 'proposals have')} been validated and published. The optimal number of proposals for the ${CONTEST.contestTypeToString(contestType)} is ${CONTEST.contestDesiredProposals(contestType)}`);
				}

				// If not selected winner
				if ((currentTodaySinceEndDays >= 5) && pendingProposalsNumber === 0 && !contest.selectedProposal) {
					delayed = true;
					alerts.push(`Around ${unitNumbers(currentTodaySinceEndDays, 'day', 'days')} without having a winner selected`);
				}

				// If contest ended and no shared files
				if ((currentTodaySinceEndDays >= 6) && contest.selectedProposal && sharedFiles.length === 0) {
					delayed = true;
					alerts.push(`Haven't received any shared files yet`);
				}

				// If not approved shared files
				if (pendingSharedFilesApproval > 0) {
					alerts.push(`${unitNumbers(pendingSharedFilesApproval, 'shared file', 'shared files')} needs to be approved`);
				}

				// Contest ended view
				if (contestEnded) {
					showBar = false;
					detailText = 'Contest has ended';
				}

				// Too much time in this phase
				if ((currentStatusDays - contestDurationDays) > 15) {
					delayed = true;
					alerts.push(`Too much time in this phase`);
				}
			}
		}

		return (
			<Fragment>
				<div className={classes.wrapper}>
					<div className={classes.dataBlock}>
						<div className={classes.topData}>
							<div className={classes.textTopData}>
								<div className={classes.stateName}>{PROJECT.stateToStringSimplified(project.state)}</div>

								<Tooltip title={'Joined experts'}>
									<div className={classes.iconData}>
										<div className={classes.iconText}>{uniqueExpertsNumber}/{maxExpertsNumber}</div> <div className={classes.iconImage}><PersonRounded fontSize='inherit' /></div>
									</div>
								</Tooltip>

								<Tooltip title={'Approved and published proposals'}>
									<div className={classes.iconData}>
										<div className={classes.iconText}>{approvedProposalsNumber}</div> <div className={classes.iconImage}><InsertDriveFileRounded fontSize='inherit' /></div>
									</div>
								</Tooltip>
							</div>

							{ round && showBar &&
								<div className={classes.durationWrapper}>
									<div className={classes.barWrapper}>
										<div className={classes.bar}>
											<div className={classes.barProgress} style={{ width: `${barWidth}%`, backgroundColor: (delayed ? '#F8BB00' : 'default') }}></div>
										</div>
									</div>

									<div className={classes.barDetails}>
										{
											delayed &&
											<Fragment>+{unitNumbers((currentStatusDays - contestDurationDays), 'day', 'days')}</Fragment>
										}
										{
											!delayed && (currentStatusDays > contestDurationDays) &&
											<Fragment>+{unitNumbers((currentStatusDays - contestDurationDays), 'day', 'days')}</Fragment>
										}
										{
											!delayed && (currentStatusDays < contestDurationDays) &&
											<Fragment>{currentStatusDays.toFixed(0)} out of {unitNumbers(contestDurationDays, 'day', 'days')}</Fragment>
										}
									</div>
								</div>
							}

							{
								detailText &&
								<div className={classes.detailText}>{detailText}</div>
							}
						</div>
					</div>

					{ alerts.length > 0 &&
						<div className={classes.alerts}>
							<div className={classes.alert} onClick={() => setShowAlerts(true)}>
								<Tooltip title={`Click to see ${unitNumbers(alerts.length, 'alert', 'alerts')}`}>
									<WarningRounded fontSize='inherit' />
								</Tooltip>
							</div>
						</div>
					}
				</div>

				<Dialog
					open={showAlerts}
					onClose={() => setShowAlerts(false)}
				>
					<DialogTitle>
						Alerts - {project.name}
					</DialogTitle>
					<DialogContent>
						{alerts.map((alert, i) => (
							<div className={classes.textAlert} key={i}>
								<div className={classes.textAlertIcon}><WarningRounded fontSize='inherit' /></div>
								<div className={classes.textAlertDescription}>{alert}</div>
							</div>
						))}
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setShowAlerts(false)}>
							Close
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<div className={classes.stateName}>{PROJECT.stateToStringSimplified(project.state)}</div>
		</Fragment>
	);
}

export default withStyles(styles)(DynamicStatus);
