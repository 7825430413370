import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { withSnackbar } from 'notistack';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {
	Button, IconButton, Tooltip, Badge, Select, MenuItem, Chip,
	Dialog, DialogTitle, DialogContent, DialogContentText,
	FormControl, TextField, DialogActions,
} from '@material-ui/core';
import { Delete, GetApp, Visibility } from '@material-ui/icons';
import { Grid, Table, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { CONTEST } from '../../../../../constants';
import ConfirmationDialog from '../../../../../components/dialog/confirmation-dialog';
import ServerAPI from '../../../../../services/server-api';

const customTheme = createTheme({
	overrides: {
		MuiTableCell: {
			root: {
				padding: '4px',
			},
		},
	},
});

const styles = () => ({

});

class SharedFileGrid extends Component {
	state = {
		openDeleteFileDialog: false,
		openRejectDialog: false,
		selectedContestRound: null,
	};

	onChangeApproval = async (e, sharedFile) => {
		const { value } = e.target;

		// If PENDING, do not do anything
		if (value === CONTEST.SHARED_FILE_APPROVAL_STATUSES.PENDING) return;

		// If APPROVED, send request now
		if (value === CONTEST.SHARED_FILE_APPROVAL_STATUSES.APPROVED) {
			return this.updateApproval(sharedFile, {
				approvalStatus: value,
			});
		}

		// If REJECTED, show dialog to write rejectionComment
		if (value === CONTEST.SHARED_FILE_APPROVAL_STATUSES.REJECTED) return this.showRejectionDialog(sharedFile);
	};

	updateApproval = async (sharedFile, data) => {
		const { enqueueSnackbar, project, type, updateData } = this.props;

		const { file } = sharedFile;
		const result = await ServerAPI.updateSharedFile(project.id, type, file.id, data);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update shared file', { variant: 'error' });
		enqueueSnackbar('Shared file updated', { variant: 'success' });

		// Update data
		updateData();
	};

	showRejectionDialog = (sharedFile) => {
		this.setState({
			openRejectDialog: true,
			sharedFile,
		});
	};

	closeRejectionDialog = () => {
		this.setState({
			openRejectDialog: false,
			sharedFile: null,
			rejectionComment: '',
		});
	};

	onChangeComment = (e) => {
		const { value } = e.target;

		this.setState({
			rejectionComment: value,
		});
	};

	confirmRejectionDialog = async () => {
		const { sharedFile, rejectionComment, } = this.state;

		this.setState({
			openRejectDialog: false,
			sharedFile: null,
			rejectionComment: '',
		});

		return this.updateApproval(sharedFile, {
			approvalStatus: CONTEST.SHARED_FILE_APPROVAL_STATUSES.REJECTED,
			rejectionComment: rejectionComment,
		});
	};

	onOpenDeleteFileDialog = (f) => {
		this.setState({
			openDeleteFileDialog: true,
			selectedFile: f,
		});
	};

	onCloseDeleteFileDialog = () => {
		this.setState({
			openDeleteFileDialog: false,
		});
	};

	onConfirmDeleteFileDialog = async () => {
		const { enqueueSnackbar, project, type, updateData } = this.props;
		const { selectedFile } = this.state;
		const { file } = selectedFile;

		// Close dialog
		this.onCloseDeleteFileDialog();

		const result = await ServerAPI.deleteSharedFile(project.id, type, file.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove file', { variant: 'error' });
		enqueueSnackbar('File deleted', { variant: 'success' });

		// Update data
		updateData();
	};

	render() {
		const { contest } = this.props;
		const { openDeleteFileDialog, openRejectDialog } = this.state;

		const columns = [
			{ name: 'actions', title: 'Actions' },
			{ name: 'name', title: 'Name' },
			{ name: 'filename', title: 'File Name' },
			{ name: 'createdAt', title: 'Created at' },
			{ name: 'approvalStatus', title: 'Approval Status' },
			{ name: 'rejectionComment', title: 'Comments' },
		];

		const widthsMap = { actions: 180, rejectionComment: 180 };
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 180 : 100),
		}));

		const rows = contest.sharedFiles.map(sharedFile => {
			const { file, approvalStatus, rejectionComment } = sharedFile;
			const { previsualizeUrl, downloadUrl } = ServerAPI.getFileUrls(file.id);

			return {
				actions: <div>
					<Tooltip title="Preview">
						<a href={previsualizeUrl} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="Preview">
								<Visibility />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Download">
						<a href={downloadUrl} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="Download">
								<GetApp />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Delete file">
						<IconButton aria-label="Delete file" onClick={() => this.onOpenDeleteFileDialog(sharedFile)}>
							<Delete />
						</IconButton>
					</Tooltip>
				</div>,
				name: file.title,
				filename: (
					<Badge
						badgeContent={approvalStatus === CONTEST.SHARED_FILE_APPROVAL_STATUSES.PENDING ? 1 : 0}
						color="secondary"
						variant="dot"
					>
						<span>{file.name}</span>
					</Badge>
				),
				createdAt: dayjs(file.createdAt).format('DD MMMM YYYY'),
				approvalStatus: <Select value={approvalStatus} onChange={(e) => this.onChangeApproval(e, sharedFile)}>
					<MenuItem value={CONTEST.SHARED_FILE_APPROVAL_STATUSES.PENDING}>
						<Chip label='Pending' style={{ backgroundColor: '#D4D4D4' }} />
					</MenuItem>
					<MenuItem value={CONTEST.SHARED_FILE_APPROVAL_STATUSES.APPROVED}>
						<Chip label='Approved' color='primary' />
					</MenuItem>
					<MenuItem value={CONTEST.SHARED_FILE_APPROVAL_STATUSES.REJECTED}>
						<Chip label='Rejected' style={{ backgroundColor: '#db5e5e', color: '#fff' }} />
					</MenuItem>
				</Select>,
				rejectionComment: <div style={{ 'white-space': 'normal' }}>
					{rejectionComment}
				</div>,
			};
		});

		return (
			<Fragment>
				<ThemeProvider theme={customTheme}>
					<Grid
						columns={columns}
						rows={rows}
					>
						<Table />
						<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
						<TableHeaderRow />
					</Grid>
					<ConfirmationDialog
						title={'Are you sure you want to delete the file?'}
						content={'This will permanently delete the file.'}
						primaryText={'Delete file'}
						secondaryText={'Cancel'}
						open={openDeleteFileDialog}
						onClose={this.onCloseDeleteFileDialog}
						onConfirm={this.onConfirmDeleteFileDialog}
					/>
				</ThemeProvider>
				<Dialog
					open={openRejectDialog}
					onClose={this.closeRejectionDialog}
					fullWidth={true}
				>
					<DialogTitle>
						Reject shared file
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Add a comment with the reasons why this shared file is not valid
						</DialogContentText>
						<FormControl>
							<TextField
								label="Comment"
								variant="outlined"
								name="comment"
								onChange={this.onChangeComment}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeRejectionDialog}>
							Cancel
						</Button>
						<Button
							color="primary"
							onClick={this.confirmRejectionDialog}
						>
							Reject shared file
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

SharedFileGrid.propTypes = {
	classes: PropTypes.object.isRequired,
	contest: PropTypes.object.isRequired,
	project: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	updateData: PropTypes.func,
};

SharedFileGrid.defaultProps = {
	updateData() {},
};

export default withSnackbar(withStyles(styles)(SharedFileGrid));
