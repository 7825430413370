const SHARED_FILE_APPROVAL_STATUSES = {
	PENDING: 'pending',
	APPROVED: 'approved',
	REJECTED: 'rejected',
};

const contestConstants = {
	SHARED_FILE_APPROVAL_STATUSES,
	CONTEST_TYPES: {
		SKETCH: 'sketch',
		MODEL: 'model',
		ELECTRICAL: 'electrical',
		MECHANICAL: 'mechanical',
		PROTOTYPING: 'prototyping',
	},
	contestTypeToString: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return 'Sketch Contest';
			case this.CONTEST_TYPES.MODEL:
				return 'Model Contest';
			case this.CONTEST_TYPES.ELECTRICAL:
				return 'Electrical Contest';
			case this.CONTEST_TYPES.MECHANICAL:
				return 'Mechanical Contest';
			case this.CONTEST_TYPES.PROTOTYPING:
				return 'Prototyping Contest';
			default:
				return null;
		}
	},
	contestMaxExperts: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return 8;
			case this.CONTEST_TYPES.MODEL:
				return 5;
			case this.CONTEST_TYPES.ELECTRICAL:
				return 2;
			case this.CONTEST_TYPES.MECHANICAL:
				return 2;
			case this.CONTEST_TYPES.PROTOTYPING:
				return 2;
			default:
				return 0;
		}
	},
	contestDesiredExperts: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return 5;
			case this.CONTEST_TYPES.MODEL:
				return 3;
			case this.CONTEST_TYPES.ELECTRICAL:
				return 2;
			case this.CONTEST_TYPES.MECHANICAL:
				return 2;
			case this.CONTEST_TYPES.PROTOTYPING:
				return 2;
			default:
				return 0;
		}
	},
	contestDesiredProposals: function (contestType) {
		switch (contestType) {
			case this.CONTEST_TYPES.SKETCH:
				return 3;
			case this.CONTEST_TYPES.MODEL:
				return 3;
			case this.CONTEST_TYPES.ELECTRICAL:
				return 2;
			case this.CONTEST_TYPES.MECHANICAL:
				return 2;
			case this.CONTEST_TYPES.PROTOTYPING:
				return 2;
			default:
				return 0;
		}
	},
	getUnseenSharedFiles: function (contest) {
		const res = [];
		const sharedFiles = contest.sharedFiles;
		for (const file of sharedFiles) {
			if (file.approvalStatus === SHARED_FILE_APPROVAL_STATUSES.PENDING) {
				res.push(file);
			}
		}
		return res;
	},
	CONTEST_STEP_STATUSES: {
		PENDING: 'pending',
		IN_PROGRESS: 'in_progress',
		DONE: 'done',
	},
	contestStepStatusToString: function (contestStepStatus) {
		switch (contestStepStatus) {
			case this.CONTEST_STEP_STATUSES.PENDING:
				return 'Pending';
			case this.CONTEST_STEP_STATUSES.IN_PROGRESS:
				return 'In progress';
			case this.CONTEST_STEP_STATUSES.DONE:
				return 'Done';
			default:
				return 'Unknown';
		}
	},
	contestStepStatusToColor: function (contestStepStatus) {
		switch (contestStepStatus) {
			case this.CONTEST_STEP_STATUSES.PENDING:
				return '#e8e8e9';
			case this.CONTEST_STEP_STATUSES.IN_PROGRESS:
				return '#6A6E73';
			case this.CONTEST_STEP_STATUSES.DONE:
				return '#3496F8';
			default:
				return 'Unknown';
		}
	},
	CONTEST_STEP_UPDATE_TYPES: {
		INFORMATION: 'information',
		ALERT: 'alert',
	},
	contestStepUpdateTypeToString: function (contestStepUpdateType) {
		switch (contestStepUpdateType) {
			case this.CONTEST_STEP_UPDATE_TYPES.INFORMATION:
				return 'Information'
			case this.CONTEST_STEP_UPDATE_TYPES.ALERT:
				return 'Alert';
			default:
				return 'gray';
		}
	},
	contestStepUpdateTypeToColor: function (contestStepUpdateType) {
		switch (contestStepUpdateType) {
			case this.CONTEST_STEP_UPDATE_TYPES.INFORMATION:
				return 'deepskyblue'
			case this.CONTEST_STEP_UPDATE_TYPES.ALERT:
				return 'darkorange';
			default:
				return 'gray';
		}
	},
};

export default contestConstants;
