import { Fragment, useState, useEffect, useCallback } from 'react';

import styles from './radar.module.css';

import Funnel from './components/Funnel';
import Graph from './components/Graph';

import DateRangePicker from '../../../../../components/inputs/date-range-picker';
import ServerAPI from '../../../../../services/server-api';

//import results from './results.json';

function getFirstDayOfMonth(year, month) {
	return new Date(year, month, 1, 2);
}

function getFirstDayPreviousMonth() {
	const date = new Date();
	return new Date(date.getFullYear(), date.getMonth() - 1, 1, 2);
}

function getTodayButPreviousMonth() {
	const date = new Date();
	return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate(), 2); 
}

export default function RadarPanel() {
	const today = new Date();
	const firstDayCurrentMonth = getFirstDayOfMonth(
		today.getFullYear(),
		today.getMonth(),
	);

	const firstDayPreviousMonth = getFirstDayPreviousMonth();
	const todayButPreviousMonth = getTodayButPreviousMonth();

	// Default selection is current month vs last month
	const [startDate, setStartDate] = useState(firstDayCurrentMonth);
	const [endDate, setEndDate] = useState(today);

	const [comparisonStartDate, setComparisonStartDate] = useState(firstDayPreviousMonth);
	const [comparisonEndDate, setComparisonEndDate] = useState(todayButPreviousMonth);

	const [results, setResults] = useState(null);

	const renderedAt = today.toLocaleString();

	const getRadarMetrics = useCallback(async () => {
		if (!startDate || !endDate || !comparisonStartDate || !comparisonEndDate) return;

		const request = await ServerAPI.getRadarMetrics(startDate, endDate, comparisonStartDate, comparisonEndDate);
		if (request.error) return;

		setResults(request.data);
	}, [startDate, endDate, comparisonStartDate, comparisonEndDate]);

	useEffect(() => {
		getRadarMetrics(startDate, endDate, comparisonStartDate, comparisonEndDate);
	}, [getRadarMetrics, startDate, endDate, comparisonStartDate, comparisonEndDate]);

	function renderDates() {
		if (!startDate || !endDate || !comparisonStartDate || !comparisonEndDate) return null;

		return (
			<div className={styles.selectedDate} contentEditable={false}>
				<b>{startDate.toDateString()} to {endDate.toDateString()}</b> vs. <b>{comparisonStartDate.toDateString()} to {comparisonEndDate.toDateString()}</b>
			</div>
		);
	}

	if (!results) {
		return <Fragment>Loading...</Fragment>
	}

	return (
		<div className={styles.panel}>
			<div className={styles.header}>
				<div className={styles.logoWrapper}>
					<div className={styles.logo} />
					<div className={styles.logoSubtitle}>LastBasic internal KPIs</div>
				</div>

				<div className={styles.dateSelector}>
					<DateRangePicker
						className={styles.datePicker}
						container={false}
						isClearable={true}
						placeholderText={'No date'}
						startDate={startDate}
						endDate={endDate}
						onChangeStart={(d) => setStartDate(d)}
						onChangeEnd={(d) => setEndDate(d)}
					/>

					vs.

					<DateRangePicker
						className={styles.datePicker}
						container={false}
						isClearable={true}
						placeholderText={'No date'}
						startDate={comparisonStartDate}
						endDate={comparisonEndDate}
						onChangeStart={(d) => setComparisonStartDate(d)}
						onChangeEnd={(d) => setComparisonEndDate(d)}
					/>

					<div className={styles.go}>Update date filter</div>
				</div>
			</div>

			<div className={styles.section}>
				<div className={styles.sectionTitle}>Inventors <span className={styles.datesString}>{renderDates()}</span></div>
				<div className={styles.sectionContent}>
					<Funnel data={results.inventors} />
					<br/>
					<Graph data={results.inventorsGraph} />
				</div>
			</div>

			<div className={styles.section}>
				<div className={styles.sectionTitle}>Experts <span className={styles.datesString}>{renderDates()}</span></div>
				<div className={styles.sectionContent}>
					<Funnel data={results.experts} />
					<br/>
					<Graph data={results.expertsGraph} />
				</div>
			</div>

			<div className={styles.section}>
				<div className={styles.sectionTitle}>Expert Activity <span className={styles.datesString}>{renderDates()}</span></div>
				<div className={styles.sectionContent}>
					<Funnel data={results.expertActivity} />
				</div>
			</div>

			<div className={styles.footer}>
				This information is confidential. View was generated on {renderedAt}.
			</div>
		</div>
	);
};
