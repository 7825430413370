export function buildFunnelData(steps, funnelData) {
	const data = [];

	for (let i = 0; i < steps.length; i++) {
		const stepCategories = Object.entries(steps[i]);
		for (const [label, stepCat] of stepCategories) {
			if (i === 0) {
				stepCat.list = funnelData.filter(stepCat.filter);
			} else {
				let currList = [];
				const prevStepCategories = Object.entries(steps[i - 1]);
				for (const [prevLabel, prevStepCat] of prevStepCategories) {
					const relationList = prevStepCat.list.filter(stepCat.filter);
					data.push([prevLabel, label, relationList.length]);
					currList = [ ...currList, ...relationList ];
				}
				stepCat.list = currList;
			}
		}
	}

	return data;
}
