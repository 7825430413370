const paymentConstants = {
	PAYOUT_STATUS: {
		NO_PAYMENT_INFO: 'no_payment_info',
		PENDING: 'pending',
		APPROVED: 'approved',
		PAYING_PROGRAMMATIC: 'paying_programmatic',
		PAID: 'paid',
		PAYMENT_RECEIVED: 'payment_received',
		CANCELED: 'canceled',
	},
	getPayoutStatusOrder: function (status) {
		switch (status) {
			case this.NO_PAYMENT_INFO:
				return 0;
			case this.PAYOUT_STATUS.PENDING:
				return 1;
			case this.PAYOUT_STATUS.APPROVED:
				return 2;
			case this.PAYOUT_STATUS.PAID:
				return 3;
			case this.PAYOUT_STATUS.PAYMENT_RECEIVED:
				return 4;
			case this.PAYOUT_STATUS.CANCELED:
				return 5;
			default:
				return null;
		}
	},
	getPayoutStatusForHumans: function (status) {
		switch (status) {
			case this.NO_PAYMENT_INFO:
				return 'No payment info';
			case this.PAYOUT_STATUS.PENDING:
				return 'Pending by expert';
			case this.PAYOUT_STATUS.APPROVED:
				return 'Approved by expert';
			case this.PAYOUT_STATUS.PAID:
				return 'Paid';
			case this.PAYOUT_STATUS.PAYMENT_RECEIVED:
				return 'Payment received by expert';
			case this.PAYOUT_STATUS.CANCELED:
				return 'Canceled';
			default:
				return null;
		}
	},
	LB_CREDIT_TRANSACTION_TYPES: {
		PROPOSAL_APPROVED: 'proposal_approved',
		CREDIT_CONVERSION: 'credit_conversion',
		OTHER: 'other',
	},
	WALLET_TRANSACTION_TYPES: {
		WALLET_CONVERSION: 'wallet_conversion',
		CREDIT_CONVERSION: 'credit_conversion',
		CONTEST_WON: 'contest_won',
		OTHER: 'other',
	},
	INVOICE_DIRECTIONS: {
		IN: 'in',
		OUT: 'out',
	},
	INVOICE_TYPES: {
		PROJECT_SETUP: 'project_setup',
		PAYOUT: 'payout',
	},
	PAYOUT_LINES: {
		NONE: 'none',
		DESIGN: 'design',
		MANUFACTURING: 'manufacturing',
		COMPONENTS: 'components',
		TESTING: 'testing',
		UPSALE: 'upsale',
	},
	getPayoutLinesForHumans: function (line) {
		switch (line) {
			case this.PAYOUT_LINES.NONE:
				return 'Not defined';
			case this.PAYOUT_LINES.COMPONENTS:
				return 'Components';
			case this.PAYOUT_LINES.DESIGN:
				return 'Design';
			case this.PAYOUT_LINES.MANUFACTURING:
				return 'Manufacturing';
			case this.PAYOUT_LINES.TESTING:
					return 'Testing';
			case this.PAYOUT_LINES.UPSALE:
				return 'Upsale';
			default:
				return line || 'Not defined';
		}
	},
	getInvoiceTypesForHumans: function (type) {
		switch (type) {
			case this.INVOICE_TYPES.PROJECT_SETUP:
				return 'Project setup';
			case this.PROJECT_SETUP.PAYOUT:
				return 'Payout';
			default:
				return type || 'Not defined';
		}
	},
};

export default paymentConstants;
