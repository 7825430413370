import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withSnackbar } from 'notistack';
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {
	Button, Select, MenuItem, Chip, Dialog, DialogTitle, DialogActions, DialogContent,
	IconButton, Tooltip, Checkbox,
} from '@material-ui/core';
import { Delete, Description, Feedback, PictureAsPdf, RecordVoiceOver } from '@material-ui/icons';
import {
	Grid, Table, TableColumnResizing, TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

import TextFieldMarkdown from '../../../../../components/inputs/text-field-markdown';
import ConfirmationDialog from '../../../../../components/dialog/confirmation-dialog';
import DateRangePicker from '../../../../../components/inputs/date-range-picker';

import PROJECT from '../../../../../constants/project';
import FileGridSelector from '../../../../../components/inputs/file-grid-selector';
import ServerAPI from '../../../../../services/server-api';
import { ObjectUtils, FileUtils } from '../../../../../utils';

const customTheme = createTheme({
	overrides: {
		MuiTableCell: {
			root: {
				padding: '4px',
			},
		},
	},
	disabled: {
		opacity: '0.5',
	},
});

const styles = (theme) => ({
	dateRange: {
		padding: "0px",
		zIndex: 999,
	},
	formControl: {
		minWidth: 120,
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	commentInput: {
		width: "calc(100% - 16px)",
	},
	sectionName: {
		fontSize: '13px',
		opacity: 0.8,
		marginBottom: '5px',
		marginTop: '20px',
	},
});

class ContestCardRow extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contest: props.contest,
			round: props.round,
			openCallExpertsDialog: false,
			openEditSpecialAttentionDialog: false,
			openDeleteContestRoundDialog: false,
			openConfirmationDialog: false,
			openCommentsDialog: false,
			roundDescription: props.round.card ? props.round.card.description : '',
			roundSpecialAttention: props.round.card ? props.round.card.specialAttention : '',
			roundMainImage: props.round.card ? props.round.card.mainImage : null,
			roundSecondaryImages: props.round.card ? props.round.card.secondaryImages : [],
			showRoundDetailsDialog: false,
		};
	}
	
	onClickCallExperts = () => {
		this.setState({
			openCallExpertsDialog: true,
		});
	};

	onCloseCallExpertsDialog = () => {
		this.setState({
			openCallExpertsDialog: false,
		});
	};
	
	onConfirmCallExpertsDialog = async () => {
		const { enqueueSnackbar, project, type } = this.props;
		const { round } = this.state;

		const result = await ServerAPI.callExperts(project.id, type, round.id);
		if (result.error) {
			enqueueSnackbar(result.error.message || 'Could not call experts', { variant: 'error' });
		} else {
			enqueueSnackbar('Call sent to experts', { variant: 'success' });
		}

		this.onCloseCallExpertsDialog();
	};

	onOpenEditSpecialAttentionDialog = () => {
		this.setState({
			openEditSpecialAttentionDialog: true,
		});
	};

	onCloseEditSpecialAttentionDialog = () => {
		this.setState({
			openEditSpecialAttentionDialog: false,
		});
	};

	onChangeInput = async (key, value) => {
		if (key === 'endDate') {
			value = new Date(value);
			value.setDate(value.getDate() + 1);
		} else if (key === 'startDate') {
			value = new Date(value);
		}

		return this.updateContestRound({
			[key]: value,
		}, key !== 'specialAttention');
	};

	onConfirmEditContestRoundDialog = async () => {
		const { round } = this.state;
		const { specialAttention } = round;

		// Close window
		this.onCloseEditSpecialAttentionDialog();

		return this.updateContestRound({
			specialAttention,
		});
	};

	onSaveConfirmationDialog = async () => {
		const { round, newClientConfirmed, sendEmail } = this.state;
		const { enqueueSnackbar, project, type, updateData } = this.props;

		const result = await ServerAPI.confirmContestCard(project.id, type, round.id, newClientConfirmed, !!sendEmail);
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Could not update contest round', { variant: 'error' });
		}

		enqueueSnackbar('Contest round updated' + (sendEmail ? ' and emails sent' : ''), { variant: 'success' });
		this.onCloseConfirmationDialog();

		// Update data
		updateData(true);
	}

	onChangeClientConfirmed = (e, round) => {
		const { value } = e.target;
		this.setState({
			newClientConfirmed: value,
			openConfirmationDialog: true
		});
	};

	onCloseConfirmationDialog = () => {
		this.setState({
			sendEmail: false,
			openConfirmationDialog: false,
		});
	}
	
	updateContestRound = async (data, sendRequest = true) => {
		const { enqueueSnackbar, project, type, updateData } = this.props;
		const { round } = this.state;

		const prevRound = {};
		for (const k of Object.keys(data)) {
			prevRound[k] = round[k];
			
			if (ObjectUtils.isPlainObject(data[k])) {
				round[k] = {
					...round[k],
					...data[k],
				};
			} else {
				round[k] = data[k];
			}
		}

		const self = this;
		async function revertChanges() {
			for (const k of Object.keys(prevRound)) {
				round[k] = prevRound[k];
			}
			return new Promise((resolve, reject) => {
				self.setState({
					round,
				}, () => {
					resolve();
				});
			});
		}
		
		// Validate
		if (!round.startDate && round.endDate) {
			await revertChanges();
			return enqueueSnackbar('Start date cannot be empty with a selected end date', { variant: 'error' });
		}
		
		if (!sendRequest) {
			return new Promise((resolve, reject) => {
				this.setState({
					round,
				}, () => {
					resolve();
				});
			});
		}
		
		const result = await ServerAPI.updateContestRound(project.id, type, round.id, data);
		if (result.error) {
			await revertChanges();
			return enqueueSnackbar(result.error.message || 'Could not update contest round', { variant: 'error' });
		}
		
		enqueueSnackbar('Contest round updated', { variant: 'success' });

		// Update data
		updateData();
	};
	
	onOpenDeleteContestRoundDialog = (round) => {
		this.setState({
			openDeleteContestRoundDialog: true,
		});
	};
	
	onCloseDeleteContestRoundDialog = () => {
		this.setState({
			openDeleteContestRoundDialog: false,
		});
	};
	
	onConfirmDeleteContestRoundDialog = async () => {
		const { enqueueSnackbar, project, type, updateData } = this.props;
		const { round } = this.state;
		
		// Close dialog
		this.onCloseDeleteContestRoundDialog();
		
		const result = await ServerAPI.deleteContestRound(project.id, type, round.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not remove file', { variant: 'error' });
		enqueueSnackbar('Round deleted', { variant: 'success' });
		
		// Update data
		updateData();
	};

	onOpenCommentDialog = (comments) => {
		this.setState({
			openCommentsDialog: comments,
		});
	};

	onCloseCommentDialog = () => {
		this.setState({
			openCommentsDialog: false,
		});
	};

	getContestCardPdfUrl = () => {
		const { contest, round } = this.state;
		return FileUtils.getContestCardPdfUrl(contest.id, round.id);
	};

	showRoundDetailsDialog = () => {
		this.setState({
			showRoundDetailsDialog: true
		});
	};

	hideRoundDetailsDialog = () => {
		const { round } = this.state;

		this.setState({
			showRoundDetailsDialog: false,
			roundDescription: round.card.description || '',
			roundSpecialAttention: round.card.specialAttention || '',
		});
	};

	updateRoundDescription = (value) => {
		this.setState({
			roundDescription: value
		});
	};

	updateRoundSpecialAttention = (value) => {
		this.setState({
			roundSpecialAttention: value
		});
	};

	updateRoundMainImage = (value) => {
		this.setState({
			roundMainImage: value
		});
	};

	updateRoundDetails = async () => {
		const { enqueueSnackbar, project, type, round, updateData } = this.props;
		const { roundDescription, roundSpecialAttention, roundMainImage, roundSecondaryImages } = this.state;

		const result = await ServerAPI.updateContestRound(project.id, type, round.id, {
			card: {
				description: roundDescription,
				specialAttention: roundSpecialAttention,
				mainImage: roundMainImage,
				secondaryImages: roundSecondaryImages,
			}
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update contest round', { variant: 'error' });
		enqueueSnackbar('Round updated successfully', { variant: 'success' });

		// Hide dialog without updating content
		this.setState({
			showRoundDetailsDialog: false
		});

		// Update data
		updateData(true);
	};

	updateRoundSecondaryImages = (newImages) => {
		this.setState({
			roundSecondaryImages: newImages,
		});
	};

	render() {
		const { classes, project } = this.props;
		const {
			round,
			openCallExpertsDialog,
			openDeleteContestRoundDialog,
			openConfirmationDialog,
			newClientConfirmed,
			sendEmail,
			openCommentsDialog,
			showRoundDetailsDialog,
			roundDescription,
			roundSpecialAttention,
			roundSecondaryImages,
		} = this.state;

		const columns = [
			{ name: 'duration', title: 'Duration' },
			{ name: 'clientConfirmed', title: 'Round status' },
			{ name: 'actions', title: 'Actions' },
		];

		const widthsMap = { actions: 250, duration: 300, clientConfirmed: 270 };
		const defaultColumnWidths = columns.map(c => ({
			columnName: c.name,
			width: widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 180 : 100),
		}));

		const rows = [round].map(round => {
			const { card } = round;
			const { clientConfirmed, comments } = card;

			const startDate = round.startDate ? new Date(round.startDate) : null;
			const uxEndDate = round.endDate ? new Date(round.endDate) : null;
			const endDate = uxEndDate ? uxEndDate.setDate(uxEndDate.getDate() - 1) : null;

			return {
				actions: <Fragment>
					<Tooltip title="Round details">
						<IconButton aria-label="Round details" onClick={this.showRoundDetailsDialog}>
							<Description />
						</IconButton>
					</Tooltip>
					<Tooltip title="PDF version">
						<a href={this.getContestCardPdfUrl()} target="_blank" rel="noopener noreferrer">
							<IconButton aria-label="PDF version">
								<PictureAsPdf />
							</IconButton>
						</a>
					</Tooltip>
					<Tooltip title="Call to experts again">
						<IconButton aria-label="Call to experts again" onClick={this.onClickCallExperts}>
							<RecordVoiceOver />
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete round">
						<IconButton aria-label="Delete round" onClick={() => this.onOpenDeleteContestRoundDialog(round)}>
							<Delete />
						</IconButton>
					</Tooltip>
				</Fragment>,
				duration: <DateRangePicker
					className={classes.dateRange}
					container={false}
					isClearable={true}
					placeholderText={'No date'}
					startDate={startDate}
					endDate={endDate}
					onChangeStart={(v) => this.onChangeInput('startDate', v)}
					onChangeEnd={(v) => this.onChangeInput('endDate', v)}
				/>,
				clientConfirmed: <Fragment>
					<Select
						disabled={clientConfirmed === PROJECT.CLIENT_CONFIRMATION.YES}
						className={classes.clientConfirmedSelect}
						labelId="clientConfirmed-label"
						id="clientConfirmed"
						name="clientConfirmed"
						value={clientConfirmed}
						onChange={(e) => this.onChangeClientConfirmed(e, round)}
						>
						<MenuItem value={PROJECT.CLIENT_CONFIRMATION.DRAFT}>
							<Chip label="Internal draft" className={classes.selectChip} style={{ backgroundColor: '#E6E6E6', cursor: 'pointer' }} />
						</MenuItem>
						<MenuItem value={PROJECT.CLIENT_CONFIRMATION.PENDING}>
							<Chip label="Client pending" className={classes.selectChip} style={{ backgroundColor: '#F5B132', cursor: 'pointer' }} />
						</MenuItem>
						<MenuItem value={PROJECT.CLIENT_CONFIRMATION.NO}>
							<Chip label="Rejected" className={classes.selectChip} style={{ backgroundColor: '#F57567', cursor: 'pointer' }} />
						</MenuItem>
						<MenuItem value={PROJECT.CLIENT_CONFIRMATION.YES}>
							<Chip label="Approved" color="primary" className={classes.selectChip} style={{ cursor: 'pointer' }} />
						</MenuItem>
					</Select>
					{comments && comments.length > 0 && comments.filter(c => c.comment !== '-').length > 0 &&
						<Tooltip title="Client's comments">
							<IconButton
								aria-label="See client's comments"
								onClick={(e) => this.onOpenCommentDialog(comments)}>
								<Feedback />
							</IconButton>
						</Tooltip>
					}
				</Fragment>,
			};
		});

		return (
			<ThemeProvider theme={customTheme}>
				<Grid
					className={classes.table}
					columns={columns}
					rows={rows}
				>
					<Table />
					<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
					<TableHeaderRow />
				</Grid>
				<Fragment>
					<Dialog
						fullWidth={true}
						open={showRoundDetailsDialog}
						onClose={this.hideRoundDetailsDialog}
					>
						<DialogTitle>
							Round details
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<TextFieldMarkdown
								multiline
								label='Description'
								variant="outlined"
								name="description"
								value={roundDescription || ''}
								onChange={(e) => this.updateRoundDescription(e.target.value)}
								inputProps={{ maxLength: 2000 }}
							/>
							<br/>
							<TextFieldMarkdown
								multiline
								label='Special attention'
								variant="outlined"
								name="specialAttention"
								value={roundSpecialAttention || ''}
								onChange={(e) => this.updateRoundSpecialAttention(e.target.value)}
							/>

							<div className={classes.sectionName}>Main image</div>
							<FileGridSelector
								defaultFileIds={round.card.mainImage ? [round.card.mainImage] : []}
								maxFileCount={1}
								maxFileSize={10 * 1024 * 1024}
								onFileIdsChange={(fileIds) => {
									return this.updateRoundMainImage(fileIds.length > 0 ? fileIds[0] : null);
								}}
								size='big'
							/>

							<div className={classes.sectionName}>Secondary images</div>
							<FileGridSelector
								defaultFileIds={roundSecondaryImages}
								maxFileCount={4}
								maxFileSize={10 * 1024 * 1024}
								onFileIdsChange={(fileIds) => {
									return this.updateRoundSecondaryImages(fileIds);
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.hideRoundDetailsDialog}
								disableElevation
							>
								Close
							</Button>
							<Button
								onClick={this.updateRoundDetails}
								color='primary'
								variant='contained'
								disableElevation
							>
								Save changes
							</Button>
						</DialogActions>
					</Dialog>
					<ConfirmationDialog
						title={'Are you sure you want to call the experts again?'}
						content={'This will send emails to experts asking to join the contest again.'}
						primaryText={'Send emails'}
						secondaryText={'Cancel'}
						variant={'contained'}
						open={openCallExpertsDialog}
						onClose={this.onCloseCallExpertsDialog}
						onConfirm={this.onConfirmCallExpertsDialog}
					/>
					<ConfirmationDialog
						title={'Are you sure you want to delete the contest card?'}
						content={'This will permanently delete the contest card.'}
						primaryText={'Delete contest card'}
						secondaryText={'Cancel'}
						color={'secondary'}
						variant={'contained'}
						open={openDeleteContestRoundDialog}
						onClose={this.onCloseDeleteContestRoundDialog}
						onConfirm={this.onConfirmDeleteContestRoundDialog}
					/>
					<Dialog
						fullWidth={true}
						open={openConfirmationDialog}
						onClose={this.onCloseConfirmationDialog}
					>
						<DialogTitle>
							Change contest card confirmation
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<div style={{ marginBottom: '0px' }}>
								{(newClientConfirmed !== PROJECT.CLIENT_CONFIRMATION.DRAFT) ? (
									<Fragment>
										<div>
											You are about to update the round status to <b>"{newClientConfirmed && newClientConfirmed.toUpperCase()}"</b>{ newClientConfirmed !== PROJECT.CLIENT_CONFIRMATION.PENDING ? ' in behalf of a client' : ''}
										</div>

										<div style={{ marginLeft: -10 }}>
											<Checkbox
												checked={sendEmail}
												onChange={(e) => {
													this.setState({
														sendEmail: e.target.checked,
													})
												}}
												name="rememberMe"
												color="primary"
											/> Send emails
										</div>
									</Fragment>
								) : (
									<div>You are about to update a contest card confirmation to <b>"{newClientConfirmed && newClientConfirmed.toUpperCase()}"</b><br/><br/></div>
								)}
							</div>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={this.onCloseConfirmationDialog}
								variant='contained'
								disableElevation
							>
								Cancel
							</Button>
							<Button
								color="primary"
								onClick={this.onSaveConfirmationDialog}
								variant='contained'
								disableElevation
							>
								Save
							</Button>
						</DialogActions>
					</Dialog>
					<Dialog
						fullWidth={true}
						open={openCommentsDialog}
						onClose={this.onCloseCommentDialog}
					>
						<DialogTitle>
							Client's comment on contest card rejection
						</DialogTitle>
						<DialogContent className={classes.dialogContent}>
							<div style={{ paddingBottom: '10px' }}>
								{project.owner.firstName} {project.owner.lastName} wrote:
							</div>
							{openCommentsDialog && openCommentsDialog.map(c => {
								if (c.comment !== '-') {
									return (
										<div style={{ marginBottom: '20px', backgroundColor: '#f2f2f2', padding: '15px', borderRadius: '4px' }}>
											{c.comment}
										</div>
									);
								}
								return null;
							})}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.onCloseCommentDialog} variant='contained' disableElevation>
								Close
							</Button>
						</DialogActions>
					</Dialog>
				</Fragment>
			</ThemeProvider>
		);
	}
}

ContestCardRow.propTypes = {
	classes: PropTypes.object.isRequired,
	round: PropTypes.object.isRequired,
	updateData: PropTypes.func.isRequired,
};

ContestCardRow.defaultProps = {
	classes: {},
	round: {},
	updateData() {},
};

export default withSnackbar(withStyles(styles)(ContestCardRow));
