import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import {
	Collapse, Divider, List, ListItem, ListItemText, ListItemIcon
} from '@material-ui/core';
import {
	AccountBalance, Assessment, Assignment, Build, Contacts,
	Description, ExitToApp, ExpandLess, ExpandMore,
	Face, Folder, Notifications, PeopleAlt, Timeline,
	AttachMoney, SpeakerNotesOutlined,
} from '@material-ui/icons';

import Config from '../config';
import * as actions from '../reducers/user/actions';

const styles = theme => ({
	listItem: {
		paddingTop: "6px",
		paddingBottom: "6px",
	},
	listItemText: {
		fontSize: "0.9rem",
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
});

class AppDrawer extends Component {
	state = {
		openPayments: false,
		openTools: false,
	};

	togglePayments = () => {
		const { openPayments } = this.state;
		this.setState({
			openPayments: !openPayments,
			openTools: false,
		});
	};

	toggleTools = () => {
		const { openTools } = this.state;
		this.setState({
			openPayments: false,
			openTools: !openTools,
		});
	};

	handleOnClick = (...args) => {
		const { onClick } = this.props;
		onClick(args);
	}

	onClickLogout = () => {
		const { logout } = this.props;
		return logout();
	};

	render() {
		const { classes } = this.props;
		const { openPayments, openTools } = this.state;

		return (
			<List>
				<ListItem button component={Link} to="/" onClick={this.handleOnClick} className={`${classes.listItem}`}>
					<ListItemIcon>
						<Timeline />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'KPIs'} />
				</ListItem>
				<ListItem button component={Link} to="/experts" onClick={this.handleOnClick} className={`${classes.listItem}`}>
					<ListItemIcon>
						<Contacts />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Experts'} />
				</ListItem>
				<ListItem button component={Link} to="/projects" onClick={this.handleOnClick} className={`${classes.listItem}`}>
					<ListItemIcon>
						<Folder />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Projects'} />
				</ListItem>
				<ListItem button component={Link} to="/financials/projects" onClick={this.handleOnClick} className={`${classes.listItem}`}>
					<ListItemIcon>
						<AttachMoney />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Financials'} />
				</ListItem>
				<ListItem button onClick={this.togglePayments} className={classes.listItem}>
					<ListItemIcon>
						<AccountBalance />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Payments'} />
					{openPayments ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={openPayments} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem button component={Link} to="/payments"
							onClick={this.handleOnClick}
							className={`${classes.listItem} ${classes.nested}`}>
							<ListItemIcon>
								<PeopleAlt />
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.listItemText }} primary={'Users'} />
						</ListItem>

						<ListItem button component={Link} to="/payments/payouts"
							onClick={this.handleOnClick}
							className={`${classes.listItem} ${classes.nested}`}>
							<ListItemIcon>
								<Description />
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.listItemText }} primary={'Payouts'} />
						</ListItem>
					</List>
				</Collapse>
				<ListItem button onClick={this.toggleTools} className={classes.listItem}>
					<ListItemIcon>
						<Build />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Tools'} />
					{openTools ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={openTools} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItem button component={Link} to="/lbcontent"
							onClick={this.handleOnClick}
							className={`${classes.listItem} ${classes.nested}`}>
							<ListItemIcon>
								<Assignment />
							</ListItemIcon>
							<ListItemText classes={{primary:classes.listItemText}} primary={'LB Content'} />
						</ListItem>
						<ListItem button component={Link} to="/impersonator"
							onClick={this.handleOnClick}
							className={`${classes.listItem} ${classes.nested}`}>
							<ListItemIcon>
								<Face />
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.listItemText }} primary={'Impersonator'} />
						</ListItem>
						<ListItem button component={Link} to="/internal-notifications"
							onClick={this.handleOnClick}
							className={`${classes.listItem} ${classes.nested}`}>
							<ListItemIcon>
								<Notifications />
							</ListItemIcon>
							<ListItemText classes={{ primary: classes.listItemText }} primary={'Int. Notifications'} />
						</ListItem>
					</List>
				</Collapse>
				<Divider />
				<ListItem button component={'a'} href={Config.lastbasic.analytics.host} target="_blank" className={`${classes.listItem}`}>
					<ListItemIcon>
						<Assessment />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Web Analytics'} />
				</ListItem>
				<ListItem button component={'a'} href={Config.requestFormUrl} target="_blank" className={`${classes.listItem}`}>
					<ListItemIcon>
						<SpeakerNotesOutlined />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'New Request'} />
				</ListItem>
				<Divider />
				<ListItem button onClick={this.onClickLogout} className={classes.listItem}>
					<ListItemIcon>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText classes={{ primary: classes.listItemText }} primary={'Logout'} />
				</ListItem>
			</List>
		);
	}
}

AppDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	onClick: PropTypes.func,
	logout: PropTypes.func.isRequired,
};

AppDrawer.defaultProps = {
	classes: {},
	onClick() {},
	logout() {},
};

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(actions.logout()),
});

export default compose(
	connect(
		null,
		mapDispatchToProps,
	),
	withStyles(styles)
)(AppDrawer);
