import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Box, Grid, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

import LoadingBox from '../../../../components/loading-box';
import Stat from '../components/stat';
import Graph from '../components/graph';

import ServerAPI from '../../../../services/server-api';

const groupByMonth = (byMonths, val) => {
	const key = dayjs(val[0]).format('YYYY-MM');
	if (typeof byMonths[key] === 'undefined') byMonths[key] = 0;
	byMonths[key] = byMonths[key] + val[1];
	return byMonths;
};

const accumulate = (acc, i) => {
	const last = (acc.length) ? acc[acc.length - 1][2] : 0;
	acc.push([ i[0], i[1], last + i[1] ]);
	return acc;
};

const styles = (theme) => ({
	root: {
		margin: "10px 20px",
		[theme.breakpoints.down('sm')]: {
			margin: "10px",
		},
	},
	header: {
		alignItems: "center",
		color: "#888",
		margin: "10px 0px",
		
		"& > svg": {
			marginRight: "5px",
			fontSize: "20px"
		},
	},
	title: {
		textAlign: "left",
	},
});

class GlobalPanel extends Component {
	state = {
		expertsChartData: [],
		isLoading: true,
		newExperts: 0,
		newProjects: 0,
		projectsChartData: []
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();
	};

	getMetrics = async () => {
		//const { startTime, endTime } = { ...this.state, ...newState };
		const startTime = new Date('1970-01-01');
		const endTime = new Date('2099-01-01');

		const results = await ServerAPI.getGlobalMetrics({ startTime, endTime });
		if (results.error) return null;

		const thisYear = dayjs().format('YYYY')

		let newExperts = 0;
		for (const item of results.data.experts.experts) {
			if (dayjs(item.createdAt).format('YYYY') === thisYear) {
				newExperts += item.experts;
			}
		}

		let newProjects = 0;
		for (const item of results.data.projects.projects) {
			if (dayjs(item.createdAt).format('YYYY') === thisYear) {
				newProjects += item.projects;
			}
		}

		let expertsChartData = [];
		for (const e of results.data.experts.experts) {
			expertsChartData.push([
				new Date(e.createdAt),
				e.experts,
			]);
		}
		expertsChartData = Object.entries(expertsChartData.reduce(groupByMonth, {}));
		expertsChartData.sort((a, b) => a[0] < b[0] ? -1 : 1);
		expertsChartData = expertsChartData.reduce(accumulate, [])

		let projectsChartData = [];
		for (const e of results.data.projects.projects) {
			projectsChartData.push([
				new Date(e.createdAt),
				e.projects,
			]);
		}
		projectsChartData = Object.entries(projectsChartData.reduce(groupByMonth, {}));
		projectsChartData.sort((a, b) => a[0] < b[0] ? -1 : 1);
		projectsChartData = projectsChartData.reduce(accumulate, [])
		
		return {
			newExperts,
			newProjects,
			expertsChartData,
			projectsChartData
		};
	};

	updateData = async (newState = {}) => {
		const metricsData = await this.getMetrics(newState);
		if (!metricsData) return;

		const {
			newExperts,
			newProjects,
			expertsChartData,
			projectsChartData
		} = metricsData;

		this.setState({
			...newState,
			isLoading: false,
			newExperts,
			newProjects,
			expertsChartData,
			projectsChartData,
		});
	};

	render() {
		const { classes } = this.props;
		const {
			expertsChartData, isLoading, newExperts,
			newProjects, projectsChartData
		} = this.state;

		if (isLoading) {
			return <LoadingBox />;
		}
		
		return (
			<Box className={classes.root}>
				<Box className={classes.header} display="flex">
					<Typography className={classes.title} variant="subtitle1">
						YTD
					</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<Stat
							label={'New Experts'}
							data={newExperts}
							/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Stat
							label={'New Projects'}
							data={newProjects}
							/>
					</Grid>
				</Grid>
				<Box className={classes.header} display="flex">
					<Typography className={classes.title} variant="subtitle1">
						Overall
					</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12}>
						<Graph
							title={'Experts created by month and accumulated'}
							labels={['Months', 'Experts', 'Experts']}
							chartData={expertsChartData}
							/>
					</Grid>
					<Grid item md={6} xs={12}>
						<Graph
							title={'Projects created by month and accumulated'}
							labels={['Months', 'Projects', 'Projects']}
							chartData={projectsChartData}
							/>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default withStyles(styles)(GlobalPanel);
