import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { FiberManualRecord } from '@material-ui/icons';

class ExpertStatus extends Component {
	render() {
		const { user } = this.props;
		const { expert, lastLoggedAt } = user;

		let color;
		let altText = "";
		
		if (expert.ndaSigned && lastLoggedAt) {
			color = "green";
			altText = "Expert has SIGNED an NDA and has LOGGED IN";
		} else if (expert.ndaSigned) {
			color = "yellow";
			altText = "Expert has NOT logged in";
		} else if (lastLoggedAt) {
			color = "yellow";
			altText = "Expert has NOT signed an NDA";
		} else {
			color = "red";
			altText = "Expert has NOT signed an NDA nor logged in";
		}

		return (
			<Tooltip title={altText}>
				<FiberManualRecord style={{ color }}/>
			</Tooltip>
		);
	}
}

ExpertStatus.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
};

ExpertStatus.defaultProps = {
	classes: {},
	user: {},
};

export default ExpertStatus;
