import React from 'react';
import ReactDOM from 'react-dom';
import {
	applyMiddleware, combineReducers, createStore,
} from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { SnackbarProvider } from 'notistack';
import { createTheme, darken, ThemeProvider } from '@material-ui/core/styles';
import { blue, pink } from '@material-ui/core/colors';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import Config from './config';
import AppRoutes from './app-routes';
import rootReducer from './reducers';
import CustomRouter from './components/custom-router';

import './index.css';

const paletteType = 'light';
const customTheme = createTheme({
	/*shadows: ["none"],
	mixins: {
		denseToolbar: {
		  minHeight: 48
		}
	},*/
	palette: {
		primary: {
			main: paletteType === 'light' ? blue[700] : blue[200],
		},
		secondary: {
			main: paletteType === 'light' ? darken(pink.A400, 0.1) : pink[200],
		},
		type: paletteType,
	},
});

const store = createStore(combineReducers(rootReducer), applyMiddleware(thunk));

ReactDOM.render(
	<Provider store={store}>
		<RollbarProvider config={Config.rollbar}>
			<ErrorBoundary>
				<SnackbarProvider maxSnack={3}>
					<ThemeProvider theme={customTheme}>
						<CustomRouter basename={Config.basePath}>
							<AppRoutes />
						</CustomRouter>
					</ThemeProvider>
				</SnackbarProvider>
			</ErrorBoundary>
		</RollbarProvider>
	</Provider>,
	document.getElementById('root')
);
