import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ServerAPI from '../../services/server-api';

const styles = () => ({
	
});

class ProjectSearch extends Component {
	constructor(props) {
		super(props);
		
		this.selectedProjects = [];
		
		this.state = {
			loading: false,
			possibleProjects: [],
		};
	}
	
	setStateSynchronous = (stateUpdate) => {
		return new Promise(resolve => {
			this.setState(stateUpdate, () => resolve());
		});
	};
	
	isMaxSelectedProjects = () => {
		const { maxSelection, defaultValue } = this.props;
		return (maxSelection !== null && maxSelection <= (this.selectedProjects.length + (defaultValue.length || 0)));
	}
	
	updatePossibleProjects = async (newState = {}) => {
		const { onSearch } = this.props;
		const { searchText } = { ...this.state, ...newState };

		if (onSearch) {
			return onSearch(searchText);
		}

		const results = await ServerAPI.getProjects({
			name: searchText
		});
		if (results.error) return [];

		return results.data.projects;
	};
	
	updateData = async (newState = {}) => {
		await this.setStateSynchronous({
			loading: true,
			...newState,
		});
		
		const possibleProjects = await this.updatePossibleProjects(newState);

		// Check if data changed
		if (deepEqual(this.state.possibleProjects, possibleProjects)) {
			return this.setState({
				loading: false,
			});
		};
		
		this.setState({
			loading: false,
			possibleProjects,
		});
	};
	
	handleInputChange = async (_, value) => {
		if (this.isMaxSelectedProjects()) return;
		
		if (value) {
			return this.updateData({
				searchText: value,
			});
		} else {
			this.setState({
				possibleProjects: []
			});
		}
	};
	
	handleAutocompleteChange = async (e, selectedProjects) => {
		const { onChange } = this.props;
		this.selectedProjects = selectedProjects;
		onChange(this.selectedProjects);
	};
	
	render() {
		const { loading, possibleProjects } = this.state;

		return (
			<Autocomplete
				multiple
				filterSelectedOptions
				options={this.isMaxSelectedProjects() ? [] : possibleProjects}
				getOptionLabel={(e) => e.name}
				getOptionSelected={(option, value) => option.id === value.id}
				onInputChange={this.handleInputChange}
				onChange={this.handleAutocompleteChange}
				defaultValue={this.props.defaultValue}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label={this.props.placeholder || 'Search project by name'}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading && <CircularProgress color="inherit" size={20} />}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				)}
			/>
		);
	}
}

ProjectSearch.propTypes = {
	classes: PropTypes.object.isRequired,
	type: PropTypes.string,
	maxSelection: PropTypes.number,
	onSearch: PropTypes.func,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.array,
};

ProjectSearch.defaultProps = {
	classes: {},
	type: null,
	maxSelection: null,
	onSearch: null,
	onChange() {},
	placeholder: null,
	defaultValue: [],
};

export default withStyles(styles)(ProjectSearch);
