import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes, Link } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import {
	AppBar, CssBaseline, Drawer,
	Hidden, IconButton, Toolbar, Tooltip, Typography,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

// KPIs
import GlobalKPIPage from './kpis';

// Expert
import ExpertPage from './expert/view';
import ExpertsPage from './expert/list';
import ExpertEditPage from './expert/edit';

// Project
import ProjectPage from './project/view';
import ProjectsPage from './project/list';
import ProjectEditPage from './project/edit';
import ProjectRawMaterialsPage from './project/raw-materials';
import ProjectContestPage from './project/contest';
import ProjectUpdatesPage from './project/updates';

// Tools
import LBContentPage from './lbcontent';
import ImpersonatorPage from './impersonator';
import InternalNotificationsPage from './internal-notifications';

// Payments
import PaymentsPage from './payments/users';
import PaymentsUserPage from './payments/user';
import PayoutsPage from './payments/payouts';

// Financials
import ProjectsFinancialsPage from './financials/projects-financials';
import ProjectFinancialsViewPage from './financials/project-financials-view-page';

import NotFoundPage from '../errors/not-found';

import Config from '../../config';
import LabIcon from '../../components/icons/lab-icon.svg';
import AppDrawer from '../../components/app-drawer';

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	appBar: {
		//width: `calc(100% - ${drawerWidth}px)`,
	    //marginLeft: drawerWidth,
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	logoImage: {
		display: 'inline-block',
		marginRight: "15px",
		verticalAlign: 'middle',
	},
	title: {
		display: 'inline-block',
		verticalAlign: 'middle',
		flexGrow: 1,
		fontSize: "1.2rem",
		color: '#fff',
		marginTop: '1px',
	},
	toolbar: {
		...theme.mixins.toolbar,
		minHeight: 'auto !important',
		height: '48px',
		[theme.breakpoints.down('sm')]: {
			height: '48px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '48px',
		},
	},
	toolbarSpace: {
		height: '48px',
		minHeight: '48px !important',
		[theme.breakpoints.down('sm')]: {
			height: '48px',
			minHeight: '48px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '48px',
			minHeight: '48px',
		},
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
	},
	contentContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		flexGrow: 1,
	},
	content: {
		height: '100%',
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	devIcon: {
		position: "absolute",
		right: '15px',
		"& img": {
			width: "50px",
			height: "50px",
			padding: "10px",
		},
	},
	envBanner: {
		textAlign: "center",
		background: "#008800",
	},
	bannerSpacing: {
		height: "20px",
		minHeight: "20px",
	},
	logo: {
		paddingTop: '4px',
		paddingBottom: '4px',
	},
});

class DashboardPage extends Component {
	state = {
		mobileOpen: false,
	};
	
	handleDrawerToggle = (val) => {
		const { mobileOpen } = this.state;
		this.setState({ mobileOpen: (typeof val !== 'undefined') ? val : !mobileOpen });
	};
	
	render() {
		const { classes } = this.props;
		const { mobileOpen } = this.state;
		
		const isDev = Config.isDev();
		
		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					{isDev && (
						<div className={classes.envBanner}>This environment is {Config.environment}</div>
					)}
					<Toolbar className={classes.toolbar}>
						<div className={classes.logo}>
							<IconButton
								className={classes.menuButton}
								color="inherit"
								aria-label="Open drawer"
								edge="start"
								onClick={() => this.handleDrawerToggle()}
								>
								<Menu />
							</IconButton>
							<Link to="/">
								<img
									className={classes.logoImage}
									src="/assets/images/logos/lastbasic-icon.svg"
									alt="LB Logo"
									height="25"
									width="25"
									/>
								<Typography variant="h6" className={classes.title}>
									Admin
								</Typography>
							</Link>
						</div>
						{isDev && (
							<Tooltip title={'This environment is for TESTING'}>
								<div className={classes.devIcon}><img alt="Test environment" src={LabIcon} /></div>
							</Tooltip>
						)}
					</Toolbar>
				</AppBar>
				<Hidden xsDown implementation="css">
					<Drawer
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						anchor="left"
						>
						{isDev && (
							<div className={classes.bannerSpacing}></div>
						)}
						<div className={classes.toolbarSpace} />
						<AppDrawer onClick={() => this.handleDrawerToggle(false)} />
					</Drawer>
				</Hidden>
				<Hidden smUp implementation="css">
					<Drawer
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
						anchor="left"
						open={mobileOpen}
						onClose={() => this.handleDrawerToggle()}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						>
						{isDev && (
							<div className={classes.bannerSpacing}></div>
						)}
						<div className={classes.toolbarSpace} />
						<AppDrawer onClick={() => this.handleDrawerToggle(false)} />
					</Drawer>
				</Hidden>
				<div className={classes.contentContainer}>
					{isDev && (
						<div className={classes.bannerSpacing}></div>
					)}
					<div className={classes.toolbarSpace} />
					<div className={classes.content}>
						<Routes>
							<Route exact path="/" element={<GlobalKPIPage />} />
							<Route exact path="/kpis" element={<GlobalKPIPage />} />
							<Route exact path="/experts" element={<ExpertsPage />} />
							<Route exact path="/expert/:id" element={<ExpertPage />} />
							<Route exact path="/expert/:id/edit" element={<ExpertEditPage />} />
							<Route exact path="/projects" element={<ProjectsPage />} />
							<Route exact path="/project/:id" element={<ProjectPage />} />
							<Route exact path="/project/:id/edit" element={<ProjectEditPage />} />
							<Route exact path="/project/:id/rawMaterials" element={<ProjectRawMaterialsPage />} />
							<Route exact path="/project/:id/contest/:type" element={<ProjectContestPage />} />
							<Route exact path="/project/:id/updates" element={<ProjectUpdatesPage />} />
							<Route exact path="/payments" element={<PaymentsPage />} />
							<Route exact path="/payments/users/:userId" element={<PaymentsUserPage />} />
							<Route exact path="/payments/users/:userId/:page" element={<PaymentsUserPage />} />
							<Route exact path="/payments/payouts" element={<PayoutsPage />} />
							<Route exact path="/financials/projects" element={<ProjectsFinancialsPage />} />
							<Route exact path="/financials/projects/:projectId" element={<ProjectFinancialsViewPage />} />
							<Route exact path="/lbcontent" element={<LBContentPage />} />
							<Route exact path="/impersonator" element={<ImpersonatorPage />} />
							<Route exact path="/internal-notifications" element={<InternalNotificationsPage />} />
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</div>
				</div>
			</div>
		);
	}
}

DashboardPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

DashboardPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(DashboardPage);
