import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import * as actions from '../../reducers/user/actions';
import { Auth } from '../../utils';

class AbstractRoute extends Component {
	getLoginData() {
		const { userData, isPrivate, logout, loginSuccess } = this.props;

		// Check tokens
		const tokens = Auth.getStoredTokens();

		console.log('UPDATE');
		console.log(!!userData);
		console.log(!!tokens);

		if (userData && !tokens) {
			logout();
			return null;
		} else if (!userData && tokens) {
			loginSuccess(tokens);
			return null;
		}
		
		const isLoggedIn = !!(tokens && userData);
		const needChange = ((isLoggedIn && !isPrivate) || (!isLoggedIn && isPrivate));

		console.log('----------');
		console.log(isLoggedIn);
		console.log(isPrivate);
		
		console.log('RENDER');

		return {
			needChange,
		};
	}
	
	render() {
		const { redirectTo } = this.props;
		
		const res = this.getLoginData();
		if (!res) return null;
	
		return (
			res.needChange
			? <Navigate to={redirectTo} />
			: <Outlet />
		);
	}
}

AbstractRoute.propTypes = {
	loginSuccess: PropTypes.func.isRequired,
	logout: PropTypes.func.isRequired,
	userData: PropTypes.shape({}),
	isPrivate: PropTypes.bool.isRequired,
	redirectTo: PropTypes.string.isRequired,
};

AbstractRoute.defaultProps = {
	loginSuccess() {},
	logout() {},
	userData: null,
	isPrivate: false,
	redirectTo: '/',
};

const mapStateToProps = ({ user: { data } }) => ({
	userData: data,
});

const mapDispatchToProps = dispatch => ({
	loginSuccess: (data) => dispatch(actions.loginSuccess(data)),
	logout: () => dispatch(actions.logout()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AbstractRoute);
