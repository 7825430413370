import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
	Button, FormControl, InputLabel, MenuItem, Select, Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Chart } from "react-google-charts";

import EXPERT from '../../../../constants/expert';
import ExpertFlags from '../components/expert-flags';
import DateRangePicker from '../../../../components/inputs/date-range-picker';
import DelayedTextField from '../../../../components/inputs/delayed-text-field';

const styles = theme => ({
	filterContainer: {
		display: "flex",
		flexDirection: "row",
		
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	filterColumn: {
		flex: "1",
		
		[theme.breakpoints.down('sm')]: {
			flex: "none",
			width: "100%",
			marginBottom: "10px",
		},
	},
	filterRow: {
		marginBottom: "30px",
		
		"&:last-child": {
			marginBottom: "0px",
		}
	},
	wideInput: {
		width: '100%',
		maxWidth: '300px',
		marginBottom: '10px',
	},
});

class ExpertFilter extends React.Component {
	shouldComponentUpdate(nextProps) {
		const prev = this.props;
		const { startTime, endTime, expertExpertise, summaryExpertsData, name, selectedFlags, typeform, userStatus } = nextProps;
		
		return (startTime !== prev.startTime) || (endTime !== prev.endTime)
			|| (expertExpertise !== prev.expertExpertise) || (summaryExpertsData !== prev.summaryExpertsData)
			|| (name !== prev.name) || (selectedFlags !== prev.selectedFlags)
			|| (typeform !== prev.typeform) || (userStatus !== prev.userStatus);
	}
	
	onStartTimeChange = (date) => {
		const { onStartTimeChange } = this.props;
		onStartTimeChange(date);
	};
	
	onEndTimeChange = (date) => {
		const { onEndTimeChange } = this.props;
		onEndTimeChange(date);
	};
	
	onExpertiseChartReady = ({ chartWrapper }) => {
		let { expertExpertise, summaryExpertsData} = this.props;
		
		expertExpertise = expertExpertise || [];
		
		const selectionArray = expertExpertise.map(e => {
			let findRow = 0;
			for (let i = 0; i < summaryExpertsData.expertExpertises.length; i++) {
				const expertise = summaryExpertsData.expertExpertises[i][0];
				if (expertise === e) {
					findRow = i;
					break;
				}
			}

			return { row: findRow, column: null };
		});
		
		chartWrapper.getChart().setSelection(selectionArray);
	};

	onExpertiseChartSelection = ({ chartWrapper }) => {
		const { onExpertiseChange } = this.props;
		const { summaryExpertsData } = this.props;
		
		const expertExpertises = summaryExpertsData.expertExpertises;
		const selection = chartWrapper.getChart().getSelection().map(s => (
			expertExpertises[s.row][0]
		));

		const expertExpertiseSelected = (selection.length > 0)
			? selection
			: null;
		
		onExpertiseChange(expertExpertiseSelected);
	};
	
	onNameStartChange = (e) => {
		const { value } = e.target;
		const { onNameStartChange } = this.props;
		onNameStartChange(value);
	};
	
	onNameChange = (e) => {
		const { value } = e.target;
		const { onNameChange } = this.props;
		onNameChange(value);
	};

	onFlagsChange = (selectedFlags) => {
		const { onFlagsChange } = this.props;
		onFlagsChange(selectedFlags);
	};

	onTypeformChange = (e) => {
		let { value } = e.target;
		const { onTypeformChange } = this.props;
		if (value === 'any') value = '';
		onTypeformChange(value);
	};
	
	onUserStatusChange = (e) => {
		const { value } = e.target;
		const { onUserStatusChange } = this.props;
		onUserStatusChange(value);
	};
	
	onClearFilter = () => {
		const { onClearFilter } = this.props;
		onClearFilter();
	};
	
	render() {
		const {
			classes,
			startTime, endTime,
			summaryExpertsData,
			name,
			flags,
			typeform,
			userStatus
		} = this.props;
		
		// Add default value to typeforms
		summaryExpertsData.typeforms = summaryExpertsData.typeforms || [];
		const unknownTypeform = summaryExpertsData.typeforms.find(t => !t.formId);
		if (unknownTypeform) {
			unknownTypeform.title = 'Any'
			unknownTypeform.formId = 'any'
		} else {
			summaryExpertsData.typeforms.push({
				title: 'Any',
				formId: 'any',
			});
		}
		summaryExpertsData.typeforms.sort((a, b) => {
			return a.title.localeCompare(b.title);
		});
		
		return (
			<div>
				<DateRangePicker
					container={false}
					startDate={startTime}
					endDate={endTime}
					onChangeStart={this.onStartTimeChange}
					onChangeEnd={this.onEndTimeChange}
					isClearable={true}
					placeholderText={'No date'}
				/>
				<div className={classes.filterContainer}>
					<div className={classes.filterColumn}>
						<Chart
							chartType="PieChart"
							loader={<div>Loading Chart</div>}
							data={[
								['Expertise', 'Quantity'],
								...(summaryExpertsData.expertExpertises || []),
							]}
							options={{
								title: 'Expert Expertise',
							}}
							chartEvents={[
								{
									eventName: "ready",
									callback: this.onExpertiseChartReady.bind(this),
								},
								{
									eventName: "select",
									callback: this.onExpertiseChartSelection.bind(this),
								}
							]}
						/>
						<FormControl className={classes.wideInput}>
							<InputLabel htmlFor="typeform-label">Typeform</InputLabel>
							<Select
								labelId="typeform-label"
								id="typeform"
								name="typeform"
								value={typeform || 'any'}
								onChange={(e) => this.onTypeformChange(e)}
							>
								{(summaryExpertsData.typeforms || []).map(t => (
									<MenuItem key={t.formId} value={t.formId}>
										{t.title}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl className={classes.wideInput}>
							<InputLabel htmlFor="userStatus-label">User Status</InputLabel>
							<Select
								labelId="userStatus-label"
								id="userStatus"
								name="userStatus"
								value={userStatus || EXPERT.USER_STATUS.ANY}
								onChange={(e) => this.onUserStatusChange(e)}
							>	
								{Object.values(EXPERT.USER_STATUS).map(k => (
									<MenuItem key={k} value={k}>
										{EXPERT.userStatusToString(k)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div className={classes.filterColumn}>
						<div className={classes.filterRow}>
							<Typography variant="subtitle2" gutterBottom>
								Expert Name
							</Typography>
							<DelayedTextField
								label="Name"
								value={name}
								onStartChange={(e) => this.onNameStartChange(e)}
								onChange={(e) => this.onNameChange(e)}
							/>
						</div>
						<div className={classes.filterRow}>
							<Typography variant="subtitle2" gutterBottom>
								Expert Flags
							</Typography>
							<ExpertFlags
								editable
								flags={flags || []}
								onChange={(f) => this.onFlagsChange(f)}
							/>
						</div>
						<div className={classes.filterRow}>
							<Typography variant="subtitle2" gutterBottom>
								Actions
							</Typography>
							<Button
								variant="outlined"
								startIcon={<Delete />}
								onClick={e => this.onClearFilter(e)}>
								Clear filters
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ExpertFilter.propTypes = {
	classes: PropTypes.object.isRequired,
	startTime: PropTypes.instanceOf(Date),
	endTime: PropTypes.instanceOf(Date),
	expertExpertise:  PropTypes.array,
	isEarlyAdopter: PropTypes.bool,
	name:  PropTypes.string,
	flags:  PropTypes.array,
	onStartTimeChange: PropTypes.func,
	onEndTimeChange: PropTypes.func,
	onExpertiseChange: PropTypes.func,
	onNameStartChange: PropTypes.func,
	onNameChange: PropTypes.func,
	onFlagsChange: PropTypes.func,
	onClearFilter: PropTypes.func,
}

ExpertFilter.defaultProps = {
	classes: {},
	startTime: null,
	endTime: null,
	expertExpertise: [],
	isEarlyAdopter: null,
	name: null,
	flags: [],
	onStartTimeChange() {},
	onEndTimeChange() {},
	onExpertiseChange() {},
	onNameStartChange() {},
	onNameChange() {},
	onFlagsChange() {},
	onTypeformChange() {},
	onUserStatusChange() {},
	onClearFilter() {},
}

export default withStyles(styles)(ExpertFilter);
