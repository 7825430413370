import React, { useState } from 'react';
import { withSnackbar } from 'notistack';
import { Select, MenuItem, Chip, withStyles } from '@material-ui/core';

import { PAYMENT } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
	select: {
		'&:hover': {
			opacity: "0.8",
		},
		'&:before': {
			border: 'none !important',
        },
        '&:after': {
			border: 'none !important',
        }
	},
	selectChip: {
		cursor: 'pointer',
	},
});

function PayoutStatus({ classes, payout, enqueueSnackbar, onChange = () => {} }) {
	const [currentValue, setCurrentValue] = useState(payout.status || PAYMENT.PAYOUT_STATUS.PENDING);

	async function changeStatus(newValue) {
		const oldValue = currentValue;		
		setCurrentValue(newValue);

		const result = await ServerAPI.changePayoutStatus({ payoutId: payout.id, status: newValue });
		if (result.error) {
			setCurrentValue(oldValue);
			return enqueueSnackbar(result.error.message || 'Payout could not be updated', { variant: 'error' });
		}
		enqueueSnackbar('Payout updated successfully', { variant: 'success' });
		onChange(newValue);
	}

	const payingProgrammatic = (currentValue === PAYMENT.PAYOUT_STATUS.PAYING_PROGRAMMATIC);
	const selectDisabled = payingProgrammatic;

	return (
		<Select className={classes.select} value={currentValue} onChange={(e) => changeStatus(e.target.value)} disabled={selectDisabled}>
			{ payingProgrammatic &&
				<MenuItem value={PAYMENT.PAYOUT_STATUS.PAYING_PROGRAMMATIC}>
					<Chip className={classes.selectChip} label="Paying via Wise" style={{ backgroundColor: 'rgb(56, 82, 125)', color: '#fff' }} />
				</MenuItem>
			}
			<MenuItem value={PAYMENT.PAYOUT_STATUS.NO_PAYMENT_INFO}>
				<Chip className={classes.selectChip} label={"No payment info"} style={{ backgroundColor: '#CF8284', color: '#fff' }} />
			</MenuItem>
			<MenuItem value={PAYMENT.PAYOUT_STATUS.PENDING}>
				<Chip className={classes.selectChip} label="Pending by expert" style={{ backgroundColor: '#FFEC7A' }} />
			</MenuItem>
			<MenuItem value={PAYMENT.PAYOUT_STATUS.APPROVED}>
				<Chip className={classes.selectChip} label="Approved by expert" style={{ backgroundColor: '#7CE3FA' }} />
			</MenuItem>
			<MenuItem value={PAYMENT.PAYOUT_STATUS.PAID}>
				<Chip className={classes.selectChip} label="Paid" style={{ backgroundColor: '#EDC1F3' }} />
			</MenuItem>
			<MenuItem value={PAYMENT.PAYOUT_STATUS.PAYMENT_RECEIVED}>
				<Chip className={classes.selectChip} label="Payment received by expert" style={{ backgroundColor: '#B1FFAD' }} />
			</MenuItem>
			<MenuItem value={PAYMENT.PAYOUT_STATUS.CANCELED}>
				<Chip className={classes.selectChip} label="Canceled" style={{ backgroundColor: '#D4D4D4' }} />
			</MenuItem>
		</Select>
	);
}

export default withSnackbar(withStyles(styles)(PayoutStatus));
