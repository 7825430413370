import React, { Fragment, useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import dayjs from 'dayjs';
import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
	IconButton, Button, TextField, Checkbox,
	Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Input,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import PayoutStatus from '../components/payout-status';
import PayoutInvoice from '../components/payout-invoice';
import PaymentInfoTable from '../components/paymentInfo-table';
import PayButton from '../components/pay-button';
import ServerAPI from '../../../../services/server-api';

import { PAYMENT } from '../../../../constants';

import { ADMIN } from '../../../../constants';
import UserUtils from '../../../../utils/user';

function showWiseOptionsBasedOnUser() {
	return UserUtils.userHasAdminRole([
		ADMIN.ROLES.SUPERADMIN, ADMIN.ROLES.MANAGER,
	]);
}

const styles = theme => ({
	root: {
		textAlign: 'center',
	},
	cardContent: {
		textAlign: 'left',
	},
	ellipsis: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: '300px',
	},
	topButtons: {
		marginBottom: '20px',
		textAlign: 'right',
	},
	note: {
		fontSize: '12px',
		padding: '10px',
		backgroundColor: '#FAEEAD',
		borderRadius: '6px',
		marginBottom: '10px',
		marginTop: '10px',
	},
	paymentInfo: {
		float: 'left',
		padding: '2px 10px 2px 10px',
		border: '1px solid #E6E6E6',
		borderRadius: '50px',
		marginTop: '6px',
		color: '#B5B5B5',
		paddingRight: '30px',
	},
	paymentInfoBubble: {
		position: 'absolute',
		width: '15px',
		height: '15px',
		borderRadius: '100%',
		display: 'inline-block',
		marginTop: '3px',
		marginLeft: '7px',
		opacity: '0.6',
	},
	paymentInfoBubbleGreen: {
		backgroundColor: '#119D1F',
	},
	paymentInfoBubbleRed: {
		backgroundColor: '#B83539',
	},
	pointer: {
		cursor: 'pointer',
	},
	netAmount: {
		color: '#ADADAD',
		fontSize: '12px',
	},
});

function PayoutsPage({ data, classes, enqueueSnackbar, setForceUpdate }) {
	const [dialogCreateShown, setDialogCreateShown] = useState(false);
	const [transactionConcept, setTransactionConcept] = useState('');
	const [transactionAmount, setTransactionAmount] = useState('');
	const [openPaymentInfoDialog, setOpenPaymentInfoDialog] = useState(false);
	const [openPayoutPdfDialog, setOpenPayoutPdfDialog] = useState(false);
	const [useFundsFromWallet, setUseFundsFromWallet] = useState(true);
	const [deleteDialogShown, setDeleteDialogShown] = useState(false);
	const [selectedTransaction, setSelectedTransaction] = useState(null);

	const showWiseOptions = showWiseOptionsBasedOnUser();

	useEffect(() => {
		if (openPayoutPdfDialog) {
			const payouts = data.payouts.filter(p => p.id === openPayoutPdfDialog.id);
			if (payouts.length > 0) setOpenPayoutPdfDialog(payouts[0]);
		}
	}, [data, openPayoutPdfDialog]);

	async function deleteTransaction(payoutId) {
		const result = await ServerAPI.deletePayout({ payoutId });
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Payout could not be deleted', { variant: 'error' });
		}

		setForceUpdate(new Date());
		setDeleteDialogShown(false);
		return enqueueSnackbar('Payout deleted successfully', { variant: 'success' });
	}

	function onDeleteTransaction(transactionId) {
		setSelectedTransaction(transactionId);
		setDeleteDialogShown(true);

		return true;
	}

	async function createTransaction() {
		const result = await ServerAPI.convertWallet({
			userId: data.id,
			amount: transactionAmount,
			concept: transactionConcept,
			createWalletTransaction: (useFundsFromWallet ? false : true),
		});
		if (result.error) {
			return enqueueSnackbar(result.error.message || 'Payout could not be created', { variant: 'error' });
		}

		setForceUpdate(new Date());
		emptyCreateFieldsAndClose();
		return enqueueSnackbar('Payout created successfully', { variant: 'success' });
	}

	function emptyCreateFieldsAndClose() {
		setDialogCreateShown(false);
		setTransactionConcept('');
		setTransactionAmount('');
	}

	function openPaymentDetails() {
		if (data.paymentInfo) return setOpenPaymentInfoDialog(true);
	}
	
	function handleChangePayoutStatus() {
		setForceUpdate();
	}

	return (
		<Fragment>
			<div className={classes.topButtons}>
				<div className={classes.paymentInfo + ' ' + (data.paymentInfo && classes.pointer) } onClick={openPaymentDetails}>
					Payment information <div className={classes.paymentInfoBubble + ' ' + (data.paymentInfo ? classes.paymentInfoBubbleGreen : classes.paymentInfoBubbleRed)}/>
				</div>

				<Button color='primary' variant='outlined' onClick={() => setDialogCreateShown(true)}>
					Create payout
				</Button>
			</div>
			<TableContainer>
				<Table aria-label="simple table" style={{borderTop: '1px solid rgba(224, 224, 224, 1)'}}>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '150px' }}>Date</TableCell>
							<TableCell style={{ width: '130px' }}>Created by</TableCell>
							<TableCell style={{ width: '130px' }}>Updated by</TableCell>
							<TableCell style={{ width: '230px' }}>Status</TableCell>
							<TableCell>Concept</TableCell>
							<TableCell style={{ width: '100px', textAlign: 'right' }}>Amount</TableCell>
							<TableCell style={{ width: '130px', textAlign: 'center' }}>Invoice</TableCell>
							<TableCell style={{ width: '30px', textAlign: 'right' }}>Delete</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{data.payouts.map(r => (
							<TableRow key={r.id}>
								<TableCell>{dayjs(r.createdAt).format('D MMM YYYY')}</TableCell>
								{r.createdBy.firstName ? (
									<TableCell className={classes.ellipsis} title={`${r.createdBy.firstName} ${r.createdBy.lastName} (${r.createdBy.email})`}>
										{`${r.createdBy.firstName} ${r.createdBy.lastName.substr(0, 1)}`}
									</TableCell>
								) : (
									<TableCell>{'-'}</TableCell>
								)}

								{r.updatedBy.firstName ? (
									<TableCell className={classes.ellipsis} title={`${r.updatedBy.firstName} ${r.updatedBy.lastName} (${r.updatedBy.email})`}>
										{`${r.updatedBy.firstName} ${r.updatedBy.lastName.substr(0, 1)}`}
									</TableCell>
								) : (
									<TableCell>{'-'}</TableCell>
								)}

								<TableCell>
									<PayoutStatus
										payout={r}
										onChange={handleChangePayoutStatus}
									/>
									{
											r.status === PAYMENT.PAYOUT_STATUS.APPROVED && showWiseOptions &&
											<PayButton payout={r} onChangeStatus={handleChangePayoutStatus} />
									}
								</TableCell>
								<TableCell className={classes.ellipsis}>{r.concept}</TableCell>
								<TableCell style={{ textAlign: 'right' }}>
									<span style={{ color: (r.amount > 0 ? '#119D1F' : '#B83539')}}>{r.amount}€</span>
									{r.netAmount &&
										<div className={classes.netAmount}>{r.netAmount}€</div>
									}
								</TableCell>
								<TableCell style={{ textAlign: 'right' }}>
									<PayoutInvoice
										payout={r}
										setForceUpdate={setForceUpdate}
									/>
								</TableCell>
								<TableCell style={{ textAlign: 'right' }}>
									<IconButton onClick={() => onDeleteTransaction(r.id) }>
										<Delete/>
									</IconButton>
								</TableCell>

							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<Dialog
				open={dialogCreateShown}
				onClose={() => setDialogCreateShown(false)}
			>
				<DialogTitle>Create payout</DialogTitle>
				<DialogContent>
					<DialogContentText>
						You are about to create a payout for {data.firstName} {data.lastName}:
						<div className={classes.note}>
							This operation will substract <b> real money</b> from the user's wallet ({ data.walletBalance }€)
						</div>

						<div style={{ marginTop: '15px', marginBottom: '5px'}}>
							<Input
								type='number'
								placeholder='Amount (€)'
								value={transactionAmount}
								onChange={(e) => setTransactionAmount(e.target.value)}
								autoFocus={true}
							/>

							<TextField
								placeholder='Payout concept'
								value={transactionConcept}
								onChange={(e) => setTransactionConcept(e.target.value)}
								style={{ marginLeft: '20px', width: '300px' }}
							/>

							<div style={{ marginLeft: '-10px', marginTop: '20px' }}>
								<Checkbox
									checked={useFundsFromWallet}
									onChange={(e) => {
										const { target: { checked } } = e;
										setUseFundsFromWallet(checked);
									}}
									color="primary"
								/>
								Use funds from wallet
							</div>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={emptyCreateFieldsAndClose}>
						Close
					</Button>
					<Button onClick={createTransaction} color="primary" variant='contained' disableElevation>
						Create payout
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openPaymentInfoDialog}
				onClose={() => setOpenPaymentInfoDialog(false)}
			>
				<DialogTitle>Payment information</DialogTitle>
				<DialogContent>
					<PaymentInfoTable
						data={data}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenPaymentInfoDialog(false)}>
						Close
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={deleteDialogShown}
				onClose={() => setDeleteDialogShown(false)}
			>
				<DialogTitle>Delete payout</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you really want to delete this payout?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setDeleteDialogShown(false)}>
						Close
					</Button>
					<Button onClick={() => deleteTransaction(selectedTransaction)} color="secondary" variant='contained' disableElevation>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}

export default withSnackbar(withStyles(styles)(PayoutsPage));
