import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import deepEqual from 'deep-equal';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Card, CardContent, Chip, Checkbox,
	Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography
} from '@material-ui/core';
import {
	ArrowBack, Edit,
	Receipt, CancelOutlined, OpenInNewOutlined,
} from '@material-ui/icons';

import Config from '../../../../config';
import { PROJECT } from '../../../../constants';
import ServerAPI from '../../../../services/server-api';
import EditedByText from '../../../../components/edited-by-text';
import LoadingBox from '../../../../components/loading-box';
import MoreActionsPopup from './more-actions-popup';
import withRouter from '../../../../components/withRouter';
import { HistoryUtils, StringUtils } from '../../../../utils';

const styles = () => ({
	root: {
		textAlign: "center"
	},
	header: {
		marginBottom: "50px",
	},
	table: {
		"& tbody tr td:first-child": {
			whiteSpace: "nowrap",
		},
	},
	editedBy: {
		marginTop: "10px",
	},
	buttons: {
		display: "flex",
		flexDirection: "row",
		textAlign: "left",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
	},
	leftButtons: {
		flex: "1",
	},
	rightButtons: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	imageThumbnail: {
		maxWidth: "100px",
		background: "#eee",
	},
	plan: {
		display: 'inline-block',
		padding: '15px',
		borderRadius: '3px',
		fontSize: '12px',
		/* textTransform: 'uppercase', */
		backgroundColor: '#fff',
		borderColor: '#B5B5B5',
		borderStyle: 'solid',
		borderWidth: '1px',
		marginBottom: '15px',
		marginRight: '10px',
		cursor: 'pointer',
		userSelect: 'none',
	},
	selectedPlan: {
		borderColor: '#0070c9',
		borderWidth: '2px',
	},
	invoiceViaEmailCheck: {
		marginLeft: -10,
		marginTop: 20,
		display: 'block',
	},
	invoiceViaEmailCheckText: {
		userSelect: 'none',
		cursor: 'pointer',
	},
	onboardingSetupBlock: {
		marginBottom: 10,
	},
	onboardingQuestion: {
		fontWeight: 500,
	},
	onboardingAnswer: {
		opacity: 0.7,
	},
});

class ProjectPage extends Component {
	constructor(props) {
		super(props);

		const { location } = props;
		const { data } = location;
		
		this.state = {
			isLoading: !(data && data.project),
			creatingInvoice: false,
			// Data
			project: (data) ? data.project : null,
			
			showDeleteInvoiceModal: false,
			showInvoiceModal: false,
		};
	}

	updateProjectData = async () => {
		const { id } = this.props.match.params;
		if (!id) return;
		
		const results = await ServerAPI.getProject(id);
		if (results.error) return null;
		
		return results.data;
	};

	updateData = async () => {
		const project = await this.updateProjectData();
		
		// Check if data changed
		if (deepEqual(this.state.project, project)) return;
		
		this.setState({
			isLoading: false,
			project,
		});
	};

	componentDidMount = async () => {
		let { project } = this.state;
		if (project) return;
		
		this.updateData();
	};

	goBack = () => {
		HistoryUtils.goBackOrPush({
			pathname: '/projects',
		});
	};
	
	onClickEdit = () => {
		const { navigate } = this.props;
		const { project } = this.state;
		
		navigate({
			pathname: (project) ? '/project/' + project.id + '/edit' : '/projects'
		});
	};
	
	onUpdate = async () => {
		await this.updateData();
	};

	getProjectIdentificationName = (project) => {
		return (project.name !== 'No name') ? (
			project.name
		) : (project.productSolution) ? (
			StringUtils.truncate(project.productSolution)
		) : (
			'Unnamed project'
		);
	};

	showCreateStripeInvoice = () => {
		const { project } = this.state;
		this.setState({
			invoiceDescription: 'Just Mechanics - '
				+ this.getProjectIdentificationName(project)
				+ ' - Generation of Industrial and Engineering designs through the LastBasic Platform',
			invoiceDueDays: 30,
			invoiceAmount: 3960,
			showInvoiceModal: true,
			sendEmail: true,
		});
	};

	closeInvoiceModal = () => {
		this.setState({
			showInvoiceModal: false,
		});
	};

	showDeleteStripeInvoice = () => {
		this.setState({
			showDeleteInvoiceModal: true,
		});
	};

	closeDeleteInvoiceModal = () => {
		this.setState({
			showDeleteInvoiceModal: false,
		});
	};

	createInvoice = async () => {
		this.setState({
			creatingInvoice: true,
		});

		const { enqueueSnackbar } = this.props;
		const { invoiceDescription, invoiceAmount, project, sendEmail } = this.state;

		const amountInCents = invoiceAmount * 100;

		const result = await ServerAPI.createProjectInvoice({
			sendEmail,
			amountInCents,
			userId: project.owner.id,
			projectId: project.id,
			description: invoiceDescription,
		});

		this.setState({
			creatingInvoice: false,
		});

		if (result.error) return enqueueSnackbar(result.error.message || 'Could not create invoice', { variant: 'error' });
		enqueueSnackbar('Invoice has been created', { variant: 'success' });

		await this.updateData();
		return this.closeInvoiceModal();
	};

	deleteInvoice = async () => {
		const { enqueueSnackbar } = this.props;
		const { project } = this.state;

		const result = await ServerAPI.deleteProjectInvoice(project.setupInvoice.id);
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not delete invoice', { variant: 'error' });
		enqueueSnackbar('Invoice has been deleted', { variant: 'success' });

		await this.updateData();
		return this.closeDeleteInvoiceModal();
	};
	
	render() {
		const { classes } = this.props;

		const {
			isLoading,
			showInvoiceModal,
			showDeleteInvoiceModal,
			creatingInvoice,

			// Data
			project,

			// Form
			invoiceDescription,
			invoiceAmount,
			sendEmail,
		} = this.state;
		
		if (isLoading) return <LoadingBox />;
		else if (!project) return <div>Project not found</div>;

		const clientOnboardingPhase = PROJECT.getState(project, PROJECT.STATES.CLIENT_ONBOARDING);
		const phaseSetupQuestions = (clientOnboardingPhase && clientOnboardingPhase.setupQuestions);

		const projectIdentificationName = this.getProjectIdentificationName(project);

		const deliveryAddress = project.owner.maker
			? project.owner.maker.deliveryAddress
			: null;

		return (
			<Fragment>
				<Card className={classes.root} variant="outlined">
					<CardContent>
						<div className={classes.header}>
							<div className={classes.buttons}>
								<div className={classes.leftButtons}>
									<Button
										startIcon={<ArrowBack />}
										onClick={(e) => this.goBack()}>
										Go Back
									</Button>
								</div>
								<div className={classes.rightButtons}>
									<Button
										variant="contained"
										color="primary"
										startIcon={<Edit />}
										onClick={(e) => this.onClickEdit()}>
										Edit
									</Button>
									<MoreActionsPopup
										project={project}
										onUpdate={() => this.onUpdate()}
									/>
								</div>
							</div>
							<Typography variant="h5" gutterBottom>
								Project
							</Typography>
							{project.hidden && (
								<Chip label={'Hidden'} />
							)}
						</div>
						<TableContainer>
							<Table className={classes.table} aria-label="simple table">
								<TableHead>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell><b>Name</b></TableCell>
										<TableCell>
											{project.name}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'name'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Project Code</b></TableCell>
										<TableCell>
											{project.code || '-'}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'number'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Image</b></TableCell>
										<TableCell>
											{project.image ? (
												<img
													className={classes.imageThumbnail}
													src={ServerAPI.getFileUrls(project.image.id).displayUrl}
													alt="Project"
												/>
											) : (
												<span>No image</span>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Client Name</b></TableCell>
										<TableCell>{project.owner.firstName + " " + project.owner.lastName}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Client Email</b></TableCell>
										<TableCell>{project.owner.email}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Client Delivery Address</b></TableCell>
										<TableCell>
											{deliveryAddress ? (
												`${deliveryAddress.street}, ${deliveryAddress.postalCode}, ${deliveryAddress.city}, ${deliveryAddress.country}`
											) : (
												'--'
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Feasibility</b></TableCell>
										<TableCell>{PROJECT.feasibilityToString(project.feasibility)}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Paid</b></TableCell>
										<TableCell>{project.paid ? 'Yes' : 'No'}</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Product Need</b></TableCell>
										<TableCell>
											{project.productNeed}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'productNeed'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Product Solution</b></TableCell>
										<TableCell>
											{project.productSolution}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'productSolution'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Important Attributes</b></TableCell>
										<TableCell>
											{project.importantAttributes}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'importantAttributes'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Ready to pay?</b></TableCell>
										<TableCell>
											{PROJECT.readyToPayToString(project.readyToPay)}
											{project.payReason && " (" + PROJECT.notPayingReasonToString(project.payReason) + ")"}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Competitor link</b></TableCell>
										<TableCell>
											{project.competitionExample || '-'}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'competitionExample'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Next Steps</b></TableCell>
										<TableCell>
											{project.nextSteps || '-'}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'nextSteps'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Admin Comments</b></TableCell>
										<TableCell>
											{project.adminComments || '-'}
											<EditedByText
												className={classes.editedBy}
												object={project}
												fieldName={'adminComments'}
											/>
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Contest order</b></TableCell>
										<TableCell>
											{project.contestOrder ? (
												project.contestOrder.map((value, i) => {
													const labelValue = value.charAt(0).toUpperCase() + value.slice(1);
													return (
														<Chip
															key={i}
															size="small"
															variant="outlined"
															color="primary"
															label={labelValue}
															style={{ marginRight: 5 }}
														/>
													);
												})
											) : (
												<span>{'-'}</span>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Phase setup questions</b></TableCell>
										<TableCell>
											{phaseSetupQuestions && phaseSetupQuestions.map(q => {
												const answers = q.answer;

												return (
													<div key={q.id} className={classes.onboardingSetupBlock}>
														<div className={classes.onboardingQuestion}>{q.question}</div>
														{answers.map(answer => (
															<div key={answer} className={classes.onboardingAnswer}>
																{answer}
															</div>
														))}
													</div>
												);
											})}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Invoice details</b></TableCell>
										<TableCell>
											{project.setupInvoice && (
												<Fragment>
													Status: {project.setupInvoice.status}
													<br/>
													Amount: {project.setupInvoice.amount.amountInCents / 100} {project.setupInvoice.amount.currency}
													<br/>
													Description: {project.setupInvoice.description}
													<br/>
													Created at: {dayjs(project.setupInvoice.createdAt).format('D MMMM YYYY')}
													{project.setupInvoice.status === 'pending' && (
														<Fragment>
															<br/>
															<br/>
															<a href={`${Config.invoices.invoiceBaseUrl}/${project.setupInvoice.id}/${project.setupInvoice.token}`} target={'_blank'} rel={'noreferrer'} style={{ textDecoration: 'none' }}>
																<Button
																	startIcon={<OpenInNewOutlined />}
																	variant={'outlined'}>
																	Open payment gateway
																</Button>
															</a>
															&nbsp;&nbsp;
															<Button
																startIcon={<CancelOutlined />}
																variant={'outlined'}
																onClick={(e) => this.showDeleteStripeInvoice()}>
																Cancel invoice
															</Button>
														</Fragment>
													)}
												</Fragment>
											)}
											{(!project.setupInvoice && !project.paid) && (
												<Button
													startIcon={<Receipt />}
													variant={'outlined'}
													onClick={(e) => this.showCreateStripeInvoice()}>
													Create invoice
												</Button>
											)}
											{(!project.setupInvoice && project.paid) && (
												<span>Project is already marked as paid, but no invoice was provided</span>
											)}
										</TableCell>
									</TableRow>
									<TableRow>
										<TableCell><b>Created at</b></TableCell>
										<TableCell>{dayjs(project.createdAt).format('D MMMM YYYY')}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</TableContainer>
					</CardContent>
				</Card>

				<Dialog
					open={showInvoiceModal}
					onClose={this.closeInvoiceModal}
				>
					<DialogTitle>New invoice</DialogTitle>
					<DialogContent>
						<DialogContentText>
							An invoice will be created and sent to {project.owner.firstName} {project.owner.lastName} ({project.owner.email}) for the project <b>{project.name}</b>. The amount is set in <b>EUR</b>
							<br/><br/>
							<span style={{ fontSize: '12px' }}>Plan:</span>
							<br/>
							<div className={classes.plan + ' ' + (parseInt(invoiceAmount) === 3960 && classes.selectedPlan)} onClick={() => this.setState({ invoiceAmount: 3960, invoiceDescription: 'Just Mechanics - ' + projectIdentificationName + ' - Generation of Industrial and Engineering designs through the LastBasic Platform' })}>Just Mechanics (3960€)</div>
							<div className={classes.plan + ' ' + (parseInt(invoiceAmount) === 5720 && classes.selectedPlan)} onClick={() => this.setState({ invoiceAmount: 5720, invoiceDescription: 'Mechanics and Electronics - ' + projectIdentificationName + ' - Generation of Industrial and Engineering designs through the LastBasic Platform' })}>Mechanics and Electronics (5720€)</div>
							<br/><br/>
							<TextField
								label={'Invoice description'}
								variant={'outlined'}
								value={invoiceDescription}
								onChange={(e) => this.setState({invoiceDescription: e.target.value})}
								style={{ width: '300px'}}
							/>
							&nbsp;&nbsp;
							<TextField
								type={'number'}
								label={'Amount'}
								variant={'outlined'}
								value={invoiceAmount}
								InputProps={{
									endAdornment: <span>EUR</span>,
								}}
								onChange={(e) => this.setState({invoiceAmount: e.target.value})}
								style={{ width: '150px'}}
							/>

							<label className={classes.invoiceViaEmailCheck}>
								<Checkbox
									checked={sendEmail}
									onChange={(e) => {
										this.setState({
											sendEmail: e.target.checked,
										})
									}}
									name="rememberMe"
									color="primary"
								/>
								&nbsp;
								<span className={classes.invoiceViaEmailCheckText}>Send invoice to client via email</span>
							</label>
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeInvoiceModal}>
							Close
						</Button>
						<Button
							color={'primary'}
							disableElevation
							disabled={creatingInvoice}
							onClick={this.createInvoice}
							variant={'contained'}
						>
							{creatingInvoice ? 'Creating...' : 'Create invoice'}
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={showDeleteInvoiceModal}
					onClose={this.closeDeleteInvoiceModal}
				>
					<DialogTitle>Delete invoice</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Do you want to delete the invoice?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.closeDeleteInvoiceModal}>
							Close
						</Button>
						<Button
							color="secondary"
							disableElevation
							onClick={this.deleteInvoice}
							variant="contained"
						>
							Delete invoice
						</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		);
	}
}

ProjectPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ProjectPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(ProjectPage)));
