import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Folder, PeopleAlt, Public, TrackChanges } from '@material-ui/icons';

import { AntTabs, AntTab, TabPanel } from '../../../components/tabs/white-tabs';

import GlobalPanel from './panels/global-panel';
import ExpertsPanel from './panels/experts-panel';
import ProjectsPanel from './panels/projects-panel';

import RadarPanel from './panels/radar-panel';

const TABS = {
	GLOBAL: 'global',
	EXPERTS: 'experts',
	PROJECTS: 'projects',
	RADAR: 'radar',
};

const styles = (theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		textAlign: "center",
		margin: "-16px",
		[theme.breakpoints.down('sm')]: {
			margin: "0px",
		},
	},
	tabs: {
		backgroundColor: theme.palette.background.paper,
	},
	iconTab: {
		"& svg": {
			verticalAlign: "top",
		},
	},
	divider: {
		margin: "8px 0px",
		height: "0px",
	},
	tabPanel: {
		flex: 1,
	},
});

class KPIPage extends Component {
	state = {
		tabSelected: TABS.RADAR,
	}

	onChangeTab = (e, v) => {
		this.setState({ tabSelected: v });
	};
	
	render() {
		const { classes } = this.props;
		const { tabSelected } = this.state;
		
		return (
			<Box className={classes.root}>
				<AntTabs
					className={classes.tabs}
					value={tabSelected}
					onChange={this.onChangeTab}
					variant="scrollable"
					scrollButtons="auto"
					>
					<AntTab
						label={<span className={classes.iconTab}><TrackChanges /> Radar</span>}
						value={TABS.RADAR}/>
					<AntTab
						label={<span className={classes.iconTab}><Public /> Global</span>}
						value={TABS.GLOBAL}/>
					<AntTab
						label={<span className={classes.iconTab}><PeopleAlt /> Experts</span>}
						value={TABS.EXPERTS} />
					<AntTab
						label={<span className={classes.iconTab}><Folder /> Projects</span>}
						value={TABS.PROJECTS} />
				</AntTabs>
				<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.RADAR}>
					<RadarPanel />
				</TabPanel>
				<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.GLOBAL}>
					<GlobalPanel />
				</TabPanel>
				<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.EXPERTS}>
					<ExpertsPanel />
				</TabPanel>
				<TabPanel className={classes.tabPanel} value={tabSelected} index={TABS.PROJECTS}>
					<ProjectsPanel />
				</TabPanel>
			</Box>
		);
	}
}

KPIPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

KPIPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(KPIPage);
