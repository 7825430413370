import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import merge from 'deepmerge';
import deepEqual from 'deep-equal';
import Skeleton from '@material-ui/lab/Skeleton';
import { withSnackbar } from 'notistack';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Card, CardContent, Divider,
	IconButton, Paper, Popover, Typography, Tooltip,
} from '@material-ui/core';
import { Chat, GetApp, KeyboardArrowDown, Launch, Mail } from '@material-ui/icons';
import {
	CustomPaging, EditingState,
	RowDetailState, PagingState, SortingState,
} from '@devexpress/dx-react-grid';
import {
	Grid, Table, TableHeaderRow, TableRowDetail, TableColumnResizing, PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';
import PopupState, { bindToggle, bindPopover } from 'material-ui-popup-state';

import CommentDialog from './comment-dialog';
import { DetailEditCell } from '../../../../components/grid/detail-cell-utils';
import ExpertCell from './expert-cell';
import ExpertDetail from './expert-detail';
import ExpertFlags from '../components/expert-flags';
import ExpertFilter from './expert-filter';
import ExpertLinksPopup from './expert-links-popup';
import ExpertStatus from '../components/expert-status';
import LoadingBox from '../../../../components/loading-box';

import { EXPERT } from '../../../../constants';
import { Sync } from '../../../../utils';
import withRouter from '../../../../components/withRouter';
import ServerAPI from '../../../../services/server-api';

const DEFAULT_FILTER = {
	startTime: null,
	endTime: null,
	expertExpertise: null,
	searchText: null,
	flags: null,
	typeform: null,
	userStatus: null,
};

const DEFAULT_SORTING = [
	{ columnName: "createdAt", direction: "desc" },
];

const styles = () => ({
	root: {
		textAlign: "center",
	},
	cardContent: {
		padding: "16px 0px",
	},
	divider: {
		margin: "16px 0px",
		height: "2px",
	},
	popupState: {
		display: "inline-block",
	},
	infoButton: {
		maxWidth: "20px",
		color: "rgba(0, 0, 0, 0.87)",
		verticalAlign: "middle",
		marginRight: "10px",
	},
	tableActions: {
		margin: "16px 0px",
		
		"& button": {
			margin: "0px 20px",
		},
		"& button:first-child": {
			marginLeft: "0px",
		},
		"& button:last-child": {
			marginLeft: "0px",
		},
	}
});

class ExpertsPage extends Component {
	lastWins = new Sync();

	constructor(props) {
		super(props);

		// Get filter
		const currentFilter = this.getInitialFilter();
		
		this.state = {
			isLoading: true,
			gridLoading: false,
			// Dialog
			selectedExpert: null,
			openCommentDialog: false,
			// Filter
			...currentFilter,
			// Data
			summaryExpertsData: {},
			expertsData: {},
			// Grid
			sorting: DEFAULT_SORTING,
			expandedRowIds: [],
			currentPage: 0,
			pageSize: 20,
		};
	}

	getSearchFilter = () => {
		const { location } = this.props;
		const searchQuery = new URLSearchParams(location.search);

		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			if (!searchQuery.get(p)) continue;
			savedFilter[p] = searchQuery.get(p);
		}

		// Validate & trsnaform
		if (savedFilter.startTime) {
			const d = new Date(savedFilter.startTime);
			if (isNaN(d.getTime())) delete savedFilter.startTime;
			else savedFilter.startTime = d;
		}
		if (savedFilter.endTime) {
			const d = new Date(savedFilter.endTime);
			if (isNaN(d.getTime())) delete savedFilter.endTime;
			else savedFilter.endTime = d;
		}
		if (savedFilter.searchText && typeof savedFilter.searchText !== 'string') {
			delete savedFilter.searchText;
		}
		const experises = Object.values(EXPERT.EXPERTISE);
		if (savedFilter.expertExpertise && !experises.includes(savedFilter.expertExpertise)) {
			delete savedFilter.expertExpertise;
		} else if (savedFilter.expertExpertise) {
			savedFilter.expertExpertise = savedFilter.expertExpertise.split(',');
		}
		const flags = Object.values(EXPERT.FLAGS);
		if (savedFilter.flags && !flags.includes(savedFilter.flags)) {
			delete savedFilter.flags;
		} else if (savedFilter.flags) {
			savedFilter.flags = savedFilter.flags.split(',');
		}

		return savedFilter;		
	};
	
	getInitialFilter = () => {
		const searchFilter = this.getSearchFilter();

		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			savedFilter[p] = searchFilter[p] || DEFAULT_FILTER[p];
		}

		return savedFilter;
	};
	
	saveCurrentFilter = (newParams) => {
		const { navigate } = this.props;
		const searchFilter = this.getSearchFilter();
		
		const savedFilter = {};
		const params = Object.keys(DEFAULT_FILTER);
		for (const p of params) {
			const value = (typeof newParams[p] !== 'undefined') ? newParams[p] : searchFilter[p];
			if (!value) continue;
			savedFilter[p] = (value instanceof Date)
				? value.getFullYear() + '-' + (value.getMonth() + 1) + '-' + value.getDate()
				: value;
		}
		
		navigate({
			search: '?' + new URLSearchParams(savedFilter).toString()
		});
	};

	setStateSynchronous = (stateUpdate) => {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    };

	updateSummaryExpertsData = async (newState = {}) => {
		const {
			startTime, endTime,
			name, searchText, flags, typeform, userStatus,
			sorting, currentPage, pageSize
		} = { ...this.state, ...newState };
		
		const results = await ServerAPI.getSummaryExperts({
			startTime, endTime,
			name, searchText, flags, typeform, userStatus,
			sorting, currentPage, pageSize
		});
		if (results.error) return {};

		results.data.expertExpertises.sort((a, b) => (a && a[0] && b && b[0] ? a[0].localeCompare(b[0]) : 1));

		return results.data;
	};
	
	updateExpertsData = async (newState = {}) => {
		const {
			startTime, endTime,
			expertExpertise, name, searchText, flags,
			typeform, userStatus,
			sorting, currentPage, pageSize
		} = { ...this.state, ...newState };

		const results = await ServerAPI.getExperts({
			startTime, endTime,
			expertExpertise, name, searchText, flags,
			typeform, userStatus,
			sorting, currentPage, pageSize
		});
		if (!results.data) return {};
		
		return results.data;
	};

	updateData = async (newState = {}) => {
		const key = this.lastWins.enter();
		
		await this.setStateSynchronous({
			gridLoading: true,
			...newState,
		});
		this.saveCurrentFilter(newState);
		
		const summaryExpertsData = await this.updateSummaryExpertsData(newState);
		const expertsData = await this.updateExpertsData(newState);

		// Get out of here
		const isLast = this.lastWins.exit(key);
		if (!isLast) return;

		// Check if data changed
		if (deepEqual(this.state.summaryExpertsData, summaryExpertsData)
			&& deepEqual(this.state.expertsData, expertsData)) {
			return this.setState({
				isLoading: false,
				gridLoading: false,
			});
		}

		this.setState({
			isLoading: false,
			gridLoading: false,
			summaryExpertsData,
			expertsData,
		});
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();
	};

	onStartTimeChange = (date) => {
		this.updateData({
			startTime: date,
			expandedRowIds: [],
			currentPage: 0,
		});
	};
	
	onEndTimeChange = (date) => {
		this.updateData({
			endTime: date,
			expandedRowIds: [],
			currentPage: 0,
		});
	};
	
	onExpertiseChange = (expertise) => {
		this.updateData({
			expertExpertise: expertise,
			expandedRowIds: [],
			currentPage: 0,
		});
	};
	
	onNameStartChange = () => {
		// Deactivate other workers
		this.lastWins.clear();
	};
	
	onNameChange = (text) => {
		this.updateData({
			searchText: text,
			expandedRowIds: [],
			currentPage: 0,
		});
	};
	
	onFlagsChange = (flags) => {
		this.updateData({
			flags,
			expandedRowIds: [],
			currentPage: 0,
		});
	};
	
	onTypeformChange = (typeform) => {
		this.updateData({
			typeform,
			expandedRowIds: [],
			currentPage: 0,
		});		
	};

	onUserStatusChange = (userStatus) => {
		this.updateData({
			userStatus,
			expandedRowIds: [],
			currentPage: 0,
		});		
	};
	
	onClearFilter = () => {
		this.updateData({
			...DEFAULT_FILTER,
			expandedRowIds: [],
			currentPage: 0,
		});
	};
	
	getAllExperts = async () => {
		const {
			startTime, endTime,
			expertExpertise, name, searchText, flags,
			typeform, userStatus
		} = this.state;
		
		const results = await ServerAPI.getAllExperts({
			startTime, endTime,
			expertExpertise, name, searchText, flags,
			typeform, userStatus
		});
		if (results.error) return null;
	
		return results.data.experts;
	}
	
	forceDownload = (data, filename = null) => {
		const encodedUri = encodeURI(data);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		if (filename) link.setAttribute("download", "expert_list.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
	
	onExportToCSVClick = async () => {
		const experts = await this.getAllExperts();
		if (!experts) return;
		let csvContent = "data:text/csv;charset=utf-8,";
		csvContent += "Fisrt name,Last name,Email,Signed NDA,Logged in,Expertise,Created at\n";
		csvContent += experts.map(e => {
			return `${e.firstName},${e.lastName},${e.email},${EXPERT.hasSignedNdaToString(e)},${EXPERT.hasLoggedInToString(e)},${e.expert.expertExpertise},${e.createdAt}`;
		}).join("\n");
		this.forceDownload(csvContent, "expert_list.csv");
	};
		
	onExportToMailto = async () => {
		const experts = await this.getAllExperts();
		if (!experts) return;
		if (experts.length > 100) {
			const { enqueueSnackbar } = this.props;
			enqueueSnackbar('Too many mails to use mailto. Downloading emails to file', { variant: 'info' });
			let csvContent = "data:text/csv;charset=utf-8,";
			csvContent += experts.map(e => e.email).join("; ");
			this.forceDownload(csvContent, "expert_mails.csv");
			return;
		}
		const expertsMails = experts.map(e => e.email).join(',');		
		this.forceDownload('mailto:?bcc=' + expertsMails);
	};

	onExpandedRowIdsChange = (expandedRowIds) => {
		this.setState({
			expandedRowIds,
		});
	};

	onCurrentPageChange = (v) => {
		this.updateData({ currentPage: v, expandedRowIds: [] });
	}
	
	onPageSizeChange = (v) => {
		this.updateData({ pageSize: v, expandedRowIds: [], currentPage: 0 });
	};

	onSortingChange = (v) => {
		this.updateData({ sorting: v, expandedRowIds: [], currentPage: 0 });
	};
	
	getDefaultColumnWidths = (columns) => {
		const widthsMap = { email: 180, expertExpertise: 180 };
		const defaultWidths = Object.fromEntries(columns.map(c => {
			return [
				c.name,
				widthsMap[c.name] || ((c.title.split(' ').length > 1) ? 110 : 90),
			];
		}));
		
		return Object.entries(defaultWidths).map(([k, v]) => ({
			columnName: k,
			width: v,
		}));
	};
	
	onOpenCommentDialog = (e, expert) => {
		this.setState({
			openCommentDialog: true,
			selectedExpert: expert,
		});
	};

	onCloseCommentDialog = () => {
		this.setState({
			openCommentDialog: false,
			selectedExpert: null,
		});
	};

	onConfirmCommentDialog = async (newComment) => {
		const { enqueueSnackbar } = this.props;
		const { selectedExpert } = this.state;

		this.onCloseCommentDialog();

		// Fake the event click
		const result = await ServerAPI.updateExpert(selectedExpert.id, {
			comment: newComment
		});
		if (result.error) return enqueueSnackbar(result.error.message || 'Could not update comment', { variant: 'error' });
		enqueueSnackbar('Expert updated', { variant: 'success' });

		// Reload list
		this.updateData();
	};
	
	render() {
		const { classes } = this.props;
		const {
			isLoading,
			gridLoading,
			// Dialog
			selectedExpert,
			openCommentDialog,
			// Filter
			startTime, endTime,
			expertExpertise,
			searchText,
			flags,
			typeform,
			userStatus,
			// Data
			summaryExpertsData,
			expertsData,
			// Grid
			sorting,
			expandedRowIds,
			currentPage,
			pageSize,
		} = this.state;
		
		const columns = [
			{ name: 'actions', title: 'Actions' },
			{ name: 'status', title: 'Status' },
			{ name: 'flags', title: 'Flags' },
			{ name: 'name', title: 'Full Name' },
			{ name: 'email', title: 'Email' },
			{ name: 'expertExpertise', title: 'Expertise' },
			{ name: 'notes', title: 'Additional Notes' },
			{ name: 'createdAt', title: 'Created at' },
		];
		
		// Get default widths from preferences
		const defaultColumnWidths = this.getDefaultColumnWidths(columns);
		
		const columnExtensions = [];
		
		const sortingStateColumnExtensions = [
			{ columnName: 'actions', sortingEnabled: false },
			{ columnName: 'status', sortingEnabled: false },
			{ columnName: 'flags', sortingEnabled: false },
			{ columnName: 'name', sortingEnabled: false },
		];
		
		let rows = [];
		if (gridLoading) {
			// Create a skeleton for each cell
			for (let i = 0; i < pageSize; i++) {
				rows.push(Object.fromEntries(columns.map(c => {
					return [c.name, (<Skeleton width="100%"><Typography>&nbsp;</Typography></Skeleton>)];
				})));
			}
		} else if (expertsData.experts && expertsData.experts.length > 0) {
			rows = expertsData.experts.map(u => {
				return {
					user: u,
					actions: <Tooltip title="Open">
						<Link to={'/expert/' + u.id} params={{ user: u }}>
							<IconButton aria-label="open">
								<Launch />
							</IconButton>
						</Link>
					</Tooltip>,
					status: <ExpertStatus user={u} />,
					flags: <ExpertFlags flags={u.expert.flags} />,
					name: <div>
						{u.firstName + " " + u.lastName}
					</div>,
					email: u.email,
					expertExpertise: <div>
						<span>{EXPERT.expertiseToString(u.expert.expertExpertise)}</span>
					</div>,
					createdAt: dayjs(u.expert.createdAt).format('D MMM YYYY'),
					ndaSigned: u.expert.ndaSigned,
					typeformName: u.expert.form && u.expert.form.formResponse.definition.title,
					lastLoggedAt: u.lastLoggedAt && dayjs(u.lastLoggedAt).format('D MMM YYYY'),
					viewMode: u.viewMode,
					notes: <div>
						<Tooltip title={u.expert.comment || '--'}>
							<IconButton aria-label="open" onClick={(e) => this.onOpenCommentDialog(e, u)}>
								<Chat />
							</IconButton>
						</Tooltip>
						<PopupState variant="popover">
							{(popupState) => (
								<div className={classes.popupState}>
									<IconButton aria-label="open" {...bindToggle(popupState)}>
										<KeyboardArrowDown />
									</IconButton>
									<Popover {...bindPopover(popupState)}>
										<Paper style={{ padding: '10px', background: "#fafafa" }}>
											<ExpertLinksPopup user={u} />
										</Paper>
									</Popover>
								</div>
							)}
						</PopupState>
					</div>,
				};
			});
		}
		
		const commitChanges = ({ changed }) => {
			expertsData.experts = expertsData.experts.map((u, i) => {
				const changes = changed[i];
				if (!changes) return u;
				return merge(u, changes);
			});
			// Update
			this.setState({ expertsData });
		};
		
		if (isLoading) {
			return <LoadingBox />;
		}
		
		return (
			<Card className={classes.root} variant="outlined">
				<CardContent className={classes.cardContent}>
					<Typography variant="h5" gutterBottom>
						Experts
					</Typography>
					<ExpertFilter
						startTime={startTime}
						endTime={endTime}
						expertExpertise={expertExpertise}
						summaryExpertsData={summaryExpertsData}
						name={searchText}
						flags={flags}
						typeform={typeform}
						userStatus={userStatus}
						onStartTimeChange={this.onStartTimeChange}
						onEndTimeChange={this.onEndTimeChange}
						onExpertiseChange={this.onExpertiseChange}
						onNameStartChange={this.onNameStartChange}
						onNameChange={this.onNameChange}
						onFlagsChange={this.onFlagsChange}
						onTypeformChange={this.onTypeformChange}
						onUserStatusChange={this.onUserStatusChange}
						onClearFilter={this.onClearFilter}
					/>
					<Divider className={classes.divider} />
					<div className={classes.tableActions}>
						<Button
							variant="outlined"
							color="primary"
							startIcon={<GetApp />}
							onClick={this.onExportToCSVClick}>
							Download to CSV
						</Button>
						<Button
							variant="outlined"
							color="primary"
							startIcon={<Mail />}
							onClick={this.onExportToMailto}>
							Mailito
						</Button>
					</div>
					<Grid
						columns={columns}
						rows={rows}
					>
						<RowDetailState
							expandedRowIds={expandedRowIds}
							onExpandedRowIdsChange={this.onExpandedRowIdsChange}
						/>
						<EditingState
							onCommitChanges={commitChanges}
						/>
						<PagingState
							currentPage={currentPage}
							onCurrentPageChange={this.onCurrentPageChange}
							pageSize={pageSize}
							onPageSizeChange={this.onPageSizeChange}
						/>
						<CustomPaging
							totalCount={expertsData.totalCount}
						/>
						<SortingState
							sorting={sorting}
							columnExtensions={sortingStateColumnExtensions}
							onSortingChange={this.onSortingChange}
						/>
						<Table
							cellComponent={ExpertCell}
							columnExtensions={columnExtensions}
						/>
						<TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
						<TableHeaderRow showSortingControls />
						<TableRowDetail contentComponent={ExpertDetail} />
						<DetailEditCell />
						<PagingPanel
							pageSizes={[20, 30, 40, 50]}
						/>
					</Grid>
				</CardContent>
				<CommentDialog
					project={selectedExpert}
					open={openCommentDialog}
					onClose={this.onCloseCommentDialog}
					onSubmit={this.onConfirmCommentDialog}
				/>
			</Card>
		);
	}
}

ExpertsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ExpertsPage.defaultProps = {
	classes: {},
};

export default withSnackbar(withStyles(styles)(withRouter(ExpertsPage)));
