function boolean (value) {
	return [true, 'true', 1, '1'].includes(value);
}

module.exports = {
	basePath: '/',
	environment: process.env.REACT_APP_ENVIRONMENT || 'local',
	isDev: function () {
		return this.environment !== 'production';
	},
	invoices: {
		invoiceBaseUrl: process.env.REACT_APP_INVOICE_BASE_URL || 'http://localhost:3000/invoices',
	},
	lastbasic: {
		api: {
			host: process.env.REACT_APP_LASTBASIC_API_HOST || 'http://localhost:8022',
			key: process.env.REACT_APP_LASTBASIC_API_KEY || '5e8b4ee98ea8856804d02aff'
		},
		platform: {
			host: process.env.REACT_APP_LASTBASIC_PLATFORM_HOST || 'http://localhost:3000'
		},
		analytics: {
			host: process.env.REACT_APP_LASTBASIC_ANALYTICS_HOST || 'http://localhost:3002'
		},
	},
	rollbar: {
		enabled: boolean(process.env.REACT_APP_ROLLBAR_ENABLED),
		accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN || '0d35f47201ba4a8aa66fc08504b9ed84',
		environment: process.env.REACT_APP_ENVIRONMENT || 'local',
		captureUncaught: true,
		captureUnhandledRejections: true
	},
	sso: {
		enabled: boolean(process.env.REACT_APP_SSO_ENABLED),
	},
	requestFormUrl: 'https://lastbasic.typeform.com/to/k4xy5eB3',
};
