import React, { Component } from 'react';
import PropTypes from 'prop-types';

import dayjs from 'dayjs';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';

const styles = () => ({
	container: {
		fontStyle: "italic",
	},
	chip: {
		height: "auto",
		padding: "3px 10px",

		"& span": {
			padding: "0px",
		}
	},
});

class EditedByText extends Component {
	render() {
		const { classes, className, object, fieldName } = this.props;
		const field = object.editions[fieldName];
		const name = (field && field.author && field.author.firstName) || 'unknown';
		return (
			<div className={classes.container + " " + className}>
				{field && field.updatedAt ? (
					<Chip
						className={classes.chip}
						label={'Edited by ' + name + ' (on ' + dayjs(field.updatedAt).format('D MMMM YYYY') + ')'}
					/>
				) : (
					<Chip
						className={classes.chip}
						label={'Original text'}
					/>
				)}
			</div>
		);
	}
}

EditedByText.propTypes = {
	className: PropTypes.string,
	object: PropTypes.object.isRequired,
	fiedlName: PropTypes.string.isRequired,
};

EditedByText.defaultProps = {
	className: "",
	object: {},
	fiedlName: "",
};

export default withStyles(styles)(EditedByText);
